import React, { useEffect, useState } from "react";
import {
  Keyboard,
  TouchableOpacity,
  TouchableWithoutFeedback,
  useWindowDimensions
} from "react-native";
import { Text, View } from "../../components/Themed";
import { colorBackground, colorTextRed } from "../../utils/colors";
import {
  ArrowUpTrayIcon,
  ChevronLeftIcon
} from "react-native-heroicons/outline";
import { SafeAreaView } from "react-native-safe-area-context";
import PrimaryButton from "../../components/ThemedComponents/PrimaryButton";
import i18n from "../../config/languageInternationalization";
import Input from "../../components/ThemedComponents/Input";
import { isAndroid, isWeb } from "../../utils/platform";
import { getUserPaymentData } from "../../redux/slices/userPaymentDataThunk";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { resetUserPaymentData } from "../../redux/slices/userPaymentData";
import { isEmail } from "../../utils/validation";
import { ActivityIndicator } from "react-native";
import { P2PTransferStackNavigatorParamList } from "../../../types/navigationTypes";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { TransferType } from "../../../types/transfer";
import { isEvmAddress, isSolAddress } from "../../utils/web3";
import { SupportedChains } from "../../utils/supportedChains";
import { UserPaymentData } from "../../../types/userPaymentData";
import { useForm, Controller } from "react-hook-form";
import { Balance } from "../../../types/balances";
import { recordError } from "../../utils/crashlytics";
import { Alert } from "../../utils/Alert";
import { navigateToDetail } from "../../components/TransferScreen/utils/navigateToDetail";
import { notEnoughFundsAlert } from "../AmountScreen";
import { feesAndLimits, preferredChains } from "../../config/P2PTransfers";

export enum IdentifierType {
  email = "email",
  wallet = "wallet"
}

interface FormData {
  identifier: string;
  reason: string;
}

export default function TransferScreen({
  navigation,
  route
}: NativeStackScreenProps<P2PTransferStackNavigatorParamList, "Transfer">) {
  const { type, tokenSymbol, amount } = route.params;
  const { walletsBalanceTokens, walletsBalanceTotal } = useAppSelector(
    (state) => state.user
  );
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const screenWidth = useWindowDimensions().width;

  useEffect(() => {
    return () => {
      dispatch(resetUserPaymentData());
    };
  }, []);

  const selectChainForTransfer = (identifier: string) => {
    if (isWalletAddress(identifier)) {
      // If it's a wallet we check if it's a Solana wallet, if not we use the highest balance EVM chain.
      if (isSolAddress(identifier)) return SupportedChains.SOLANA;
      const highestBalanceChain = findChainForTransfer(
        walletsBalanceTokens,
        walletsBalanceTotal,
        amount,
        true
      );
      return highestBalanceChain;
    }

    // It's an email
    const chain = findChainForTransfer(
      walletsBalanceTokens,
      walletsBalanceTotal,
      amount
    );

    return chain;
  };

  function TransferCTA() {
    if (isLoading === true) return <ActivityIndicator color={"white"} />;
    // isLoading is false
    if (type === TransferType.PAY) return i18n.t("amountScreenPay");
    if (type === TransferType.RECEIVE)
      return i18n.t("transferDetailScreenRequest");
  }

  async function sendOrRequestPayment({ identifier, reason }: FormData) {
    try {
      setIsLoading(true);
      let chain: SupportedChains | undefined;
      if (type === TransferType.PAY) {
        chain = selectChainForTransfer(identifier);
        if (!chain) return;
      }

      const identifierType = isEmail(identifier)
        ? IdentifierType.email
        : IdentifierType.wallet;

      if (identifierType === IdentifierType.email) {
        const userPaymentData = (await dispatch(
          getUserPaymentData(identifier)
        ).unwrap()) as UserPaymentData | undefined;

        if (!userPaymentData) return;

        return navigateToDetail({
          navigation,
          type,
          receiver: {
            address: identifier,
            isUser: true,
            isWallet: false,
            userPaymentData
          },
          chain,
          amount,
          tokenSymbol,
          message: reason
        });
      } else {
        return navigateToDetail({
          navigation,
          type,
          receiver: {
            address: identifier,
            isUser: false,
            isWallet: true
          },
          chain,
          amount,
          tokenSymbol,
          message: undefined
        });
      }
    } catch (error) {
      recordError(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <TouchableWithoutFeedback onPress={() => !isWeb() && Keyboard.dismiss()}>
      <View style={{ backgroundColor: colorBackground, flex: 1 }}>
        <SafeAreaView
          edges={!isAndroid() ? ["bottom"] : ["top"]}
          style={{ flex: 1, backgroundColor: colorBackground }}
        >
          {/* Header */}
          <View
            style={{
              flexDirection: "row",
              paddingVertical: 20,
              paddingHorizontal: 16,
              width: isWeb() ? screenWidth * 0.5 : undefined,
              minWidth: isWeb() ? 400 : undefined,
              alignSelf: isWeb() ? "center" : undefined
            }}
          >
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <ChevronLeftIcon size={24} color="white" />
            </TouchableOpacity>
            <View style={{ flex: 1, alignItems: "center" }}>
              <Text
                style={{
                  fontSize: 24,
                  fontWeight: "500",
                  textAlign: "center"
                }}
              >
                {tokenSymbol} {amount}
              </Text>
            </View>
          </View>
          <View style={{ paddingHorizontal: 16 }}>
            {/* To/From Input Section */}
            <View
              style={{
                flexDirection: "row",
                marginTop: 5,
                width: isWeb() ? screenWidth * 0.5 : undefined,
                minWidth: isWeb() ? 400 : undefined,
                alignSelf: isWeb() ? "center" : undefined
              }}
            >
              <Text
                style={{
                  flex: 4,
                  maxWidth: 80,
                  fontSize: 20,
                  marginTop: type != TransferType.PAY ? 10 : 0,
                  alignSelf: "center"
                }}
              >
                {type == TransferType.PAY
                  ? i18n.t("transferScreenTo")
                  : i18n.t("transferScreenFrom")}
              </Text>
              <View style={{ flex: 1 }}>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      style={
                        errors.identifier ? { borderColor: colorTextRed } : {}
                      }
                      onBlur={onBlur}
                      onChangeText={onChange}
                      defaultValue={value}
                      placeholder={i18n.t("transferScreenNameEmailPhone")}
                    />
                  )}
                  name="identifier"
                  rules={{
                    required: true,
                    validate: (value) => validateIdentifier(value, type)
                  }}
                  defaultValue=""
                />
                {errors.identifier?.message && (
                  <Text style={{ color: colorTextRed }}>
                    {i18n.t(errors.identifier.message)}
                  </Text>
                )}
              </View>
            </View>
            {/* For Input Section */}
            <View
              style={{
                flexDirection: "row",
                marginTop: 15,
                width: isWeb() ? screenWidth * 0.5 : undefined,
                minWidth: isWeb() ? 400 : undefined,
                alignSelf: isWeb() ? "center" : undefined
              }}
            >
              <Text
                style={{
                  flex: 1,
                  maxWidth: 80,
                  fontSize: 20,
                  alignSelf: "center"
                }}
              >
                {i18n.t("transferScreenFor")}
              </Text>
              <View style={{ flex: 1 }}>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      style={errors.reason ? { borderColor: colorTextRed } : {}}
                      onBlur={onBlur}
                      onChangeText={onChange}
                      value={value}
                      multiline={true}
                      numberOfLines={4}
                    />
                  )}
                  name="reason"
                  rules={{ required: true }}
                  defaultValue=""
                />
                {errors.reason && (
                  <Text style={{ color: colorTextRed }}>
                    {i18n.t("transferScreenEnterReason")}
                  </Text>
                )}
              </View>
            </View>
          </View>
          {/* Send Button Section */}
          <PrimaryButton
            accessibilityLabel="payP2pBtn"
            style={{
              marginTop: 40,
              marginHorizontal: 16,
              alignSelf: isWeb() ? "center" : undefined,
              width: isWeb() ? screenWidth * 0.5 : undefined,
              minWidth: isWeb() ? 400 : undefined
            }}
            onPress={handleSubmit(sendOrRequestPayment)}
            isDisabled={isLoading}
          >
            {TransferCTA()}
          </PrimaryButton>
        </SafeAreaView>
      </View>
    </TouchableWithoutFeedback>
  );
}

const isWalletAddress = (identifier: string): boolean =>
  isEvmAddress(identifier) || isSolAddress(identifier);
// Validate the to/from field to be either an email or a wallet address, in the case of wallet address, the transfer type must be pay. Returns an error message as the value of errors.identifier.message if invalid, true if valid
function validateIdentifier(identifier: string, type: TransferType) {
  if (isEmail(identifier)) return true;
  if (isWalletAddress(identifier)) {
    if (type === TransferType.PAY) return true;
    return "transferScreenInvalidType"; // If it's a wallet address, transfer type must be pay
  }
  return "transferScreenInvalidAddress";
}

export function findChainForTransfer(
  balanceTokens: Balance[],
  balanceTotal: number,
  amount: number,
  onlyEVM = false
) {
  if (!balanceTotal) {
    notEnoughFundsAlert(amount);
    return undefined;
  }
  const balances = calculateBalances(balanceTokens, onlyEVM);
  return findSuitableChain(balances, amount, balanceTotal);
}

function calculateBalances(balanceTokens: Balance[], onlyEVM = false) {
  const balanceByChain: { [chain: string]: { total: number; usdc: number } } =
    {};
  balanceTokens.forEach((balance) => {
    const { chain, symbol } = balance.tokenMetadata;
    if (onlyEVM && chain === SupportedChains.SOLANA) return;
    const balancePrice = Number(balance.price);
    if (!balanceByChain[chain]) {
      balanceByChain[chain] = { total: 0, usdc: 0 };
    }

    balanceByChain[chain].total += balancePrice;
    if (symbol === "USDC") {
      balanceByChain[chain].usdc += balancePrice;
    }
  });

  return balanceByChain;
}

function findSuitableChain(
  balances: { [chain: string]: { total: number; usdc: number } },
  amount: number,
  balanceTotal: number
) {
  try {
    const amountWithSwapFee = amount * feesAndLimits.swapFeeMultiplier;
    const amountWithBridgingFee = amount * feesAndLimits.bridgingFeeMultiplier;
    const minimumEthereumAmount = feesAndLimits.minimumEthereumAmount;

    const sortedChains = Object.keys(balances).sort(
      (a, b) => balances[b].total - balances[a].total
    ) as SupportedChains[];
    const highestBalanceChain = sortedChains[0];
    const secondHighestBalanceChain = sortedChains[1];
    // Check first for a chain with enough USDC for a direct transfer
    for (const chain of sortedChains) {
      const { usdc } = balances[chain];
      if (
        usdc > amount &&
        (chain !== SupportedChains.ETHEREUM || amount >= minimumEthereumAmount)
      ) {
        return chain;
      }
    }

    // No chain has enough USDC for transfer, we'll check if a preferred chain has enough balance for a swap
    for (const chain of preferredChains) {
      if (balances[chain] && balances[chain].total >= amountWithSwapFee) {
        return chain;
      }
    }

    // No chain has enough USDC for transfer and no preferred chain has enough balance for a swap, we'll select the chain with the highest balance if the surplus are covered

    if (
      balances[highestBalanceChain].total < amountWithSwapFee &&
      balanceTotal < amountWithBridgingFee
    ) {
      throw new Error("transferScreenNotEnoughFundsDirectOrBridge");
    }

    if (
      highestBalanceChain === SupportedChains.ETHEREUM &&
      amount < minimumEthereumAmount
    ) {
      if (balances[secondHighestBalanceChain].total >= amountWithSwapFee) {
        return secondHighestBalanceChain;
      }
      throw new Error("transferScreenNoMinEthereum");
    }

    return highestBalanceChain;
  } catch (error: any) {
    Alert.alert(
      i18n.t(error.message, {
        minimumEthereumAmount: feesAndLimits.minimumEthereumAmount
      })
    );
    return undefined;
  }
}
