import React, { useEffect } from "react";
import { Image, Alert } from "react-native";
import { Text, View } from "../../components/Themed";
import { SafeAreaView } from "react-native-safe-area-context";
import PrimaryButton from "../../components/ThemedComponents/PrimaryButton";
import i18n from "../../config/languageInternationalization";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { colorBackground } from "../../utils/colors";
import Images from "../../assets/images";
import { LoginStackParamList } from "../../../types/navigationTypes";
import { isWeb } from "../../utils/platform";
import FastImageWrapper from "../../utils/FastImageWrapper";
import { setOnboardingStatus } from "../../redux/slices/userThunk";
import {
  OnboardingStatus,
  setLoginInitiated,
  setUserCredentials
} from "../../redux/slices/user";
import { REACT_APP_AUTH_AUDIENCE } from "@env";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { useCustomAuth } from "../../hooks/useAuth";
import {
  getCurrentNotificationsConfig,
  updatePushToken
} from "../../utils/pushNotifications";
import { checkPinCodeStatus } from "./PinCodeSetup";
import { recordError } from "../../utils/crashlytics";
import CheckoutOnrampStatusScreen from "../../components/CheckoutStatus";

type LandingScreenNavigationProp = NativeStackScreenProps<
  LoginStackParamList,
  "LandingScreen"
>;

export default function LandingScreen({
  navigation
}: LandingScreenNavigationProp) {
  const { user, login, signinSilent, logout } = useCustomAuth();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const { onboardingStatus, loginInitiated, isSharedAccessToken } =
    useAppSelector((state) => state.user);

  useEffect(() => {
    if (user && loginInitiated) {
      (async () => {
        dispatch(
          setUserCredentials({
            accessToken: user.access_token as string | undefined,
            refreshToken: user.refresh_token as string | undefined,
            idToken: user.id_token as string | undefined
          })
        );

        const hasNotificationsEnabled = await getCurrentNotificationsConfig();
        if (!hasNotificationsEnabled) {
          dispatch(setOnboardingStatus(OnboardingStatus.NOTIFICATIONS));
        } else {
          if (isWeb()) {
            dispatch(setOnboardingStatus(OnboardingStatus.ONBOARD));
          } else {
            await checkPinCodeStatus(dispatch);
          }
          // If user has the notifications enabled, return the token to the document
          await updatePushToken(hasNotificationsEnabled, user.access_token);
        }
        dispatch(setLoginInitiated(false));
      })();
    }
  }, [user, onboardingStatus, loginInitiated]);

  useEffect(() => {
    // auto login if we receive an auth code from another instance
    if (isWeb() && isSharedAccessToken) {
      (async () => {
        try {
          dispatch(setLoginInitiated(true));
          await signinSilent();
        } catch (e: any) {
          recordError(e, "LandingScreen");
        }
      })();
    }
  }, [isSharedAccessToken]);

  const ImageComponent = FastImageWrapper || Image;

  const { merchantTheme } = useAppSelector((state) => state.theme);

  const handleLogin = async () => {
    try {
      if (user) {
        logout(false);
      }
      dispatch(setLoginInitiated(true));
      setLoading(true);
      if (!isWeb()) {
        await login();
      } else {
        // @ts-ignore
        await login({
          scope: "openid email offline_access",
          extraQueryParams: {
            audience: REACT_APP_AUTH_AUDIENCE
          }
        });
      }
    } catch (e: any) {
      Alert.alert(
        i18n.t("alertError", { error: e.message ?? i18n.t("unkownError") })
      );
      recordError(e, "LandingScreen");
    } finally {
      setLoading(false);
    }
  };
  return (
    <SafeAreaView
      style={{
        flex: 1,
        justifyContent: "center",
        backgroundColor: colorBackground
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 60
        }}
      >
        {merchantTheme?.logo && (
          <ImageComponent
            source={{ uri: merchantTheme?.logo }}
            style={{
              height: isWeb() ? 120 : 124,
              width: isWeb() ? 120 : 124,
              marginRight: 30
            }}
          />
        )}
        <ImageComponent
          source={Images.SphereOneIcon}
          style={{ height: isWeb() ? 120 : 124, width: isWeb() ? 120 : 124 }}
        />
      </View>
      <View style={{ marginBottom: 120 }}>
        <Text
          style={{
            textAlign: "center",
            fontSize: 48,
            fontWeight: "700"
          }}
        >
          SphereOne
        </Text>
        <Text
          style={{
            textAlign: "center",
            fontSize: 24,
            fontWeight: "300"
          }}
        >
          {i18n.t("landingScreenSubtitle")}
        </Text>
      </View>

      <View
        style={{
          paddingHorizontal: 16,
          alignItems: isWeb() ? "center" : undefined,
          marginTop: isWeb() ? -100 : 0
        }}
      >
        <PrimaryButton
          onPress={handleLogin}
          isLoading={loading || isSharedAccessToken}
          disabled={loading}
          style={{ width: isWeb() ? 300 : undefined }}
        >
          {i18n.t("landingScreenLogInWithInvite")}
        </PrimaryButton>
      </View>
    </SafeAreaView>
  );
}
