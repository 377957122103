import { Dimensions, Platform, ScaledSize } from "react-native";

export function isAndroid(): boolean {
  return Platform.OS === "android";
}

export function isWeb(): boolean {
  return Platform.OS === "web";
}

/**
 * Dimensions.get("window").height
 *
 * @returns {number} the window's height
 */
export function getWindowHeight(): number {
  return Dimensions.get("window").height;
}

/**
 * Dimensions.get("window").width
 *
 * @returns {number} the window's width
 */
export function getWindowWidth(): number {
  return Dimensions.get("window").width;
}

/**
 * Dimensions.get("screen").height
 *
 * @returns {number} the screen's height
 */
export function getScreenHeight(): number {
  return Dimensions.get("screen").height;
}

/**
 * Dimensions.get("screen").width
 *
 * @returns {number} the screen's width
 */
export function getScreenWidth(): number {
  return Dimensions.get("screen").width;
}

/**
 * Returns the maximum width for the PWA.
 *
 * @param {ScaledSize} dimensions the window's dimensions
 * @returns {number} the maximum width for the PWA
 */

export function pwaMaxWidth(dimensions: ScaledSize): number {
  return Math.min(600, dimensions.width);
}

export function isWebViewedOnIOS() {
  if (isWeb()) {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /iPhone|iPod|iPad/.test(userAgent);
  }
  return false;
}

export function isWebViewedOnAndroid() {
  if (isWeb()) {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /Android/.test(userAgent);
  }
  return false;
}
