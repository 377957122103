// import notifee, { AuthorizationStatus } from "@notifee/react-native";
import { FirebaseMessagingTypes } from "@react-native-firebase/messaging";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { msg } from "./firebase.web";
import { onNotificationActionPress } from "./onNotificationActionPress.web";
import { recordError } from "./crashlytics";
import icon from "../assets/images/SphereIconLogoGreen.png";
import { getDocumentData, updateDocumentData } from "./server";
import { isWebViewedOnIOS } from "./platform";

// @params: notificationsEnabled : boolean = if this is true, FCM token would be added if it is not in the array

export async function updatePushToken(
  notificationsEnabled: boolean,
  accessToken: string
): Promise<void> {
  try {
    const token = await AsyncStorage.getItem("@FCMtoken");

    const { data: userInfo, error } = await getDocumentData({
      url: "/user",
      accessToken
    });

    if (error) throw new Error(error);

    const FCMtokens = userInfo.FCMtokens;
    //asks if FCMtokens exists and is an array.
    if (FCMtokens && Array.isArray(FCMtokens)) {
      //if user clicks enabled
      if (notificationsEnabled) {
        // asks if token is in the array already
        if (token && !FCMtokens.includes(token)) {
          const { error } = await updateDocumentData({
            url: "/user",
            accessToken,
            updateData: { FCMtokens: [...FCMtokens, token] }
          });
          if (error) throw new Error(error);
        }
      }
      //if disabled notifications removes from the array in firestore
      else {
        const { data: updateResult, error } = await updateDocumentData({
          url: "/user",
          accessToken,
          updateData: {
            FCMtokens: [...FCMtokens.filter((e) => e !== token)]
          }
        });

        if (error) throw new Error(error);
      }
    } else {
      //if FCMtokens doesn't exist in user and enabled push notifications

      if (notificationsEnabled && token !== null) {
        const { error } = await updateDocumentData({
          url: "/user",
          accessToken,
          updateData: { FCMtokens: [token] }
        });

        if (error) throw new Error(error);
      }
    }
  } catch (e) {
    recordError(e, "pushNotifications.ts");
  }
}

export async function setAsyncStorageNotificationSettings(enabled: boolean) {
  return await AsyncStorage.setItem("@notifications", enabled.toString());
}

export const getCurrentNotificationsConfig = async () => {
  try {
    if (!isWebViewedOnIOS()) {
      const status = Notification.permission;
      if (status === "granted") return true;
      else return false;
    } else return false;
  } catch (e) {
    recordError(e, "pushNotifications.ts");
  }
};

export async function requestUserPermission(accessToken: string) {
  try {
    if (!msg) return null;
    const enabled = await msg.getToken();

    if (enabled) {
      await AsyncStorage.setItem("@FCMtoken", enabled);
      await setAsyncStorageNotificationSettings(true);
      updatePushToken(true, accessToken);
    }
  } catch (e) {
    recordError(e, "pushNotifications.web.ts");
  }
}

// Currently used in PushNotificationScreen in Profile.
export async function updateNotificationSettings(
  notificationsEnabled = true,
  accessToken: string
) {
  if (!msg) return null;
  if (notificationsEnabled) {
    requestUserPermission(accessToken);
  } else {
    updatePushToken(notificationsEnabled, accessToken);
  }
}

export async function onMessageReceived(
  message: FirebaseMessagingTypes.RemoteMessage,
  navigation: any
) {
  try {
    if (message.data) {
      const messageData = JSON.parse(message.data.notifee);
      const notification = new Notification(messageData.title, {
        body: messageData.body,
        icon
      });

      notification.onclick = function (event) {
        event.preventDefault();
        onNotificationActionPress({
          notificationData: messageData.data,
          navigation
        });
        notification.close();
      };
    }
  } catch (e) {
    recordError(e, "pushNotifications.ts");
  }
}

export function handleBackgroundNotifications({
  navigation,
  accessToken
}: any): void {
  try {
    if ("serviceWorker" in navigator) {
      // listen for messages from the service worker
      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data && event.data.background) {
          onNotificationActionPress({
            notificationData: event.data,
            navigation
          });
        }
      });
    }
  } catch (e) {
    recordError(e, "pushNotifications.ts");
  }
}

export async function removeTokenFromDocument(accessToken: string) {
  try {
    const token = await AsyncStorage.getItem("@FCMtoken");
    const { data: userInfo } = await getDocumentData({
      url: "/user",
      accessToken
    });
    await updateDocumentData({
      url: "/user",
      accessToken,
      updateData: {
        FCMtokens: [...userInfo.FCMtokens.filter((e: string) => e !== token)]
      }
    });
  } catch (error) {
    recordError(error, "pushNotifications.ts");
  }
}

export async function getCurrentFCMtoken() {
  try {
    return await AsyncStorage.getItem("@FCMtoken");
  } catch (error) {
    recordError(error, "Get current token pushNotifications.ts");
  }
}
