import { createSlice } from "@reduxjs/toolkit";
import { UserPaymentDataState } from "../../../types/userPaymentData";
import { getUserPaymentData } from "./userPaymentDataThunk";

const initialState: UserPaymentDataState = {
  userUid: "",
  email: "",
  phone: "",
  username: "",
  wallets: {},
  loading: false,
  error: ""
};

const userPaymentDataSlice = createSlice({
  name: "userPaymentData",
  initialState,
  reducers: {
    resetUserPaymentData: () => ({ ...initialState, loading: false })
  },
  extraReducers: (builder) => {
    builder.addCase(getUserPaymentData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserPaymentData.fulfilled, (state, { payload }) => {
      return {
        ...state,
        ...payload,
        loading: false,
        error: ""
      };
    });
    builder.addCase(getUserPaymentData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Error retrieving payment data";
    });
  }
});

export const { resetUserPaymentData } = userPaymentDataSlice.actions;
export default userPaymentDataSlice.reducer;
