import React, { useEffect, useState } from "react";
import ContactBadge from "../../components/Badges/ContactBadge";
import { Text, View } from "../../components/Themed";
import PrimaryButton from "../../components/ThemedComponents/PrimaryButton";
import i18n from "../../config/languageInternationalization";
import {
  colorBackground,
  colorBackgroundSecondaryButton,
  colorLightGrayText,
  darkBlue
} from "../../utils/colors";
import { ActivityIndicator } from "react-native";
import { recordError } from "../../utils/crashlytics";
import { useAppSelector } from "../../redux/hooks";
import { isWeb } from "../../utils/platform";
import { Alert } from "../../utils/Alert";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { P2PTransferStackNavigatorParamList } from "../../../types/navigationTypes";
import {
  Action,
  TransactionDocument,
  TransactionType,
  TransferType,
  TxStatus
} from "../../../types/transfer";
import { callFunction } from "../../utils/server";
import { UsdcAddresses } from "../../redux/slices/tokensThunk";
import { findChainForTransfer } from "./P2PTransferScreen";
import { notEnoughFundsAlert } from "../AmountScreen";
import SafeAreaView from "../../components/ThemedComponents/SafeAreaView";
import {
  ArrowPathIcon,
  ArrowRightIcon,
  BoltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  CurrencyDollarIcon
} from "react-native-heroicons/solid";
import { TouchableOpacity } from "react-native-gesture-handler";
import axios from "axios";
import SecondaryButton from "../../components/ThemedComponents/SecondaryButton";
import { SupportedChains } from "../../utils/supportedChains";
import InsertPinCode from "../../components/InsertPinCode";
import { RouteBatchesStepper } from "../../components/OnRampScreen/Details";

export enum BatchType {
  TRANSFER = "TRANSFER",
  SWAP = "SWAP",
  BRIDGE = "BRIDGE"
}
export function formatBatch(title: string, actions: Action[]) {
  const renderObj: {
    type: BatchType;
    title: string | null;
    operations: string[];
  } = {
    type: BatchType.TRANSFER,
    title,
    operations: []
  };

  const hexToNumber = (hex: string, decimals: number) =>
    (parseInt(hex, 16) / Math.pow(10, decimals))
      .toFixed(6)
      .replace(/\.?0+$/, "");

  actions.forEach(({ transferData, swapData, bridgeData }) => {
    if (transferData) {
      renderObj.type = BatchType.TRANSFER;
      renderObj.operations.push(
        `${hexToNumber(
          transferData.fromAmount.hex,
          transferData.fromToken.decimals
        )} ${transferData.fromToken.symbol} in ${transferData.fromChain}`
      );
    } else if (swapData) {
      renderObj.type = BatchType.SWAP;
      renderObj.operations.push(
        `${hexToNumber(swapData.fromAmount.hex, swapData.fromToken.decimals)} ${
          swapData.fromToken.symbol
        } to ${hexToNumber(swapData.toAmount.hex, swapData.toToken.decimals)} ${
          swapData.toToken.symbol
        } in ${swapData.fromChain}`
      );
    } else if (bridgeData) {
      renderObj.type = BatchType.BRIDGE;
      renderObj.operations.push(
        `${hexToNumber(
          bridgeData.quote.fromAmount.hex,
          bridgeData.quote.fromToken.decimals
        )} ${bridgeData.quote.fromToken.symbol} in ${
          bridgeData.quote.fromToken.chain
        } to ${hexToNumber(
          bridgeData.quote.toAmount.hex,
          bridgeData.quote.toToken.decimals
        )} ${bridgeData.quote.toToken.symbol} in ${
          bridgeData.quote.toToken.chain
        }`
      );
    }
  });

  return renderObj;
}

export default function TransferDetailScreen({
  navigation,
  route
}: NativeStackScreenProps<
  P2PTransferStackNavigatorParamList,
  "TransferDetail"
>) {
  const { type, receiver, amount, tokenSymbol, message, isNotification } =
    route.params;
  const [isLoading, setIsLoading] = useState(false);
  const { walletsBalanceTotal, walletsBalanceTokens, accessToken, uid, email } =
    useAppSelector((state) => state.user);
  const [chargeId, setChargeId] = useState<string | undefined>(undefined);
  const [chain, setChain] = useState<SupportedChains | undefined>(undefined);
  const [estimations, setEstimations] = useState<Estimations>({
    gasCostUsd: "-",
    feesCostUsd: "-",
    timeEstimateMin: "-",
    batches: [],
    error: null
  });

  const handleChain = () => {
    if (route.params.chain) return route.params.chain;

    // User is trying to fulfill a request, if there's no chain available the function will thrown an alert
    const chain = findChainForTransfer(
      walletsBalanceTokens,
      walletsBalanceTotal,
      amount
    );
    if (!chain) throw new Error("No chain available for transfer");
    return chain;
  };

  const handlePayOrRequest = async () => {
    Alert.alert(
      i18n.t("transferDetailScreenAreYouSure"),
      i18n.t(
        type === TransferType.PAY
          ? "transferDetailScreenAreYouSureMsgSend"
          : "transferDetailScreenAreYouSureMsgRequest",
        {
          sendeeName: receiver.address,
          amount: amount,
          currency: tokenSymbol
        }
      ),
      [
        {
          text: i18n.t("verificationPhoneInputScreenConfirm"),
          onPress: () => {
            type === TransferType.PAY ? transferFunds() : requestFunds();
          }
        },
        {
          text: i18n.t("profileScreenCancel")
        }
      ]
    );
  };

  const requestFunds = async () => {
    try {
      setIsLoading(true);
      const { error } = await callFunction({
        url: "/payment-request",
        accessToken: accessToken as string,
        method: "POST",
        data: {
          amount,
          symbol: tokenSymbol,
          requestorAddress: email, // of the user that's requesting
          detail: message,
          toUid: receiver.userPaymentData?.userUid // of the user who is being requested
        }
      });
      // a push notification will be sent to the requested user from the server function
      if (error) throw new Error(error);

      navigation.navigate("Success", {
        type: TransferType.RECEIVE,
        receiver,
        amount,
        tokenSymbol
      });
    } catch (error) {
      recordError(error, "TransferDetailScreen.tsx");
      Alert.alert(
        i18n.t("transferDetailScreenRequestFailed"),
        i18n.t("transferDetailScreenRequestFailedMsg", {
          sendeeName: receiver.address
        }),
        [
          {
            text: i18n.t("transferDetailScreenTryAgain")
          }
        ]
      );
    } finally {
      setIsLoading(false);
    }
  };

  const transferFunds = async () => {
    if (!chain) return;
    if (amount > walletsBalanceTotal) {
      return notEnoughFundsAlert(amount);
    }
    try {
      if (!chargeId) throw new Error("No transactionId returned from server");
      setIsLoading(true);
      setModal(true);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const [modal, setModal] = React.useState(false);

  const callback = async (wrappedDek: string) => {
    await callFunction({
      url: "/pay",
      accessToken: accessToken as string,
      data: { transactionId: chargeId, wrappedDek },
      method: "POST"
    });

    setIsLoading(false);

    return navigation.navigate("Success", {
      type,
      receiver,
      amount,
      tokenSymbol
    });
  };

  const handleError = (error: any) => {
    recordError(error, "TransferDetailScreen.tsx");
    Alert.alert(
      i18n.t("transferDetailScreenPaymentFailed"),
      i18n.t("transferDetailScreenPaymentFailedMsg"),
      [
        {
          text: i18n.t("transferDetailScreenTryAgain")
        }
      ]
    );
  };

  const TransferDetailCTA = () => {
    // isLoading is true, show spinner
    if (isLoading) return <ActivityIndicator color={"white"} />;
    if (!estimations.batches.length && !estimations?.error)
      return "Loading Estimations...";
    // isLoading is false
    if (type === TransferType.PAY) return i18n.t("amountScreenPay");
    if (type === TransferType.RECEIVE)
      return i18n.t("transferDetailScreenRequest");
  };

  useEffect(() => {
    // Create payment charge for transfer
    async function createCharge() {
      try {
        // Select the chain
        const chain = handleChain();
        setChain(chain);

        // Create the charge for payment and estimation
        const transactionParams: TransactionDocument = {
          chain,
          symbol: tokenSymbol,
          status: TxStatus.PENDING,
          tokenAddress: UsdcAddresses[chain],
          updatedAt: Date.now(),
          senderUid: uid as string,
          toAddress: receiver.address,
          total: String(amount),
          type: TransactionType.PAYMENT
        };
        const { data: transactionId } = await callFunction({
          url: "/transactions",
          accessToken: accessToken as string,
          data: { transactionParams },
          method: "POST"
        });
        setChargeId(transactionId);
      } catch (error) {
        recordError(error, "TransferDetailScreen.tsx");
        Alert.alert("Error", "There was an error creating the transaction");
      }
    }
    if (type === TransferType.PAY) createCharge();
  }, []);

  return (
    <View style={{ backgroundColor: colorBackground, flex: 1 }}>
      <SafeAreaView style={{ backgroundColor: "transparent" }}>
        <View
          style={
            isWeb()
              ? { flex: 1, alignItems: "center" }
              : { paddingHorizontal: 16 }
          }
        >
          <ContactBadge
            isUser={receiver.isUser}
            name={receiver.address}
            detail={`${amount} ${tokenSymbol} ${chain ? `- ${chain}` : ""}`}
            Icon={receiver.userPaymentData?.profilePicture}
            badgeStyle={{
              height: 60,
              width: 60,
              borderRadius: 60,
              backgroundColor: darkBlue
            }}
          />

          {/* Payment details */}
          {type === TransferType.PAY && (
            <EstimationCard
              chargeId={chargeId}
              estimations={estimations}
              setEstimations={setEstimations}
            />
          )}

          {/* Pay or Request button */}
          <View
            style={{
              paddingHorizontal: 0,
              paddingBottom: 28,
              width: "100%"
            }}
          >
            <PrimaryButton
              accessibilityLabel="PayButton"
              style={{
                marginTop: 78,
                width: isWeb() ? "100%" : undefined,
                flex: isWeb() ? 1 : undefined
              }}
              onPress={() => handlePayOrRequest()}
              isDisabled={
                isLoading || !estimations.batches.length || !!estimations?.error
              }
            >
              {TransferDetailCTA()}
            </PrimaryButton>
            <SecondaryButton
              onPress={() =>
                !isNotification
                  ? navigation.goBack()
                  : navigation.navigate("Amount")
              }
              style={{ marginTop: 12 }}
            >
              Cancel
            </SecondaryButton>
          </View>
        </View>
        {modal && chargeId && (
          <InsertPinCode
            target={chargeId}
            cb={callback}
            closeModal={() => setModal(false)}
            handleError={handleError}
          />
        )}
      </SafeAreaView>
    </View>
  );
}

interface Estimations {
  gasCostUsd: string;
  feesCostUsd: string;
  timeEstimateMin: string;
  batches: {
    type: BatchType;
    title: string | null;
    operations: string[];
  }[];
  error: null | string;
}

function EstimationCard({
  chargeId,
  estimations,
  setEstimations
}: {
  chargeId?: string;
  estimations: Estimations;
  setEstimations: (estimations: Estimations) => void;
}) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const user = useAppSelector((state) => state.user);

  async function fetchEstimations(transactionId: string) {
    try {
      const res = await axios.request({
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/pay/route`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`
        },
        data: JSON.stringify({
          transactionId
        })
      });
      const batches = JSON.parse(res.data.data.estimation.route);

      return {
        gasCostUsd: "$ " + Number(res.data.data.estimation.costUsd).toFixed(2),
        feesCostUsd: "$ 0.00",
        timeEstimateMin:
          Number(res.data.data.estimation.timeEstimate).toFixed(2) + "m",
        batches: batches.map((b: any) => formatBatch(b.description, b.actions)),
        error: null
      };
    } catch (e: any) {
      return {
        gasCostUsd: "-",
        feesCostUsd: "-",
        timeEstimateMin: "-",
        batches: [],
        error: e?.response?.data?.error?.message ?? "Something went wrong"
      };
    }
  }

  useEffect(() => {
    if (!user?.uid || !chargeId) return;
    setIsLoading(true);
    fetchEstimations(chargeId).then((estimations) => {
      setEstimations(estimations);
      setIsLoading(false);
    });
  }, [user?.uid, chargeId]);

  return (
    <View style={{ marginTop: 16, width: "100%" }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        {/* Estimations */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
          }}
        >
          <BoltIcon fill={colorLightGrayText} style={{ height: 16 }} />
          <Text style={{ marginLeft: 8, color: colorLightGrayText }}>
            {estimations.gasCostUsd}
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
          }}
        >
          <CurrencyDollarIcon
            fill={colorLightGrayText}
            style={{ height: 16 }}
          />
          <Text style={{ marginLeft: 8, color: colorLightGrayText }}>
            {estimations.feesCostUsd}
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
          }}
        >
          <ClockIcon fill={colorLightGrayText} style={{ height: 16 }} />
          <Text style={{ marginLeft: 8, color: colorLightGrayText }}>
            {estimations.timeEstimateMin}
          </Text>
        </View>

        {/* Toggle collapse */}
        <TouchableOpacity
          disabled={isLoading}
          onPress={() => setIsCollapsed(!isCollapsed)}
          style={{
            backgroundColor: colorBackgroundSecondaryButton,
            borderRadius: 100,
            justifyContent: "center",
            alignItems: "center",
            height: 32,
            width: 32
          }}
        >
          {isCollapsed ? (
            <ChevronDownIcon fill={colorLightGrayText} style={{ height: 16 }} />
          ) : (
            <ChevronUpIcon fill={colorLightGrayText} style={{ height: 16 }} />
          )}
        </TouchableOpacity>
      </View>

      {/* Steps list */}
      {!isCollapsed && (
        <View style={{ paddingTop: 16 }}>
          <RouteBatchesStepper batches={estimations.batches} />
        </View>
      )}
      {estimations?.error && (
        <Text style={{ color: "red" }}>{estimations.error}</Text>
      )}
    </View>
  );
}
