import React, { useEffect, useRef } from "react";
import { Linking, ScrollView, View } from "react-native";
import { Text } from "../../components/Themed";
import {
  colorBackground,
  colorIndigo,
  colorPink,
  colorRed,
  colorTextBlue,
  colorYellow,
  skyBlue,
  colorTextRed,
  colorGreen,
  gray800,
  gray400,
  gray600,
  white,
  gray700
} from "../../utils/colors";
import SecondaryButton from "../../components/ThemedComponents/SecondaryButton";
import SettingsCellContainer from "../../components/ProfileScreen/SettingsCellContainer";
import SettingsCell from "../../components/ProfileScreen/SettingsCell";
import Avatar from "../../components/Avatar";
import {
  ArrowRightOnRectangleIcon,
  BellIcon,
  HandRaisedIcon,
  PencilSquareIcon,
  ShieldExclamationIcon,
  TrashIcon,
  UserGroupIcon,
  LinkIcon,
  WalletIcon
} from "react-native-heroicons/outline";
import i18n from "../../config/languageInternationalization";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getProfileImage } from "../../redux/slices/userThunk";
import { recordError } from "../../utils/crashlytics";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { REACT_APP_DASHBOARD_URL, SUPPORT_EMAIL } from "@env";
import { formatDeleteAccountEmail } from "../../utils/emailTemplates";
import UpgradeToMerchantButton from "../../components/ProfileScreen/UpgradeToMerchantButton";
import { isWeb } from "../../utils/platform";
import { openWebComposer } from "../../components/ProfileScreen/utils/openWebComposer";
import { useModal } from "../../hooks/useModal";
import { Alert } from "../../utils/Alert";
import ContactSupport from "../../components/ProfileScreen/ContactSupport";
import { ProfileStackNavigatorParamList } from "../../../types/navigationTypes";
import * as WebBrowser from "expo-web-browser";
import { useCustomAuth } from "../../hooks/useAuth";
import SafeAreaView from "../../components/ThemedComponents/SafeAreaView";

type ProfileScreenNavigationProp = NativeStackScreenProps<
  ProfileStackNavigatorParamList,
  "ProfileScreen"
>;

export default function ProfileScreen({
  navigation
}: ProfileScreenNavigationProp) {
  const { name, pfp, uid, email } = useAppSelector((state) => state.user);
  const { logout } = useCustomAuth();
  const dispatch = useAppDispatch();
  const actionSheet: any = useRef();
  const supportModal = useModal();

  useEffect(() => {
    if (pfp === undefined && pfp === null) {
      dispatch(getProfileImage());
    }
  }, []);

  // Update handlers when screens and actions are ready
  const handleEditProfilePress = () =>
    navigation.navigate("EditProfileScreen", { fromOnboarding: false });
  const handleNotificationsPress = () =>
    navigation.navigate("PushNotificationsScreen");
  const handlePrivacyPolicyPress = () =>
    navigation.navigate("PrivacyPolicyScreen");
  const handleSupportPress = () =>
    !isWeb() ? actionSheet.current.show() : supportModal.showModal();
  const handleTermsAndConditionsPress = () =>
    navigation.navigate("TermsAndConditionsScreen");
  const handleRecoverWalletScreen = () =>
    navigation.navigate("RecoverWalletScreen");
  const onDeleteAccountConfirm = async () => {
    try {
      const { subject, body } = formatDeleteAccountEmail({ uid, name });
      if (isWeb()) {
        openWebComposer({
          to: SUPPORT_EMAIL,
          subject,
          body
        });
        await logout();
      } else {
        await Linking.openURL(
          `mailto:${SUPPORT_EMAIL}?cc=${email}&subject=${subject}&body=${body}`
        );
        await logout();
      }
    } catch (e: any) {
      recordError(e, "ProfileScreen.tsx");
    }
  };

  // TODO: Need to figure out what to do with Delete Account Flow
  const handleDeleteAccountPress = () => {
    Alert.alert(
      i18n.t("profileScreenDeleteAccount"),
      i18n.t("profileScreenDeleteAccountConfirmation"),
      [
        { text: i18n.t("profileScreenCancel"), style: "cancel" },
        {
          text: i18n.t("linkedAccountsalertTitle"), // Delete
          onPress: async () => {
            await onDeleteAccountConfirm();
          },
          style: "destructive"
        }
      ]
    );
  };

  const DASHBOARD_URL = REACT_APP_DASHBOARD_URL;
  const openMerchantForm = () => {
    if (DASHBOARD_URL && typeof DASHBOARD_URL === "string") {
      WebBrowser.openBrowserAsync(DASHBOARD_URL);
    }
  };

  return (
    <View style={{ backgroundColor: colorBackground, flex: 1 }}>
      <SafeAreaView
        style={
          isWeb()
            ? {
                backgroundColor: gray800,
                borderWidth: 1,
                borderColor: gray700,
                borderRadius: 16,
                marginVertical: 20
              }
            : { backgroundColor: "transparent" }
        }
      >
        <ScrollView
          style={{ flex: 1 }}
          contentContainerStyle={{
            paddingTop: 24,
            marginHorizontal: 16,
            alignItems: "center"
          }}
          showsVerticalScrollIndicator={false}
        >
          <View
            style={{
              position: "relative",
              alignSelf: "stretch",
              alignItems: "center"
            }}
          >
            {/* PROFILE PICTURE */}
            <Avatar
              nameOrEmail={name !== undefined ? name : (email as string)}
              source={pfp !== undefined && pfp !== "" ? { uri: pfp } : null}
              size={128}
              style={{ marginBottom: 12 }}
            />
          </View>

          <Text style={{ textAlign: "center", fontSize: 22 }}>{name}</Text>

          <SecondaryButton
            style={{
              marginTop: 8,
              paddingVertical: 8,
              paddingHorizontal: 16,
              backgroundColor: gray800,
              borderRadius: 8,
              borderColor: gray600,
              borderWidth: 1
            }}
            textStyle={{ fontSize: 14, fontWeight: "500" }}
            leftIcon={<PencilSquareIcon size={18} color={gray400} />}
            onPress={handleEditProfilePress}
          >
            {i18n.t("profileScreenEditProfile")}
          </SecondaryButton>

          {/* Settings */}
          <View
            style={{
              alignSelf: isWeb() ? "center" : "stretch",
              width: isWeb() ? "100%" : undefined
            }}
          >
            <Text
              style={{
                marginBottom: 8,
                color: white,
                fontSize: 14
              }}
            >
              {i18n.t("profileScreenSettingsLabel")}
            </Text>
            <SettingsCellContainer>
              <SettingsCell
                title={i18n.t("linkedAccountsRecoverWallet")}
                icon={<WalletIcon size={24} color="white" />}
                iconBgColor={colorPink}
                onPress={handleRecoverWalletScreen}
              />

              <SettingsCell
                title={i18n.t("profileScreenNotifications")}
                icon={<BellIcon size={24} color="white" />}
                iconBgColor={colorGreen}
                onPress={handleNotificationsPress}
              />
              <SettingsCell
                title={i18n.t("profileScreenPrivacyPolicy")}
                icon={<HandRaisedIcon size={24} color="white" />}
                iconBgColor={colorIndigo}
                onPress={handlePrivacyPolicyPress}
              />
              <SettingsCell
                title={i18n.t("profileScreenSupport")}
                icon={<UserGroupIcon size={24} color="white" />}
                iconBgColor={colorYellow}
                onPress={handleSupportPress}
              />
              <SettingsCell
                title={i18n.t("profileScreenTermsAndConditions")}
                icon={<ShieldExclamationIcon size={24} color="white" />}
                iconBgColor={colorIndigo}
                removeBottomBorder
                onPress={handleTermsAndConditionsPress}
              />
              <SettingsCell
                title={i18n.t("profileScreenLogout")}
                titleStyle={{ color: colorTextBlue }}
                icon={<ArrowRightOnRectangleIcon size={24} color="white" />}
                iconBgColor={skyBlue}
                onPress={logout}
              />
            </SettingsCellContainer>

            <SettingsCellContainer style={{ marginTop: 16 }}>
              <SettingsCell
                title={i18n.t("profileScreenDeleteAccount")}
                titleStyle={{ color: colorTextRed }}
                icon={<TrashIcon size={24} color="white" />}
                iconBgColor={colorRed}
                removeBottomBorder
                onPress={handleDeleteAccountPress}
              />
            </SettingsCellContainer>
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              margin: 20,
              width: "100%"
            }}
          >
            <UpgradeToMerchantButton openMerchantForm={openMerchantForm} />
          </View>
          <ContactSupport
            actionSheet={actionSheet}
            supportModal={supportModal}
          />
        </ScrollView>
      </SafeAreaView>
    </View>
  );
}
