import React from "react";
import { View } from "../Themed";

export function TabIndicator({ active }: { active: boolean }) {
  return (
    <View
      style={{
        height: 4,
        width: active ? 30 : 12,
        backgroundColor: active ? "white" : "rgba(255, 255, 255, 0.3)",
        borderRadius: 5,
        marginHorizontal: 3
      }}
    />
  );
}

export default function TabsIndicator({
  currentIndex
}: {
  currentIndex: number;
}) {
  return (
    <View style={{ flexDirection: "row", marginBottom: 57 }}>
      <TabIndicator active={currentIndex === 0} />
      <TabIndicator active={currentIndex === 1} />
      <TabIndicator active={currentIndex === 2} />
    </View>
  );
}
