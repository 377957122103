import React from "react";
import { Dimensions } from "react-native";
import { SvgProps } from "react-native-svg";
import { colorCardBackground } from "../../utils/colors";
import { Text, View } from "../Themed";
import { StyleSheet } from "react-native";
import { isWeb } from "../../utils/platform";

const { width: screenWidth } = Dimensions.get("screen");

export default function TransferWarnings({
  message,
  icon
}: {
  message: string;
  icon: React.ClassicElement<SvgProps>;
}) {
  return (
    <View
      style={isWeb() ? webStyles.conteinerStyles : mobileStyles.containerStyles}
    >
      <View style={isWeb() ? webStyles.iconStyles : mobileStyles.iconStyles}>
        {icon}
      </View>
      <View style={isWeb() ? webStyles.viewMessage : {}}>
        <Text
          style={isWeb() ? webStyles.messageStyles : mobileStyles.messageStyles}
        >
          {message}
        </Text>
      </View>
    </View>
  );
}

const mobileStyles = StyleSheet.create({
  containerStyles: {
    flexDirection: "row",
    height: 48,
    padding: 12,
    backgroundColor: colorCardBackground,
    alignItems: "flex-start",
    borderRadius: 14
  },
  iconStyles: {
    marginLeft: 10,
    marginRight: 22
  },
  messageStyles: {
    fontWeight: "400",
    fontSize: 16
  }
});

const webStyles = StyleSheet.create({
  conteinerStyles: {
    flexDirection: "row",
    padding: 12,
    backgroundColor: colorCardBackground,
    alignItems: "flex-start",
    borderRadius: 14,
    width: screenWidth > 600 ? 600 : 400,
    position: "relative"
  },
  iconStyles: {
    marginLeft: 10,
    marginRight: 22,
    position: "absolute"
  },
  viewMessage: {
    flex: 1
  },
  messageStyles: {
    fontWeight: "400",
    fontSize: 16,
    textAlign: "center"
  }
});
