import React, { useEffect, useRef, useState } from "react";
import {
  Animated,
  Image,
  Pressable,
  RefreshControl,
  ScrollView,
  TouchableOpacity
} from "react-native";
import { Text, View } from "../components/Themed";
import TransactionBadge from "../components/Badges/TransactionBadge";
import {
  blue500,
  colorLightGray,
  darkBlue,
  gray400,
  gray600,
  gray700,
  gray800,
  green300,
  green500,
  green900,
  primary600,
  purple300,
  purple900,
  red300,
  red900,
  skyBlue,
  white
} from "../utils/colors";
import {
  custom_transaction_DateTimeFormat,
  dd_MMMM_DateFormat,
  HH_mm_TimeFormat
} from "../utils/times";
import { isEmail, isEmptyString } from "../utils/validation";
import { DraggablePanel } from "../components/DraggablePanel";
import format from "date-fns/format";
import { MagnifyingGlassIcon } from "react-native-heroicons/outline";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  XCircleIcon
} from "react-native-heroicons/solid";
import i18n from "../config/languageInternationalization";
import { es } from "date-fns/locale";
import Input from "../components/ThemedComponents/Input";
import { recordError } from "../utils/crashlytics";
import { parseTitle } from "../utils/parseTitleAccount";
import Toast from "react-native-toast-message";
import * as Clipboard from "expo-clipboard";
import { TransactionType, TxStatus } from "../../types/transfer";
import { isWeb } from "../utils/platform";
import ContactSupport from "../components/ProfileScreen/ContactSupport";
import { useModal } from "../hooks/useModal";
import { useAppSelector } from "../redux/hooks";
import { getDocumentData } from "../utils/server";
import SafeAreaView from "../components/ThemedComponents/SafeAreaView";
import { verifyJWT } from "../utils/verifyJWT";
import { useSpinAnimation } from "../hooks/useSpinAnimation";
import images from "../assets/images";
import TransactionDetailModal from "../components/TransactionDetailModal";

export type TempTransaction = {
  amount: string;
  id: string;
  receiverUid: string;
  senderUid: string;
  status: string;
  time: Date;
  type: TransactionType;
  toAddress: string;
};

export enum TxType {
  SEND = "SEND",
  RECEIVE = "RECEIVE",
  NULL = "NULL"
}

export default function TransactionsScreen() {
  const filterButtonTexts = ["All", "PROCESSING", "SUCCESS", "FAILURE"];
  const actionSheet: any = useRef();
  const supportModal = useModal();

  const [transactionList, setTransactionList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [filterTextSelected, setFilterTextSelected] = useState("All");
  const [selectedTransaction, setSelectedTransaction] =
    useState<TempTransaction>({} as TempTransaction);
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const spin = useSpinAnimation(loading);

  const { accessToken, uid } = useAppSelector((state) => state.user);

  async function getTransactions(refreshSearchResults = false) {
    try {
      setLoading(true);
      if (!accessToken) throw new Error("No access token");
      if (refreshSearchResults) updateList("All");
      const data = await getDocumentData({
        url: "/transactions",
        accessToken
      });
      const decodeTransactions = (await verifyJWT(data.data)).transactions;

      if (data.error) throw new Error(data.error);

      const allTransactions: TempTransaction[] = [];

      decodeTransactions.forEach((t: any) => {
        const { total, status, symbol, toAddress, dateCreated } = t;
        const transaction = {
          id: t.uid,
          toAddress,
          status: status,
          amount: `${total} ${symbol}`,
          time: dateCreated && new Date(dateCreated?._seconds * 1000),
          type: t.type,
          receiverUid: t.receiverUid,
          senderUid: t.senderUid
        };
        allTransactions.push(transaction);
      });

      setSearchResult(allTransactions);
      setTransactionList(allTransactions);
    } catch (err) {
      recordError(err, "TransactionsScreen.tsx");
      if (refreshSearchResults) updateList("All");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getTransactions();
  }, []);

  const handleRefresh = async () => {
    await getTransactions(true);
  };

  // ONLY if user has never made a transaction before.
  function emptyTransactions() {
    if (loading) return null;
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          marginTop: 32
        }}
      >
        <Image source={images.Search} style={{ width: 240, height: 240 }} />
        <Text
          style={{
            color: gray400,
            fontWeight: "bold",
            fontSize: 20,
            marginBottom: 10
          }}
        >
          {i18n.t("transactionsScreenNoActivity")}
        </Text>
      </View>
    );
  }

  // ONLY if user's search finds no matches in their transaction list
  function emptyList() {
    if (loading) return null;
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          marginTop: 32
        }}
      >
        <Text style={{ fontWeight: "bold", fontSize: 20, marginBottom: 10 }}>
          {i18n.t("transactionsScreenNoResultsMatched")}
        </Text>
        <Text>{i18n.t("transactionsScreenNoResultsMatchedSubtitle")}</Text>
      </View>
    );
  }

  function updateList(buttonText: string, isButtonClick = false) {
    // make sure not to re-render the list again if same filter button is clicked again
    if (filterTextSelected == buttonText && isButtonClick) return;
    setFilterTextSelected(buttonText);
    // reset search input since list is re-rendered with new contents
    setSearchInput("");
    if (buttonText == "All") {
      setSearchResult(transactionList);
    } else {
      const result = transactionList.filter((user: TempTransaction) => {
        return user.status.toLowerCase() === buttonText.toLowerCase();
      });
      setSearchResult(result);
    }
  }

  function performSearch(input: string) {
    setSearchInput(input);
    const trimmedInput = input.trim().toLowerCase();
    if (isEmptyString(trimmedInput) || trimmedInput.length == 0) {
      updateList(filterTextSelected);
    } else {
      // get original list based on current filter button selected to perform search in
      const filteredList = transactionList.filter((user: TempTransaction) => {
        if (filterTextSelected.toLowerCase() === "all") {
          return user;
        }
        return user.status.toLowerCase() === filterTextSelected.toLowerCase();
      });
      const result = filteredList.filter((user: TempTransaction) => {
        return user.toAddress.toLowerCase().includes(trimmedInput);
      });
      setSearchResult(result);
    }
  }

  function displaySearchResults() {
    const dateList: string[] = [];
    const sortedList = searchResult.sort(
      (a: TempTransaction, b: TempTransaction) => (a.time > b.time ? -1 : 1)
    );
    sortedList.map((search: TempTransaction) => {
      const dateString = getDisplayTimeDate(search, dd_MMMM_DateFormat);
      if (dateList.length == 0) {
        // if initially empty list
        dateList.push(dateString);
      } else {
        const index = dateList.findIndex((date) => date == dateString); // see if it already exists
        if (index == -1) {
          // if not exist, add it
          dateList.push(dateString);
        }
      }
    });
    return dateList.length != 0
      ? dateList.map((date, i) => {
          const today = format(new Date(), dd_MMMM_DateFormat); // get today's date
          const displayList = sortedList.filter((item: TempTransaction) => {
            return getDisplayTimeDate(item, dd_MMMM_DateFormat) == date;
          });
          return (
            <View key={i} style={{ flexDirection: "column", marginTop: 32 }}>
              <Text style={{ color: colorLightGray, marginStart: 5 }}>
                {date == today ? i18n.t("transactionsScreenToday") : `${date}`}{" "}
              </Text>

              {displayList.map((tx: TempTransaction, index: number) => {
                return (
                  <TransactionBadge
                    key={index}
                    name={
                      isEmail(tx.toAddress)
                        ? tx.toAddress
                        : parseTitle(tx.toAddress, 4)
                    }
                    time={getDisplayTimeDate(tx, HH_mm_TimeFormat)}
                    transactionType={getTransactionType(tx.type, tx.senderUid)}
                    status={tx.status}
                    amount={tx.amount}
                    containerStyle={{ marginTop: 12 }}
                    badgeStyle={{
                      height: 40,
                      width: 40,
                      borderRadius: 30,
                      backgroundColor: darkBlue
                    }}
                    showBadgeIndicator={
                      tx.status === TxStatus.PENDING ||
                      tx.status === TxStatus.WAITING
                        ? true
                        : false
                    }
                    // showBorder={index == displayList.length - 1 ? false : true}
                    onPress={() => {
                      setSelectedTransaction(tx);
                      setShowBottomSheet(true);
                    }}
                  />
                );
              })}
            </View>
          );
        })
      : emptyList();
  }

  const handleOnPressCopy = async (
    value: string,
    type: "Address" | "Transaction"
  ) => {
    await Clipboard.setStringAsync(value);
    Toast.show({
      type: "info",
      position: "top",
      text1: `${type} copied to clipboard`
    });
  };

  function handleStatus(status: string) {
    let textStatus;
    switch (status) {
      case TxStatus.FAILURE:
        textStatus = (
          <Text style={{ fontSize: 16, color: red300 }}>
            {i18n.t(`transactionsScreenFAILURE`, { count: 1 })}
          </Text>
        );
        break;
      case TxStatus.CANCELED:
        textStatus = (
          <Text style={{ fontSize: 16, color: red300 }}>
            {i18n.t(`transactionsScreenCANCELED`, { count: 1 })}
          </Text>
        );
        break;
      case TxStatus.SUCCESS:
        textStatus = (
          <Text style={{ fontSize: 16, color: green300 }}>
            {i18n.t(`transactionsScreenSUCCESS`, { count: 1 })}
          </Text>
        );
        break;
      default:
        textStatus = (
          <Text style={{ fontSize: 16, color: purple300 }}>
            {i18n.t(`transactionsScreenPENDING`, { count: 1 })}
          </Text>
        );
        break;
    }

    return (
      <View
        style={{
          backgroundColor: [TxStatus.CANCELED, TxStatus.FAILURE].includes(
            status as TxStatus
          )
            ? red900
            : TxStatus.SUCCESS === (status as TxStatus)
            ? green900
            : purple900,
          paddingVertical: 2,
          paddingHorizontal: 10,
          borderRadius: 6
        }}
      >
        {textStatus}
      </View>
    );
  }

  const handleSupportPress = () =>
    !isWeb() ? actionSheet.current.show() : supportModal.showModal();

  function renderBottomSheet() {
    return isWeb() ? (
      <TransactionDetailModal
        transactionType={getTransactionType(
          selectedTransaction.type,
          selectedTransaction.senderUid
        )}
        visible={showBottomSheet}
        transactionId={selectedTransaction.id}
        name={selectedTransaction.toAddress}
        amount={selectedTransaction.amount}
        status={selectedTransaction.status as TxStatus}
        date={selectedTransaction.time}
        onClose={() => setShowBottomSheet(false)}
        onReportIssue={handleSupportPress}
      />
    ) : (
      <DraggablePanel
        visible={showBottomSheet}
        expandable={false}
        borderRadius={20}
        overlayOpacity={0.6}
        onDismiss={() => setShowBottomSheet(false)}
      >
        {/* Amount */}
        <View
          style={{
            flexDirection: "row",
            marginTop: 10
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              paddingHorizontal: 16
            }}
          >
            <View
              style={{
                flexDirection: "column",
                marginBottom: 10
              }}
            >
              <TouchableOpacity
                onPress={() =>
                  handleOnPressCopy(selectedTransaction.toAddress, "Address")
                }
              >
                <Text
                  style={{
                    color:
                      selectedTransaction.status == "Pending"
                        ? gray400
                        : getTransactionType(
                            selectedTransaction.type,
                            selectedTransaction.senderUid
                          ) === TxType.RECEIVE
                        ? green500
                        : white,
                    fontSize: 16,
                    marginBottom: 8
                  }}
                >
                  {getTransactionType(
                    selectedTransaction.type,
                    selectedTransaction.senderUid
                  ) === TxType.SEND
                    ? "-"
                    : "+"}
                  {selectedTransaction.amount}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            marginHorizontal: 16,
            backgroundColor: gray800,
            padding: 24,
            borderRadius: 8,
            marginBottom:
              selectedTransaction.status === TxStatus.FAILURE ? 0 : 10
          }}
        >
          {/* Name, date */}
          <View style={{ flexDirection: "row", marginTop: 10 }}>
            <View style={{ flex: 1, flexDirection: "column" }}>
              <View style={{ flexDirection: "column", marginBottom: 10 }}>
                <TouchableOpacity
                  onPress={() =>
                    handleOnPressCopy(selectedTransaction.toAddress, "Address")
                  }
                >
                  <Text style={{ color: "white", fontSize: 16 }}>
                    {selectedTransaction.toAddress}
                  </Text>
                </TouchableOpacity>
                <Text style={{ color: gray400 }}>
                  {getDisplayTimeDate(
                    selectedTransaction,
                    custom_transaction_DateTimeFormat
                  )}
                </Text>
              </View>
              <View
                style={{
                  height: 1,
                  backgroundColor: gray700
                }}
              />
            </View>
          </View>
          {/* Status */}
          <View className="h-12">
            <View
              style={{
                flex: 1,
                flexDirection: "column",
                paddingVertical: 10
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignContent: "center"
                }}
              >
                <Text style={{ fontSize: 18, color: colorLightGray }}>
                  {i18n.t("transactionsScreenStatus")}
                </Text>
                {handleStatus(selectedTransaction.status)}
              </View>
            </View>
            <View
              style={{
                height: 1,
                backgroundColor: gray700
              }}
            />
          </View>
          {/* ID */}
          <View className="h-12">
            <View style={{ flex: 1, flexDirection: "column" }}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignContent: "center",
                  paddingVertical: 10
                }}
              >
                <Text style={{ fontSize: 18, color: colorLightGray }}>
                  {i18n.t("transactionScreenTransaction")}
                </Text>
                <TouchableOpacity
                  style={{ alignItems: "center", justifyContent: "center" }}
                  onPress={() =>
                    handleOnPressCopy(selectedTransaction.id, "Transaction")
                  }
                >
                  <Text style={{ fontSize: 14, color: colorLightGray }}>
                    {selectedTransaction.id}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
            <View
              style={{
                height: 1,
                backgroundColor: gray700
              }}
            />
          </View>
          {/* Statement */}
          <View className="h-12">
            <View style={{ flex: 1, flexDirection: "column" }}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignContent: "center",
                  paddingVertical: 10
                }}
              >
                <Text style={{ fontSize: 18, color: colorLightGray }}>
                  {i18n.t("transactionScreenStatement")}
                </Text>
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  onPress={() =>
                    handleOnPressCopy(selectedTransaction.id, "Transaction")
                  }
                >
                  <Text
                    style={{
                      fontSize: 16,
                      color: blue500
                    }}
                  >
                    {i18n.t("sendNftDownload")}
                  </Text>
                  <View>
                    <ArrowDownTrayIcon size={18} color={blue500} />
                  </View>
                </TouchableOpacity>
              </View>
            </View>
            <View
              style={{
                height: 1,
                backgroundColor: gray700
              }}
            />
          </View>
          <View className="h-12">
            <View style={{ flex: 1, flexDirection: "column" }}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignContent: "center",
                  paddingVertical: 10,
                  alignItems: "center"
                }}
              >
                <Text style={{ fontSize: 18, color: colorLightGray }}>
                  {i18n.t("transactionsScreenFees")}
                </Text>
                <Text style={{ fontSize: 16, color: colorLightGray }}>
                  {i18n.t("transactionsScreenNoFee")}
                </Text>
              </View>
            </View>
            <View
              style={{
                height: 1,
                backgroundColor: gray700
              }}
            />
          </View>
        </View>
        {/* Report an issue */}
        {selectedTransaction.status === "FAILURE" && (
          <View
            style={{
              marginHorizontal: 16,
              marginVertical: 10,
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              backgroundColor: gray800,
              borderRadius: 8,
              borderColor: gray600,
              borderWidth: 1
            }}
          >
            <Pressable
              style={({ pressed }) => [
                {
                  backgroundColor: pressed ? "gray" : "transparent",
                  borderRadius: 10,
                  padding: 10
                }
              ]}
              onPress={handleSupportPress}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: 18,
                  textDecorationLine: "none"
                }}
              >
                {i18n.t("transactionsScreenContactSupport")}
              </Text>
            </Pressable>
          </View>
        )}
        <ContactSupport
          subject={i18n.t("somethingWentWrongInTransaction", {
            selectedTransactionId: selectedTransaction.id
          })}
          actionSheet={actionSheet}
          supportModal={supportModal}
        />
      </DraggablePanel>
    );
  }

  function getDisplayTimeDate(
    transaction: TempTransaction | null | undefined,
    dateTimeFormat: string
  ) {
    // doing a bunch of null/undefined/empty checks
    if (transaction === undefined || transaction === null) return "";
    if (transaction.time === undefined || transaction.time === null) return "";
    const dateTimeString = format(transaction.time, dateTimeFormat, {
      locale: i18n.locale === "en" ? undefined : es
    });

    return dateTimeString;
  }

  function getTransactionType(
    type: TransactionType,
    senderUid: string
  ): TxType {
    if (type === TransactionType.ONRAMP) return TxType.RECEIVE;
    return senderUid === uid ? TxType.SEND : TxType.RECEIVE;
  }

  return (
    <SafeAreaView
      style={{
        marginVertical: isWeb() ? 20 : 0,
        paddingHorizontal: 16,
        paddingTop: isWeb() ? 24 : 32,
        backgroundColor: isWeb() ? gray800 : "transparent",
        borderRadius: isWeb() ? 16 : 0
      }}
    >
      {isWeb() && (
        <View style={{ marginBottom: 12 }}>
          <Text style={{ fontWeight: "700", fontSize: 20 }}>Transactions</Text>
        </View>
      )}
      {/* Search Input */}
      <View style={{ flexDirection: "row", marginBottom: isWeb() ? 12 : 32 }}>
        <Input
          style={{
            flex: 1,
            marginRight: 10,
            backgroundColor: gray700,
            paddingVertical: isWeb() ? 4 : 6
          }}
          value={searchInput}
          placeholder={i18n.t("transactionsScreenSearchPlaceholder")}
          onChangeText={(t) => performSearch(t)}
          leftIcon={<MagnifyingGlassIcon size={25} color={"gray"} />}
          rightIcon={
            <>
              {searchInput !== "" && (
                <TouchableOpacity onPress={() => performSearch("")}>
                  <XCircleIcon size={25} color={"gray"} />
                </TouchableOpacity>
              )}
            </>
          }
        />
        <TouchableOpacity
          style={{
            borderRadius: 8,
            backgroundColor: primary600,
            paddingVertical: 0,
            paddingHorizontal: 10,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <MagnifyingGlassIcon size={20} color={"white"} />
        </TouchableOpacity>
      </View>
      <View
        style={{
          flexDirection: isWeb() ? "column" : "row",
          justifyContent: isWeb() ? "flex-start" : "space-evenly",
          marginLeft: 0
        }}
      >
        {/* Filter Buttons */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: isWeb() ? "space-between" : "flex-start",
            gap: 6,
            width: "100%",
            marginBottom: 5
          }}
        >
          {filterButtonTexts.map((buttonText, index: number) => {
            return (
              <Pressable
                key={index}
                style={({ pressed }) => ({
                  flex: isWeb() ? 1 : 0,
                  paddingVertical: 5,
                  paddingHorizontal: 15,
                  backgroundColor:
                    filterTextSelected === buttonText ? primary600 : gray700,
                  borderRadius: 99,
                  opacity: pressed ? 0.6 : 1
                })}
                onPress={() => updateList(buttonText, true)}
              >
                <Text
                  style={{
                    textAlign: "center",
                    color: filterTextSelected === buttonText ? "white" : gray400
                  }}
                >
                  {i18n.t(`transactionsScreen${buttonText}`, { count: 2 })}
                </Text>
              </Pressable>
            );
          })}
          {/* Refresh Button (Web) */}
          {isWeb() && (
            <TouchableOpacity
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
              onPress={handleRefresh}
            >
              <Animated.View
                style={{ height: 25, width: 25, transform: [{ rotate: spin }] }}
              >
                <ArrowPathIcon color={"white"} />
              </Animated.View>
            </TouchableOpacity>
          )}
        </View>
      </View>

      {/* Transaction List */}
      <ScrollView
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            colors={["white"]}
            progressBackgroundColor={skyBlue} // Android ONLY
            tintColor={skyBlue} // iOS ONLY
            refreshing={loading}
            onRefresh={handleRefresh}
          />
        }
      >
        <View onStartShouldSetResponder={() => true}>
          {transactionList.length == 0
            ? emptyTransactions()
            : displaySearchResults()}
        </View>
      </ScrollView>
      {renderBottomSheet()}
    </SafeAreaView>
  );
}
