import React from "react";
import { View, ViewProps } from "react-native";
import { colorCardBackground } from "../../utils/colors";

export default function SettingsCellContainer(props: ViewProps) {
  const { children, style, ...otherProps } = props;
  return (
    <View
      style={[{ backgroundColor: "transparent", borderRadius: 14 }, style]}
      {...otherProps}
    >
      {children}
    </View>
  );
}
