import React from "react";
import SafeAreaView from "./ThemedComponents/SafeAreaView";
import { Text, View } from "./Themed";
import {
  colorBackgroundTransparent,
  gray700,
  gray800,
  primary500
} from "../utils/colors";
import FormControl from "./ThemedComponents/FormControl";
import { TargetOperation, getUserDek } from "../screens/WalletActionScreen";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Image } from "react-native";
import SphereOneLogoWhiteWithLetters from "../assets/images/SphereOneLogoWhiteWithLetters.png";
import CheckCircle from "../assets/images/CheckCircle.png";
import {
  PinCodePrimaryButton,
  PinCodeSecondaryButton,
  PinCodeSubtitle,
  PinCodeTitle
} from "../screens/AddPinCodeScreen";
import { isWeb } from "../utils/platform";
import i18n from "../config/languageInternationalization";
import { setShowRecoveryFlowModal } from "../redux/slices/user";
import { useHeaderHeight } from "@react-navigation/elements";
import { TouchableWithoutFeedback, Keyboard } from "react-native";
import PasswordInput from "./ThemedComponents/PasswordInput";

enum PinCodeState {
  INSERT,
  SUCCESS
}

export const SphereOneLogoWhite = () => {
  return (
    <Image
      style={{ width: 200, height: 38, marginBottom: 50 }}
      source={SphereOneLogoWhiteWithLetters}
    />
  );
};

export const GreenCheckCircleIcon = () => {
  return <Image style={{ width: 75, height: 75 }} source={CheckCircle} />;
};

function InsertPinCode({
  cb,
  closeModal,
  target,
  handleError,
  text,
  containerStyles,
  width,
  height,
  position,
  parentSetLoading
}: {
  cb?: (wrappedDek: string) => any;
  closeModal: () => void;
  target: TargetOperation | string;
  handleError?: (error: string) => void;
  text?: string;
  containerStyles?: string;
  width?: string;
  height?: string;
  position?: "absolute" | "relative";
  parentSetLoading?: (loading: boolean) => void;
}) {
  const [pinCode, setPinCode] = React.useState("");
  const [pinCodeError, setPinCodeError] = React.useState("");
  const [state, setState] = React.useState(PinCodeState.INSERT);
  const [loading, setLoading] = React.useState(false);
  const { accessToken } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const headerHeight = useHeaderHeight();

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        if (!isWeb()) Keyboard.dismiss();
      }}
    >
      <SafeAreaView
        style={{
          width: width ? width : isWeb() ? "100vw" : "100%",
          height: height
            ? height
            : isWeb()
            ? `calc(100vh - ${headerHeight}px)`
            : "100%",
          position: position || "absolute",
          zIndex: 1000,
          backgroundColor: colorBackgroundTransparent
        }}
      >
        <View
          className={
            containerStyles ||
            "m-auto p-8 text-center sm:max-w-[50%] xl:max-w-[33%]"
          }
          style={{ backgroundColor: colorBackgroundTransparent }}
        >
          <View style={{ alignItems: "center" }}>
            <SphereOneLogoWhite />
          </View>
          <View
            style={{
              backgroundColor: gray800,
              borderRadius: 8,
              borderWidth: 1,
              borderColor: gray700,
              padding: 32
            }}
          >
            {state === PinCodeState.INSERT && (
              <FormControl style={{ gap: 24 }}>
                <PinCodeTitle text={i18n.t("pinCodeEnter")} />
                <PinCodeSubtitle
                  text={i18n.t("pinCodeTarget", {
                    text: text || "make a purchase"
                  })}
                />
                <PasswordInput
                  testID="pin-code-input"
                  id="pin-code-input"
                  value={pinCode ?? ""}
                  onChangeText={(e) => {
                    if (pinCode.length <= 4 && !isNaN(Number(e))) setPinCode(e);
                  }}
                  maxLength={4}
                  keyboardType="numeric"
                />
                {pinCodeError ? (
                  <Text className="text-red-600">{pinCodeError}</Text>
                ) : (
                  <></>
                )}
                <PinCodePrimaryButton
                  handleOnPress={async () => {
                    try {
                      if (!pinCode || !accessToken) return;
                      setLoading(true);
                      setPinCodeError("");
                      const wrappedDek = await getUserDek(accessToken, {
                        target,
                        pinCode
                      });
                      closeModal();
                      cb && (await cb(wrappedDek));
                      setState(PinCodeState.SUCCESS);
                    } catch (e: any) {
                      if (e?.message?.includes("pin code")) {
                        return setPinCodeError(i18n.t("pinCodeIncorrect"));
                      } else {
                        closeModal();
                        handleError &&
                          handleError(
                            e?.response || e?.message || i18n.t("alertError")
                          );
                      }
                    } finally {
                      setLoading(false);
                    }
                  }}
                  text={i18n.t("authScreensContinue")}
                  disabled={pinCode.length !== 4 || loading}
                  isLoading={loading}
                />
                <PinCodeSecondaryButton
                  isDisabled={loading}
                  handleOnPress={() => {
                    parentSetLoading && parentSetLoading(false);
                    closeModal();
                  }}
                  text={i18n.t("back")}
                />
                <Text
                  style={{ color: primary500, alignSelf: "flex-start" }}
                  onPress={() => {
                    dispatch(setShowRecoveryFlowModal(true));
                    closeModal();
                  }}
                >
                  {i18n.t("forgotPIN")}
                </Text>
              </FormControl>
            )}
            {state === PinCodeState.SUCCESS && (
              <FormControl style={{ gap: 24 }}>
                <View style={{ alignItems: "center" }}>
                  <GreenCheckCircleIcon />
                </View>
                <PinCodeTitle text={i18n.t("pinCodeInsertedOK")} />
                <PinCodePrimaryButton
                  text={i18n.t("authScreensContinue")}
                  handleOnPress={closeModal}
                />
              </FormControl>
            )}
          </View>
        </View>
      </SafeAreaView>
    </TouchableWithoutFeedback>
  );
}

export default InsertPinCode;
