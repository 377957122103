import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { BottomTabNavigatorParamList } from "../../types/navigationTypes";
import { getRemoteValue } from "../utils/fetchRemoteConfig";
import { colorBackground } from "../utils/colors";
import { isAndroid } from "../utils/platform";
import { HomeNavigator } from "./HomeNavigator";
import {
  ClockIcon,
  CurrencyDollarIcon,
  HomeIcon,
  UserIcon
} from "react-native-heroicons/outline";
import TransactionsScreen from "../screens/TransactionsScreen";
import { ProfileNavigator } from "./ProfileNavigator";
import { P2PTransferNavigator } from "./P2PTransferNavigator";
import { BanknotesIcon, LinkIcon } from "react-native-heroicons/solid";
import { CheckoutNavigator } from "./CheckoutNavigator.";
import { LinkedAccountsNavigation } from "./LinkedAccountsNavigator";

// ================ BOTTOM TAB ================ //

const BottomTab = createBottomTabNavigator<BottomTabNavigatorParamList>();
export function BottomTabNavigator() {
  const activeColorRemoteConfig = getRemoteValue("activeColor").asString();

  return (
    <BottomTab.Navigator
      initialRouteName="Home"
      sceneContainerStyle={{
        backgroundColor: colorBackground
      }}
      screenOptions={{
        tabBarHideOnKeyboard: true,
        tabBarActiveTintColor: activeColorRemoteConfig
          ? activeColorRemoteConfig
          : "grey",
        headerShown: false,
        tabBarStyle: isAndroid()
          ? { height: 50, elevation: 0, borderTopWidth: 0 }
          : { backgroundColor: "transparent" }
      }}
    >
      <BottomTab.Screen
        name="Home"
        component={HomeNavigator}
        options={{
          tabBarLabel: () => null,
          tabBarIcon: ({ color }) => <HomeIcon color={color} size={30} />
        }}
      />
      <BottomTab.Screen
        name="Activity"
        component={TransactionsScreen}
        options={{
          tabBarLabel: () => null,
          tabBarIcon: ({ color }) => <ClockIcon color={color} size={30} />
        }}
      />
      <BottomTab.Screen
        name="Buy"
        component={CheckoutNavigator}
        options={{
          tabBarLabel: () => null,
          tabBarIcon: ({ color }) => <BanknotesIcon color={color} size={30} />,
          headerShown: false
        }}
      />
      <BottomTab.Screen
        name="Profile"
        component={ProfileNavigator}
        options={{
          tabBarLabel: () => null,
          tabBarIcon: ({ color }) => <UserIcon color={color} size={30} />
        }}
      />
      <BottomTab.Screen
        name="LinkedAccounts"
        component={LinkedAccountsNavigation}
        options={{
          tabBarLabel: () => null,
          tabBarIcon: ({ color }) => <LinkIcon color={color} size={30} />
        }}
      />
    </BottomTab.Navigator>
  );
}
