import firebase from "firebase/app";
import "firebase/remote-config";
import { colorPrimary } from "./colors";
import { recordError } from "./crashlytics";

const remoteConfig = firebase.remoteConfig();

remoteConfig.defaultConfig = {
  activeColor: colorPrimary
};

export const fetchRemoteConfig = async () => {
  try {
    await remoteConfig.fetch();
    const fetchedRemotely = await remoteConfig.activate();
    if (fetchedRemotely) {
      // Dispatch reducer updates here
    }
  } catch (e) {
    recordError(e);
  }
};

export const refreshConfig = async () => {
  await remoteConfig.fetchAndActivate();
};

export const getRemoteValue = (key: string) => remoteConfig.getValue(key);
