import AsyncStorage from "@react-native-async-storage/async-storage";
import * as SecureStore from "expo-secure-store";
import i18n from "../config/languageInternationalization";
import { isEnrolledAsync } from "expo-local-authentication";
import { isWeb } from "./platform";
import { createOrRecoverWallets } from "./server";
import jwtDecode from "jwt-decode";
import { OnrampLinkParams } from "./setMerchantParams";
import { CheckoutOnrampState } from "../redux/slices/checkoutOnramp";
import { DehydratedState } from "@tanstack/react-query";
import { SupportedChains } from "./supportedChains";
import { OnrampPreferencesStorage } from "../../types/onRamp";

// TODO: All local storage and secure storage writings go here.
// TODO: Keep cleanup function updated
export const cleanStorage = async () => {
  // Get the current value of @allowBiometricValidation
  const allowBiometricValidation = await AsyncStorage.getItem(
    "@allowBiometricValidation"
  );
  await cleanDek();
  await AsyncStorage.clear();
  isWeb() && localStorage.clear();

  // Restore the value of @allowBiometricValidation if it was set
  if (allowBiometricValidation === "true") {
    await AsyncStorage.setItem(
      "@allowBiometricValidation",
      allowBiometricValidation
    );
  }
};

// Biometric preference
export const setBiometricPreference = async (
  enabled: boolean
): Promise<any> => {
  return AsyncStorage.setItem("@allowBiometricValidation", enabled.toString());
};
export const getBiometricPreference = async (): Promise<boolean> => {
  const preference = await AsyncStorage.getItem("@allowBiometricValidation");
  return preference === "true";
};
export const hasTouchIdOrFaceIdConfigured = async (): Promise<boolean> => {
  return isEnrolledAsync();
};

// LockScreen PinCode
export const setPinCode = (pincode: string): Promise<any> => {
  return AsyncStorage.setItem("@pincode", pincode);
};
export const getPinCode = async (): Promise<string | null> => {
  return AsyncStorage.getItem("@pincode");
};

const canUseSecureStore = async () => {
  return (await SecureStore.isAvailableAsync()) && (await isEnrolledAsync());
};

// wrapped Dek storage
export const setDek = async (dek: string) => {
  if (await canUseSecureStore()) {
    return await Promise.all([
      AsyncStorage.setItem("@DEK-storage", "SecureStore"),
      SecureStore.setItemAsync("DEK", dek, {
        requireAuthentication: true,
        authenticationPrompt: i18n.t("dekStore")
      })
    ]);
  } else {
    return await Promise.all([
      AsyncStorage.setItem("@DEK-storage", "AsyncStorage"),
      AsyncStorage.setItem("@DEK", dek)
    ]);
  }
};
export const getDek = async (accessToken: string) => {
  const getDekFromStorage = async () => {
    const dekStorage = await AsyncStorage.getItem("@DEK-storage");

    if (dekStorage === "SecureStore") {
      return await SecureStore.getItemAsync("DEK", {
        requireAuthentication: true,
        authenticationPrompt: i18n.t("dekRetrieve")
      });
    } else return await AsyncStorage.getItem("@DEK");
  };
  const getDekFromApi = async () => {
    if (!accessToken) throw new Error("Missing access token");
    const { data } = await createOrRecoverWallets({ accessToken });
    return data;
  };

  const cachedDek = await getDekFromStorage();
  const decodedDek: any = cachedDek ? jwtDecode(cachedDek) : null;
  if (
    !decodedDek ||
    (decodedDek?.exp && Math.floor(Date.now() / 1000) > decodedDek?.exp)
  ) {
    const dek = await getDekFromApi();
    setDek(dek);
    return dek;
  } else return cachedDek;
};
export const cleanDek = async () => {
  const dekStorage = await AsyncStorage.getItem("@DEK-storage");

  if (dekStorage === "SecureStore") {
    await SecureStore.deleteItemAsync("DEK");
  } else await AsyncStorage.removeItem("@DEK");

  await AsyncStorage.removeItem("@DEK-storage");
};

// Payment Charge ID
export const setCharge = (chargeId: string) => {
  return AsyncStorage.setItem("@charge", chargeId);
};
export const getCharge = async () => {
  return AsyncStorage.getItem("@charge");
};
export const cleanCharge = async () => {
  return AsyncStorage.removeItem("@charge");
};

export const setCheckoutAction = (action: string) => {
  return AsyncStorage.setItem("@checkout-action", action);
};
export const getCheckoutAction = async (): Promise<string | null> => {
  return (await AsyncStorage.getItem("@checkout-action")) ?? null;
};
export const cleanCheckoutAction = async () => {
  return AsyncStorage.removeItem("@checkout-action");
};

export const setCheckoutWallet = (address: string, label: string) => {
  return AsyncStorage.setItem(
    "@checkout-wallet",
    JSON.stringify({ address, label })
  );
};
export const getCheckoutWallet = async (): Promise<{
  address: string;
  label: string;
} | null> => {
  const checkoutWallet = await AsyncStorage.getItem("@checkout-wallet");
  return checkoutWallet ? JSON.parse(checkoutWallet) : null;
};
export const cleanCheckoutWallet = async () => {
  return AsyncStorage.removeItem("@checkout-wallet");
};

// Request Charge ID
export const setRequestCharge = (chargeId: string) => {
  return AsyncStorage.setItem("@requestCharge", chargeId);
};
export const getRequestCharge = async () => {
  return AsyncStorage.getItem("@requestCharge");
};
export const cleanRequestCharge = async () => {
  return AsyncStorage.removeItem("@requestCharge");
};

// Merchant Charge ID
export const setMerchantCharge = (chargeId: string) => {
  return AsyncStorage.setItem("@merchantCharge", chargeId);
};
export const getMerchantCharge = async () => {
  return AsyncStorage.getItem("@merchantCharge");
};
export const cleanMerchantCharge = async () => {
  return AsyncStorage.removeItem("@merchantCharge");
};

// Merchant Charge OnRamp Params
export const setMerchantChargeOnRampParams = (params: string) => {
  return AsyncStorage.setItem("@merchantChargeOnRampParams", params);
};
export const getMerchantChargeOnRampParams = async () => {
  return AsyncStorage.getItem("@merchantChargeOnRampParams");
};
export const cleanMerchantChargeOnRampParams = async () => {
  return AsyncStorage.removeItem("@merchantChargeOnRampParams");
};

// Temp wallet DEK
export const setTempWalletKey = (key: string) => {
  return AsyncStorage.setItem("@tempWalletKey", key);
};
export const getTempWalletKey = async () => {
  return AsyncStorage.getItem("@tempWalletKey");
};
export const cleanTempWalletKey = async () => {
  return AsyncStorage.removeItem("@tempWalletKey");
};

export const setAuthCode = (authCode: string) => {
  return AsyncStorage.setItem("@authCode", authCode);
};

export const getAuthCode = async () => {
  return AsyncStorage.getItem("@authCode");
};

export const cleanAuthCode = async () => {
  return AsyncStorage.removeItem("@authCode");
};

export const setUserOIDC = (userToSave: object) => {
  return AsyncStorage.setItem("userOIDC", JSON.stringify(userToSave));
};

export const getUserOIDC = (): Promise<string | null> => {
  return AsyncStorage.getItem("userOIDC");
};

export const cleanUserOIDC = () => {
  return AsyncStorage.removeItem("userOIDC");
};

// CheckoutOnramp
export const setCheckoutTxIdFromStorage = (
  onrampLinkParams: OnrampLinkParams | { txId: string }
) => {
  return AsyncStorage.setItem(
    "@checkoutTxId",
    JSON.stringify(onrampLinkParams)
  );
};
export const getCheckoutTxIdFromStorage = async () => {
  const onrampLinkTx = await AsyncStorage.getItem("@checkoutTxId");
  return onrampLinkTx ? JSON.parse(onrampLinkTx) : null;
};

export const cleanCheckoutTxIdFromStorage = async () => {
  return AsyncStorage.removeItem("@checkoutTxId");
};

export const setOnrampPreferences = (preferences: OnrampPreferencesStorage) => {
  if (
    preferences.chain &&
    !Object.values(SupportedChains).includes(preferences.chain)
  )
    return;
  return AsyncStorage.setItem(
    "@onrampPreferences",
    JSON.stringify(preferences)
  );
};

export const getOnrampPreferences =
  async (): Promise<OnrampPreferencesStorage | null> => {
    const preferences = await AsyncStorage.getItem("@onrampPreferences");
    return preferences ? JSON.parse(preferences) : null;
  };

export const cleanOnrampPreferences = () => {
  return AsyncStorage.removeItem("@onrampPreferences");
};

export const setLoginInitiatedFromStorage = (loginInitiated: boolean) => {
  return AsyncStorage.setItem(
    "@loginInitiated",
    JSON.stringify(loginInitiated)
  );
};

export const getLoginInitiatedFromStorage = async () => {
  const loginInitiated = await AsyncStorage.getItem("@loginInitiated");
  return loginInitiated ? JSON.parse(loginInitiated) : null;
};

export const cleanLoginInitiatedFromStorage = () => {
  return AsyncStorage.removeItem("@loginInitiated");
};

export const setQueryClientState = (queryClientState: DehydratedState) => {
  return AsyncStorage.setItem(
    "@queryClientState",
    JSON.stringify(queryClientState)
  );
};

export const getQueryClientState = async () => {
  const queryClientState = await AsyncStorage.getItem("@queryClientState");
  return queryClientState ? JSON.parse(queryClientState) : null;
};

export const cleanQueryClientState = () => {
  return AsyncStorage.removeItem("@queryClientState");
};

export const setStoredCheckoutState = (
  checkoutState: CheckoutOnrampState | null
) => {
  return AsyncStorage.setItem(
    "@storedCheckoutState",
    JSON.stringify(checkoutState)
  );
};

export const getStoredCheckoutState = async () => {
  const storedCheckoutState = await AsyncStorage.getItem(
    "@storedCheckoutState"
  );
  return storedCheckoutState ? JSON.parse(storedCheckoutState) : null;
};

export const cleanStoredCheckoutState = () => {
  return AsyncStorage.removeItem("@storedCheckoutState");
};
