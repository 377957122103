import axios from "axios";
import React from "react";
import { TouchableOpacity } from "react-native";
import { XMarkIcon } from "react-native-heroicons/outline";
import { Balance } from "../../../../types/balances";
import {
  TransactionDocument,
  TransactionType,
  TxStatus
} from "../../../../types/transfer";
import { UserPaymentData } from "../../../../types/userPaymentData";
import i18n from "../../../config/languageInternationalization";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getUserPaymentData } from "../../../redux/slices/userPaymentDataThunk";
import { IdentifierType } from "../../../screens/Transfer/P2PTransferScreen";
import { gray600, primary600 } from "../../../utils/colors";
import { callFunction } from "../../../utils/server";
import { isEmail } from "../../../utils/validation";
import { View, Text } from "../../Themed";
import Button from "../../ThemedComponents/Button";
import FormControl from "../../ThemedComponents/FormControl";
import Input from "../../ThemedComponents/Input";
import { State } from "./SendTransferModal";
import { REACT_APP_BASE_URL as baseURL } from "@env";
import { Alert } from "../../../utils/Alert";
import { isEvmAddress, isSolAddress } from "../../../utils/web3";

interface SendStepProps {
  onClose: () => void;
  state: State;
  setState: (x: any) => void;
  token: Balance;
  loading: boolean;
  setShowPincode: (e: boolean) => void;
  setTxId: (e: string) => void;
  setLoading: (e: boolean) => void;
}

const SendStep: React.FC<SendStepProps> = ({
  onClose,
  state,
  setState,
  token,
  loading,
  setShowPincode,
  setTxId,
  setLoading
}: SendStepProps): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { uid, accessToken } = useAppSelector((state) => state.user);
  const [errors, setErrors] = React.useState<{
    toAddress: string | null;
    reason: string | null;
    tokenAmount: string | null;
  }>({
    toAddress: null,
    reason: null,
    tokenAmount: null
  });

  const validateState = (): boolean => {
    if (isNaN(Number(state.tokenAmount))) {
      setErrors({ ...errors, tokenAmount: "Please enter a number." });
      return false;
    } else if (Number(state.tokenAmount) > Number(token.amount)) {
      setErrors({ ...errors, tokenAmount: "Insufficient balances" });
      return false;
    } else {
      setErrors({ ...errors, tokenAmount: null });
    }
    if (
      !isEmail(state.toAddress) &&
      !isEvmAddress(state.toAddress) &&
      !isSolAddress(state.toAddress)
    ) {
      setErrors({
        ...errors,
        toAddress: "Ensure that this is a valid email or address"
      });
      return false;
    }
    setErrors({ tokenAmount: null, toAddress: null, reason: null });
    return true;
  };

  const handleSubmit = async () => {
    try {
      const isValidForm = validateState();
      if (!isValidForm) return;
      setLoading(true);
      const identifierType = isEmail(state.toAddress)
        ? IdentifierType.email
        : IdentifierType.wallet;
      let transactionParams: TransactionDocument;
      if (identifierType == IdentifierType.email) {
        const userPaymentData = (await dispatch(
          getUserPaymentData(state.toAddress)
        ).unwrap()) as UserPaymentData | undefined;

        if (!userPaymentData)
          return Alert.alert(
            "Something went wrong fetching the data of the receiver, please try again"
          );
        const destinationAddress = Object.values(userPaymentData.wallets).find(
          (wallet) =>
            wallet.chains.includes(token.tokenMetadata.chain) &&
            !wallet.isImported
        )?.address;

        if (!destinationAddress) {
          return Alert.alert(
            `User ${userPaymentData.username} doesn't have a ${token.tokenMetadata.chain} wallet yet`
          );
        }

        transactionParams = {
          chain: token.tokenMetadata.chain,
          symbol: token.tokenMetadata.symbol,
          status: TxStatus.PENDING,
          tokenAddress: token.tokenMetadata.address,
          updatedAt: Date.now(),
          senderUid: uid as string,
          toAddress: destinationAddress,
          total: String(state.tokenAmount),
          type: TransactionType.PAYMENT
        };
      } else {
        transactionParams = {
          chain: token.tokenMetadata.chain,
          symbol: token.tokenMetadata.symbol,
          status: TxStatus.PENDING,
          tokenAddress: token.tokenMetadata.address,
          updatedAt: Date.now(),
          senderUid: uid as string,
          toAddress: state.toAddress,
          total: String(state.tokenAmount),
          type: TransactionType.PAYMENT
        };
      }

      const data = await callFunction({
        url: "/transactions",
        accessToken: accessToken as string,
        data: { transactionParams },
        method: "POST"
      });

      if (!data) {
        return Alert.alert(
          "Oops, something went wrong creating the transaction please try again"
        );
      }

      setTxId(data.data);
      await axios.request({
        url: baseURL + "/pay/route",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json"
        },
        data: {
          transactionId: data.data
        },
        method: "POST"
      });
      setShowPincode(true);
    } catch (error: any) {
      if (
        error.response.data.error.message.includes(
          "Not enough balance to pay for gas of batch"
        )
      ) {
        return Alert.alert(
          "Not enough balance to pay for gas, please decrease the desired amount"
        );
      }
      return Alert.alert(error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <FormControl className="gap-6">
      <View className="flex-row items-center justify-between">
        <Text
          style={{ color: "white" }}
          className="text-left text-xl font-medium leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
        >
          {`Sending ${token?.tokenMetadata?.name} in ${token?.tokenMetadata?.chain} chain`}
        </Text>
        <TouchableOpacity onPress={onClose}>
          <XMarkIcon size={30} color="white" />
        </TouchableOpacity>
      </View>
      {/* To address */}
      <View className="items-start">
        <Text className="text-lg mb-1 text-white">{i18n.t("sendNftTo")}</Text>
        <Input
          value={state.toAddress}
          onChangeText={(e) => {
            setState({
              ...state,
              toAddress: e
            });
            if (!isEmail(e) && !isEvmAddress(e) && !isSolAddress(e)) {
              setErrors({
                ...errors,
                toAddress: "Ensure that this is a valid email or address"
              });
            } else {
              setErrors({ ...errors, toAddress: null });
            }
          }}
          keyboardType="default"
        />
        {errors.toAddress && (
          <Text className="text-red-500 text-center w-full">
            {errors.toAddress}
          </Text>
        )}
      </View>
      {/* Token amount */}
      <View className="items-start">
        <Text className="text-lg mb-1 text-white">Token amount</Text>
        <Input
          placeholder={token.tokenMetadata.symbol}
          value={state.tokenAmount}
          onChangeText={(e) => {
            setState({
              ...state,
              tokenAmount: e
            });
            if (isNaN(Number(state.tokenAmount))) {
              setErrors({ ...errors, tokenAmount: "Put a valid amount" });
              return false;
            } else if (Number(state.tokenAmount) > Number(token.amount)) {
              setErrors({ ...errors, tokenAmount: "Insufficient balances" });
              return false;
            } else {
              setErrors({ ...errors, tokenAmount: null });
            }
          }}
          keyboardType="default"
        />
        <Text>
          Your balance: {token.price}USD ≃ {token.amount}
          {token.tokenMetadata.symbol}
        </Text>
        {errors.tokenAmount && (
          <Text className="text-red-500 text-center w-full">
            {errors.tokenAmount}
          </Text>
        )}
      </View>
      {/* Reason */}
      <View className="items-start">
        <Text className="text-lg mb-1 text-white">
          {i18n.t("sendNftReason")}
        </Text>
        <Input
          value={state.reason}
          onChangeText={(e) =>
            setState({
              ...state,
              reason: e
            })
          }
          keyboardType="default"
          multiline={true}
          numberOfLines={4}
        />
      </View>
      <View className="flex-row justify-between">
        <Button
          style={{
            backgroundColor: gray600,
            borderRadius: 8,
            width: "45%"
          }}
          isDisabled={loading}
          onPress={onClose}
        >
          {i18n.t("sendNftCancel")}
        </Button>
        <Button
          style={{
            backgroundColor: primary600,
            borderRadius: 8,
            width: "45%"
          }}
          onPress={handleSubmit}
          disabled={loading}
          isLoading={loading}
        >
          {i18n.t("sendNftConfirm")}
        </Button>
      </View>
    </FormControl>
  );
};

export default SendStep;
