import React from "react";
import { View, ViewProps } from "react-native";
import { colorCardBackground } from "../../utils/colors";

export default function LinkedAccountCellContainer(props: ViewProps) {
  const { children, style, ...otherProps } = props;
  return (
    <View
      style={[
        { backgroundColor: colorCardBackground, borderRadius: 14 },
        style
      ]}
      {...otherProps}
    >
      {children}
    </View>
  );
}
