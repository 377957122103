import React from "react";
import { View, ViewProps } from "react-native";
import { nearBlack } from "../../utils/colors";

export default function PaymentMethodCellContainer(props: ViewProps) {
  const { children, style, ...otherProps } = props;
  return (
    <View
      style={[
        {
          borderRadius: 14,
          marginBottom: 10,
          backgroundColor: nearBlack,
          marginRight: 16,
          marginLeft: 16,
          paddingBottom: 8,
          paddingTop: 8,
          paddingLeft: 12,
          paddingRight: 12
        },
        style
      ]}
      {...otherProps}
    >
      {children}
    </View>
  );
}
