import React from "react";
import { ExclamationCircleIcon } from "react-native-heroicons/solid";
import {
  StyledText,
  StyledView
} from "../ThemedComponents/NativeWindStyledWrappers";
import { AmountError } from "../../../types/onRamp";
type Status = "error" | "success" | "warning";

export const InfoAlert = ({
  title,
  message,
  status,
  Button
}: {
  title: string;
  message: string;
  status: Status;
  Button?: React.ElementType;
}) => {
  const statusStyledTextColor = {
    error: { hex: "#FB7185", tw: "text-rose-400" },
    success: { hex: "#34D399", tw: "text-emerald-400" },
    warning: { hex: "#FB923C", tw: "text-orange-400" }
  };
  const statusBorderColor = {
    error: { hex: "#F98080", tw: "border-red-900" },
    success: { hex: "#014737", tw: "border-emerald-900" },
    warning: { hex: "#771D1D", tw: "border-orange-900" }
  };
  return (
    <StyledView
      className={`w-full  p-4 bg-gray-800 rounded-md border justify-center items-start flex-col mb-3 ${statusBorderColor[status].tw}`}
    >
      <StyledView className="self-stretch flex-col justify-start items-start">
        <StyledView className="self-stretch justify-start items-center flex-row">
          <ExclamationCircleIcon
            size={16}
            color={statusStyledTextColor[status].hex}
          />
          <StyledText
            className={`grow shrink basis-0 text-base font-semibold ml-2 ${statusStyledTextColor[status].tw}`}
          >
            {title.charAt(0).toUpperCase() + title.slice(1).toLowerCase()}
          </StyledText>
        </StyledView>
        <StyledText
          className={`self-stretch text-sm font-normal mt-1.5 ${statusStyledTextColor[status].tw}`}
        >
          {message}
        </StyledText>
        {Button && <Button />}
      </StyledView>
    </StyledView>
  );
};
export const ShowAmountError = ({
  amountError
}: {
  amountError: AmountError;
}) => {
  return (
    <>
      <InfoAlert
        title={amountError.type === "invalid" ? "error" : "warning"}
        message={amountError.message}
        status={amountError.type === "invalid" ? "error" : "warning"}
      />
    </>
  );
};

export const Badge = ({ text }: { text: string }) => {
  return (
    <StyledView className="h-5 py-0.5 px-2 bg-sky-100 rounded-md justify-start items-center inline-flex">
      <StyledText className="text-end text-blue-800 text-xs font-medium leading-none">
        {text}
      </StyledText>
    </StyledView>
  );
};
