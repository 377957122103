import { LinkedAccount } from "../redux/slices/user";
import { LinkedAccountWallet } from "../../types/accounts";
import { supportedChains, SupportedChains } from "./supportedChains";

export const findWalletByChain = (
  wallets: LinkedAccount[],
  chain: string
): LinkedAccountWallet => {
  // Find the SupportedChainsManager instance for the given chain
  const chainManager = supportedChains.find(
    (c) => c.name() === chain || c.code() === chain
  );
  if (!chainManager) {
    throw new Error(`Unsupported chain: ${chain}`);
  }
  const chainName: SupportedChains = chainManager.name();
  const findWallet = wallets.find(
    (w) =>
      w.type === "wallet" &&
      w.isImported === false &&
      w.chains.includes(chainName)
  ) as LinkedAccountWallet;

  if (!findWallet) {
    throw new Error(`Not wallet found for: ${chain}`);
  }
  return findWallet as LinkedAccountWallet;
};
