import React, { useState } from "react";
import {
  Image,
  Keyboard,
  TouchableOpacity,
  TouchableWithoutFeedback
} from "react-native";

import {
  colorBackground,
  colorBackgroundTransparent,
  colorInputBackground,
  colorInputBorder,
  gray700,
  gray800,
  white
} from "../utils/colors";
import { useAppSelector } from "../redux/hooks";
import SphereOneLogoWhiteWithLetters from "../assets/images/SphereOneLogoWhiteWithLetters.png";
import CheckCircle from "../assets/images/CheckCircle.png";
import {
  PinCodePrimaryButton,
  PinCodeSecondaryButton,
  PinCodeSubtitle,
  PinCodeTitle
} from "./AddPinCodeScreen";
import { isWeb } from "../utils/platform";
import i18n from "../config/languageInternationalization";
import SafeAreaView from "../components/ThemedComponents/SafeAreaView";
import { Text, View } from "../components/Themed";
import FormControl from "../components/ThemedComponents/FormControl";
import Input from "../components/ThemedComponents/Input";
import { callFunction } from "../utils/server";
import { useDispatch } from "react-redux";
import {
  setIsPhoneAdded,
  setPinCodeSetup,
  setShowRecoveryFlowModal
} from "../redux/slices/user";
import handlePhoneNumberChange from "../utils/formatePhoneNumber";
import CountryCodeSelector from "../components/ThemedComponents/CountryCodeSelector";
import { CountryItem } from "react-native-country-codes-picker";
import { isValidPhoneNumber } from "libphonenumber-js";

enum PhoneCode {
  INSERT_PHONE,
  INSERT_CODE,
  SUCCESS
}

export const SphereOneLogoWhite = () => {
  return (
    <Image
      style={{ width: 200, height: 38, marginBottom: 50 }}
      source={SphereOneLogoWhiteWithLetters}
    />
  );
};

export const GreenCheckCircleIcon = () => {
  return <Image style={{ width: 75, height: 75 }} source={CheckCircle} />;
};

function RecoveryFlowScreen() {
  const [phone, setPhone] = React.useState("");
  const [phoneFormatted, setPhoneFormatted] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [code, setCode] = React.useState("");
  const [codeError, setCodeError] = React.useState("");
  const { accessToken, isPhoneAdded } = useAppSelector((state) => state.user);
  const [flow, setFlow] = React.useState(PhoneCode.INSERT_PHONE);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const handleOnClickInsertPhone = async () => {
    if (!phone || !accessToken) return;
    setPhoneError("");
    const phoneFormatted = handlePhoneNumberChange(dialCode + phone);
    setPhoneFormatted(phoneFormatted);
    if (!isValidPhoneNumber(phoneFormatted))
      throw new Error("Invalid phone number");
    setLoading(true);
    await callFunction({
      url: "/otpgenerator",
      method: "POST",
      accessToken,
      data: { phone: phoneFormatted }
    });
    setFlow(PhoneCode.INSERT_CODE);
  };

  // logic to add the country selector
  const [showSelectCountryModal, setShowSelectCountryModal] = useState(false);
  const [countryFlag, setCountryFlag] = useState("");
  const [dialCode, setDialCode] = useState("");
  const onCountrySelect = (country: CountryItem) => {
    setPhoneError("");
    setCountryFlag(country.flag);
    setDialCode(country.dial_code);
  };

  const CountrySelector = () => {
    return (
      <TouchableOpacity
        onPress={() => setShowSelectCountryModal(true)}
        testID="EditProfileCountryCodeButton"
        style={{
          backgroundColor: colorInputBackground,
          justifyContent: "center",
          alignItems: "baseline",
          paddingHorizontal: 20,
          borderRadius: 8,
          borderWidth: 1,
          borderColor: colorInputBorder
        }}
      >
        <Text
          style={{
            color: white,
            fontSize: 16
          }}
        >
          {countryFlag
            ? `${countryFlag} ${dialCode}`
            : i18n.t("countryPickerSelectPlaceholder")}
        </Text>
        <CountryCodeSelector
          show={showSelectCountryModal}
          onPrimaryClick={(item: CountryItem) => {
            onCountrySelect(item);
            setShowSelectCountryModal(false);
          }}
          onSecondaryClick={() => {
            setShowSelectCountryModal(false);
            Keyboard.dismiss();
          }}
        />
      </TouchableOpacity>
    );
  };

  if (!isPhoneAdded)
    return (
      <TouchableWithoutFeedback
        onPress={() => {
          if (!isWeb()) Keyboard.dismiss();
        }}
      >
        <SafeAreaView
          style={{
            width: isWeb() ? "100vw" : "100%",
            height: isWeb() ? "100vh" : "100%",
            position: "absolute",
            zIndex: 1000,
            backgroundColor: colorBackground
          }}
        >
          <View
            className={`m-auto p-8 text-center sm:max-w-[50%] xl:max-w-[33%] bg-${colorBackgroundTransparent}`}
          >
            <View style={{ alignItems: "center" }}>
              <SphereOneLogoWhite />
            </View>
            <View
              style={{
                backgroundColor: gray800,
                borderRadius: 8,
                borderWidth: 1,
                borderColor: gray700,
                padding: 32
              }}
            >
              {flow === PhoneCode.INSERT_PHONE && (
                <FormControl style={{ gap: 24 }}>
                  <PinCodeTitle text={i18n.t("verifyPhoneTitle")} />
                  <PinCodeSubtitle text={i18n.t("verifyPhoneText")} />
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap"
                    }}
                  >
                    <CountrySelector />
                    <Input
                      keyboardType="phone-pad"
                      onChangeText={(t) => setPhone(t)}
                      defaultValue={phone}
                      value={phone}
                      placeholder={i18n.t("editProfileScreenPhonePlaceholder")}
                      style={{ flex: 1 }}
                    />
                    {phoneError && (
                      <Text
                        style={{
                          textAlign: "left",
                          paddingTop: 10,
                          color: "#dc2626"
                        }}
                      >
                        {phoneError}
                      </Text>
                    )}
                  </View>
                  <PinCodePrimaryButton
                    handleOnPress={async () => {
                      try {
                        await handleOnClickInsertPhone();
                      } catch (e: any) {
                        return setPhoneError(i18n.t("insertPhoneError"));
                      } finally {
                        setLoading(false);
                      }
                    }}
                    text={i18n.t("authScreensContinue")}
                    disabled={loading}
                    isLoading={loading}
                  />
                  <PinCodeSecondaryButton
                    isDisabled={loading}
                    handleOnPress={() => {
                      dispatch(setShowRecoveryFlowModal(false));
                    }}
                    text={i18n.t("appPreviewSkip")}
                  />
                </FormControl>
              )}
              {flow === PhoneCode.INSERT_CODE && (
                <FormControl style={{ gap: 24 }}>
                  <PinCodeTitle text={i18n.t("insertOtpCodeTitle")} />
                  <PinCodeSubtitle text={i18n.t("insertOtpCodeText")} />
                  <Input
                    value={code ?? ""}
                    onChangeText={(e) => {
                      if (e.length <= 6 && !isNaN(Number(e))) {
                        setCode(e);
                      }
                    }}
                    maxLength={6}
                    keyboardType="numeric"
                  />
                  {codeError ? (
                    <Text style={{ color: "#dc2626" }}>{codeError}</Text>
                  ) : (
                    <></>
                  )}
                  <PinCodePrimaryButton
                    handleOnPress={async () => {
                      try {
                        if (!code || !accessToken) return;
                        setLoading(true);
                        setCodeError("");
                        await callFunction({
                          url: "/optverifier",
                          method: "POST",
                          accessToken,
                          data: { phone: phoneFormatted, otpCode: code }
                        });
                        setFlow(PhoneCode.SUCCESS);
                      } catch (e: any) {
                        return setCodeError(i18n.t("insertOtpCodeError"));
                      } finally {
                        setLoading(false);
                      }
                    }}
                    text={i18n.t("authScreensContinue")}
                    disabled={loading}
                    isLoading={loading}
                  />
                  <PinCodeSecondaryButton
                    isDisabled={loading}
                    handleOnPress={() => {
                      dispatch(setShowRecoveryFlowModal(false));
                    }}
                    text={i18n.t("back")}
                  />
                </FormControl>
              )}

              {flow === PhoneCode.SUCCESS && (
                <FormControl style={{ gap: 24 }}>
                  <View style={{ alignItems: "center" }}>
                    <GreenCheckCircleIcon />
                  </View>
                  <PinCodeTitle text={i18n.t("verifyPhoneSuccess")} />
                  <PinCodePrimaryButton
                    text={i18n.t("authScreensContinue")}
                    handleOnPress={() => {
                      dispatch(setIsPhoneAdded());
                      dispatch(setShowRecoveryFlowModal(false));
                    }}
                  />
                </FormControl>
              )}
            </View>
          </View>
        </SafeAreaView>
      </TouchableWithoutFeedback>
    );
  else
    return (
      <TouchableWithoutFeedback
        onPress={() => {
          if (!isWeb()) Keyboard.dismiss();
        }}
      >
        <SafeAreaView
          style={{
            width: isWeb() ? "100vw" : "100%",
            height: isWeb() ? "100vh" : "100%",
            position: "absolute",
            zIndex: 1000,
            backgroundColor: colorBackground
          }}
        >
          <View
            className={`m-auto p-8 text-center sm:max-w-[50%] xl:max-w-[33%] bg-${colorBackgroundTransparent}`}
          >
            <View style={{ alignItems: "center" }}>
              <SphereOneLogoWhite />
            </View>
            <View
              style={{
                backgroundColor: gray800,
                borderRadius: 8,
                borderWidth: 1,
                borderColor: gray700,
                padding: 32
              }}
            >
              {flow === PhoneCode.INSERT_PHONE && (
                <FormControl style={{ gap: 24 }}>
                  <PinCodeTitle text={i18n.t("forgotPIN")} />
                  <PinCodeSubtitle text={i18n.t("insertPhoneText")} />
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap"
                    }}
                  >
                    <CountrySelector />
                    <Input
                      keyboardType="phone-pad"
                      onChangeText={(t) => setPhone(t)}
                      defaultValue={phone}
                      value={phone}
                      placeholder={i18n.t("editProfileScreenPhonePlaceholder")}
                      style={{ flex: 1 }}
                    />
                    {phoneError && (
                      <Text
                        style={{
                          textAlign: "left",
                          paddingTop: 10,
                          color: "#dc2626"
                        }}
                      >
                        {phoneError}
                      </Text>
                    )}
                  </View>
                  <PinCodePrimaryButton
                    handleOnPress={async () => {
                      try {
                        await handleOnClickInsertPhone();
                      } catch (e: any) {
                        return setPhoneError(i18n.t("insertPhoneError"));
                      } finally {
                        setLoading(false);
                      }
                    }}
                    text={i18n.t("authScreensContinue")}
                    disabled={loading}
                    isLoading={loading}
                  />
                  <PinCodeSecondaryButton
                    isDisabled={loading}
                    handleOnPress={() => {
                      dispatch(setShowRecoveryFlowModal(false));
                    }}
                    text={i18n.t("back")}
                  />
                </FormControl>
              )}
              {flow === PhoneCode.INSERT_CODE && (
                <FormControl style={{ gap: 24 }}>
                  <PinCodeTitle text={i18n.t("insertOtpCodeTitle")} />
                  <PinCodeSubtitle text={i18n.t("insertOtpCodeText")} />
                  <Input
                    value={code ?? ""}
                    onChangeText={(e) => {
                      if (e.length <= 6 && !isNaN(Number(e))) {
                        setCode(e);
                      }
                    }}
                    maxLength={6}
                    keyboardType="numeric"
                  />
                  {codeError ? (
                    <Text style={{ color: "#dc2626" }}>{codeError}</Text>
                  ) : (
                    <></>
                  )}
                  <PinCodePrimaryButton
                    handleOnPress={async () => {
                      try {
                        if (!code || !accessToken) return;
                        setLoading(true);
                        setCodeError("");
                        await callFunction({
                          url: "/user/recovery/pin",
                          method: "POST",
                          accessToken,
                          data: { phone: phoneFormatted, otpCode: code }
                        });
                        setFlow(PhoneCode.SUCCESS);
                      } catch (e: any) {
                        return setCodeError(i18n.t("insertOtpCodeError"));
                      } finally {
                        setLoading(false);
                      }
                    }}
                    text={i18n.t("verify")}
                    disabled={loading}
                    isLoading={loading}
                  />
                  <PinCodeSecondaryButton
                    isDisabled={loading}
                    handleOnPress={() => {
                      setCode("");
                      setCodeError("");
                      setFlow(PhoneCode.INSERT_PHONE);
                    }}
                    text={i18n.t("back")}
                  />
                </FormControl>
              )}

              {flow === PhoneCode.SUCCESS && (
                <FormControl style={{ gap: 24 }}>
                  <View style={{ alignItems: "center" }}>
                    <GreenCheckCircleIcon />
                  </View>
                  <PinCodeTitle text={i18n.t("pinCodeSuccessfullyReset")} />
                  <PinCodePrimaryButton
                    text={i18n.t("authScreensContinue")}
                    handleOnPress={() => {
                      dispatch(setPinCodeSetup(false));
                      dispatch(setShowRecoveryFlowModal(false));
                    }}
                  />
                </FormControl>
              )}
            </View>
          </View>
        </SafeAreaView>
      </TouchableWithoutFeedback>
    );
}

export default RecoveryFlowScreen;
