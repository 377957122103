import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { CashoutStackNavigatorParamList } from "../../types/navigationTypes";
import PaymentMethodScreen from "../screens/PaymentMethodScreen";

// ================ CASHOUT STACK ================ //
const CashoutStack =
  createNativeStackNavigator<CashoutStackNavigatorParamList>();

export function CashoutNavigator() {
  return (
    <CashoutStack.Navigator>
      <CashoutStack.Screen
        name="PaymentMethodScreen"
        component={PaymentMethodScreen}
        options={{ headerShown: false }}
      />
    </CashoutStack.Navigator>
  );
}
