import { SupportedChains } from "../utils/supportedChains";

export const preferredChains = [
  SupportedChains.POLYGON,
  SupportedChains.BINANCE,
  SupportedChains.SOLANA,
  SupportedChains.AVALANCHE
];

export const feesAndLimits = {
  swapFeeMultiplier: 1.1,
  bridgingFeeMultiplier: 1.25,
  minimumEthereumAmount: 15
};
