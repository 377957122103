import { useEffect } from "react";
// import dynamicLinks, {
//   FirebaseDynamicLinksTypes,
// } from "@react-native-firebase/dynamic-links";
export const useDynamicLinks = () => {
  useEffect(() => {
    // const unsubscribe = dynamicLinks().onLink((link) => {
    //   handleLinks(link);
    // });
    // When the component is unmounted, remove the listener
    // return () => unsubscribe();
  }, []);

  useEffect(() => {
    // dynamicLinks()
    //   .getInitialLink()
    //   .then((link) => handleLinks(link));
  }, []);
};

export const handleLinks = async () => {
  // //TODO add logic to every link that we use
  // if (link?.url.includes("invitedBy")) {
  //   const referral = link.url.split("invitedBy=")[1];
  //   //Saves the referralId until you register
  //   await AsyncStorage.setItem("@invitedBy", referral);
  // }
  // // payment charge
  // if (link?.url.includes("charge")) {
  //   // url format: https://sphereone.xyz/?charge=nsMbkOC3FYoKw93QDWfu&key=2uMS8TmKA82So/fhz3elLdRudXBWQSTAvJgzxUIbs9U=
  //   const url = link.url;
  //   const splitUrl = url.split("&");
  //   const chargeId = splitUrl[0].split("=")[1];
  //   await setCharge(chargeId);
  // }
  // if (link?.url.includes("key")) {
  //   const key = decodeURIComponent(link.url.split("key=")[1]);
  //   await setTempWalletKey(key);
  // }
  // // request charge
  // if (link?.url.includes("requestCharge")) {
  //   const charge = decodeURIComponent(link.url.split("requestCharge=")[1]);
  //   await setRequestCharge(charge);
  // }
};
