import React, { useEffect } from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  LoginNavigationProps,
  LoginStackParamList
} from "../../types/navigationTypes";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useNavigation } from "@react-navigation/native";
import { OnboardingStatus } from "../redux/slices/user";
import { hideSplashScreen } from "../redux/slices/splash";
import { isWeb } from "../utils/platform";
import AppPreviewScreen from "../screens/Onboarding/AppPreviewScreen";
import LandingScreen from "../screens/Onboarding/LandingScreen";
import PinCodeSetup from "../screens/Onboarding/PinCodeSetup";
import RecoverWalletScreen from "../screens/Profile/RecoverWallet";
import AskPushNotificationsScreen from "../screens/Onboarding/NotificationsScreen";
import { useCustomAuth } from "../hooks/useAuth";
import WalletActionScreen from "../screens/WalletActionScreen";

type Onboarding = "PREVIEW" | "LANDING_SCREEN" | "NOTIFICATIONS" | "PINCODE";

type OnboardingScreenNames =
  | "AppPreview"
  | "LandingScreen"
  | "NotificationsScreen"
  | "PinCodeSetup";

// ================ LOGIN STACK ================ //
const LoginStack = createNativeStackNavigator<LoginStackParamList>();

export function LoginNavigator() {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<LoginNavigationProps>();
  const { user } = useCustomAuth();
  const displaySplash = useAppSelector((state) => state.splash.display);
  const onboardingStatus = useAppSelector(
    (state) => state.user.onboardingStatus
  );

  useEffect(() => {
    if (
      onboardingStatus &&
      onboardingStatus !== OnboardingStatus.ONBOARD &&
      user
    ) {
      const onboardingNavigationMap: {
        [key in Onboarding]: OnboardingScreenNames;
      } = {
        [OnboardingStatus.PREVIEW]: "AppPreview",
        [OnboardingStatus.LANDING_SCREEN]: "LandingScreen",
        [OnboardingStatus.NOTIFICATIONS]: "NotificationsScreen",
        [OnboardingStatus.PINCODE]: "PinCodeSetup"
      };

      const destination = onboardingNavigationMap[onboardingStatus];

      if (destination) {
        navigation.navigate(destination);
      }
      dispatch(hideSplashScreen());
    } else if (!user && displaySplash) {
      dispatch(hideSplashScreen());
    }
  }, [onboardingStatus, user]);

  return (
    <LoginStack.Navigator>
      <LoginStack.Group screenOptions={{ headerShown: false }}>
        {!isWeb() && (
          <LoginStack.Screen name="AppPreview" component={AppPreviewScreen} />
        )}
        <LoginStack.Screen name="LandingScreen" component={LandingScreen} />
        <LoginStack.Screen
          name="NotificationsScreen"
          component={AskPushNotificationsScreen}
        />
        {!isWeb() && (
          <LoginStack.Screen name="PinCodeSetup" component={PinCodeSetup} />
        )}
        <LoginStack.Screen
          name="RecoverWalletScreen"
          component={RecoverWalletScreen}
        />
        <LoginStack.Screen
          name="WalletActionScreen"
          component={WalletActionScreen}
        />
      </LoginStack.Group>
    </LoginStack.Navigator>
  );
}
