import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { SplashStackNavigatorParamList } from "../../types/navigationTypes";
import SplashScreen from "../screens/SplashScreen";

const SplashStack = createNativeStackNavigator<SplashStackNavigatorParamList>();

export function SplashNavigator() {
  return (
    <SplashStack.Navigator>
      <SplashStack.Group screenOptions={{ headerShown: false }}>
        <SplashStack.Screen name="Splash" component={SplashScreen} />
      </SplashStack.Group>
    </SplashStack.Navigator>
  );
}
