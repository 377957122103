import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { LinkedAccountsParamsList } from "../../types/navigationTypes";
import LinkedAccounts from "../screens/Profile/LinkedAccounts";
import WalletActionScreen from "../screens/WalletActionScreen";

const LinkedAccountsStack =
  createNativeStackNavigator<LinkedAccountsParamsList>();

export const LinkedAccountsNavigation = () => {
  return (
    <LinkedAccountsStack.Navigator>
      <LinkedAccountsStack.Screen
        name="LinkedAccounts"
        component={LinkedAccounts}
        options={{ headerShown: false }}
      />
      <LinkedAccountsStack.Screen
        name="WalletActionScreen"
        component={WalletActionScreen}
        options={{ headerShown: false }}
      />
    </LinkedAccountsStack.Navigator>
  );
};
