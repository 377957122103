import React, { useEffect } from "react";
import { Image, StyleSheet, Animated, Easing, View } from "react-native";
import logo from "../assets/images/SphereIconLogoGreen.png";
import SafeAreaView from "./ThemedComponents/SafeAreaView";

const Loading = () => {
  const spinValue = new Animated.Value(0);
  const rotate = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "360deg"]
  });

  const spin = () => {
    spinValue.setValue(0);
    Animated.timing(spinValue, {
      toValue: 1,
      duration: 2000,
      easing: Easing.linear,
      useNativeDriver: true
    }).start(() => {
      spin();
    });
  };
  useEffect(() => {
    spin();
  }, []);

  return (
    <View>
      <Animated.View style={[styles.container, { transform: [{ rotate }] }]}>
        <Image style={styles.image} source={logo} />
      </Animated.View>
    </View>
  );
};

export const styles = StyleSheet.create({
  container: {
    marginTop: 20
  },
  image: {
    width: 75,
    height: 75
  }
});

export default Loading;
