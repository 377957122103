import React, { ReactNode } from "react";
import { StyleProp, ViewStyle, useWindowDimensions } from "react-native";
import { isWeb, pwaMaxWidth } from "../../utils/platform";
import { Edge, SafeAreaView as View } from "react-native-safe-area-context";
import { colorBackground } from "../../utils/colors";

interface SafeAreaViewProps {
  style?: StyleProp<ViewStyle>;
  children?: ReactNode;
  edges?: Edge[];
  className?: string;
}

export default function SafeAreaView({
  style,
  children,
  edges,
  ...props
}: SafeAreaViewProps) {
  const dimensions = useWindowDimensions();
  return (
    <View
      {...props}
      style={[
        {
          flex: 1,
          backgroundColor: colorBackground
        },
        isWeb() && {
          width: pwaMaxWidth(dimensions),
          alignSelf: "center"
        },
        style
      ]}
      edges={edges}
    >
      {children}
    </View>
  );
}
