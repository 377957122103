import React from "react";
import { createDrawerNavigator } from "@react-navigation/drawer";
import CustomDrawer from "./CustomDrawer.web";
import {
  ClockIcon,
  CurrencyDollarIcon,
  HomeIcon,
  UserIcon,
  BanknotesIcon,
  LinkIcon
} from "react-native-heroicons/outline";
import TransactionsScreen from "../screens/TransactionsScreen";
import { ProfileNavigator } from "./ProfileNavigator";
import { HomeNavigator } from "./HomeNavigator";
import { CheckoutNavigator } from "./CheckoutNavigator.";
import { LinkedAccountsNavigation } from "./LinkedAccountsNavigator";

const Drawer = createDrawerNavigator();

export function DrawerTabNavigator() {
  return (
    <Drawer.Navigator
      drawerContent={(props) => <CustomDrawer {...props} />}
      initialRouteName="Home"
      screenOptions={{
        headerTintColor: "white",
        headerTransparent: false,
        headerStyle: { backgroundColor: "transparent" }
      }}
    >
      <Drawer.Screen
        name="Home"
        component={HomeNavigator}
        options={{
          drawerIcon: ({ color }) => (
            <HomeIcon testID="HomeIcon" color={color} size={20} />
          )
        }}
      />
      <Drawer.Screen
        name="Activity"
        component={TransactionsScreen}
        options={{
          drawerIcon: ({ color }) => <ClockIcon color={color} size={20} />
        }}
      />
      <Drawer.Screen
        name="Buy"
        component={CheckoutNavigator}
        options={{
          title: "Buy",
          drawerIcon: ({ color }) => <BanknotesIcon color={color} size={20} />,
          headerShown: false
        }}
      />
      <Drawer.Screen
        name="Profile"
        component={ProfileNavigator}
        options={{
          drawerIcon: ({ color }) => <UserIcon color={color} size={20} />
        }}
      />
      <Drawer.Screen
        name="LinkedAccounts"
        component={LinkedAccountsNavigation}
        options={{
          title: "Linked Accounts",
          drawerIcon: ({ color }) => <LinkIcon color={color} size={20} />,
          drawerLabel: "Linked Accounts"
        }}
      />
    </Drawer.Navigator>
  );
}
