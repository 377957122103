import React from "react";
import { CountryPicker } from "react-native-country-codes-picker";
import { CountryItem } from "react-native-country-codes-picker/types/Types";
import i18n, {
  cellphoneLanguage
} from "../../config/languageInternationalization";
import {
  backdropCountryCode,
  colorBackground,
  colorInputBackground,
  colorInputBorder,
  colorInputPlaceholder
} from "../../utils/colors";

export type CountrySelectorProps = {
  show: boolean;
  onPrimaryClick?: (countryCode: CountryItem) => void;
  onSecondaryClick?: () => void;
};

/**
 * Stylized Country Picker from "react-native-country-codes-picker"
 * to fit with app style.
 * However, there are certain UI limitations that cannot be achieved.
 *
 * NOTE: May need to implement custom Country Picker component instead
 * of relying on third-party library, if more functionalities are desired.
 *
 * @param show Show or Hide UI component
 * @param onPrimaryClick Function to trigger when user selects a country
 * @param onSecondaryClick Function to trigger when user clicks outside of the Country Picker
 *
 * @returns Stylized CountryPicker component
 */
export default function CountryCodeSelector({
  show,
  onPrimaryClick,
  onSecondaryClick
}: CountrySelectorProps) {
  return (
    <CountryPicker
      lang={cellphoneLanguage}
      show={show}
      pickerButtonOnPress={(item: CountryItem) => {
        onPrimaryClick && onPrimaryClick(item);
      }}
      onBackdropPress={() => {
        onSecondaryClick && onSecondaryClick();
      }}
      inputPlaceholder={i18n.t("countryPickerSearchPlaceholder")}
      placeholderTextColor={colorInputPlaceholder}
      style={{
        modal: {
          backgroundColor: colorBackground,
          maxHeight: "95%",
          minHeight: "95%",
          maxWidth: "100%",
          minWidth: "100%"
        },
        backdrop: { backgroundColor: backdropCountryCode },
        line: { backgroundColor: colorBackground },
        itemsList: { backgroundColor: colorBackground },
        textInput: {
          backgroundColor: colorInputBackground,
          borderRadius: 8,
          padding: 10,
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 1,
          borderColor: colorInputBorder,
          fontSize: 14,
          color: "white",
          fontFamily: "DMSans-Regular"
        },
        countryButtonStyles: {
          backgroundColor: colorInputBackground
        },
        countryName: {
          color: "white"
        },
        dialCode: { color: "white" }
      }}
    />
  );
}
