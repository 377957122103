export const formatDeleteAccountEmail = ({
  uid,
  name
}: {
  uid?: string | null;
  name?: string;
}) => {
  return {
    subject: encodeURIComponent("Account Deletion Request"),
    body: encodeURIComponent(`Hello,\n\n
    Account Deletion Request has been sent for:\n
    User ID: ${uid}\n
    Name: ${name}\n\n
    I understand that this process can take up 6 to 7 days. In order to complete, all funds and assets must be transferred, along with private keys backed up.\n
    If this is not the case, you will receive an email from the Support Team before your account is deleted.\n
    Otherwise, you will receive a new confirmation email when your account has been deleted successfully.`)
  };
};
