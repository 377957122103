import i18n from "../../../config/languageInternationalization";
import { FilterOption, HomeFilterType } from "../../../../types/filterTypes";

export const HomeFilters: FilterOption[] = [
  {
    name: i18n.t("homeScreenAccountsTitle"),
    type: HomeFilterType.ACCOUNT
  },
  {
    name: i18n.t("homeScreenCollectiblesTitle"),
    type: HomeFilterType.COLLECTIBLES
  }
];
