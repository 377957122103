import React from "react";
import { Text, TextProps } from "react-native";
import { colorAlert } from "../../utils/colors";

export default function FormErrorMessage(props: TextProps) {
  const { style, ...otherProps } = props;
  return (
    <Text
      style={[
        {
          color: colorAlert,
          fontSize: 12
        },
        style
      ]}
      {...otherProps}
    >
      *{props.children}
    </Text>
  );
}
