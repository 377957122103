import React, { useEffect } from "react";
import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { LogBox } from "react-native";
import Toast from "react-native-toast-message";
import Navigation from "./src/navigation";
import { store, persistor } from "./src/redux/store";
import { Provider } from "react-redux";
import { AppRegistry } from "react-native";

import useColorScheme from "./src/hooks/useColorScheme";
import useCachedResources from "./src/hooks/useCachedResources";

import { useToken } from "./src/hooks/useNotifications";
import { PersistGate } from "redux-persist/integration/react";
import { fetchRemoteConfig } from "./src/utils/fetchRemoteConfig";
import { isWeb, isWebViewedOnIOS } from "./src/utils/platform";
import { handleBackgroundNotifications } from "./src/utils/pushNotifications";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProviderComponent } from "./src/components/AuthProviderComponent";
import { withExpoSnack } from "nativewind";
import { setMerchantParams } from "./src/utils/setMerchantParams";
import axios from "axios";
import { PWA_DOMAIN } from "@env";

//------------------
const state = store.getState();
const accessToken = state.user.accessToken;
handleBackgroundNotifications({ accessToken });
// BACKGROUND

//------------------
// Headless
// this is used for iPhone.
function HeadlessCheck({ isHeadless }: { isHeadless: boolean }) {
  if (isHeadless) {
    // App has been launched in the background by iOS, ignore
    return null;
  }

  return <App />;
}

AppRegistry.registerComponent("app", () => HeadlessCheck);

function App() {
  LogBox.ignoreAllLogs();

  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  const queryClient = new QueryClient();

  useEffect(() => {
    fetchRemoteConfig();
    if (isWeb()) {
      setMerchantParams();
      if (!isWebViewedOnIOS()) {
        if (Notification.permission === "granted") {
          useToken();
        }
      }
    } else {
      useToken();
    }
  }, []);

  useEffect(() => {
    if (!isWeb()) axios.defaults.headers.common["origin"] = PWA_DOMAIN;
  }, []);

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AuthProviderComponent>
              <SafeAreaProvider>
                <Navigation colorScheme={colorScheme} />
                <StatusBar style="light" />
                <Toast position="top" />
              </SafeAreaProvider>
            </AuthProviderComponent>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    );
  }
}

export default isWeb() ? withExpoSnack(App) : App;
