import React, { useEffect } from "react";

export default function useDisableBackNavigation({
  navigation
}: {
  navigation: any;
}) {
  useEffect(() => {
    const unsubscribe = navigation.addListener("beforeRemove", (e: any) => {
      if (e.data.action.type === "GO_BACK") {
        e.preventDefault();
        //"GO_BACK" is emitted by hardware button

        //  navigation.dispatch(e.data.action);
      }
    });
    return () => unsubscribe();
  }, [navigation]);
  return null;
}
