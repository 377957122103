import { createSlice } from "@reduxjs/toolkit";

const splashSlice = createSlice({
  name: "splash",
  initialState: {
    display: true
  },
  reducers: {
    hideSplashScreen: (state) => {
      state.display = false;
    }
  }
});

export const { hideSplashScreen } = splashSlice.actions;
export default splashSlice.reducer;
