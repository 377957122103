import { StyleSheet } from "react-native";
import {
  colorBackground,
  colorBorder,
  colorGrayMessageText,
  colorProfileTitleText
} from "../../utils/colors";
import { getWindowHeight, isAndroid } from "../../utils/platform";

export const hairlineWidth = StyleSheet.hairlineWidth;
export const actionSheetStyles = StyleSheet.create({
  overlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    opacity: 0.5,
    backgroundColor: "black"
  },
  wrapper: {
    flex: 1,
    flexDirection: "row"
  },
  body: {
    flex: 1,
    alignSelf: "flex-end",
    paddingHorizontal: 10,
    backgroundColor: "transparent",
    marginBottom: isAndroid()
      ? getWindowHeight() * 0.01
      : getWindowHeight() * 0.03
  },
  titleBox: {
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colorBackground,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10
  },
  titleText: {
    color: colorProfileTitleText,
    fontSize: 12,
    lineHeight: 18,
    fontWeight: "400"
  },
  messageBox: {
    height: 30,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white"
  },
  messageText: {
    color: colorGrayMessageText,
    fontSize: 12
  },
  buttonBox: {
    height: 50,
    marginTop: hairlineWidth,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colorBackground,
    borderTopColor: colorBorder,
    borderWidth: 0.5
  },
  buttonText: {
    fontSize: 18
  },
  cancelButtonBox: {
    height: 50,
    marginTop: 6,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colorBackground,
    marginBottom: 10
  }
});

export default actionSheetStyles;
