import { useEffect } from "react";
import { msg } from "../utils/firebase.web";
import { onMessageReceived } from "../utils/pushNotifications.web";
import { recordError } from "../utils/crashlytics";
import AsyncStorage from "@react-native-async-storage/async-storage";

// ADD LISTENER FOR FOREGROUND NOTIFICATION
export function useForegroundNotifications({
  navigation
}: {
  navigation: any;
}) {
  useEffect(() => {
    if (!msg) return;

    try {
      //listener foreground FCM
      msg.onMessage((payload) => onMessageReceived(payload, navigation));
    } catch (e) {
      recordError(e, "useNotifications.ts");
    }
  }, []);
}

// Get the device token
export async function useToken() {
  if (!msg) return;

  try {
    msg.getToken().then(async (token) => {
      await AsyncStorage.setItem("@FCMtoken", token);
    });
  } catch (e) {
    recordError(e, "useNotifications.ts");
  }
}
