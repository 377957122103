import React from "react";
import { CheckIcon } from "react-native-heroicons/outline";
import { CheckCircleIcon } from "react-native-heroicons/solid";
import Header from "../components/Header";
import { Text, View } from "../components/Themed";
import PrimaryButton from "../components/ThemedComponents/PrimaryButton";
import i18n from "../config/languageInternationalization";
import { checkMarkGreen, colorBackground } from "../utils/colors";
import { TransferType } from "../../types/transfer";
import { isWeb } from "../utils/platform";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { P2PTransferStackNavigatorParamList } from "../../types/navigationTypes";
import SafeAreaView from "../components/ThemedComponents/SafeAreaView";

export default function SuccessScreen({
  navigation,
  route
}: NativeStackScreenProps<P2PTransferStackNavigatorParamList, "Success">) {
  const { type, receiver, amount, tokenSymbol, link } = route.params;

  function displayContent() {
    switch (type) {
      case TransferType.PAY:
        return (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <View>
              <CheckIcon size={100} color={checkMarkGreen} />
            </View>
            <View style={{ marginTop: 25, marginStart: 75, marginEnd: 75 }}>
              <Text style={{ fontSize: 25, textAlign: "center" }}>
                {i18n.t("successScreenYouSent", {
                  amount: amount,
                  currency: tokenSymbol,
                  sendeeName: receiver.address
                })}
              </Text>
              <Text
                style={{
                  fontSize: 15,
                  color: "lightgray",
                  textAlign: "center",
                  marginTop: 25
                }}
              >
                {i18n.t("successScreenPayPleaseWait")}
              </Text>
            </View>
          </View>
        );
      case TransferType.RECEIVE:
        return (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <View>
              <CheckCircleIcon size={100} color={checkMarkGreen} />
            </View>
            <View style={{ marginTop: 25, marginStart: 80, marginEnd: 80 }}>
              <Text style={{ fontSize: 25, textAlign: "center" }}>
                {i18n.t("successScreenYouRequested", {
                  amount: amount,
                  sendeeName: receiver.address,
                  currency: tokenSymbol
                })}
              </Text>
              <Text
                style={{
                  fontSize: 15,
                  color: "lightgray",
                  textAlign: "center",
                  marginTop: 25
                }}
              >
                {i18n.t("successScreenReceivePleaseWait")}
              </Text>
            </View>
          </View>
        );
      case TransferType.CASH_IN:
        return (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <View>
              <CheckCircleIcon size={100} color={checkMarkGreen} />
            </View>
            <View style={{ marginTop: 25, marginStart: 80, marginEnd: 80 }}>
              <Text style={{ fontSize: 25, textAlign: "center" }}>
                {i18n.t("successScreenYouAdded", { amount: amount })}
              </Text>
              <Text
                style={{
                  fontSize: 15,
                  color: "lightgray",
                  textAlign: "center",
                  marginTop: 25
                }}
              >
                {i18n.t("successScreenMoneyWillAppearInYourBankAccount")}
              </Text>
            </View>
          </View>
        );

      default:
        return <View />;
    }
  }

  return (
    <SafeAreaView style={{ paddingTop: -80 }}>
      <Header onBackPress={() => navigation.popToTop()} xIcon />
      {displayContent()}
      {isWeb() && link && (
        <Text
          style={{
            fontSize: 15,
            color: "lightgray",
            textAlign: "center",
            marginVertical: 25
          }}
        >
          {i18n.t("shareLink", { name: receiver.address, link })}
        </Text>
      )}
      <View
        style={
          isWeb()
            ? { paddingBottom: 44, marginTop: 20 }
            : {
                flexDirection: "row",
                paddingHorizontal: 16,
                paddingBottom: 44
              }
        }
      >
        <PrimaryButton
          style={isWeb() ? { width: 400, alignSelf: "center" } : { flex: 1 }}
          onPress={() => navigation.popToTop()}
        >
          {i18n.t("successScreenDone")}
        </PrimaryButton>
      </View>
    </SafeAreaView>
  );
}
