import { createSlice } from "@reduxjs/toolkit";
import { getTheme } from "./themeThunk";

export interface ThemeData {
  name: string;
  primaryColor: string;
  logo: string;
}

export interface ThemeState {
  merchantTheme?: ThemeData;
  loading: boolean;
}

const initialState: ThemeState = {
  loading: false
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTheme.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTheme.fulfilled, (state, action) => {
      state.loading = false;
      state.merchantTheme = action.payload;
    });
    builder.addCase(getTheme.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default themeSlice.reducer;
