import { setAskingPermission } from "../redux/slices/user";
import { AppState, AppStateStatus } from "react-native";

export const handleAppStateChange = async ({
  nextAppState,
  isAskingPermissions,
  dispatch,
  setPromptValidation,
  setHasValidated
}: {
  nextAppState: AppStateStatus;
  isAskingPermissions: boolean;
  dispatch: any;
  setPromptValidation: React.Dispatch<React.SetStateAction<boolean>>;
  setHasValidated: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  if (
    nextAppState === "active" &&
    isAskingPermissions &&
    AppState.currentState === "active"
  ) {
    dispatch(setAskingPermission(false));
    return;
  }

  if (
    AppState.currentState.match(/inactive|background/) &&
    nextAppState === "active"
  ) {
    // App came to foreground
    if (AppState.currentState === "background" && !isAskingPermissions) {
      setPromptValidation(true);
    }
  } else {
    if (isAskingPermissions) return;
    setPromptValidation(false);
    setHasValidated(false);
  }
};
