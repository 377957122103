import React, { useState } from "react";
import { colorCollectableTextBackground } from "../../utils/colors";
import { Text, View } from "../Themed";
import { Image, TouchableOpacity } from "react-native";
import { isEmptyString } from "../../utils/validation";
import { SupportedChains } from "../../utils/supportedChains";

export default function CollectableCard({
  name,
  image,
  tokenId,
  chain,
  nftTokenAddress,
  fromAddress,
  nft,
  setNft,
  setModal
}: {
  name: string;
  image: string;
  tokenId: string;
  chain?: SupportedChains;
  nftTokenAddress?: string;
  fromAddress?: string;
  nft: any;
  setNft: (x: any) => void;
  setModal: (x: boolean) => void;
}) {
  const [imageSrc, setImageSrc] = useState<string>(image);
  const fallbackImage =
    "https://firebasestorage.googleapis.com/v0/b/spheremvp.appspot.com/o/og%2Fbroken-image-fallback-3.png?alt=media&token=603d5ccb-4d45-4866-8d5a-2b149a8657fe";

  return (
    <TouchableOpacity
      onPress={() => {
        if ([chain, nftTokenAddress, fromAddress].includes(undefined)) return;
        else {
          setNft({
            ...nft,
            name,
            tokenId,
            chain,
            nftTokenAddress,
            fromAddress
          });
          setModal(true);
        }
      }}
    >
      <Image
        source={{ uri: imageSrc }}
        resizeMethod={"auto"}
        onError={() => {
          // NOTE: purposely not setting the fallback image here
          // because we want to show an empty spot for empty Collectibles
          if (isEmptyString(imageSrc)) return;
          // NOTE: Should only show the fallback image if <Image> fails to load
          // an actual image url.
          setImageSrc(fallbackImage);
        }}
        style={{
          height: 100,
          width: 100,
          borderRadius: 6
        }}
      />
      {name && (
        <Text
          ellipsizeMode={"tail"}
          numberOfLines={1}
          style={{
            backgroundColor: colorCollectableTextBackground,
            justifyContent: "center",
            alignContent: "flex-start",
            marginHorizontal: 8,
            marginVertical: 8,
            paddingHorizontal: 8,
            paddingVertical: 8,
            borderRadius: 6,
            position: "absolute",
            bottom: 0,
            width: "89%"
          }}
        >
          {name}
        </Text>
      )}
    </TouchableOpacity>
  );
}
