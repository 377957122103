import {
  OnrampProviders,
  OnrampCreateOrder,
  OnrampGenericResult,
  OnrampGetFiatsResult,
  OnrampGetTokenPrice,
  OnrampGetTokenPriceResponse,
  OnrampPaymentMethod,
  WertData,
  OnrampGetTokenType,
  OnrampTokenTypeResponse
} from "../../../../types/onRamp";
import { isWeb } from "../../../utils/platform";
import { callFunction } from "../../../utils/server";
import * as WebBrowser from "expo-web-browser";
import WertWidget from "@wert-io/widget-initializer";
import { colorPrimary } from "../../../utils/colors";
import { recordError } from "../../../utils/crashlytics";
import { Alert } from "../../../utils/Alert";
import i18n from "../../../config/languageInternationalization";
import { SupportedChains } from "../../../utils/supportedChains";
import { DisplayPaymentMethod } from "../../../utils/validatePaymentMethods";

export class Onramp {
  name: OnrampProviders;
  private accessToken?: string;
  constructor(provider: OnrampProviders, accessToken?: string) {
    this.name = provider.toLowerCase() as OnrampProviders;
    this.accessToken = accessToken;
  }

  setProvider(provider: OnrampProviders) {
    this.name = provider;
  }

  async getSupportedChains(
    fiatSymbol: string,
    countryCode: string
  ): Promise<OnrampGenericResult[] | undefined> {
    try {
      const { data: res, error } = await callFunction({
        url: `/onramp/${this.name}/supported-chains?fiatSymbol=${fiatSymbol}&countryCode=${countryCode}`,
        method: "GET"
      });
      if (error) throw new Error(error);
      return res;
    } catch (error: any) {
      recordError(error, "Onramp get supported chains");
      Alert.alert(i18n.t("onRampCantGetSupportedChains"));
    }
  }

  async getSupportedTokens(
    chain: string,
    fiatSymbol: string,
    countryCode: string
  ): Promise<OnrampGenericResult[] | undefined> {
    try {
      const { data: res, error } = await callFunction({
        url: `/onramp/${this.name}/supported-tokens?chain=${chain}&fiatSymbol=${fiatSymbol}&countryCode=${countryCode}`,
        method: "GET"
      });
      if (error) throw new Error(error);
      return res;
    } catch (error: any) {
      recordError(error, "Onramp get supported tokens");
      Alert.alert(i18n.t("onRampCantGetSupportedTokens"));
    }
  }

  async getSupportedFiats(
    fiatSymbol: string,
    countryCode: string
  ): Promise<OnrampGetFiatsResult | undefined> {
    try {
      const { data: res, error } = await callFunction({
        url: `/onramp/${this.name}/supported-fiats?fiatSymbol=${fiatSymbol}&countryCode=${countryCode}`,
        accessToken: this.accessToken,
        method: "GET"
      });
      if (error) throw new Error(error);
      return res;
    } catch (error: any) {
      recordError(error, "Onramp get supported fiats");
    }
  }

  async getSupportedPaymentMethods(
    fiatSymbol: string,
    chain?: string,
    tokenSymbol?: string
  ): Promise<OnrampPaymentMethod[] | undefined> {
    try {
      const { data: res, error } = await callFunction({
        url: `/onramp/${this.name}/payment-methods?fiatSymbol=${fiatSymbol}&chain=${chain}&tokenSymbol=${tokenSymbol}`,
        accessToken: this.accessToken,
        method: "GET"
      });
      if (error) throw new Error(error);
      return res;
    } catch (error: any) {
      recordError(error, "Onramp get payment methods");
      Alert.alert(i18n.t("onRampCantGetPaymentMethods"));
    }
  }

  async getTokenPrice(
    params: OnrampGetTokenPrice
  ): Promise<OnrampGetTokenPriceResponse | undefined> {
    try {
      const token = JSON.stringify(params.token);
      const urlParams = new URLSearchParams(
        JSON.parse(JSON.stringify({ ...params, token }))
      );
      const { data: res, error } = await callFunction({
        url: `/onramp/${this.name}/prices?${urlParams}`,
        method: "GET"
      });
      if (error) throw new Error(error);
      return res;
    } catch (error: any) {
      recordError(error, "Onramp get token price");
    }
  }

  async getTokenType(
    params: OnrampGetTokenType
  ): Promise<OnrampTokenTypeResponse | undefined> {
    try {
      const { data: res, error } = await callFunction({
        url: `/onramp/${this.name}/token-type?fiat=${params.fiat}&countryCode=${params.countryCode}&chain=${params.chain}&token=${params.token}&tokenAddress=${params.tokenAddress}`,
        method: "GET"
      });
      if (error) throw new Error(error);
      return res as OnrampTokenTypeResponse;
    } catch (error: any) {
      recordError(error, "Onramp get token type");
    }
  }

  async getOrder(id: string) {
    try {
      const { data: res, error } = await callFunction({
        url: `/onramp/${this.name}/order/${id}`,
        accessToken: this.accessToken,
        method: "GET"
      });
      if (error) throw new Error(error);
      return res;
    } catch (error: any) {
      recordError(error, "Onramp get order");
      Alert.alert(i18n.t("onRampCantGetOrder"));
    }
  }

  async postOrder(params: OnrampCreateOrder) {
    try {
      const { data: res, error } = await callFunction({
        url: `/onramp/${this.name}/order`,
        accessToken: this.accessToken,
        method: "POST",
        data: params
      });
      if (error) throw new Error(error);
      return res;
    } catch (error: any) {
      recordError(error, "Onramp create order");
    }
  }

  async redirect({
    orderResponse,
    setShowWert
  }: {
    orderResponse: string | WertData;
    setShowWert: React.Dispatch<React.SetStateAction<WertData | undefined>>;
  }) {
    try {
      if (this.name === OnrampProviders.WERT) {
        const themeOptions = {
          color_buttons: colorPrimary,
          color_main_text: colorPrimary,
          color_icons: colorPrimary
        };
        const wertObject = { ...(orderResponse as WertData), ...themeOptions };
        if (isWeb()) {
          const wertWidget = new WertWidget(wertObject);
          return wertWidget.mount();
        }
        return setShowWert(wertObject);
      } else {
        if (isWeb()) {
          Alert.alert(
            i18n.t("onRampPopupMsg", {
              orderResponse: orderResponse
            })
          );
        }
        return await WebBrowser.openBrowserAsync(orderResponse as string);
      }
    } catch (error: any) {
      recordError(error, "Onramp redirect to provider");
      Alert.alert(i18n.t("onRampCantCreateOrder"));
    }
  }

  // Validate amount
  validateAmount(
    tokenAmount: string,
    fiatAmount: string,
    supportedPaymentMethods: OnrampPaymentMethod[] | DisplayPaymentMethod[],
    paymentMethod: string
  ) {
    let isValid = true;
    let type: "minimum" | "maximum" | null = null;
    const selectedPaymentMethod = supportedPaymentMethods?.find(
      (method: OnrampPaymentMethod) => method.value === paymentMethod
    );
    const conversionRate = Number(fiatAmount) / Number(tokenAmount);
    const minInFiat = Number(selectedPaymentMethod?.min);
    const maxInFiat = Number(selectedPaymentMethod?.max);
    const minInToken = minInFiat / conversionRate;
    const maxInToken = maxInFiat / conversionRate;

    if (Number(tokenAmount) < Number(minInToken)) {
      isValid = false;
      type = "minimum";
    } else if (Number(tokenAmount) > Number(maxInToken)) {
      isValid = false;
      type = "maximum";
    }

    return {
      isValid,
      minInFiat,
      maxInFiat,
      minInToken,
      maxInToken,
      type
    };
  }
}

export const providersArray = Object.values(OnrampProviders).map(
  (provider) => ({
    label: provider.toUpperCase(),
    value: provider
  })
);

export const getOnrampProvidersByChain = async (
  chain: SupportedChains,
  fiatSymbol: string,
  countryCode: string,
  accessToken: string
) => {
  try {
    const { data: res, error } = await callFunction({
      url: `/onramp/${chain.toLowerCase()}?fiatSymbol=${fiatSymbol}&countryCode=${countryCode}`,
      accessToken,
      method: "GET"
    });
    if (error) throw new Error(error);
    return res;
  } catch (error: any) {
    recordError(error, "Onramp get providers by chain");
  }
};
