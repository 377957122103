import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { TransferType } from "./transfer";
import { OnrampGenericResult, OnrampProviders } from "./onRamp";
import { SupportedChains } from "../src/utils/supportedChains";
import { UserPaymentData } from "./userPaymentData";
import { DisplayPaymentMethod } from "../src/utils/validatePaymentMethods";

// ================ ROOT STACK ================ //

export type RootStackNavigatorParamList = {
  Root: undefined;
  NotFound: undefined;
  Success: {
    type: TransferType;
    sendee: { name: string; contact?: string; isContact?: boolean };
    amount: number | string;
    currency: string;
    link?: string;
  };
  FrontFinanceTransferScreen: {
    txId: string;
    frontFinanceChainsData: any;
  };
};
export type RootNavigationProps =
  NativeStackNavigationProp<RootStackNavigatorParamList>;

// ================ LOGIN STACK ================ //

export type LoginStackParamList = {
  ProfileScreen: undefined;
  AppPreview: undefined;
  LandingScreen: undefined;
  Register: undefined;
  EditProfileScreen: { fromOnboarding: true };
  RecoverWalletScreen: undefined;
  PinCodeSetup: undefined;
  NotificationsScreen: undefined;
};
export type LoginNavigationProps =
  NativeStackNavigationProp<LoginStackParamList>;

// ================ PROFILE STACK ================ //

export type ProfileStackNavigatorParamList = {
  LandingScreen: undefined;
  ProfileScreen: undefined;
  EditProfileScreen: {
    fromOnboarding: false;
  };
  ReferFriendScreen: undefined;
  ChooseNftScreen: undefined;
  PushNotificationsScreen: undefined;
  PrivacyPolicyScreen: undefined;
  TermsAndConditionsScreen: undefined;
  NotificationsScreen: undefined;
  UpgradeToMerchantScreen: undefined;
  RecoverWalletScreen: undefined;
};

// ================ CASHOUT STACK ================ //

export type CashoutStackNavigatorParamList = {
  CashoutScreen: { type: string };
  PaymentMethodScreen: undefined;
};

export type OnRampNavigatorParmList = {
  OnRamps: undefined;
  OnRampScreen: {
    provider: OnrampProviders;
    fiats: OnrampGenericResult[];
  };
  OnRampAmount: {
    paymentMethod: DisplayPaymentMethod;
    fiat: string;
    countryFlag: string;
    provider: OnrampProviders;
    isLinkFlow: boolean;
  };
  FrontFinanceTransferScreen: {
    txId: string;
    frontFinanceChainsData: any;
  };
};

// ================ LINKED ACCOUNTS STACK ================ //
export type LinkedAccountsParamsList = {
  LinkedAccounts: undefined;
  WalletActionScreen: {
    walletId: string;
    action: WalletActions;
    readOnly: boolean;
  };
};

export enum WalletActions {
  RENAME,
  DELETE,
  PRIVATE_KEY
}

// ================ HOME STACK ================ //

export type HomeStackNavigatorParamList = {
  HomeScreen: undefined;
  CashoutScreen: undefined;
  NftCheckoutScreen: undefined;
  OnRampScreenLink: undefined;
  CheckoutOnramp: { txId: string };
  CheckoutOnrampStatus: { txId: string };
};

// ================ BOTTOM TAB ================ //

export type DrawerTabNavigatorParamList = {
  Home: undefined;
  LinkedAccounts: undefined;
  Amount: { type: string };
  Activity: undefined;
  Profile: undefined;
  Buy: { fromPWA: boolean };
};
export type BottomTabNavigatorParamList = {
  Home: undefined;
  LinkedAccounts: undefined;
  Activity: undefined;
  Profile: undefined;
  P2PTransfer: undefined;
  Buy: { fromPWA: boolean };
};

// ================ P2P TRANSFER STACK ================ //
export interface TransactionReceiver {
  address: string; // email or wallet address
  isUser: boolean;
  isWallet: boolean;
  userPaymentData?: UserPaymentData;
}

export type P2PTransferStackNavigatorParamList = {
  Amount: undefined;
  NotFound: undefined;
  Transfer: {
    type: TransferType;
    chain: string;
    address: string;
    amount: number;
    tokenSymbol: string;
  };
  Success: {
    type: TransferType;
    receiver: TransactionReceiver;
    amount: number | string;
    tokenSymbol: string;
    link?: string;
  };
  TransferDetail: {
    type: TransferType;
    receiver: TransactionReceiver;
    amount: number;
    tokenSymbol: string;
    chain?: SupportedChains;
    message?: string;
    isNotification?: boolean;
  };
  NftCheckoutScreen: undefined;
  OnRampScreenLink: undefined;
};

export type P2PTransferNavigationProps =
  NativeStackNavigationProp<P2PTransferStackNavigatorParamList>;

// ================ SPLASH STACK ================ //
export type SplashStackNavigatorParamList = {
  Splash: undefined;
};

export type SplashNavigationProps =
  NativeStackNavigationProp<SplashStackNavigatorParamList>;

// ================ CHECKOUT STACK ================ //

export type CheckoutStackNavigatorParamList = {
  CheckoutOnramp: undefined;
  CheckoutOnrampStatus: undefined;
  Activity: undefined;
  Login: undefined;
  Home: undefined;
};
