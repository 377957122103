import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { CheckoutStackNavigatorParamList } from "../../types/navigationTypes";
import CheckoutOnrampScreen from "../screens/CheckoutOnrampScreen";
import TransactionsScreen from "../screens/TransactionsScreen";
import { LoginNavigator } from "./LoginNavigator";
import { RootNavigator } from "./RootNavigator";

const CheckoutStack =
  createNativeStackNavigator<CheckoutStackNavigatorParamList>();

export function CheckoutNavigator() {
  return (
    <CheckoutStack.Navigator>
      <CheckoutStack.Screen
        name="CheckoutOnramp"
        component={CheckoutOnrampScreen}
        options={{ headerShown: false }}
      />
      <CheckoutStack.Screen name="Activity" component={TransactionsScreen} />
      <CheckoutStack.Screen
        name="Login"
        component={LoginNavigator}
        options={{ headerShown: false }}
      />
      <CheckoutStack.Screen
        name="Home"
        component={RootNavigator}
        options={{ headerShown: false }}
      />
    </CheckoutStack.Navigator>
  );
}
