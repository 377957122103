import { combineReducers, configureStore } from "@reduxjs/toolkit";
import tokensSlice from "./slices/tokens";
import userSlice from "./slices/user";
import splashSlice from "./slices/splash";
import checkoutOnrampSlice from "./slices/checkoutOnramp";
import themeSlice from "./slices/theme";
import reduxFlipper from "redux-flipper";
import userPaymentDataSlice from "./slices/userPaymentData";
import forceUpdate from "./slices/forceUpdate";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { persistReducer, persistStore } from "redux-persist";

const reducers = combineReducers({
  user: userSlice,
  tokens: tokensSlice,
  userPaymentData: userPaymentDataSlice,
  splash: splashSlice,
  forceUpdate: forceUpdate,
  theme: themeSlice,
  checkoutOnramp: checkoutOnrampSlice
});

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
  blacklist: ["splash", "forceUpdate", "theme", "checkoutOnramp"]
};

const persistedReducer = persistReducer(persistConfig, reducers);

// if NODE_ENV === "development" or undefined (in our local), show redux devtools
// or else, it's production, don't show redux devtools
const showDevTools =
  process.env.NODE_ENV === "development" || process.env.NODE_ENV === undefined;

export const store = configureStore({
  reducer: persistedReducer,
  devTools: showDevTools,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(reduxFlipper())
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
