import axios from "axios";
import { REACT_APP_BASE_URL as baseURL } from "@env";

export const updateDocumentData = async ({
  url,
  accessToken,
  updateData
}: {
  url: string;
  accessToken: string;
  updateData: any;
}) => {
  const response = await axios.put(baseURL + url, updateData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      contentType: "application/json"
    }
  });
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
};

export const getDocumentData = async ({
  url,
  accessToken
}: {
  url: string;
  accessToken?: string;
}) => {
  const response = await axios.get(baseURL + url.toString(), {
    headers: {
      Authorization: accessToken && `Bearer ${accessToken}`,
      contentType: "application/json"
    }
  });
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
};

export const postDocumentData = async ({
  url,
  data,
  accessToken
}: {
  url: string;
  data: { [key: string]: any };
  accessToken: string;
}) => {
  const response = await axios.post(baseURL + url, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      contentType: "application/json"
    }
  });

  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
};

export const deleteProfileImage = async ({
  accessToken,
  thumbnail = false
}: {
  accessToken: string;
  thumbnail?: boolean;
}) => {
  try {
    const response = await axios.delete(
      baseURL + "/user/profileImage" + (thumbnail ? "?type=thumbnail" : ""),
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          contentType: "application/json"
        },
        method: "DELETE"
      }
    );
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return response.data;
  } catch (e: any) {
    if (!e.response.data.error.includes("No such object:"))
      throw new Error(
        typeof e === "string" ? e : "Something went wrong deleting the image"
      );
  }
};

export async function upgradeToMerchant(data: any, accessToken: string) {
  const response = await postDocumentData({
    url: baseURL + "/merchant",
    data: { businessName: data.businessName.toLowerCase() },
    accessToken
  });

  return response.data.data;
}

export async function getUser(accessToken: string) {
  try {
    const { data, error } = await getDocumentData({
      url: "/user",
      accessToken
    });
    if (error) throw new Error(error);
    return data;
  } catch (e) {
    return undefined;
  }
}

export async function getProfileImageApiCall({
  type,
  accessToken
}: {
  type?: "thumbnail";
  accessToken: string;
}) {
  try {
    return await getDocumentData({
      url: `/user/profileImage${type ? "?type=thumbnail" : ""}`,
      accessToken
    });
  } catch (e: any) {
    console.error("error getting profile image", e.response.data.error);
  }
}

export const getUserWallets = async ({
  accessToken
}: {
  accessToken: string;
}) => {
  const response = await axios.get(baseURL + "/user/wallets", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      contentType: "application/json"
    }
  });
  if (response.data.error) throw new Error(response.data.error);
  return response.data;
};

export const callFunction = async ({
  url,
  accessToken,
  method,
  data
}: {
  url: string;
  accessToken?: string;
  method: "GET" | "POST" | "DELETE" | "PUT";
  data?: any;
}) => {
  try {
    const response = await axios.request({
      url: baseURL + url,
      headers: {
        Authorization: accessToken && `Bearer ${accessToken}`,
        contentType: "application/json"
      },
      method,
      data
    });

    return response.data;
  } catch (e: any) {
    throw new Error(formatErrorMessage(e, "callFunction " + url));
  }
};

export const getFundsAvailable = async (
  accessToken: string,
  refreshCache = false
) => {
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseURL}/getFundsAvailable?refreshCache=${refreshCache}`,
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  const res = await axios.request(config);

  return res.data;
};

export const createSardineOrder = async ({
  accessToken,
  data
}: {
  accessToken: string;
  data: any;
}) => {
  const response = await axios.request({
    url: baseURL + "/sardineOrders",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      contentType: "application/json"
    },
    method: "POST",
    data
  });
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
};

export const createOrRecoverWallets = async ({
  accessToken
}: {
  accessToken: string;
}) => {
  const response = await axios.post(
    baseURL + "/createOrRecoverAccount",
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        contentType: "application/json"
      }
    }
  );
  if (response.data.error) throw new Error(response.data.error);
  return response.data;
};

export const formatErrorMessage = (e: any, functionName: string) => {
  if (typeof e === "string") return e;
  if (typeof e === "object") {
    if (typeof e.data?.error === "object" && e?.data?.error?.message) {
      return e?.data?.error?.message;
    } else if (typeof e?.data?.error === "string") {
      return e?.data?.error;
    } else if (
      typeof e?.response?.data?.error === "object" &&
      e?.response?.data?.error?.message
    ) {
      return e?.response?.data?.error?.message;
    } else if (typeof e?.response?.data?.error === "string") {
      return e?.response?.data?.error;
    } else if (e?.message && typeof e.message === "string") return e.message;
    return "Something went wrong in function " + functionName;
  }
};
