import { SupportedChains } from "../src/utils/supportedChains";
import { Balance, TokenMetadata } from "./balances";
import { UserPaymentData } from "./userPaymentData";

export interface NonUserPayLink {
  contact?: string;
  amount: number;
  currency: string;
  message: string;
  uid: string | undefined;
  name: string | undefined;
  sendeeName?: string;
  tokenAddress: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  tokens: Balance[];
  email?: string | null;
  address?: string;
  navigation?: any;
  sendee?: any;
}

export interface RequestFunds {
  amount: number;
  currency: string;
  tokenAddress: string;
  message: string;
  uid: string | undefined | null;
  name: string | undefined;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  sendee?: { name: string; contact: string };
  userPaymentData?: UserPaymentData;
  navigation: any;
  accessToken: string;
}

export enum TransferType {
  PAY = "PAY",
  RECEIVE = "RECEIVE",
  CASH_OUT = "CASHOUT",
  CASH_IN = "CASHIN"
}

export enum TransactionType {
  PAYMENT = "PAYMENT", // Send money with pay function to an specific address
  REQUEST = "REQUEST", // Requests money to someone
  MERCHANT = "MERCHANT", // Merchant payment
  ONRAMP = "ONRAMP", // Buy crypto with fiat
  NFT = "NFT",
  SMARTCONTRACT = "SMARTCONTRACT",
  NFT_TRANSFER = "NFT_TRANSFER"
}

export interface ChargeItem {
  name: string;
  image: string;
  amount: number;
  quantity: number;
}

export enum SupportedNetworks {
  SOLANA = "Solana",
  EVM = "EVM",
  POLYGON = "Polygon"
}

// type
export interface TransactionDocument {
  toAddress: string;
  tokenAddress: string;
  symbol: string;
  status: TxStatus;
  updatedAt: number;
  chain: SupportedChains;
  senderUid: string;
  receiverUid?: string;
  isTest?: boolean;
  total: string;
  type: TransactionType;
}

export interface MerchantTransaction extends TransactionDocument {
  items: ChargeItem[];
  totalUsd: number;
  toAddressUser: boolean;
  toAddressLabel: string;
}

export enum TxStatus {
  PENDING = "PENDING", // Not executed already
  PROCESSING = "PROCESSING", // Waiting to get mined
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  CANCELED = "CANCELED",
  WAITING = "WAITING" // Waiting for provider to confirm the onramp
}

interface BigNumberObj {
  type: string;
  hex: string;
}

interface SwapData {
  fromChain: string;
  fromAddress: string;
  fromToken: TokenMetadata;
  toToken: TokenMetadata;
  fromAmount: BigNumberObj;
  toAmount: BigNumberObj;
  estimatedGas: BigNumberObj;
}

interface TransferData {
  fromChain: string;
  fromAmount: BigNumberObj;
  fromAddress: string;
  fromToken: TokenMetadata;
  toAddress: string;
  smartWallet: boolean;
}
// TODO: complete this interface
interface BridgeData {
  quote: {
    fromChain: string;
    fromAddress: string;
    fromToken: TokenMetadata;
    toChain: string;
    toAddress: string;
    toToken: TokenMetadata;
    fromAmount: BigNumberObj;
    toAmount: BigNumberObj;
    estimatedGas: BigNumberObj;
  };
}

interface Estimate {
  time: number;
  costUsd: number;
  ethGas: BigNumberObj;
  maticGas: BigNumberObj;
  avaxGas: BigNumberObj;
  arbGas: BigNumberObj;
  bscGas: BigNumberObj;
  solGas: BigNumberObj;
  optGas: BigNumberObj;
  eosEvmGas: BigNumberObj;
  baseGas: BigNumberObj;
  flowGas: BigNumberObj;
  klaytnGas: BigNumberObj;
}

export interface Action {
  swapData?: SwapData;
  transferData?: TransferData;
  bridgeData?: BridgeData;
  estimate: Estimate;
  status: string;
  type: string;
}

interface To {
  toAmount: string;
  toAddress: string;
  toChain: string;
  toToken: TokenMetadata;
}

interface Estimation {
  costUsd: number;
  timeEstimate: number;
  gas: string;
  route: string;
}

export interface ParsedRoute {
  id: string;
  batches: Batch[];
  status: string;
  toToken: TokenMetadata;
  toAddress: string;
  toChain: string;
  toAmount: string;
  estimate: Estimate;
  fromUid: string;
}

export interface Batch {
  description: string;
  status: string;
  actions: Action[];
  estimate: Estimate;
}

export interface RouteResponse {
  txId: string;
  status: string;
  total: number;
  totalUsd: number;
  estimation: Estimation;
  to: To;
  startTimestamp: number;
  limitTimestamp: number;
}
