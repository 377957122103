import React, { useState } from "react";
import {
  Image,
  Text,
  TouchableOpacity,
  View,
  ImageSourcePropType,
  StyleSheet,
  useWindowDimensions
} from "react-native";
import { EllipsisVerticalIcon } from "react-native-heroicons/solid";

import {
  colorAlert,
  colorBorder,
  colorCardBackground,
  colorLabelTextSecondary,
  colorLightGrayElipsisIcon,
  colorLightGrayText,
  colorYellow,
  gray300,
  gray400,
  gray700
} from "../../utils/colors";
import { SupportedChains } from "../../utils/supportedChains";
import i18n from "../../config/languageInternationalization";
import {
  DocumentDuplicateIcon,
  PencilSquareIcon,
  XMarkIcon
} from "react-native-heroicons/outline";
import { isWeb } from "../../utils/platform";
import { Modal } from "react-native";
import { gray600 } from "../../utils/colors";
import images from "../../assets/images";
import { useNavigation } from "@react-navigation/native";
import { WalletActions } from "../../../types/navigationTypes";

interface WalletProps {
  image?: ImageSourcePropType | string;
  publicKey: string;
  chains: SupportedChains[];
  onPress?: () => any;
  onPressIcon?: () => any;
  removeBottomBorder?: boolean;
  label?: string;
  isImported?: boolean;
  missingStarkKey?: boolean;
  chainLogos?: {
    [chainName: string]: string;
  };
}

const mapLogoToChain = (
  chainName: string,
  chainLogos: { [key: string]: string } | undefined
) => {
  if (!chainName || !chainLogos) {
    return {
      uri: "https://png.pngtree.com/png-vector/20220220/ourmid/pngtree-lucky-coin-png-image_4407874.png"
    };
  }
  return {
    uri: chainLogos[chainName]
  };
};

const WalletModal = ({
  visible,
  onClose,
  walletInfo,
  setShowAllChains,
  chainLogos
}: {
  visible: boolean;
  onClose: () => void;
  walletInfo: WalletProps;
  setShowAllChains: React.Dispatch<React.SetStateAction<boolean>>;
  chainLogos: { [key: string]: string } | undefined;
}) => {
  const navigation = useNavigation();
  const dimensions = useWindowDimensions();

  type WalletActionScreenParams = {
    walletId: string;
    action: WalletActions;
  };

  return (
    <Modal visible={visible} animationType="fade" transparent>
      <View style={styles.modalContainer}>
        <View
          style={{
            ...styles.modalContent,
            width: isWeb() ? "30%" : "80%",
            padding: dimensions.width > 600 ? 32 : 16
          }}
        >
          {/* Title */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <View style={styles.modalTitleContainer}>
              <Text style={styles.modalTitle}>
                {walletInfo.label
                  ? walletInfo.label
                  : i18n.t("renameWalletNamePlaceholder")}
              </Text>
              <TouchableOpacity
                onPress={() => {
                  setShowAllChains(false);
                  const params: WalletActionScreenParams = {
                    walletId: walletInfo.publicKey,
                    action: WalletActions.RENAME
                  };
                  // Need to see why this is an issue
                  // @ts-ignore
                  navigation.navigate("WalletActionScreen", params);
                }}
              >
                <PencilSquareIcon size="16" color={gray300} />
              </TouchableOpacity>
            </View>

            <TouchableOpacity onPress={onClose}>
              <XMarkIcon size="20" color={gray300} />
            </TouchableOpacity>
          </View>

          {/* Subtitle */}
          {walletInfo.isImported ? (
            <Text style={{ ...styles.modalSubtitle, marginBottom: 10 }}>
              {i18n.t("externalWallet")}
            </Text>
          ) : (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                marginBottom: 16,
                alignItems: "center"
              }}
            >
              <Image
                source={images?.SphereOneIconSolid}
                style={{
                  height: 24,
                  width: 24,
                  borderRadius: 10,
                  marginRight: 6,
                  backgroundColor: "black"
                }}
              />
              <Text style={styles.modalSubtitle}>
                {i18n.t("sphereOneWallet")}
              </Text>
            </View>
          )}

          {/* Wallet Address */}
          <TouchableOpacity
            onPress={walletInfo.onPress}
            style={styles.modalWalletAddressContainer}
          >
            <View
              style={{
                ...styles.walletTitleContainer,
                justifyContent: "space-between"
              }}
            >
              <Text
                style={{ ...styles.walletTitle, fontSize: isWeb() ? 14 : 10 }}
              >
                {walletInfo.publicKey}
              </Text>
              <DocumentDuplicateIcon size="16" color="gray" />
            </View>
          </TouchableOpacity>

          {/* Chains list */}
          <View style={styles.modalChainsContainer}>
            {walletInfo.chains.map((chainName) => (
              <View
                style={{ ...styles.chainContainer, margin: 5 }}
                key={chainName}
              >
                <Image
                  style={styles.chainImage}
                  source={
                    mapLogoToChain(chainName, chainLogos) as ImageSourcePropType
                  }
                />
                <Text style={styles.chainName}>{chainName}</Text>
              </View>
            ))}
          </View>
          {walletInfo.missingStarkKey && (
            <Text
              style={{ ...styles.subtitles, color: colorYellow, marginTop: 12 }}
            >
              {i18n.t("missingStarkKey")}
            </Text>
          )}
        </View>
      </View>
    </Modal>
  );
};

export default function LinkedWallet({
  chains,
  publicKey,
  onPress,
  onPressIcon,
  removeBottomBorder,
  label,
  isImported,
  missingStarkKey,
  chainLogos
}: WalletProps) {
  const maxChainsToShow = 4;
  const [showAllChains, setShowAllChains] = useState(false);

  const visibleChains = chains.slice(0, maxChainsToShow);
  const remainingChains = chains.length - maxChainsToShow;

  const walletInfo: WalletProps = {
    label,
    isImported,
    publicKey,
    chains,
    onPress,
    missingStarkKey,
    chainLogos
  };

  return (
    <View
      style={{
        ...styles.walletCellContainer,
        borderBottomWidth: removeBottomBorder ? 0 : 0.5
      }}
    >
      <View style={{ ...styles.walletInfoContainer }}>
        <TouchableOpacity onPress={onPress}>
          <View style={styles.walletTitleContainer}>
            <Text style={styles.walletTitle}>{publicKey}</Text>
            <DocumentDuplicateIcon size="16" color={gray300} />
          </View>
        </TouchableOpacity>

        <View style={{ ...styles.walletChainContainer }}>
          {visibleChains &&
            visibleChains.map((chainName, i) => (
              <View style={styles.chainContainer} key={i}>
                <Image
                  style={styles.chainImage}
                  source={
                    mapLogoToChain(chainName, chainLogos) as ImageSourcePropType
                  }
                />
                <Text style={styles.chainName}>{chainName}</Text>
              </View>
            ))}
          <TouchableOpacity
            style={{
              ...styles.chainContainer,
              borderWidth: 1,
              borderColor: gray400
            }}
            onPress={() => setShowAllChains(true)}
          >
            <Text style={styles.chainName}>
              +{remainingChains > 0 ? remainingChains : "Show"} more
            </Text>
          </TouchableOpacity>
        </View>

        {missingStarkKey && (
          <Text style={{ ...styles.subtitles, color: colorYellow }}>
            {i18n.t("missingStarkKey")}
          </Text>
        )}
      </View>

      <View>
        <TouchableOpacity onPress={onPressIcon}>
          <EllipsisVerticalIcon
            accessibilityLabel={
              isImported ? "imported" : label ? "labeled" : "non-labeled"
            }
            style={styles.mr16}
            size={34}
            color={colorLightGrayElipsisIcon}
          />
        </TouchableOpacity>
      </View>

      <WalletModal
        visible={showAllChains}
        onClose={() => setShowAllChains(false)}
        walletInfo={walletInfo}
        setShowAllChains={setShowAllChains}
        chainLogos={chainLogos}
      />
    </View>
  );
}

export const styles = StyleSheet.create({
  walletCellContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomColor: colorBorder,
    marginVertical: 5,
    padding: 10,
    paddingVertical: 12
  },
  walletInfoContainer: {
    flex: 1,
    flexDirection: "column",
    paddingHorizontal: 10
  },
  walletTitleContainer: {
    flexDirection: "row",
    padding: 4,
    justifyContent: "flex-start",
    borderRightColor: colorLabelTextSecondary,
    color: "white",
    marginBottom: 4
  },
  walletTitle: {
    color: "white",
    fontSize: isWeb() ? 14 : 12,
    marginRight: 10,
    overflow: "hidden"
  },
  walletChainContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center"
  },
  chainContainer: {
    backgroundColor: gray700,
    flexDirection: "row",
    marginHorizontal: 2,
    borderRadius: 4,
    paddingVertical: 4,
    paddingHorizontal: 8,
    marginBottom: 6,
    alignItems: "center"
  },
  chainImage: {
    height: 16,
    width: 16,
    marginRight: 2
  },
  chainName: {
    fontSize: isWeb() ? 12 : 10,
    color: colorLightGrayText,
    marginLeft: 2
  },

  subtitles: {
    color: colorLabelTextSecondary,
    fontSize: isWeb() ? 12 : 10,
    marginTop: 6
  },
  mr16: { paddingRight: 16 },
  // Modal styles
  modalContainer: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    justifyContent: "center",
    alignItems: "center"
  },
  modalContent: {
    borderRadius: 10,
    backgroundColor: colorCardBackground
  },
  modalTitleContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
    marginBottom: 20
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: gray300,
    marginRight: 8
  },
  modalSubtitle: {
    fontSize: 16,
    marginBottom: 4,
    color: gray300
  },
  modalWalletAddressContainer: {
    backgroundColor: gray700,
    borderColor: gray600,
    borderWidth: 1,
    borderRadius: 8,
    padding: 10,
    marginBottom: 20
  },
  modalChainsContainer: {
    flexDirection: "row",
    flexWrap: "wrap"
  }
});
