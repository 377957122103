import React from "react";
import { Dimensions, Linking } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Text, View } from "../components/Themed";
import PrimaryButton from "../components/ThemedComponents/PrimaryButton";
import i18n from "../config/languageInternationalization";
import { IOS_APP_ID } from "@env";
import { colorBackground, colorLightGrayText } from "../utils/colors";
import VersionCheck from "react-native-version-check-expo";
import { Alert } from "../utils/Alert";

const { width, height } = Dimensions.get("screen");

export default function ForceUpdate({}) {
  async function openStore() {
    const storeLink = await VersionCheck.getStoreUrl({
      appID: IOS_APP_ID
    });

    Linking.canOpenURL(storeLink).then(
      (supported) => {
        supported && Linking.openURL(storeLink);
      },
      () => Alert.alert("Error", i18n.t("openAppStore"))
    );
  }

  return (
    <SafeAreaView
      style={{
        width,
        height,
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 200,
        backgroundColor: colorBackground
      }}
    >
      <View
        style={[
          {
            flexDirection: "row",
            alignItems: "center",
            paddingVertical: 24,
            paddingHorizontal: 14
          }
        ]}
      >
        <Text style={{ fontSize: 18 }}>{i18n.t("forceUpdateTitle")}</Text>
      </View>
      <View style={{ paddingHorizontal: 16 }}>
        <Text
          style={{
            marginBottom: 28,
            color: colorLightGrayText,
            fontWeight: "400"
          }}
        >
          {i18n.t("forceUpdateDescription")}
        </Text>
        <PrimaryButton
          style={{ marginVertical: 16 }}
          onPress={() => openStore()}
        >
          {i18n.t("forceUpdateUpdate")}
        </PrimaryButton>
      </View>
    </SafeAreaView>
  );
}
