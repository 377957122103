import React from "react";
import { Pressable, TouchableOpacity, useWindowDimensions } from "react-native";
import { BoltIcon, PhotoIcon, TrashIcon } from "react-native-heroicons/outline";
import i18n from "../../config/languageInternationalization";
import {
  colorBackgroundTransparent,
  colorBorder,
  colorModalBodyBackground,
  colorModalOverlayBackground,
  gray600,
  gray700
} from "../../utils/colors";
import { Text, View } from "../Themed";
import { isWeb } from "../../utils/platform";

export default function PhotoMethodSelectorModal({
  visible,
  setVisible,
  onUploadPhoto,
  onChooseNFT,
  onDeleteAvatar
}: {
  visible: boolean;
  setVisible: (visible: boolean) => any;
  onUploadPhoto: () => any;
  onChooseNFT: () => any;
  onDeleteAvatar: () => any;
}) {
  const { width, height } = useWindowDimensions();

  if (!visible) return null;
  return (
    <Pressable
      onPress={() => setVisible(false)}
      style={{
        width,
        height: isWeb() ? height - 85 : height,
        backgroundColor: "transparent",
        position: "relative",
        zIndex: 100,
        alignItems: "center"
      }}
    >
      <View
        style={
          isWeb()
            ? {
                backgroundColor: gray700,
                marginTop: 200,
                width: 260,
                borderRadius: 8,
                borderWidth: 1,
                borderColor: gray600
              }
            : {
                backgroundColor: gray700,
                marginTop: 200,
                width: 260,
                borderRadius: 8
              }
        }
      >
        <TouchableOpacity
          testID="uploadPhotoButton"
          onPress={onUploadPhoto}
          style={{
            padding: 12,
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottomColor: colorBorder,
            borderBottomWidth: 0.5
          }}
        >
          <Text style={{ fontSize: 18 }}>
            {i18n.t("chooseProfilePictureModalUploadPhoto")}
          </Text>
          <PhotoIcon color="white" size={24} />
        </TouchableOpacity>
        <TouchableOpacity
          testID="chooseNFTButton"
          onPress={onChooseNFT}
          style={{
            padding: 12,
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottomColor: colorBorder,
            borderBottomWidth: 0.5
          }}
        >
          <Text style={{ fontSize: 18 }}>
            {i18n.t("chooseProfilePictureModalChooseCollectable")}
          </Text>
          <BoltIcon color="white" size={24} />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={onDeleteAvatar}
          style={{
            padding: 12,
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <Text style={{ fontSize: 18 }}>
            {i18n.t("chooseProfilePictureModalRemoveAvatar")}
          </Text>
          <TrashIcon color="white" size={24} />
        </TouchableOpacity>
      </View>
    </Pressable>
  );
}
