import React from "react";
import { Image, StyleSheet } from "react-native";
import { SvgUri } from "react-native-svg";
import { gray500, usdValueColor } from "../utils/colors";
import { isWeb } from "../utils/platform";
import { Text, View } from "./Themed";

export default function ValueListElement({
  currency,
  amount,
  icon,
  price,
  style
}: {
  currency: string;
  amount: number | string;
  icon: string;
  price?: string;
  style?: { [key: string]: string | number };
}) {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: 48,
        marginLeft: 10,
        marginRight: 10,
        ...style
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start"
        }}
      >
        <View style={[styles.iconContainer]}>
          {icon.endsWith(".svg") && !isWeb() ? (
            <SvgUri
              uri={icon}
              height={35}
              width={35}
              style={{
                borderRadius: 50,
                marginTop: isWeb() ? 0 : 86,
                width: 35,
                height: 35
              }}
            />
          ) : (
            <Image
              source={{ uri: icon }}
              style={{
                borderRadius: 50,
                resizeMode: "cover",
                marginTop: isWeb() ? 0 : 86,
                width: 35,
                height: 35
              }}
            />
          )}
        </View>

        <Text style={{ fontWeight: "bold", fontSize: 20, marginLeft: 15 }}>
          {String(currency).substring(0, 10)}
        </Text>
      </View>

      <View style={{ width: isWeb() ? "25%" : "35%" }}>
        <Text style={{ fontWeight: "500", fontSize: 14, marginRight: 5 }}>
          ${price}
        </Text>
        <Text
          style={{
            fontWeight: "400",
            fontSize: 12,
            marginRight: 5,
            alignSelf: "flex-start",
            marginTop: 6,
            color: gray500
          }}
        >
          {`${amount} ${currency}`}
        </Text>
      </View>
    </View>
  );
}

export const styles = StyleSheet.create({
  iconContainer: {
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    alignSelf: "center",
    bottom: isWeb() ? 0 : 25
  },
  icon: {
    resizeMode: "cover",
    marginTop: 86,
    width: 35,
    height: 35
  }
});
