import BigNumber from "bignumber.js";

function returnStringIfNotString(x: any) {
  if (typeof x != "string") {
    return x.toString();
  } else {
    return x;
  }
}

export default function numberWithCommas(x: any) {
  const y = returnStringIfNotString(x);
  if (y.includes(`.`)) {
    const a = y.slice(0, y.indexOf("."));
    const b = y.slice(y.indexOf("."), y.length);
    return `${a.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${b}`;
  } else {
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

export const normalizeDecimals = (amount: string, decimals: number) => {
  const newBigNumber = new BigNumber(amount);
  const amountTokens = newBigNumber.dividedBy(10 ** decimals);
  return amountTokens;
};

/**
 * Converts a decimal amount to its smallest unit based on the specified number of decimals.
 *
 * @param amount - The decimal amount to convert. This should be a string to avoid precision issues with JavaScript numbers.
 * @param decimals -  The number of decimal places in the smallest unit. For example, for Ether to Wei conversion, this would be 18.
 * @returns The converted amount in the smallest unit as a string.
 *
 *  @example
 * // Returns '1000000000000000000'
 * convertToSmallestUnit('1', 18);
 *
 * @example
 * // Returns '2000000'
 * convertToSmallestUnit('2', 6);
 *
 * @example
 * // Returns '300'
 * convertToSmallestUnit('0.3', 3);
 */
export const convertToSmallestUnit = (
  amount: string,
  decimals: number
): string => {
  const bigNumberAmount = new BigNumber(amount);
  const multiplier = new BigNumber(10).pow(decimals);
  return bigNumberAmount.multipliedBy(multiplier).toFixed(0);
};
