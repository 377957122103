import React from "react";
import {
  Image,
  Text,
  TouchableOpacity,
  View,
  ImageSourcePropType
} from "react-native";
import { EllipsisVerticalIcon } from "react-native-heroicons/solid";
import { colorBorder, colorLabelTextSecondary } from "../../utils/colors";
import constantsLayout from "../../constants/Layout";

export default function LinkedAccountCell({
  bankIconImageSource,
  cardDescription,
  bankName,
  onPress,
  onPressIcon,
  removeBottomBorder
}: {
  bankIconImageSource: ImageSourcePropType;
  cardDescription: string;
  bankName: string;
  onPress?: () => any;
  onPressIcon?: () => any;
  removeBottomBorder?: boolean;
}) {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        flexDirection: "row",
        alignItems: "center"
      }}
    >
      {/* TODO 
      The image property should have the same name that the property in bankName so we
      can just made bankNameIcon[bank] and the image would be there
      */}
      <Image
        source={bankIconImageSource}
        height={40}
        width={40}
        style={{ height: 40, width: 40, borderRadius: 7, marginHorizontal: 16 }}
      />

      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottomColor: colorBorder,
          borderBottomWidth: removeBottomBorder ? 0 : 0.5,
          paddingVertical: 11
        }}
      >
        <View style={{ height: 38, width: constantsLayout.window.width * 0.6 }}>
          <Text style={[{ color: "white", fontSize: 14 }]}>
            {cardDescription}
          </Text>
          <Text style={[{ color: colorLabelTextSecondary, fontSize: 12 }]}>
            {bankName}
          </Text>
        </View>
        <TouchableOpacity onPress={onPressIcon}>
          <EllipsisVerticalIcon
            style={{ marginRight: 16 }}
            size={24}
            color={colorBorder}
          />
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
}
