import parsePhoneNumber from "libphonenumber-js";

const handlePhoneNumberChange = (text: string) => {
  let formattedPhoneNumber = text;

  const parsedPhoneNumber = parsePhoneNumber(text);
  if (parsedPhoneNumber) {
    formattedPhoneNumber = parsedPhoneNumber.formatInternational();
  }
  return formattedPhoneNumber;
};

export default handlePhoneNumberChange;
