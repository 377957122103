import { OnrampProviders } from "../../types/onRamp";
import { SphereToken } from "../redux/slices/tokens";
import { SupportedChains } from "../utils/supportedChains";
import {
  PaymentMethodTypes,
  getIconComponentByName
} from "../utils/validatePaymentMethods";

export const DEFAULT_CHAIN = SupportedChains.POLYGON;
export const DEFAULT_TOKEN: SphereToken = {
  address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
  chain: SupportedChains.POLYGON,
  decimals: 6,
  logoURI:
    "https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
  name: "USD Coin (PoS)",
  symbol: "USDC"
};

export const DEFAULT_TOKEN_AMOUNT = "100";

export const DEFAULT_WERT_PAYMENT_METHODS = [
  {
    title: "Card",
    label: "Card",
    description: "Pay with credit or debit cards",
    icon: getIconComponentByName(PaymentMethodTypes.CARD),
    type: PaymentMethodTypes.CARD,
    value: "card",
    min: 5,
    max: 10800,
    provider: OnrampProviders.WERT
  },
  {
    title: "Apple Pay",
    label: "Apple Pay",
    description: "Buy crypto using Apple Pay",
    icon: getIconComponentByName(PaymentMethodTypes.APPLE),
    type: PaymentMethodTypes.APPLE,
    value: "card",
    min: 5,
    max: 10800,
    provider: OnrampProviders.WERT
  }
];

export const EXCLUDED_TOKENS = [
  { address: "0xCCb93dABD71c8Dad03Fc4CE5559dC3D89F67a260", symbol: "WJEWEL" }
];
