import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ProfileStackNavigatorParamList } from "../../types/navigationTypes";
import ProfileScreen from "../screens/Profile/ProfileScreen";
import EditProfileScreen from "../screens/Profile/EditProfileScreen";
import ChooseNftScreen from "../screens/Profile/ChooseNftScreen";
import PushNotificationsScreen from "../screens/Profile/PushNotificationsScreen";
import PrivacyPolicyScreen from "../screens/Profile/PrivacyPolicyScreen";
import UpgradeToMerchantScreen from "../screens/Profile/UpgradeToMerchantScreen";
import TermsAndConditionsScreens from "../screens/Profile/TermsAndConditionsScreen";
import AskPushNotificationsScreen from "../screens/Onboarding/NotificationsScreen";
import LinkedAccounts from "../screens/Profile/LinkedAccounts";
import RecoverWalletScreen from "../screens/Profile/RecoverWallet";
import WalletActionScreen from "../screens/WalletActionScreen";
// ================ PROFILE STACK ================ //
const ProfileStack =
  createNativeStackNavigator<ProfileStackNavigatorParamList>();
export function ProfileNavigator() {
  return (
    <ProfileStack.Navigator>
      <ProfileStack.Screen
        name="ProfileScreen"
        component={ProfileScreen}
        options={{ headerShown: false }}
      />
      <ProfileStack.Screen
        name="EditProfileScreen"
        component={EditProfileScreen}
        options={{ headerShown: false }}
      />
      <ProfileStack.Screen
        name="ChooseNftScreen"
        component={ChooseNftScreen}
        options={{ headerShown: false }}
      />
      <ProfileStack.Screen
        name="PushNotificationsScreen"
        component={PushNotificationsScreen}
        options={{ headerShown: false }}
      />
      <ProfileStack.Screen
        name="PrivacyPolicyScreen"
        component={PrivacyPolicyScreen}
        options={{ headerShown: false }}
      />
      <ProfileStack.Screen
        name="TermsAndConditionsScreen"
        component={TermsAndConditionsScreens}
        options={{ headerShown: false }}
      />
      <ProfileStack.Screen
        name="NotificationsScreen"
        component={AskPushNotificationsScreen}
        options={{ headerShown: false }}
      />
      <ProfileStack.Screen
        name="UpgradeToMerchantScreen"
        component={UpgradeToMerchantScreen}
        options={{ headerShown: false }}
      />
      <ProfileStack.Screen
        name="LinkedAccountsScreen"
        component={LinkedAccounts}
        options={{ headerShown: false }}
      />
      <ProfileStack.Screen
        name="RecoverWalletScreen"
        component={RecoverWalletScreen}
        options={{ headerShown: false }}
      />
      <ProfileStack.Screen
        name="WalletActionScreen"
        component={WalletActionScreen}
        options={{ headerShown: false }}
      />
    </ProfileStack.Navigator>
  );
}
