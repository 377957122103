import { Switch, View } from "react-native";
import React, { useState, useEffect } from "react";
import { Text } from "../../components/Themed";
import {
  colorDarkGray4,
  colorGreenSwitch,
  colorCardBackground,
  colorBackground,
  gray800,
  gray700
} from "../../utils/colors";
import Header from "../../components/Header";
import i18n from "../../config/languageInternationalization";
import { getCurrentNotificationsConfig } from "../../utils/pushNotifications";
import Modal from "../../components/ThemedComponents/Modal";
import { toggleSwitch } from "../../components/PushNotificationsScreen/utils/toggleSwitch";
import { useAppSelector } from "../../redux/hooks";
import SafeAreaView from "../../components/ThemedComponents/SafeAreaView";
import { isWeb } from "../../utils/platform";

export default function PushNotificationsScreen() {
  const [isEnabled, setIsEnabled] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { accessToken } = useAppSelector((state) => state.user);
  const handleModalConfirm = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getCurrentNotificationsConfig().then((res) =>
      setIsEnabled(res ? res : false)
    );
  }, []);

  return (
    <View style={{ backgroundColor: colorBackground, flex: 1 }}>
      <SafeAreaView
        style={
          isWeb()
            ? {
                flex: 1,
                marginVertical: 20,
                borderRadius: 16,
                backgroundColor: gray800,
                borderColor: gray700,
                borderWidth: 1
              }
            : { backgroundColor: "transparent" }
        }
      >
        <Header title={i18n.t("notificationsScreenNotifications")} xIcon />
        <View
          style={{
            height: 55,
            backgroundColor: isWeb() ? gray800 : colorCardBackground,
            borderRadius: 14,
            justifyContent: "space-between",
            alignItems: "center",
            padding: 12,
            marginHorizontal: 16,
            flexDirection: "row",
            borderWidth: isWeb() ? 1 : 0,
            borderColor: isWeb() ? gray700 : "transparent"
          }}
        >
          <Text style={{ fontSize: 16 }}>
            {i18n.t("notificationsScreenPushNotifications")}
          </Text>
          <Switch
            testID="pushNotificationsSwitch"
            trackColor={{ false: colorDarkGray4, true: colorGreenSwitch }}
            thumbColor={"white"}
            ios_backgroundColor={colorDarkGray4}
            onValueChange={(val) =>
              toggleSwitch(val, accessToken as string, setIsEnabled)
            }
            value={isEnabled}
          />
        </View>
        <Modal
          visible={isModalVisible}
          title={i18n.t("allowNotificationsModalTitle")}
          message={i18n.t("allowNotificationsModalMsg")}
          buttonLabel={"OK"}
          onConfirm={handleModalConfirm}
        />
      </SafeAreaView>
    </View>
  );
}
