import {
  setAsyncStorageNotificationSettings,
  updateNotificationSettings
} from "../../../utils/pushNotifications";
import { recordError } from "../../../utils/crashlytics";
import { isWebViewedOnIOS } from "../../../utils/platform";

export const toggleSwitch = async (
  val: boolean,
  accessToken: string,
  setIsEnabled: (val: boolean) => void,
  showModal: () => void
) => {
  try {
    if (!isWebViewedOnIOS()) {
      const permission = await Notification.requestPermission();
      if (permission !== "granted") {
        showModal();
      }
    }
  } catch (e: any) {
    recordError(e, "PushNotificationsScreen.tsx");
    return;
  }

  setIsEnabled(val);
  setAsyncStorageNotificationSettings(val);
  try {
    // update app and FCM notification settings
    updateNotificationSettings(val, accessToken);
  } catch (e) {
    recordError(e, "PushNotificationsScreen.tsx");
  }
};
