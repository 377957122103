import React from "react";
import { Image, TouchableOpacity, View } from "react-native";
import greenStar from "../../assets/images/greenStar.png";
import { Text } from "../Themed";
import i18n from "../../config/languageInternationalization";
import { colorLabelTextSecondary, fligthSafetyBg } from "../../utils/colors";

const UpgradeToMerchantButton = ({
  openMerchantForm
}: {
  openMerchantForm: () => void;
}) => {
  return (
    <TouchableOpacity
      onPress={openMerchantForm}
      style={{
        flex: 1,
        flexDirection: "row",
        backgroundColor: fligthSafetyBg,
        padding: 10,
        marginTop: 10,
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        borderRadius: 12
      }}
    >
      <Image
        source={greenStar}
        style={{ width: 36, height: 36, marginLeft: 10 }}
      />

      <View style={{ flexDirection: "column", marginLeft: 10 }}>
        <Text style={{ marginBottom: 4, fontSize: 17 }}>
          {i18n.t("profileScreenUpgradeButtonTitle")}
        </Text>
        <Text
          style={{
            marginBottom: 4,
            fontSize: 15,
            color: colorLabelTextSecondary
          }}
        >
          {i18n.t("profileScreenUpgradeButtonSubTitle")}
        </Text>
      </View>
      <Image
        source={greenStar}
        style={{ width: 36, height: 36, marginRight: 10 }}
      />
    </TouchableOpacity>
  );
};

export default UpgradeToMerchantButton;
