import React from "react";
import {
  DrawerContentScrollView,
  DrawerItemList
} from "@react-navigation/drawer";
import { TouchableOpacity, View } from "react-native";
import { colorBackground } from "../utils/colors";
import Avatar from "../components/Avatar";
import { useAppSelector } from "../redux/hooks";
import { Text } from "../components/Themed";
import i18n from "../config/languageInternationalization";
import { ArrowRightOnRectangleIcon } from "react-native-heroicons/solid";
import { XMarkIcon } from "react-native-heroicons/outline";
import { DrawerActions } from "@react-navigation/native";
import { useCustomAuth } from "../hooks/useAuth";

export default function CustomDrawer(props: any) {
  const { name = "", pfp, email } = useAppSelector((state) => state.user);
  const { logout } = useCustomAuth();

  return (
    <View style={{ flex: 1 }}>
      <DrawerContentScrollView
        {...props}
        contentContainerStyle={{ flex: 1, backgroundColor: colorBackground }}
      >
        <View style={{ width: "100%", alignItems: "flex-end" }}>
          <TouchableOpacity
            style={{ padding: 15 }}
            onPress={() => {
              props.navigation.dispatch(DrawerActions.closeDrawer());
            }}
          >
            <XMarkIcon size={30} color="#fff" />
          </TouchableOpacity>
        </View>
        {/* Avatar section */}
        <View style={{ alignItems: "center" }}>
          <Avatar
            nameOrEmail={name ?? (email as string)}
            source={pfp !== undefined && pfp !== "" ? { uri: pfp } : null}
            size={128}
            style={{ marginBottom: 12 }}
          />

          <Text
            style={{
              textAlign: "center",
              fontSize: name.length > 24 ? 18 : 22,
              color: "#fff",
              padding: 20
            }}
            numberOfLines={1}
          >
            {name}
          </Text>
        </View>
        <View style={{ flex: 1, backgroundColor: colorBackground }}>
          <DrawerItemList {...props} />
        </View>
      </DrawerContentScrollView>
      <View
        style={{
          backgroundColor: colorBackground,
          padding: 20,
          borderTopWidth: 1,
          borderTopColor: "#fff"
        }}
      >
        <TouchableOpacity
          style={{ flexDirection: "row", alignItems: "center" }}
          onPress={() => logout()}
        >
          <View
            style={{
              height: 30,
              width: 30,
              borderRadius: 8
            }}
          >
            <ArrowRightOnRectangleIcon size={24} color="white" />
          </View>
          <Text>{i18n.t("profileScreenLogout")}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
