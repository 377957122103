import React, { useEffect, useRef, useState } from "react";
import { FlatList, ScrollView, useWindowDimensions } from "react-native";
import Toast from "react-native-toast-message";
import * as Clipboard from "expo-clipboard";
import LinkedAccountCell from "../../components/LinkedAccountsScreen/LinkedAccountCell";
import LinkedAccountCellContainer from "../../components/LinkedAccountsScreen/LinkedAccountCellContainer";
import LinkedWallet from "../../components/LinkedAccountsScreen/LinkedAccountWallets";
import { Text, View } from "../../components/Themed";
import { colorBackground, gray800 } from "../../utils/colors";
import i18n from "../../config/languageInternationalization";
import LinkedBankAccount from "../../components/LinkedAccountsScreen/LinkedAccountBank";
import { LinkedAccountWallet } from "../../../types/accounts";
import { OnboardingStatus } from "../../redux/slices/user";
import {
  getChainLogos,
  getLinkedAccounts,
  setOnboardingStatus
} from "../../redux/slices/userThunk";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { isWeb } from "../../utils/platform";
import { Alert } from "../../utils/Alert";
import Button from "../../components/ThemedComponents/Button";
import { recordError } from "../../utils/crashlytics";
import { callFunction } from "../../utils/server";
import { ActionSheetCustom as ActionSheet } from "react-native-actionsheet";
import actionSheetStyles from "../../components/ProfileScreen/ActionSheetStyles";
import { WalletActions } from "../../../types/navigationTypes";
import SafeAreaView from "../../components/ThemedComponents/SafeAreaView";
import { useNavigation } from "@react-navigation/native";
import InsertPinCode from "../../components/InsertPinCode";
import {
  AddIMXStarkPrivateKey,
  ModalStateEnumIMX
} from "../../components/AddIMXStarkPrivateKey";

export default function LinkedAccounts() {
  const navigation = useNavigation<any>();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [swapLoading, setSwapLoading] = useState(false);
  const [pendingOnRamp, setPendingOnRamp] = useState<any>(null);
  const [walletSelected, setWalletSelected] =
    React.useState<LinkedAccountWallet | null>(null);
  const [actionSheetOptions, setActionSheetOptions] = useState<string[]>([]);
  const {
    linkedAccounts,
    isOnboard,
    accessToken,
    walletsBalanceTotal,
    onboardingStatus,
    chainLogos
  } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (!chainLogos) {
      dispatch(getChainLogos());
    }
  }, [chainLogos]);

  const handleWalletAccountOnPress = async (account: LinkedAccountWallet) => {
    await Clipboard.setStringAsync(account.address);
    Toast.show({
      type: "info",
      text1: i18n.t("addressCopied"),
      position: "top"
    });
  };

  useEffect(() => {
    // create/recover DEK and Linked Accounts during onboarding
    if (!isOnboard) {
      const unsubscribe = navigation.addListener("beforeRemove", (e: Event) =>
        e.preventDefault()
      );
      if (accessToken) {
        // TODO
        // CHECK THIS BUT IT SHOULD BE NEEDED THE DEK BECAUSE WE ARE ASKING FOR ONE IN EACH ACTION THAT NEEDS IT
        // handleCreateOrRecoverDek(accessToken, unsubscribe);
        dispatch(getLinkedAccounts()).finally(() => {
          setLoading(false);
        });
      }
      return () => unsubscribe();
    } else {
      setLoading(false);
    }
  }, [isOnboard, accessToken, onboardingStatus]);

  useEffect(() => {
    if (isWeb() && !linkedAccounts.length) {
      dispatch(getLinkedAccounts());
    }
  }, [linkedAccounts.length]);

  useEffect(() => {
    async function getPendingOnRamps() {
      try {
        const res = await callFunction({
          url: "/onramp-transactions",
          accessToken: accessToken as string,
          method: "GET"
        });
        setPendingOnRamp(res.data[0]);
      } catch (e) {
        console.error("Error getting pending onramps", e);
      }
    }

    return navigation.addListener("focus", getPendingOnRamps);
  }, []);

  const [modal, setModal] = React.useState(false);

  const callback = async (wrappedDek: string) => {
    setSwapLoading(true);
    await callFunction({
      url: "/pay",
      accessToken: accessToken as string,
      data: { transactionId: pendingOnRamp.id, wrappedDek },
      method: "POST"
    }).finally(() => setSwapLoading(false));
  };

  const handleError = (error: any) => {
    Alert.alert(i18n.t("errorSwappingToken"), error.message);
  };

  const swapOnramp = async () => {
    if ((walletsBalanceTotal ?? 0) < pendingOnRamp.totalUsd) {
      return Alert.alert(
        i18n.t("notArrivedOnrampTitle"),
        i18n.t("notArrivedOnrampSubtitle")
      );
    }
    setModal(true);
  };

  const actionSheet: any = useRef();

  async function removeUserWallet() {
    try {
      if (!walletSelected?.isImported) {
        return Alert.alert(i18n.t("sphereWalletCannotBeRemoved"));
      }
      if (walletSelected.readOnly) {
        await callFunction({
          url: `/user/wallets/${walletSelected.id}`,
          accessToken: accessToken as string,
          method: "DELETE"
        });
        Toast.show({
          text1: i18n.t("walletRemovedSuccessfully"),
          position: "top"
        });
        return dispatch(getLinkedAccounts());
      }
      navigation.navigate("WalletActionScreen", {
        walletId: walletSelected.id,
        action: WalletActions.DELETE
      });
    } catch (error) {
      recordError(error, "LinkedAccounts.tsx - removeUserWallet");
    } finally {
      setWalletSelected(null);
    }
  }

  const showActionSheet = (wallet: LinkedAccountWallet) => {
    actionSheet.current?.show();
    setWalletSelected(wallet);

    // Determine weather we add the option to add a stark private key or not
    const options = [
      i18n.t("renameLinkedAccount"),
      i18n.t("removeLinkedAccount"),
      i18n.t("privateKey"),
      i18n.t("close")
    ];
    if (wallet.missingStarkKey) {
      options.splice(3, 0, i18n.t("addStarkPrivateKey"));
    }
    setActionSheetOptions(options);
  };

  return (
    <View style={{ backgroundColor: colorBackground, flex: 1 }}>
      <SafeAreaView>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View
            style={{
              paddingVertical: 24,
              paddingHorizontal: 14,
              paddingBottom: 100
            }}
          >
            <View style={{ paddingHorizontal: 16 }}>
              <LinkedAccountCellContainer>
                <FlatList
                  data={linkedAccounts}
                  style={{ backgroundColor: gray800, borderRadius: 16 }}
                  renderItem={({ item, index }) => {
                    if (item.type === "creditCard") {
                      return (
                        <LinkedAccountCell
                          bankIconImageSource={item.bankIconImageSource}
                          cardDescription={item.cardDescription}
                          bankName={item.bankName}
                          removeBottomBorder={
                            index === (linkedAccounts?.length as number) - 1
                          }
                          onPress={() => null}
                          onPressIcon={() => null}
                          key={item.id}
                        />
                      );
                    } else if (item.type === "wallet")
                      return (
                        <>
                          <LinkedWallet
                            isImported={item.isImported}
                            image={item.image}
                            chainLogos={chainLogos}
                            publicKey={item.address}
                            chains={item.chains}
                            label={item.label}
                            onPress={() => handleWalletAccountOnPress(item)}
                            removeBottomBorder={
                              index === (linkedAccounts?.length as number) - 1
                            }
                            key={item.id}
                            onPressIcon={() => showActionSheet(item)}
                            missingStarkKey={item.missingStarkKey}
                          />
                        </>
                      );
                    else if (item.type === "bankAccount")
                      return (
                        <LinkedBankAccount
                          image={item.image}
                          bank={item.bank}
                          ubi={item.ubi}
                          onPress={() => null}
                          onPressIcon={() => null}
                          removeBottomBorder={
                            index === (linkedAccounts?.length as number) - 1
                          }
                          key={index}
                        />
                      );
                    else return null;
                  }}
                  keyExtractor={(item) => item.id}
                  ListEmptyComponent={
                    <Text
                      style={{
                        marginHorizontal: 16,
                        marginVertical: 12,
                        fontSize: 18
                      }}
                    >
                      {!isOnboard
                        ? i18n.t("linkedAccountsWalletsBeingCreated")
                        : i18n.t("linkedAccountsNoLinkedAccounts")}
                    </Text>
                  }
                />
              </LinkedAccountCellContainer>

              {isOnboard && pendingOnRamp && (
                <View style={{ marginTop: 16 }}>
                  <Button isLoading={swapLoading} onPress={swapOnramp} rounded>
                    {i18n.t("swapOnRamp")} {pendingOnRamp.symbol}
                  </Button>
                </View>
              )}
            </View>
          </View>
        </ScrollView>
        <ActionSheet
          styles={actionSheetStyles}
          ref={actionSheet}
          title={i18n.t("actionSheetLinkedAccount")}
          options={actionSheetOptions}
          cancelButtonIndex={actionSheetOptions.length - 1}
          destructiveButtonIndex={1}
          onPress={async (i) => {
            if (i === 0) {
              navigation.navigate("WalletActionScreen", {
                walletId: walletSelected?.id,
                action: WalletActions.RENAME
              });
            } else if (i === 1) {
              Alert.alert(
                i18n.t("alertTitleBeforeRemoveLinkedAccount"),
                i18n.t("alertSubtitleBeforeRemoveLinkedAccount"),
                [
                  {
                    text: i18n.t("authScreensContinue"),
                    onPress: async () =>
                      walletSelected && (await removeUserWallet()),
                    style: "default"
                  },
                  {
                    text: i18n.t("profileScreenCancel"),
                    style: "destructive"
                  }
                ]
              );
            } else if (i === 2) {
              if (walletSelected?.readOnly) {
                return Alert.alert(
                  i18n.t("error"),
                  i18n.t("privateKeyReadOnlyError")
                );
              }

              Alert.alert(
                i18n.t("revealPrivateKey"),
                i18n.t("warningPrivateKey"),
                [
                  {
                    text: "Ok",
                    style: "default",
                    onPress: async () => {
                      navigation.navigate("WalletActionScreen", {
                        walletId: walletSelected?.id,
                        readOnly: walletSelected?.readOnly,
                        action: WalletActions.PRIVATE_KEY
                      });
                    }
                  }
                ]
              );
            } else if (i === 3 && walletSelected?.missingStarkKey) {
              return Alert.alert(i18n.t("missingStarkPkAlert"));
            }
          }}
        />
        {modal && pendingOnRamp.id && (
          <InsertPinCode
            cb={callback}
            handleError={handleError}
            target={pendingOnRamp.id}
            closeModal={() => setModal(false)}
          />
        )}
      </SafeAreaView>
    </View>
  );
}
