import { TransferType } from "../../../../types/transfer";
import { navigateToDetail } from "./navigateToDetail";
import { SupportedChains } from "../../../utils/supportedChains";

interface RequestNotificationData {
  amount: number;
  symbol: string;
  detail: string;
  requestorAddress: string;
  toUid: string;
}

const fulfillRequest = async ({
  notificationData,
  navigation
}: {
  notificationData: RequestNotificationData;
  navigation: any;
}) => {
  if (!notificationData) return;
  // Navigate to the TransferDetail screen with the data needed to complete the transfer there
  return navigateToDetail({
    navigation,
    type: TransferType.PAY,
    receiver: {
      address: notificationData.requestorAddress,
      isUser: true,
      isWallet: false
    },
    amount: notificationData.amount,
    tokenSymbol: notificationData.symbol,
    message: notificationData.detail
  });
};

export default fulfillRequest;
