import { View, Text, Image } from "react-native";
import React from "react";
import { TouchableOpacity } from "react-native-gesture-handler";
import Button from "../ThemedComponents/Button";
import { primary600 } from "../../utils/colors";
import FormControl from "../ThemedComponents/FormControl";
import CheckCircle from "../../assets/images/CheckCircle.png";
import ReceiptRow from "./ReceiptRow";
import { ArrowDownTrayIcon } from "react-native-heroicons/solid";
import i18n from "../../config/languageInternationalization";

interface PostNftProps {
  nftName: string;
  toAddress: string;
  onClose: () => void;
}

export default function PostTransfer({
  nftName,
  toAddress,
  onClose
}: PostNftProps) {
  return (
    <FormControl style={{ gap: 24 }}>
      <View className="items-center">
        <Image style={{ width: 75, height: 75 }} source={CheckCircle} />
        <Text
          style={{ color: "white" }}
          className="text-left text-xl font-medium leading-tight tracking-tight text-gray-900 my-5 md:text-3xl dark:text-white"
        >
          {i18n.t("sendNftTitle")}
        </Text>
        <View className="w-full text-gray-400">
          <View className="py-2.5 border-b border-solid border-gray-700 items-start">
            <Text className="font-semibold text-white">
              {i18n.t("sendNftDetail")}
            </Text>
          </View>

          <ReceiptRow label={i18n.t("sendNftStatus")}>
            <View className="flex py-0.5 px-2.5 bg-green-900 rounded">
              <Text className="text-green-300">
                {i18n.t("sendNftCompleted")}
              </Text>
            </View>
          </ReceiptRow>

          <ReceiptRow label={i18n.t("sendNftName")}>
            <Text className="text-blue-500">{nftName}</Text>
          </ReceiptRow>

          <ReceiptRow label={i18n.t("sendNftRecipient")}>
            <Text className="text-blue-500">{toAddress}</Text>
          </ReceiptRow>

          <ReceiptRow label={i18n.t("sendNftStatement")}>
            <TouchableOpacity className="flex-row">
              <Text className="text-blue-500 mr-1.5">
                {i18n.t("sendNftDownload")}
              </Text>
              <ArrowDownTrayIcon size={16} color={"#3F83F8"} />
            </TouchableOpacity>
          </ReceiptRow>
        </View>
        <Button
          style={{
            backgroundColor: primary600,
            borderRadius: 8,
            marginTop: 40,
            width: "100%"
          }}
          onPress={onClose}
        >
          {i18n.t("sendNftDone")}
        </Button>
      </View>
    </FormControl>
  );
}
