import { useRef, useEffect } from "react";
import { Animated, Easing } from "react-native";

export function useSpinAnimation(loading: boolean) {
  const spinValue = useRef(new Animated.Value(0)).current;

  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "360deg"]
  });

  useEffect(() => {
    if (loading) {
      Animated.loop(
        Animated.timing(spinValue, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: true,
          easing: Easing.linear // Smooth continuous rotation
        })
      ).start();
    } else {
      spinValue.setValue(0); // Reset the spin value when loading is done
    }

    return () => {
      spinValue.stopAnimation(); // Stop the animation if the component is unmounted
    };
  }, [loading]);

  return spin;
}
