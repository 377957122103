// This function is the responsible of format the name of the user when is showed in the screen,
// with the first letter of each name/lastname with uppercase

const formatName = (name: string) => {
  const completeNameWithUppercase = name
    ?.split(" ")
    .map((name: string) => {
      return name.slice(0, 1).toLocaleUpperCase() + name.slice(1).toLowerCase();
    })
    .join(" ")
    .toString();

  return completeNameWithUppercase;
};

export default formatName;
