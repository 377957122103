import React, { useEffect, useRef, useState } from "react";
import i18n from "../config/languageInternationalization";
import {
  Keyboard,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions
} from "react-native";
import { ActivityIndicator } from "react-native";
import { recordError } from "../utils/crashlytics";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { isWeb } from "../utils/platform";
import {
  cleanLoginInitiatedFromStorage,
  cleanQueryClientState,
  cleanStoredCheckoutState,
  getLoginInitiatedFromStorage,
  getQueryClientState,
  getStoredCheckoutState,
  setLoginInitiatedFromStorage,
  setQueryClientState,
  setStoredCheckoutState
} from "../utils/localStorage";
import { TxStatus } from "../../types/transfer";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useNavigation } from "@react-navigation/native";
import { Alert } from "../utils/Alert";
import { CheckoutStackNavigatorParamList } from "../../types/navigationTypes";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as countryInfo from "countries-information";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  InformationCircleIcon
} from "react-native-heroicons/outline";
import { SupportedChains } from "../utils/supportedChains";
import {
  getOrderIdFromResponse,
  handleGoBack,
  handleOnrampTransaction,
  isSphereOneMethod,
  isWert,
  payErrorMessage
} from "../utils/onramp";
import { OnrampGenericResult, OnrampProviders } from "../../types/onRamp";
import { Onramp } from "../components/OnRampScreen/utils/onRampProviders";
import {
  QueryClient,
  dehydrate,
  hydrate,
  useQueryClient
} from "@tanstack/react-query";
import { findWalletByChain } from "../utils/findWalletByChain";
import CountryCodeSelector from "../components/ThemedComponents/CountryCodeSelector";
import { CountryItem } from "react-native-country-codes-picker";
import { useCustomAuth } from "../hooks/useAuth";
import { REACT_APP_AUTH_AUDIENCE } from "@env";
import {
  BuyStatus,
  CheckoutOnrampState,
  RouteStatus,
  checkoutActions
} from "../redux/slices/checkoutOnramp";
import { useDebounce } from "../hooks/useDebounce";
import {
  StyledSafeAreaView,
  StyledText,
  StyledTextInput,
  StyledTouchableOpacity,
  StyledView
} from "../components/ThemedComponents/NativeWindStyledWrappers";
import {
  CheckoutSkeleton,
  priceSkeletonLayout,
  summarySkeletonLayout
} from "../components/OnRampScreen/CheckoutSkeleton";
import { InfoAlert, ShowAmountError } from "../components/OnRampScreen/Alerts";
import { OnrampWidget } from "../components/OnRampScreen/Widgets";
import { BuyCryptoDropdown } from "../components/OnRampScreen/BuyCryptoDropdown";
import { PaymentMethodsSection } from "../components/OnRampScreen/PaymentMethods";
import {
  useCountryData,
  useFetchCharge,
  useFetchPaymentMethods,
  useFetchQuote,
  useGetRouteEstimation,
  useSupportedChains,
  useSupportedTokens
} from "../hooks/useOnRamp";
import {
  ChargeDetails,
  PaymentDetails
} from "../components/OnRampScreen/Details";
import InsertPinCode from "../components/InsertPinCode";
import CheckoutOnrampStatus from "../components/CheckoutStatus";
import { gray300, yellowWarning } from "../utils/colors";
import axios from "axios";
import { REACT_APP_BASE_URL } from "@env";
import { callFunction } from "../utils/server";
import { LinkedAccount } from "../redux/slices/user";
import { AppDispatch } from "../redux/store";
import { DEFAULT_TOKEN_AMOUNT } from "../config/onramp";
import { SphereToken } from "../redux/slices/tokens";
import { Text, View } from "../components/Themed";

const CheckoutOnrampScreen = () => {
  const { txId, tokenToOnramp } = useAppSelector(
    (state) => state.checkoutOnramp
  );

  return (
    <StyledSafeAreaView className="bg-gray-800 flex-col h-full w-full">
      <ScrollView>
        <Header />
        {tokenToOnramp?.chain === SupportedChains.DFK && (
          <View
            style={{ backgroundColor: yellowWarning }}
            className="flex flex-row justify-center items-center py-1 bg-red-500"
          >
            <InformationCircleIcon
              className="opacity-0 md:opacity-100"
              style={{ marginRight: 4 }}
              color="rgb(156 163 175)"
              strokeWidth={2}
            />
            <Text>{i18n.t("onrampWarning")}</Text>
          </View>
        )}
        <StyledView className="bg-gray-800 relative md:flex-row justify-start items-center md:items-start inline-flex p-4 md:p-6 h-auto">
          {txId ? <Checkout /> : <BuyCrypto />}
        </StyledView>
      </ScrollView>
    </StyledSafeAreaView>
  );
};

export default CheckoutOnrampScreen;

const Checkout = () => {
  const { charge, isChargeLoading } = useFetchCharge();
  const { amountError, tokenToOnramp } = useAppSelector(
    (state) => state.checkoutOnramp
  );
  const { width } = useWindowDimensions();

  return (
    <>
      <StyledView className="flex-col justify-start items-center inline-flex w-full md:w-1/2 md:pr-8">
        <CheckoutSkeleton
          layout={summarySkeletonLayout}
          isLoading={isChargeLoading}
        >
          {charge?.toAddressUser && (
            <AddWalletToAccount
              walletAddress={charge?.toAddress}
              walletLabel={charge?.toAddressLabel}
            />
          )}

          <ChargeDetails />
          {amountError && <ShowAmountError amountError={amountError} />}
          <StyledView className="h-px w-full bg-gray-600" />
          <StyledView className="py-3 justify-start items-center flex-row">
            <InformationCircleIcon
              className="opacity-0 md:opacity-100"
              size={tokenToOnramp ? (width < 768 ? 32 : 24) : 16}
              style={{ marginRight: 4 }}
              color="rgb(156 163 175)"
              strokeWidth={3}
            />
            <StyledText className={`text-gray-300 text-xs font-normal`}>
              {tokenToOnramp
                ? i18n.t("checkoutTokenNotAvailable", {
                    tokenToOnramp: tokenToOnramp.onRampTo.symbol,
                    symbol: charge?.symbol
                  })
                : i18n.t("checkoutPurchaseInfo", {
                    total: charge?.total,
                    symbol: charge?.symbol
                  })}
            </StyledText>
          </StyledView>
          <PaymentDetails totalUsd={charge?.totalUsd} />
        </CheckoutSkeleton>
      </StyledView>
      <StyledView className="opacity-0 py-4 md:py-0 md:opacity-100 w-full md:w-px md:h-full  bg-gray-600" />
      <PaymentMethods />
    </>
  );
};

const BuyCrypto = () => {
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();
  const {
    chain,
    token,
    tokenAmount,
    fiat: countryFiat,
    amountError,
    tokenToOnramp,
    paymentMethod,
    walletAddress,
    walletLabel
  } = useAppSelector((state) => state.checkoutOnramp);
  const fiat = isWert(paymentMethod?.provider) ? "USD" : countryFiat;
  const [inputValue, setInputValue] = useState(""); // inputValue is the user's input for the tokenAmount. It's debounced to avoid unnecessary re-renders or calculations while the user is still typing.
  const debounce = useDebounce(1000);
  const { data: chains } = useSupportedChains();
  const { data: tokens } = useSupportedTokens();
  const { data: quoteData, isLoading: isQuoteLoading } = useFetchQuote();
  const defaultAmountSet = useRef(false);

  const setTokenAmount = (value: string) => {
    const regex = /^[0-9.,]*$/; // Only allow numbers, commas and dots
    if (regex.test(value) || value === "") {
      const sanitizedValue = value.replace(/,/g, "."); // Replace commas with dots
      setInputValue(sanitizedValue);
      debounce((debouncedValue: string) => {
        dispatch(checkoutActions.setTokenAmount(debouncedValue));
      }, sanitizedValue);
    }
  };

  const handleChooseToken = (item: SphereToken) => {
    dispatch(checkoutActions.onBuyCryptoDropdownChangeCleanup());
    dispatch(checkoutActions.setToken(item));
    setInputValue("");
  };

  const handleChooseChain = (item: OnrampGenericResult) => {
    dispatch(checkoutActions.onBuyCryptoDropdownChangeCleanup());
    dispatch(checkoutActions.setToken(undefined));
    dispatch(checkoutActions.setChain(item.value as SupportedChains));
    setInputValue("");
  };

  useEffect(() => {
    (async function () {
      const loginInitiated = await getLoginInitiatedFromStorage();
      if (loginInitiated) {
        // Sync the input value with the token amount after the user logs in
        setInputValue(tokenAmount ?? "");
      } else if (!tokenAmount && !defaultAmountSet.current) {
        // Set the default token amount if it haven't set yet and the user is not coming from login
        setTokenAmount(DEFAULT_TOKEN_AMOUNT);
        defaultAmountSet.current = true;
      }
    })();
  }, [tokenAmount]);

  return (
    <>
      <StyledView className="flex-col justify-start inline-flex w-full md:w-1/2 md:pr-8">
        {/* Add wallet */}
        {walletAddress && (
          <AddWalletToAccount
            walletLabel={walletLabel}
            walletAddress={walletAddress}
          />
        )}

        {/* Select chain */}
        <BuyCryptoDropdown
          data={chains || [{ label: chain || "", value: chain || "" }]}
          value={chain}
          onChange={(item) => handleChooseChain(item)}
          placeholder={i18n.t("onRampSelectBlockchain")}
          width="100%"
        />
        <StyledView className="flex-row py-3">
          {/* Select token amount */}
          <StyledTextInput
            className={`h-9 mr-3 px-2.5 py-2 bg-gray-700 rounded-lg border-[1px] border-gray-600 justify-between items-start text-gray-50 ${
              isWeb() ? "w-full" : "w-1/2"
            }`}
            autoCorrect={false}
            placeholder={`Enter token amount`}
            editable={true}
            keyboardType="decimal-pad"
            value={inputValue}
            onChangeText={setTokenAmount}
          />
          {/* Select token  */}
          <BuyCryptoDropdown
            data={tokens as any} // TODO: fix this
            value={token?.symbol}
            onChange={(item: any) => handleChooseToken(item)} // TODO: fix this
            placeholder={i18n.t("onRampSelectCryptocurrency")}
            width="47%"
          />
        </StyledView>
        <StyledView className="pb-3 flex-row justify-between text-sm">
          <StyledText className="font-semibold">
            {tokenAmount} {token?.symbol}
          </StyledText>
          <CheckoutSkeleton
            layout={priceSkeletonLayout}
            isLoading={isQuoteLoading}
            alignItems="flex-end"
          >
            <StyledText>
              {fiat} {quoteData?.fiatAmount ?? "-"}
            </StyledText>
          </CheckoutSkeleton>
        </StyledView>
        {amountError && <ShowAmountError amountError={amountError} />}
        <StyledView className="h-px w-full bg-gray-600" />
        <StyledView
          className={`pt-3 justify-start items-center flex-row ${
            tokenToOnramp && "pb-3"
          }`}
        >
          {tokenToOnramp ? (
            <>
              <InformationCircleIcon
                className="opacity-0 md:opacity-100"
                size={width < 768 ? 32 : 24}
                style={{ marginRight: 4 }}
                color="rgb(156 163 175)"
                strokeWidth={3}
              />
              <StyledText className={`text-gray-300 text-xs font-normal`}>
                {i18n.t("checkoutTokenNotAvailable", {
                  tokenToOnramp: tokenToOnramp.onRampTo.symbol,
                  symbol: token?.symbol
                })}
              </StyledText>
            </>
          ) : null}
        </StyledView>
        <PaymentDetails />
      </StyledView>
      <StyledView className="opacity-0 py-4 md:py-0 md:opacity-100 w-full md:w-px md:h-full  bg-gray-600" />
      <PaymentMethods />
    </>
  );
};

function PaymentMethods() {
  const dispatch = useAppDispatch();
  const navigation =
    useNavigation<
      NativeStackNavigationProp<
        CheckoutStackNavigatorParamList,
        "CheckoutOnramp",
        undefined
      >
    >();
  const {
    buyStatus,
    buyStatusMessage,
    onrampUrl,
    showWert,
    paymentMethod,
    txId,
    fiat,
    tokenToOnramp,
    onrampAmount,
    tokenAmount,
    token,
    chain,
    customOnrampTxId,
    walletAddress
  } = useAppSelector((state) => state.checkoutOnramp);
  const { accessToken, linkedAccounts, phone, email, uid } = useAppSelector(
    (state) => state.user
  );
  const { defaultMethods, moreMethods, paymentMethodsLoading } =
    useFetchPaymentMethods();
  const { data: quoteData } = useFetchQuote();
  const { charge } = useFetchCharge();

  if (defaultMethods.length && !paymentMethod) {
    dispatch(checkoutActions.setPaymentMethod(defaultMethods[0]));
  }

  const handlePayWithSphereOneAlertButton = () => {
    if (buyStatus === BuyStatus.SUCCESS) {
      navigation.navigate("Activity");
    } else {
      dispatch(
        checkoutActions.setBuyStatusDetails({
          buyStatus: null,
          buyStatusMessage: null
        })
      );
    }
  };

  const PayWithSphereOneAlertButton = () => {
    return (
      <StyledTouchableOpacity
        onPress={handlePayWithSphereOneAlertButton}
        className={`h-8 px-3 py-2 mt-3 rounded-lg justify-center items-center flex ${
          buyStatus === BuyStatus.SUCCESS ? "bg-emerald-700" : "bg-red-600"
        }`}
      >
        <StyledText className="text-white text-xs font-medium">
          {buyStatus === BuyStatus.SUCCESS ? "Go to Activity" : "Try again"}
        </StyledText>
      </StyledTouchableOpacity>
    );
  };

  const handlePayCharge = async (wrappedDek: string) => {
    try {
      if (!accessToken) throw new Error("No access token");
      dispatch(checkoutActions.setBuyLoading(true));

      await axios.request({
        url: REACT_APP_BASE_URL + "/pay",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          contentType: "application/json"
        },
        data: { transactionId: txId, wrappedDek },
        method: "POST"
      });

      dispatch(
        checkoutActions.setBuyStatusDetails({
          buyStatus: BuyStatus.SUCCESS,
          buyStatusMessage: i18n.t("checkoutBuySuccessMessage")
        })
      );
    } finally {
      dispatch(checkoutActions.setBuyLoading(false));
      dispatch(checkoutActions.setWrappedDek(wrappedDek));
    }
  };

  const handlePayError = async (error: any) => {
    recordError("CheckoutOnrampScreen", error);
    dispatch(
      checkoutActions.setBuyStatusDetails({
        buyStatus: BuyStatus.ERROR,
        buyStatusMessage: payErrorMessage(error)
      })
    );
  };

  // Only for plain onramps
  const handlePostOrder = async (wrappedDek?: string) => {
    // TODO: We should rename these functions and split them in onramp / onrampCustomToken / payCharge
    if (
      !paymentMethod?.provider ||
      !fiat ||
      paymentMethod.provider === "sphereone" ||
      !chain ||
      !tokenAmount ||
      !token ||
      !accessToken
    ) {
      return;
    }

    try {
      let onrampToAddress: string;
      const onrampAmountWithFee =
        txId && !tokenToOnramp
          ? (Number(onrampAmount) * 1.02).toString()
          : onrampAmount; // Hot fix to add a 2% fee to the amount when paying a charge and it's not a custom token
      const orderChain = tokenToOnramp?.onRampTo.chain || chain;
      const userWallet = findWalletByChain(linkedAccounts, orderChain); // TODO:
      if (txId || tokenToOnramp) {
        // We'll use pay for a custom onramp, so we need spher wallet
        onrampToAddress = userWallet.address;
        if (
          txId &&
          walletAddress &&
          charge?.toAddress.toLowerCase() !== walletAddress?.toLowerCase()
        )
          throw new Error("Wallet address mismatch");
      } else {
        // Just a simple onramp, we can go straight to it
        onrampToAddress = walletAddress ?? userWallet.address;
      }

      dispatch(checkoutActions.setBuyLoading(true));

      const onRampInstance = new Onramp(paymentMethod.provider, accessToken!);
      const order = await onRampInstance?.postOrder({
        customerId: uid as string,
        email: email as string,
        phone: phone as string,
        walletAddress: onrampToAddress,
        fiatAmount: String(quoteData?.fiatAmount),
        tokenAmount: String(onrampAmount ?? tokenAmount),
        tokenSymbol: tokenToOnramp?.onRampTo.symbol ?? token.symbol,
        paymentMethod: paymentMethod.value,
        chain: orderChain,
        fiatSymbol: !isWert(paymentMethod.provider) ? fiat : "USD"
      });

      const orderId = getOrderIdFromResponse(
        paymentMethod.provider,
        order,
        isWert(paymentMethod.provider) ? order : null
      );
      if (txId && wrappedDek) {
        // This calls the pay endpoint
        handleOnrampTransaction(
          "PUT",
          {
            order_id: orderId,
            onrampFromToken: tokenToOnramp?.onRampTo
          },
          accessToken,
          wrappedDek,
          txId
        );
      } else if (customOnrampTxId && wrappedDek) {
        handleOnrampTransaction(
          "PUT",
          {
            order_id: orderId,
            onrampFromToken: tokenToOnramp?.onRampTo
          },
          accessToken,
          wrappedDek,
          customOnrampTxId
        );
      }

      if (paymentMethod.provider == OnrampProviders.WERT) {
        dispatch(checkoutActions.setShowWert(order));
      } else {
        dispatch(checkoutActions.setOnrampUrl(order));
      }
    } catch (error: any) {
      handlePostOrderError(error);
    } finally {
      dispatch(checkoutActions.setBuyLoading(false));
      dispatch(checkoutActions.setWrappedDek(wrappedDek));
    }
  };

  const handlePostOrderError = async (error: any) => {
    if (error.message == `Not wallet found for: ${chain}`) {
      return Alert.alert(i18n.t("walletNotFound", { chain }));
    }
    dispatch(
      checkoutActions.setBuyStatusDetails({
        buyStatus: BuyStatus.ERROR,
        buyStatusMessage: i18n.t("onRampCantPostOrder")
      })
    );
    recordError(error);
  };

  const renderBuyView = () => {
    if (buyStatus === BuyStatus.PINCODE && txId) {
      return (
        <InsertPinCode
          target={txId}
          cb={
            isSphereOneMethod(paymentMethod?.provider)
              ? handlePayCharge
              : handlePostOrder
          }
          closeModal={() =>
            dispatch(checkoutActions.setBuyStatusDetails({ buyStatus: null }))
          }
          containerStyles="m-auto p-8 text-center"
          width="100%"
          position="relative"
          handleError={
            isSphereOneMethod(paymentMethod?.provider)
              ? handlePayError
              : handlePostOrderError
          }
        />
      );
    } else if (
      buyStatus &&
      buyStatus === BuyStatus.SUCCESS &&
      buyStatusMessage
    ) {
      return <CheckoutOnrampStatus />;
    } else if (!buyStatus && !isSphereOneMethod(paymentMethod?.provider)) {
      return <OnrampWidget />;
    } else if (buyStatus === BuyStatus.PINCODE && customOnrampTxId) {
      return (
        <InsertPinCode
          target={customOnrampTxId}
          cb={handlePostOrder}
          closeModal={() =>
            dispatch(checkoutActions.setBuyStatusDetails({ buyStatus: null }))
          }
          containerStyles="m-auto p-8 text-center"
          width="100%"
          position="relative"
          handleError={handlePostOrderError}
        />
      );
    }
    return (
      <InfoAlert
        title={"Error"}
        message={buyStatusMessage || i18n.t("checkoutTransactionErrorMessage")}
        status={"error"}
        Button={PayWithSphereOneAlertButton}
      />
    );
  };

  return (
    <StyledView className="flex-col inline-flex flex-grow w-full md:w-1/2 md:pl-8">
      {!buyStatus && !onrampUrl && !showWert ? (
        <>
          <PaymentMethodsSection
            title={i18n.t("checkoutDefaultPaymentMethods")}
            paymentMethodsLoading={paymentMethodsLoading}
            methods={defaultMethods}
          />

          {moreMethods.length > 0 && (
            <PaymentMethodsSection
              title={i18n.t("checkoutMorePaymentMethods")}
              methods={moreMethods}
              paymentMethodsLoading={paymentMethodsLoading}
              showSearchBar={true}
            />
          )}

          <StyledView className="h-px w-full mb-3 bg-gray-600" />
          <BuyButton
            handlePostOrder={handlePostOrder}
            handlePayCharge={handlePayCharge}
          />
        </>
      ) : (
        <StyledView
          style={{
            flexDirection: "column",
            justifyContent: "flex-end",

            paddingVertical: 8,
            flex: isWeb() ? 1 : undefined
          }}
        >
          <StyledView className="pb-4 flex-row items-center">
            <StyledTouchableOpacity
              onPress={() => {
                dispatch(checkoutActions.onBuyViewGoBackCleanup());
              }}
            >
              <ChevronLeftIcon size={14} color={gray300} strokeWidth={4} />
            </StyledTouchableOpacity>
            <StyledText className="text-white text-sm font-semibold pl-4">
              Payment via {paymentMethod?.label}
            </StyledText>
          </StyledView>
          <StyledView className="h-px w-full mb-4 bg-gray-600" />
          {renderBuyView()}
        </StyledView>
      )}
    </StyledView>
  );
}

export const createCustomOnrampTransaction = async ({
  chain,
  tokenAmount,
  walletAddress,
  tokenAddress,
  symbol,
  onrampFromToken,
  accessToken,
  linkedAccounts,
  dispatch
}: {
  chain: SupportedChains;
  tokenAmount: string;
  walletAddress: string | null;
  tokenAddress: string;
  symbol: string;
  onrampFromToken: SphereToken;
  accessToken: string;
  linkedAccounts: LinkedAccount[];
  dispatch: AppDispatch;
}) => {
  const userWallet = findWalletByChain(linkedAccounts, chain as string);
  const { data: id } = await callFunction({
    method: "POST",
    url: `/onramp-transactions`,
    accessToken: accessToken,
    data: {
      chain: chain,
      total: tokenAmount,
      toAddress: walletAddress ?? userWallet.address,
      tokenAddress: tokenAddress,
      symbol,
      onrampFromToken,
      totalUsd: "" // TODO: add totalUsd to the onramp transaction
    }
  });
  dispatch(checkoutActions.setCustomOnrampTxId(id));
  return id;
};

function BuyButton({
  handlePayCharge,
  handlePostOrder
}: {
  handlePayCharge: (wrappedDek: string) => Promise<void>;
  handlePostOrder: (wrappedDek?: string) => Promise<void>;
}) {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.checkoutOnramp);
  const queryClient = useQueryClient();
  const { user, login } = useCustomAuth();
  const { charge } = useFetchCharge();
  const { data: quoteData, isLoading: quoteLoading } = useFetchQuote();
  const { paymentMethodsLoading } = useFetchPaymentMethods();
  const { linkedAccounts, accessToken } = useAppSelector((state) => state.user);
  const isOnrampMethod = !isSphereOneMethod(state?.paymentMethod?.provider);
  const { isFetching: isRouteFetching } = useGetRouteEstimation();

  const handleBuy = async () => {
    if (user && linkedAccounts.length) {
      return handleUserLoggedIn();
    } else {
      return handleUserNotLoggedIn();
    }
  };

  const handleUserLoggedIn = async () => {
    // If it's a normal onramp or we already have the DEK for an onramp with charge, we can go straight to create the order
    if (
      (!state.customOnrampTxId && !state.txId) ||
      (state.wrappedDek && isOnrampMethod && !state.customOnrampTxId)
    ) {
      return await handlePostOrder(state?.wrappedDek);
    }
    // If it's pay with SphereOne and we have the DEK, we can go straight to pay
    if (state.wrappedDek && isSphereOneMethod(state?.paymentMethod?.provider)) {
      return handlePayCharge(state.wrappedDek);
    }

    // Else, we need to get the DEK first
    dispatch(
      checkoutActions.setBuyStatusDetails({
        buyStatus:
          state.routeStatus === RouteStatus.ERROR
            ? BuyStatus.ERROR
            : BuyStatus.PINCODE
      })
    );
  };

  const handleUserNotLoggedIn = async () => {
    setLoginInitiatedFromStorage(true);
    dispatch(checkoutActions.setBuyLoading(true));
    await handleLogin(queryClient, login, state);
  };
  function renderBuyButtonStyledText() {
    if (state.buyLoading || isRouteFetching || (user && !linkedAccounts.length))
      return <ActivityIndicator size={17} color={"white"} />;
    if (
      state.routeStatus === RouteStatus.PENDING &&
      (state.tokenToOnramp || state.txId)
    )
      return (
        <StyledText className="text-white font-medium">
          Get estimation
        </StyledText>
      );

    return <StyledText className="text-white font-medium"> Buy </StyledText>;
  }
  useEffect(() => {
    const initiateCustomOnrampRouteAfterLogin = async () => {
      const loginInitiated = await getLoginInitiatedFromStorage();
      if (!user || !loginInitiated || !linkedAccounts.length) return;

      if (state.tokenToOnramp && !state.txId) {
        await createCustomOnrampTransaction({
          chain: state.chain!,
          tokenAmount: state.tokenAmount!,
          walletAddress: state.walletAddress,
          tokenAddress: state.tokenToOnramp.address,
          symbol: state.token?.symbol as string,
          onrampFromToken: state.tokenToOnramp.onRampTo,
          accessToken: accessToken!,
          linkedAccounts,
          dispatch
        });
      }
      cleanLoginInitiatedFromStorage();
    };
    initiateCustomOnrampRouteAfterLogin();
  }, [user, linkedAccounts.length]);

  const isButtonDisabled = () =>
    paymentMethodsLoading ||
    !state.paymentMethod ||
    (state.txId && !charge) ||
    (isOnrampMethod && quoteLoading) ||
    (isOnrampMethod && !quoteData) ||
    state.buyLoading ||
    isRouteFetching ||
    (!!user && !linkedAccounts.length) ||
    (!state.routeStatus && !isOnrampMethod) ||
    ((charge?.status === TxStatus.WAITING || state.isChargeStatusWaiting) &&
      !isOnrampMethod);

  return (
    <StyledTouchableOpacity
      disabled={isButtonDisabled()}
      testID="CheckoutBuyButton"
      className="w-full py-3 px-3 mb-3 bg-blue-700 rounded-lg justify-center items-center flex"
      onPress={() => handleBuy()}
    >
      {renderBuyButtonStyledText()}
    </StyledTouchableOpacity>
  );
}

const Header = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const navigation = useNavigation();
  const { countryCode, txId } = useAppSelector((state) => state.checkoutOnramp);
  const {
    name,
    countryCode: profileCountryCode,
    currency: profileCurrency
  } = useAppSelector((state) => state.user);
  const { user, logout } = useCustomAuth();
  const countries = countryInfo.getAllCountries();
  const [showCountryList, setShowCountryList] = useState(false);
  const { data: countryData, isLoading } = useCountryData();
  const { width } = useWindowDimensions();

  if (!isLoading && !countryCode) {
    const countryCode = countryData?.country_code || profileCountryCode;
    const currency = countryData?.currency || (profileCurrency as string);
    dispatch(
      checkoutActions.setCountryData({
        countryCode,
        countryFlag: countryInfo.getCountryInfoByCode(countryCode)?.emoji,
        fiat: currency
      })
    );
  }
  const formatName = (name: string | undefined) => {
    if (name?.includes("@")) {
      return name.split("@")[0];
    } else return name;
  };
  const onCountrySelect = (country: CountryItem) => {
    const currency = countries.filter((c: any) =>
      c.alpha2.includes(country.code)
    );
    dispatch(
      checkoutActions.setCountryData({
        countryCode: country.code,
        countryFlag: country.flag,
        fiat: currency.length > 0 ? currency[0].currencies[0] : undefined
      })
    );
    dispatch(checkoutActions.onCountryDataChangeCleanup());
  };

  // Restore the query client state when the component mounts after coming from login
  useEffect(() => {
    async function restoreState() {
      const savedQueryClientState = await getQueryClientState();
      const storedCheckoutState = await getStoredCheckoutState();
      hydrate(queryClient, savedQueryClientState);
      if (storedCheckoutState) {
        dispatch(checkoutActions.setCheckoutOnrampState(storedCheckoutState));
      }
      cleanQueryClientState();
      cleanStoredCheckoutState();
    }
    restoreState();
  }, []);

  return (
    <>
      <StyledView className="bg-gray-800 w-full h-20 p-4 md:p-6 flex-row justify-start items-center">
        <StyledView className="flex-grow flex-shrink flex-basis-0 h-7 flex-row justify-start items-center">
          {/** Back button */}
          <StyledTouchableOpacity
            onPress={() => handleGoBack(txId, navigation, dispatch)}
          >
            <ChevronLeftIcon size={14} color={gray300} strokeWidth={4} />
          </StyledTouchableOpacity>
          <StyledView className="w-3.5 h-3.5 py-0.5 origin-top-left flex justify-center items-center" />
          <StyledText className="w-full text-white text-sm md:text-lg font-semibold ">
            Buy {txId ? "item" : "crypto"}
          </StyledText>
        </StyledView>
        <StyledView className="self-stretch flex-row justify-start items-center">
          {user ? (
            <StyledView className="flex-row justify-start gap-3 items-center">
              <StyledTouchableOpacity onPress={() => logout()}>
                <StyledView className="w-6 h-6 bg-gray-600 rounded-lg flex-col justify-center items-center">
                  <StyledText className="text-center text-gray-100 text-xs font-medium leading-3">
                    {name ? name[0] : null}
                  </StyledText>
                </StyledView>
              </StyledTouchableOpacity>
              {width >= 500 && (
                <StyledView className="flex-col justify-start items-start ">
                  <StyledText className="text-gray-50 text-xs font-semibold leading-3">
                    {formatName(name)}
                  </StyledText>
                </StyledView>
              )}
            </StyledView>
          ) : (
            <LoginButton />
          )}
          <StyledView className="h-full w-px m-3 bg-gray-600" />
          <StyledView className="flex flex-row justify-start items-center">
            <StyledText className="text-xl">
              {countryCode &&
                countryInfo.getCountryInfoByCode(countryCode)?.emoji}
            </StyledText>
            <StyledText className="text-gray-50 text-sm font-normal leading-tight pl-3">
              {countryCode}
            </StyledText>
            <StyledTouchableOpacity
              className="p-3"
              onPress={() => setShowCountryList(true)}
            >
              <ChevronDownIcon
                id="show-country-selector"
                size={14}
                color={gray300}
                strokeWidth={4}
              />
            </StyledTouchableOpacity>
          </StyledView>
        </StyledView>
      </StyledView>
      <StyledView className="h-px w-full bg-gray-600" />
      <CountryCodeSelector
        show={showCountryList}
        onPrimaryClick={async (item: CountryItem) => {
          onCountrySelect(item);
          setShowCountryList(false);
        }}
        onSecondaryClick={() => {
          setShowCountryList(false);
          Keyboard.dismiss();
        }}
      />
    </>
  );
};

const LoginButton = () => {
  const queryClient = useQueryClient();
  const { login } = useCustomAuth();
  const state = useAppSelector((state) => state.checkoutOnramp);
  return (
    <StyledTouchableOpacity
      className="w-16 h-8 px-3 py-2 rounded-lg bg-gray-800 border-[1px] border-gray-600 justify-center items-center flex"
      onPress={() => handleLogin(queryClient, login, state)}
    >
      <StyledText className="text-gray-300 text-xs font-medium">
        Sign in
      </StyledText>
    </StyledTouchableOpacity>
  );
};

function AddWalletToAccount({
  walletLabel,
  walletAddress
}: {
  walletLabel: string | null;
  walletAddress: string;
}) {
  const userWallets = useAppSelector((state) => state.user.linkedAccounts);
  const accessToken = useAppSelector((state) => state.user.accessToken);
  const [addWalletLoading, setAddWalletLoading] = useState(false);
  const [walletAdded, setWalletAdded] = useState(false);

  const shortenAddress = (str: string) =>
    str.substring(0, 4) + "..." + str.substring(str.length - 4);

  const handleAddWallet = async () => {
    Alert.alert(i18n.t("addWallet"), i18n.t("addWalletDescription"), [
      { text: "Cancel", style: "cancel" },
      {
        text: "OK",
        onPress: async () => {
          setAddWalletLoading(true);
          try {
            if (!accessToken) {
              throw new Error("You need to be authenticated first");
            }

            await axios.post(
              REACT_APP_BASE_URL + "/user/wallets",
              { address: walletAddress, label: walletLabel },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  contentType: "application/json"
                }
              }
            );
            setWalletAdded(true);
            Alert.alert("Success", "Wallet added");
          } catch (e: any) {
            Alert.alert("Error", "Could not add wallet: " + e?.message);
          } finally {
            setAddWalletLoading(false);
          }
        }
      }
    ]);
  };

  useEffect(() => {
    const wallet = userWallets.find(
      (wallet) => wallet?.id.toLowerCase() === walletAddress.toLowerCase()
    );
    if (wallet) {
      setWalletAdded(true);
    }
  }, [userWallets]);

  return (
    <StyledView className="bg-gray-700 items-center mb-3 border-gray-600 rounded-md border p-2 flex flex-row justify-between">
      <StyledText>
        {walletLabel} wallet - {shortenAddress(walletAddress)}
      </StyledText>
      {!walletAdded && (
        <StyledTouchableOpacity
          onPress={handleAddWallet}
          disabled={addWalletLoading}
          className="bg-blue-500 rounded p-1 px-2 text-white"
        >
          <StyledText>
            {addWalletLoading ? "Loading..." : "Add wallet"}
          </StyledText>
        </StyledTouchableOpacity>
      )}
    </StyledView>
  );
}

const handleLogin = async (
  queryClient: QueryClient,
  login: () => Promise<void>,
  state: CheckoutOnrampState
) => {
  // save query client state and selected payment method in local storage
  const dehydratedState = dehydrate(queryClient);
  setQueryClientState(dehydratedState);
  setStoredCheckoutState(state);
  // @ts-ignore
  await login({
    scope: "openid email offline_access",
    extraQueryParams: {
      audience: REACT_APP_AUTH_AUDIENCE
    }
  });
};
