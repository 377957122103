import React, { useEffect, useState } from "react";
import {
  KeyboardAvoidingView,
  TouchableOpacity,
  Keyboard,
  ScrollView
} from "react-native";
import { CameraIcon } from "react-native-heroicons/outline";
import Toast from "react-native-toast-message";
import { getCountryCallingCode, isValidPhoneNumber } from "libphonenumber-js";
import { CountryItem } from "react-native-country-codes-picker/types/Types";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as countryInfo from "countries-information";

import Header from "../../components/Header";
import { Text, View } from "../../components/Themed";
import Input from "../../components/ThemedComponents/Input";
import {
  blue600,
  colorBackground,
  colorLabelTextSecondary,
  colorPrimary,
  gray300,
  gray400,
  gray600,
  gray700,
  gray800,
  green500,
  skyBlue,
  white
} from "../../utils/colors";
import PrimaryButton from "../../components/ThemedComponents/PrimaryButton";
import FormControl from "../../components/ThemedComponents/FormControl";
import {
  isEmptyString,
  isPhoneNumber,
  isValidUsername
} from "../../utils/validation";
import PhotoMethodSelectorModal from "../../components/EditProfileScreen/ChooseProfilePictureModal";
import Avatar from "../../components/Avatar";
import { launchImageLibrary } from "react-native-image-picker";
import i18n from "../../config/languageInternationalization";
import { storage } from "../../utils/firebase";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setProfileImage, updateUserInfo } from "../../redux/slices/userThunk";
import { EditUserState } from "../../../types/accounts";
import { isAndroid, isWeb } from "../../utils/platform";
import CountryCodeSelector from "../../components/ThemedComponents/CountryCodeSelector";
import handlePhoneNumberChange from "../../utils/formatePhoneNumber";
import { setAskingPermission } from "../../redux/slices/user";
import { recordError } from "../../utils/crashlytics";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { ProfileStackNavigatorParamList } from "../../../types/navigationTypes";
import { Alert } from "../../utils/Alert";
import { countryCodes } from "react-native-country-codes-picker/constants/countryCodes";
import { deleteProfileImage } from "../../utils/server";
import SafeAreaView from "../../components/ThemedComponents/SafeAreaView";

type ProfileUserErrorState = {
  name: string;
  username: string;
  phone: string;
};

const initialState: EditUserState = {
  uid: "",
  name: "",
  username: "",
  phone: "",
  countryCode: undefined,
  countryFlag: undefined,
  currency: ""
};
const initialErrors: ProfileUserErrorState = {
  name: "",
  username: "",
  phone: ""
};

type EditProfileScreenNavigationProp = NativeStackScreenProps<
  ProfileStackNavigatorParamList,
  "EditProfileScreen"
>;

export default function EditProfileScreen({
  navigation,
  route
}: EditProfileScreenNavigationProp) {
  const dispatch = useAppDispatch();
  const [showSelectCountryModal, setShowSelectCountryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [
    chooseProfilePictureModalVisible,
    setChooseProfilePictureModalVisible
  ] = useState(false);
  const [state, setState] = useState<EditUserState>(initialState);
  const [errors, setErrors] = useState<ProfileUserErrorState>(initialErrors);

  const originalState = useAppSelector((state) => state.user);
  const countries = countryInfo.getAllCountries();
  const [dialCode, setDialCode] = useState<string>(
    countryCodes.find((e) => e.code === originalState.countryCode)?.dial_code ??
      ""
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [newChanges, setNewChanges] = useState<boolean>(false);
  const { uid, email } = useAppSelector((state) => state.user);
  const { fromOnboarding } = route.params;

  const handlePickPhotoOnPress = () =>
    setChooseProfilePictureModalVisible(true);

  const handleOnUploadPhoto = async () => {
    try {
      dispatch(setAskingPermission(true));
      const result = await launchImageLibrary({
        selectionLimit: 1,
        mediaType: "photo"
      });
      setChooseProfilePictureModalVisible(false);
      if (result?.assets?.length) {
        setLoading(true);
        const uri = result.assets[0].uri;
        const uploadUri = !isAndroid() ? uri?.replace("file://", "") : uri;

        const task = storage.ref(`images/profilepictures/${originalState.uid}`);
        dispatch(setAskingPermission(true));

        if (!isWeb()) await task.putFile(uploadUri as string);
        else {
          if (!uri) return;
          const base64String = uri;
          const mimeTypeRegex = /^data:image\/(.+?);/;
          const matches = base64String.match(mimeTypeRegex);
          const mimeType = matches && matches[1];

          if (!mimeType) return;
          const metadata = {
            contentType: "image/" + mimeType
          };
          await task.putString(base64String, "data_url", metadata);
        }
        await dispatch(setProfileImage(await task.getDownloadURL()));

        if (!originalState.accessToken) throw new Error("No access token");

        Alert.alert(
          i18n.t("editProfileScreenPhotoUploaded"),
          i18n.t("editProfileScreenPhotoUploadedMessage"),
          [
            {
              text: "OK"
            }
          ],
          { cancelable: false }
        );
      }
    } catch (e: any) {
      setChooseProfilePictureModalVisible(false);
      recordError(e, "EditProfileScreen.tsx");
      Alert.alert(i18n.t("alertErrorTitle"), i18n.t("alertErrorSubtitle"));
    } finally {
      dispatch(setAskingPermission(false));
      setLoading(false);
    }
  };

  const handleOnChooseNFT = () => {
    navigation.navigate("ChooseNftScreen");
    setChooseProfilePictureModalVisible(false);
  };

  const handleOnDeleteAvatar = async () => {
    try {
      setChooseProfilePictureModalVisible(false);
      await dispatch(setProfileImage(""));
      deleteProfileImage({
        accessToken: originalState.accessToken as string
      });
    } catch (error) {
      recordError(error);
    }
  };

  function countErrors(errors: object) {
    let errorsCount = 0;
    for (const error of Object.values(errors)) {
      if (error !== "") errorsCount++;
    }
    return errorsCount;
  }

  async function validateErrors(state: EditUserState) {
    const editErrors: ProfileUserErrorState = { ...initialErrors };

    // Name validations
    if (!state.name || isEmptyString(state.name))
      editErrors.name = i18n.t("editProfileScreenNameIsRequiredErrorMessage");

    // Phone validations
    if (
      !state.phone ||
      (!isEmptyString(state.phone) && !isPhoneNumber(state.phone)) ||
      !isValidPhoneNumber(state.phone) ||
      state.countryCode === undefined
    )
      editErrors.phone = i18n.t("editProfileScreenPhoneIsInvalidErrorMessage");

    // Username validations
    if (!state.username || isEmptyString(state.username))
      editErrors.username = i18n.t("editProfileScreenRequiredUsernameError");
    else if (!isValidUsername(state.username))
      editErrors.username = i18n.t("editProfileScreenInvalidUsernameError");

    // If we already have errors to report let's break here to avoid api call
    if (countErrors(editErrors) > 0) return editErrors;

    return editErrors;
  }

  function createUpdateObject(state: EditUserState, newState: EditUserState) {
    const updates: EditUserState = {};

    const { name, username, phone, countryCode, countryFlag, currency } =
      newState;

    if (state.name !== name) updates.name = name;
    if (state.username !== username) updates.username = username;
    if (state.phone !== phone) updates.phone = phone?.replace(/\s/g, "");
    if (state.countryCode !== countryCode) updates.countryCode = countryCode;
    if (state.countryFlag !== countryFlag) updates.countryFlag = countryFlag;
    if (currency && state.currency !== currency) updates.currency = currency;
    return updates;
  }

  // Save user data
  async function handleSave() {
    try {
      setLoading(true);
      const newErrors = await validateErrors(state);
      setErrors(newErrors);

      // If errors break function here
      if (countErrors(newErrors) > 0) return null;
      const updates = createUpdateObject(originalState, state);

      if (Object.values(updates).length > 0) {
        // There are updates
        if (!uid) throw new Error("Unauthorized");
        await dispatch(updateUserInfo(updates)).unwrap();
        Toast.show({
          type: "success",
          text1: i18n.t("editProfileScreenProfileInfoUpdateMessage")
        });

        if (fromOnboarding) {
          navigation.navigate("NotificationsScreen");
        }
      }
    } catch (error: any) {
      Toast.show({
        type: "error",
        text1: i18n.t("editProfileScreenProfileInfoUpdateMessageError")
      });
      recordError(error.message, "EditProfileScreen.tsx");
    } finally {
      setNewChanges(false);
      setLoading(false);
    }
  }

  const handleOnChangeText = (
    text: string,
    name: keyof typeof initialState
  ) => {
    !newChanges && setNewChanges(true);
    setState({
      ...state,
      [name]: text
    });
  };

  const onCountrySelect = (country: CountryItem) => {
    const currency = countries.filter((c: any) =>
      c.alpha2.includes(country.code)
    );
    setState({
      ...state,
      phone: `${country.dial_code} ${
        state.phone ? state.phone?.substring(dialCode?.length || 0) : ""
      }`,
      countryCode: country.code,
      countryFlag: country.flag,
      currency: currency.length > 0 ? currency[0].currencies[0] : undefined
    });
    setDialCode(country.dial_code);
  };

  useEffect(() => {
    setState({
      ...state,
      uid: originalState.uid,
      name: originalState.name,
      username: originalState.username,
      phone: originalState.phone,
      countryCode: originalState.countryCode,
      countryFlag: originalState.countryFlag
    });
  }, [originalState.name]);

  useEffect(() => {
    if (state.phone) {
      const numberFormatted = handlePhoneNumberChange(state.phone ?? "");
      setPhoneNumber(numberFormatted);
    }
    if (!state.phone && state.countryCode) {
      const dialCode = getCountryCallingCode(state.countryCode);
      dialCode && setPhoneNumber(`+${dialCode}`);
    }
  }, [state]);

  return (
    <SafeAreaView
      style={
        isWeb()
          ? {
              backgroundColor: gray800,
              borderWidth: 1,
              borderColor: gray700,
              marginVertical: 20,
              borderRadius: 16
            }
          : { position: "relative" }
      }
    >
      <Header
        titleStyles={{ fontSize: 20, fontWeight: "700" }}
        title={i18n.t("editProfileScreenEditProfile")}
      />
      <View
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 1000,
          alignItems: "center"
        }}
      >
        <PhotoMethodSelectorModal
          visible={chooseProfilePictureModalVisible}
          setVisible={setChooseProfilePictureModalVisible}
          onUploadPhoto={handleOnUploadPhoto}
          onChooseNFT={handleOnChooseNFT}
          onDeleteAvatar={handleOnDeleteAvatar}
        />
      </View>
      <ScrollView style={{ marginBottom: 10 }}>
        {/* Profile picture */}
        <TouchableOpacity
          onPress={handlePickPhotoOnPress}
          testID="EditProfilePictureButton"
          style={{
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <View style={{ position: "relative" }}>
            <Avatar
              nameOrEmail={
                originalState.name !== undefined
                  ? originalState.name
                  : (email as string)
              }
              source={
                originalState.pfp !== undefined && originalState.pfp !== ""
                  ? { uri: originalState.pfp }
                  : null
              }
              size={150}
            />
            <View
              style={{
                width: 36,
                height: 36,
                backgroundColor: green500,
                position: "absolute",
                right: 3,
                bottom: 3,
                borderRadius: 100,
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <CameraIcon size={25} color="white" />
            </View>
          </View>
        </TouchableOpacity>

        {/* User data form */}
        <KeyboardAvoidingView
          behavior="position"
          style={{
            paddingHorizontal: 16,
            marginTop: isWeb() ? 48 : 24,
            marginBottom: 32
          }}
        >
          <Text
            style={{
              marginBottom: 20,
              color: colorLabelTextSecondary,
              fontSize: 16
            }}
          >
            {i18n.t("editProfileScreenPersonalInformation")}
          </Text>
          {/* Email input */}
          <Input
            inputStyle={{ color: gray400 }}
            style={{
              marginBottom: 20,
              backgroundColor: gray700,
              borderWidth: 1,
              borderColor: gray600,
              borderRadius: 8,
              paddingVertical: 14,
              paddingHorizontal: 16
            }}
            value={email!}
            editable={false}
          />

          {/* Name input */}
          <FormControl
            style={{ marginBottom: 20 }}
            isInvalid={errors.name !== ""}
            errorMessage={errors.name}
          >
            <Input
              inputStyle={{ color: white }}
              style={{
                backgroundColor: gray700,
                borderWidth: 1,
                borderColor: gray600,
                borderRadius: 8,
                paddingVertical: 14,
                paddingHorizontal: 16
              }}
              testID="EditProfileNameInput"
              onChangeText={(t) => handleOnChangeText(t, "name")}
              defaultValue={state.name}
              placeholder={i18n.t("editProfileScreenNamePlaceholder")}
            />
          </FormControl>

          {/* Username input */}
          <FormControl
            style={{ marginBottom: 20 }}
            isInvalid={errors.username !== ""}
            errorMessage={errors.username}
          >
            <Input
              inputStyle={{ color: white }}
              style={{
                backgroundColor: gray700,
                borderWidth: 1,
                borderColor: gray600,
                borderRadius: 8,
                paddingVertical: 14,
                paddingHorizontal: 16
              }}
              testID="EditProfileUsernameInput"
              onChangeText={(t) => handleOnChangeText(t, "username")}
              defaultValue={state.username}
              placeholder={i18n.t("authScreensUsernamePlaceholder")}
            />
          </FormControl>

          {/* Phone input */}
          <FormControl
            testID="EditProfilePhoneInput"
            isInvalid={errors.phone !== ""}
            errorMessage={errors.phone}
          >
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap"
              }}
            >
              <TouchableOpacity
                onPress={() => setShowSelectCountryModal(true)}
                testID="EditProfileCountryCodeButton"
                style={{
                  backgroundColor: colorBackground,
                  justifyContent: "center",
                  alignItems: "baseline",
                  paddingHorizontal: 20
                }}
              >
                <Text
                  style={{
                    color: colorPrimary,
                    fontSize: 16
                  }}
                >
                  {state.countryFlag
                    ? state.countryFlag
                    : i18n.t("countryPickerSelectPlaceholder")}
                </Text>
              </TouchableOpacity>
              <Input
                inputStyle={{ color: white }}
                style={{
                  flex: 1,
                  backgroundColor: gray700,
                  borderWidth: 1,
                  borderColor: gray600,
                  borderRadius: 8,
                  paddingVertical: 14,
                  paddingHorizontal: 16
                }}
                keyboardType="phone-pad"
                onChangeText={(t) => handleOnChangeText(t, "phone")}
                defaultValue={phoneNumber}
                placeholder={i18n.t("editProfileScreenPhonePlaceholder")}
              />
            </View>
          </FormControl>
        </KeyboardAvoidingView>

        <CountryCodeSelector
          show={showSelectCountryModal}
          onPrimaryClick={(item: CountryItem) => {
            onCountrySelect(item);
            setShowSelectCountryModal(false);
          }}
          onSecondaryClick={() => {
            setShowSelectCountryModal(false);
            Keyboard.dismiss();
          }}
        />

        {!isWeb() && (
          <PrimaryButton
            isDisabled={!newChanges}
            textStyle={{ color: white }}
            style={{
              marginHorizontal: 16,
              borderRadius: 8,
              backgroundColor: blue600
            }}
            onPress={handleSave}
            isLoading={loading}
          >
            {i18n.t("editProfileScreenSave")}
          </PrimaryButton>
        )}
      </ScrollView>
      {isWeb() && (
        <PrimaryButton
          isDisabled={!newChanges}
          textStyle={{ color: white }}
          style={{
            marginHorizontal: 16,
            borderRadius: 8,
            backgroundColor: blue600,
            marginBottom: 24
          }}
          onPress={handleSave}
          isLoading={loading}
        >
          {i18n.t("editProfileScreenSave")}
        </PrimaryButton>
      )}
    </SafeAreaView>
  );
}
