import { useState, useCallback } from "react";

interface ModalProps {
  [key: string]: any;
}
export interface ModalHook {
  visible: boolean;
  props: ModalProps;
  showModal: (newProps?: ModalProps) => void;
  hideModal: () => void;
}
export function useModal(): ModalHook {
  const [visible, setVisible] = useState(false);
  const [props, setProps] = useState<ModalProps>({});

  const showModal = useCallback((newProps: ModalProps = {}) => {
    setProps(newProps);
    setVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setVisible(false);
  }, []);

  return {
    visible,
    props,
    showModal,
    hideModal
  };
}
