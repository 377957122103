import React, { useEffect } from "react";
import { KeyboardAvoidingView, ScrollView, View } from "react-native";
import WebView from "react-native-webview";
import { colorBackgroundTransparent } from "../../utils/colors";
import { WertData } from "../../../types/onRamp";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { BuyStatus, checkoutActions } from "../../redux/slices/checkoutOnramp";
import { isWeb } from "../../utils/platform";
import { TxStatus } from "../../../types/transfer";
import i18n from "../../config/languageInternationalization";
import { useTransactionStatusInterval } from "../../hooks/useOnRamp";

export const WertWebview = () => {
  const dispatch = useAppDispatch();
  const { showWert } = useAppSelector((state) => state.checkoutOnramp);
  const code = createWertWebViewCode(showWert);
  return (
    <View style={{ flex: 1 }}>
      <WebView
        originWhitelist={["*"]}
        source={{ html: code }}
        javaScriptEnabled={true}
        onMessage={(event: any) => {
          // here we receive the message from the "close" listener in the Wert webview
          if (event.nativeEvent.data === "close") {
            dispatch(
              checkoutActions.setCheckoutOnrampState({ showWert: null })
            );
          }
        }}
      />
    </View>
  );
};

export function createWertWebViewCode(wertData: WertData | null): string {
  return `
    <html>
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
      </head>
      <body style="background-color:${colorBackgroundTransparent}; max-width:100%; height:auto;">
        <script type="text/javascript" src="https://javascript.wert.io/wert-5.0.0.js"></script>
        <script type="text/javascript">
          const wertObject = ${JSON.stringify(wertData)};
          wertObject.listeners = {
            close: function() {
              window.ReactNativeWebView.postMessage("close");
            },
          };
          const wertWidget = new WertWidget(wertObject);
          wertWidget.open();
        </script>
      </body>
    </html>
  `;
}

export const OnrampWidget = () => {
  const { onrampUrl, showWert, txId, customOnrampTxId } = useAppSelector(
    (state) => state.checkoutOnramp
  );
  const transactionId = txId ?? customOnrampTxId;
  const dispatch = useAppDispatch();

  useTransactionStatusInterval(transactionId, (res) => {
    if (res.data.data.status !== TxStatus.WAITING) {
      dispatch(
        checkoutActions.setBuyStatusDetails({
          buyStatus: BuyStatus.SUCCESS,
          buyStatusMessage: i18n.t("checkoutBuySuccessMessage")
        })
      );
    }
  });

  return (
    <View
      style={{
        flexDirection: "column",
        justifyContent: "flex-end",
        paddingHorizontal: 16,
        paddingVertical: 8,
        flex: isWeb() ? 1 : undefined
      }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        bounces={true}
      >
        <KeyboardAvoidingView
          style={{
            zIndex: 50,
            height: 560,
            width: "100%",
            alignItems: isWeb() ? "center" : undefined
          }}
        >
          {!isWeb() ? (
            <>
              {!showWert ? (
                <WebView
                  source={{ uri: onrampUrl ?? "" }}
                  allowFileAccess={true}
                  allowsInlineMediaPlayback={true}
                  allowsFullscreenVideo={true}
                  allowsBackForwardNavigationGestures={false}
                  allowsLinkPreview={false}
                  scalesPageToFit={false}
                  keyboardDisplayRequiresUserAction={true}
                  scrollEnabled={true}
                  style={{
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: "#58585f",
                    margin: "auto"
                  }}
                />
              ) : (
                <WertWebview />
              )}
            </>
          ) : (
            <iframe
              height="100%"
              width="100%"
              src={!showWert ? (onrampUrl as string) : undefined}
              srcDoc={showWert ? createWertWebViewCode(showWert) : undefined}
              title="Onramper widget"
              allow="accelerometer; autoplay; camera; gyroscope; payment"
            ></iframe>
          )}
        </KeyboardAvoidingView>
      </ScrollView>
    </View>
  );
};
