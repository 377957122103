import { Platform } from "react-native";
import { UserAttributes } from "../../types/attributes";
// import { crashReport } from "./firebase";

/**
 * Track unique attributes pertaining to the User of these recorded events
 *
 * @param userAttributes An object representing the user that app wants to collect and
 * associate with the collected error events. For now, app is only collecting the
 * user's uid and Platform, so there isn't much. But if in near future, we want more items
 * to collect, we'll add them to UserAttributes.
 */
export async function setAttributes(userAttributes: UserAttributes) {
  // await Promise.all([
  //   crashReport.setUserId(userAttributes.uid),
  //   crashReport.setAttributes({
  //     platform: Platform.OS,
  //   }),
  // ]);
}

/**
 * Record the error
 *
 * @param err This is where the error will be assigned.
 *
 * @param message Any special or custom message to associate with this crash.
 *
 * #### Example
 *
 * ```ts
 * import { auth, storage } from "./firebase";
 * import { recordError } from "./crashlytics";
 *
 * function getSomething() {
 *  try {
 *   // ...
 *  } catch (e) {
 *   recordError(e, "GetSomething");
 *  }
 * };
 * ```
 */
export async function recordError(err: any, message?: string) {
  // if (message) crashReport.log(message);
  // crashReport.recordError(err);
}
