import React from "react";
import {
  StyleProp,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps
} from "react-native";
import { colorPrimary } from "../../utils/colors";
import { Text } from "../Themed";

interface LinkProps extends TouchableOpacityProps {
  children: string;
  textStyle?: StyleProp<TextStyle>;
}

export default function Link(props: LinkProps) {
  const { style, textStyle, children, ...otherProps } = props;
  return (
    <TouchableOpacity style={[style]} {...otherProps}>
      <Text style={[{ color: colorPrimary }, textStyle]}>{children}</Text>
    </TouchableOpacity>
  );
}
