import { View, Text } from "react-native";
import React, { ReactNode } from "react";

export default function ReceiptRow({
  label,
  children
}: {
  label: string;
  children: ReactNode;
}) {
  return (
    <View className="py-2.5 border-b border-solid border-gray-700 items-start flex-row justify-between">
      <Text className="text-gray-400">{label}</Text>
      {children}
    </View>
  );
}
