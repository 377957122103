import React, { useEffect, useState } from "react";
import { Text, View } from "./Themed";
import axios from "axios";
import {
  CheckIcon,
  ClockIcon,
  XMarkIcon
} from "react-native-heroicons/outline";
import { white } from "../utils/colors";
import {
  StyledText,
  StyledTouchableOpacity
} from "./ThemedComponents/NativeWindStyledWrappers";
import { useNavigation } from "@react-navigation/native";
import { useAppSelector } from "../redux/hooks";
import { TxStatus } from "../../types/transfer";
import i18n from "../config/languageInternationalization";
import { Alert } from "../utils/Alert";
import { useTransactionStatusInterval } from "../hooks/useOnRamp";

export default function CheckoutOnrampStatus() {
  const { txId, customOnrampTxId } = useAppSelector(
    (state) => state.checkoutOnramp
  );
  const accessToken = useAppSelector((state) => state.user.accessToken);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [successUrl, setSuccessUrl] = useState("");
  const [cancelUrl, setCancelUrl] = useState("");
  const transactionId = customOnrampTxId ?? txId;

  useTransactionStatusInterval(transactionId, (res) => {
    setPaymentStatus(res.data.data.status);
    setSuccessUrl(res.data.data.successUrl ?? "");
    setCancelUrl(res.data.data.cancelUrl ?? "");
  });

  if (paymentStatus === TxStatus.SUCCESS)
    return <SuccessfulTx successUrl={successUrl} />;
  else if (paymentStatus === TxStatus.FAILURE)
    return <FailedTx cancelUrl={cancelUrl} />;
  else if (paymentStatus === TxStatus.PROCESSING) return <ProcessingTx />;
  else if (paymentStatus === TxStatus.PENDING) return <PendingTx />;
  else
    return (
      <View className="bg-gray-800  items-center">
        <Text className="py-10">{i18n.t("loading")}</Text>
      </View>
    );
}

function SuccessfulTx({ successUrl }: { successUrl: string }) {
  const navigation = useNavigation<any>();
  return (
    <View className="bg-gray-800 py-10 items-center">
      <View style={{ alignItems: "center", marginBottom: 12, marginTop: 60 }}>
        <View className="bg-green-500 h-10 w-10 rounded-full items-center justify-center">
          <CheckIcon width={24} height={24} color={white} strokeWidth={2} />
        </View>
      </View>
      <Text className="mb-2 text-2xl text-center">
        {i18n.t("successTxTitle")}
      </Text>
      <Text style={{ maxWidth: 300, textAlign: "center" }}>
        {i18n.t("successTxSubtitle")}
      </Text>
      <StyledTouchableOpacity
        onPress={() => {
          successUrl && window.open(successUrl, "_blank");
          navigation.navigate("Activity");
        }}
        className="px-4 py-2 mt-4 bg-blue-700 rounded-lg justify-center items-center flex"
      >
        <StyledText className="text-white font-medium">
          {i18n.t("continue")}
        </StyledText>
      </StyledTouchableOpacity>
    </View>
  );
}

function ProcessingTx() {
  const navigation = useNavigation<any>();
  return (
    <View className="bg-gray-800 py-10 items-center">
      <View style={{ alignItems: "center", marginBottom: 12, marginTop: 60 }}>
        <View className="bg-green-500 h-10 w-10 rounded-full items-center justify-center">
          <ClockIcon width={24} height={24} color={white} strokeWidth={2} />
        </View>
      </View>
      <Text className="mb-2 text-2xl text-center">
        {i18n.t("processingTxTitle")}
      </Text>
      <Text style={{ maxWidth: 300, textAlign: "center" }}>
        {i18n.t("processingTxSubtitle")}
      </Text>
      <StyledTouchableOpacity
        onPress={() => navigation.navigate("Activity")}
        className="px-4 py-2 mt-4 bg-blue-700 rounded-lg justify-center items-center flex"
      >
        <StyledText className="text-white font-medium">
          {i18n.t("continue")}
        </StyledText>
      </StyledTouchableOpacity>
    </View>
  );
}

function FailedTx({ cancelUrl }: { cancelUrl: string }) {
  const navigation = useNavigation<any>();
  return (
    <View className="bg-gray-800 py-10 items-center">
      <View style={{ alignItems: "center", marginBottom: 12, marginTop: 60 }}>
        <View className="bg-red-500 h-10 w-10 rounded-full items-center justify-center">
          <XMarkIcon width={24} height={24} color={white} strokeWidth={2} />
        </View>
      </View>
      <Text className="mb-2 text-2xl text-center">
        {i18n.t("failedTxTitle")}
      </Text>
      <Text style={{ maxWidth: 300, textAlign: "center" }}>
        {i18n.t("failedTxSubtitle")}
      </Text>
      <StyledTouchableOpacity
        onPress={() => {
          cancelUrl && window.open(cancelUrl, "_blank");
          navigation.navigate("Activity");
        }}
        className="px-4 py-2 mt-4 bg-blue-700 rounded-lg justify-center items-center flex"
      >
        <StyledText className="text-white font-medium">
          {i18n.t("continue")}
        </StyledText>
      </StyledTouchableOpacity>
    </View>
  );
}

function PendingTx() {
  const navigation = useNavigation<any>();
  return (
    <View className="bg-gray-800 py-10 items-center">
      <View style={{ alignItems: "center", marginBottom: 12, marginTop: 60 }}>
        <View className="bg-blue-500 h-10 w-10 rounded-full items-center justify-center">
          <ClockIcon width={24} height={24} color={white} strokeWidth={2} />
        </View>
      </View>
      <Text className="mb-2 text-2xl text-center">
        {i18n.t("pendingTxTitle")}
      </Text>
      <Text style={{ maxWidth: 300, textAlign: "center" }}>
        {i18n.t("pendingTxSubtitle")}
      </Text>
      <StyledTouchableOpacity
        onPress={() => navigation.navigate("Activity")}
        className="px-4 py-2 mt-4 bg-blue-700 rounded-lg justify-center items-center flex"
      >
        <StyledText className="text-white font-medium">
          {i18n.t("continue")}
        </StyledText>
      </StyledTouchableOpacity>
    </View>
  );
}
