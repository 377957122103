import React, { ReactNode } from "react";
import { FlexAlignType, ViewStyle } from "react-native";
import SkeletonContent from "react-native-skeleton-content";
import { flowbiteBorderColor, gray400 } from "../../utils/colors";

export interface ICustomViewStyle extends ViewStyle {
  children?: ICustomViewStyle[];
  key?: number | string;
}

export const summarySkeletonLayout = [
  {
    key: 1,
    width: 144,
    height: 192,
    borderRadius: 8,
    alignSelf: "center" as FlexAlignType,
    marginBottom: 12
  },
  {
    key: 2,
    width: "100%",
    height: 64,
    marginBottom: 12
  },
  {
    key: 3,
    width: "100%",
    height: 32
  }
];

export const paymentMethodsSkeletonLayout = [
  {
    key: 1,
    width: "100%",
    height: 48,
    marginBottom: 10,
    borderRadius: 8
  },
  {
    key: 2,
    width: "100%",
    height: 48,
    marginBottom: 10,
    borderRadius: 8
  },
  {
    key: 3,
    width: "100%",
    height: 48,
    marginBottom: 10,
    borderRadius: 8
  },
  {
    key: 4,
    width: "100%",
    height: 48,
    marginBottom: 10,
    borderRadius: 8
  }
];

export const priceSkeletonLayout = [
  {
    key: 1,
    width: 40,
    height: 8
  }
];
export const CheckoutSkeleton = ({
  isLoading,
  children,
  layout,
  alignItems
}: {
  isLoading: boolean;
  children: ReactNode;
  layout: ICustomViewStyle[];
  alignItems?: FlexAlignType;
}) => {
  return (
    <SkeletonContent
      containerStyle={{ flex: 1, width: "100%", alignItems: alignItems }}
      isLoading={isLoading}
      boneColor={flowbiteBorderColor}
      highlightColor={gray400}
      layout={layout}
    >
      {children}
    </SkeletonContent>
  );
};
