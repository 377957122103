import React from "react";
import {
  TouchableOpacity,
  TouchableOpacityProps,
  Text,
  ActivityIndicator,
  StyleProp,
  TextStyle
} from "react-native";
import { colorPrimary, colorPrimaryDisabled } from "../../utils/colors";
import { View } from "../Themed";

export interface ButtonProps extends TouchableOpacityProps {
  isDisabled?: boolean;
  isLoading?: boolean;
  textStyle?: StyleProp<TextStyle>;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  rounded?: boolean;
  className?: string;
}

export default function Button(props: ButtonProps) {
  const {
    style,
    leftIcon,
    rightIcon,
    rounded,
    textStyle,
    isLoading,
    isDisabled,
    ...otherProps
  } = props;

  return (
    <TouchableOpacity
      disabled={isLoading || isDisabled}
      className={`${isDisabled ? "bg-blue-800" : "bg-blue-600"}`} // disabled: seems not to work
      style={[
        {
          paddingVertical: 12,
          paddingHorizontal: 24,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          borderRadius: rounded ? 100 : 0
        },
        style
      ]}
      {...otherProps}
    >
      {isLoading ? (
        <ActivityIndicator style={{ height: 24 }} color="white" />
      ) : (
        <>
          {leftIcon && <View style={{ marginRight: 8 }}>{leftIcon}</View>}
          <Text
            style={[
              {
                fontWeight: "700",
                textAlign: "center",
                color: "white",
                fontSize: 18
              },
              textStyle
            ]}
          >
            {props.children}
          </Text>
          {rightIcon && <View style={{ marginLeft: 8 }}>{rightIcon}</View>}
        </>
      )}
    </TouchableOpacity>
  );
}
