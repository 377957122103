import React, { ReactElement } from "react";
import { Text, View, ViewProps } from "../Themed";
import FormErrorMessage from "./FormErrorMessage";
import { InputProps } from "./Input";

interface FormControlProps extends ViewProps {
  children: ReactElement<InputProps> | ReactElement<InputProps>[];
  isInvalid?: boolean;
  errorMessage?: string;
  label?: string;
}

export default function FormControl(props: FormControlProps) {
  const { isInvalid, label, errorMessage, children, ...otherProps } = props;

  // Iterate over each input children and append the isInvalid prop
  const childrenWithProps = React.Children.map(
    children,
    (child: ReactElement<InputProps>) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { isInvalid });
      }
      return child;
    }
  );

  return (
    <View {...otherProps}>
      {label !== undefined && (
        <Text style={{ fontSize: 16, marginBottom: 4, fontWeight: "500" }}>
          {label}
        </Text>
      )}
      {childrenWithProps}
      {isInvalid && (
        <FormErrorMessage style={{ marginTop: 8 }}>
          {errorMessage}
        </FormErrorMessage>
      )}
    </View>
  );
}
