import React, { useEffect } from "react";
import { useWindowDimensions } from "react-native";
import SplashScreenImage from "../assets/images/SplashScreen.png";
import { Image } from "react-native";
import { pwaMaxWidth } from "../utils/platform";
import SafeAreaView from "../components/ThemedComponents/SafeAreaView";
import { setMerchantParams } from "../utils/setMerchantParams";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getTheme } from "../redux/slices/themeThunk";

export default function SplashScreen() {
  const dimensions = useWindowDimensions();
  const dispatch = useAppDispatch();
  useEffect(() => {
    setMerchantParams((apikey?: string, txId?: string) => {
      if (apikey || txId)
        dispatch(
          getTheme({
            apiKey: apikey ?? "",
            txId: txId ?? ""
          })
        );
    });
  }, []);

  return (
    <SafeAreaView>
      <Image
        source={SplashScreenImage}
        style={{
          width: pwaMaxWidth(dimensions),
          height: dimensions.height,
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 200
        }}
      />
    </SafeAreaView>
  );
}
