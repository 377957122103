import React from "react";
import { BuildingLibraryIcon } from "react-native-heroicons/outline";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  CardsIcons,
  MoneyGramIcon
} from "../components/CashoutScreen/PaymentMethodsIcons";
import Header from "../components/Header";
import PaymentMethodCell from "../components/CashoutScreen/PaymentMethodCell";
import PaymentMethodCellContainer from "../components/CashoutScreen/PaymentMethodCellContainer";
const PaymentMethodScreen = () => {
  return (
    <SafeAreaView>
      <Header title={"Cash Out"} styles={{ marginBottom: 10 }} />
      <PaymentMethodCellContainer>
        <PaymentMethodCell
          title={"VISA MasterCard"}
          icon={<CardsIcons />}
          iconBgColor={"transparent"}
          subtitle={"1.5%"}
        />
      </PaymentMethodCellContainer>
      <PaymentMethodCellContainer>
        <PaymentMethodCell
          title={"Money Gram"}
          icon={<MoneyGramIcon />}
          iconBgColor={"transparent"}
          subtitle={"1.5%"}
        />
      </PaymentMethodCellContainer>
      <PaymentMethodCellContainer>
        <PaymentMethodCell
          title={"To Bank Account"}
          icon={<BuildingLibraryIcon size={30} color="white" />}
          iconBgColor={"transparent"}
        />
      </PaymentMethodCellContainer>
    </SafeAreaView>
  );
};

export default PaymentMethodScreen;
