import React from "react";
import { Image, Pressable, ViewStyle } from "react-native";
import images from "../../assets/images";
import {
  colorTransactionSeparator,
  gray400,
  gray500,
  gray700,
  gray800,
  green500,
  purple300,
  purple900,
  white
} from "../../utils/colors";
import { Text, View } from "../Themed";
import { TxType } from "../../screens/TransactionsScreen";
import { isWeb } from "../../utils/platform";
import { TxStatus } from "../../../types/transfer";

export type TransactionBageProps = {
  name: string;
  time?: string | undefined;
  status?: string | undefined;
  amount?: string | number | undefined;
  onPress?: any | undefined;
  badgeStyle?: ViewStyle | undefined;
  containerStyle?: ViewStyle | undefined;
  showBadgeIndicator?: boolean | undefined;
  showBorder?: boolean | undefined;
  transactionType?: TxType | undefined;
};

export default function TransactionBadge({
  name,
  time,
  status,
  amount,
  onPress,
  badgeStyle,
  containerStyle,
  showBadgeIndicator,
  showBorder,
  transactionType
}: TransactionBageProps) {
  const handleStatusColor = (status?: string) => {
    if (status === TxStatus.FAILURE || status === TxStatus.CANCELED)
      return "red";
    else if (status === TxStatus.SUCCESS) return "green";
    else return "transparent";
  };

  return (
    <Pressable
      style={[
        {
          backgroundColor: gray800,
          padding: 8,
          borderRadius: 8,
          borderColor: gray700,
          borderWidth: 1
        },
        containerStyle
      ]}
      onPress={onPress}
    >
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: isWeb() ? "flex-start" : "space-between",
          alignContent: "center",
          alignItems: "center"
        }}
      >
        <View
          style={{
            width: isWeb() ? "30%" : "45%",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <View>
            <View
              style={{
                ...badgeStyle,
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Text style={{ fontSize: 20 }}>{name[0]}</Text>
            </View>
            {showBadgeIndicator ? (
              <Image
                source={images.BlueClockIcon}
                resizeMode={"contain"}
                style={{
                  height: 30,
                  width: 30,
                  position: "absolute",
                  bottom: 0,
                  right: 0
                }}
              />
            ) : (
              <></>
            )}
          </View>
          <View
            style={{
              flex: 2,
              flexDirection: "column",
              justifyContent: "flex-start",
              alignContent: "flex-start",
              marginStart: 5,
              paddingLeft: 10
            }}
          >
            <Text>{name}</Text>
            {time ? <Text style={{ color: gray500 }}>{time}</Text> : <></>}
          </View>
        </View>
        {status === "Pending" ? (
          <View
            style={{
              width: "20%",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <View
              style={{
                paddingHorizontal: 10,
                paddingVertical: 2,
                backgroundColor: purple900,
                justifyContent: "center",
                borderRadius: 6,
                alignItems: "center"
              }}
            >
              <Text style={{ color: purple300, fontSize: isWeb() ? 14 : 12 }}>
                Pending
              </Text>
            </View>
          </View>
        ) : (
          <View
            style={{
              width: "20%",
              justifyContent: "center",
              alignItems: "center"
            }}
          />
        )}
        <View
          style={{
            flexDirection: "row",
            marginLeft: 20,
            marginRight: isWeb() ? 0 : 5,
            alignItems: "center"
          }}
        >
          <View
            style={{
              height: 5,
              width: 5,
              borderRadius: 10,
              backgroundColor: handleStatusColor(status),
              marginRight: 10
            }}
          />
          <View
            style={{
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            {amount ? (
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: "600",
                  color:
                    status == "Pending"
                      ? gray400
                      : transactionType === TxType.RECEIVE
                      ? green500
                      : white
                }}
              >
                {transactionType === TxType.SEND ? "-" : "+"}
                {amount}
              </Text>
            ) : (
              <View />
            )}
          </View>
        </View>
      </View>
      {showBorder ? (
        <View
          style={{ height: 1, backgroundColor: colorTransactionSeparator }}
        />
      ) : (
        <></>
      )}
    </Pressable>
  );
}
