import { SupportedChains } from "./supportedChains";
import { getDocumentData } from "./server";

export async function getTokenPrice(
  chain: string,
  address: string,
  amount: number,
  symbol: string
): Promise<number> {
  const { data: price } = await getDocumentData({
    url: `/onramp/custom/price/?chain=${chain}&tokenAddress=${address}&tokenAmount=${amount}&tokenSymbol=${symbol}`
  });

  return price;
}
