import React, { useState } from "react";
import { TouchableOpacity, StyleSheet } from "react-native";
import { Text, View } from "../Themed";
import {
  colorButtonBackgroundBlue,
  colorTextBlue,
  gray700,
  primary600,
  white
} from "../../utils/colors";
import { FilterOption } from "../../../types/filterTypes";

const Filters = ({
  buttons,
  handleOnPress
}: {
  buttons: FilterOption[];
  handleOnPress: (arg0?: any) => void;
}) => {
  const [activeBtn, setActiveBtn] = useState(0);

  const handleClick = (id: number, buttonLabel: FilterOption) => {
    setActiveBtn(id);
    handleOnPress(buttonLabel);
  };

  return (
    <>
      {buttons.map((buttonLabel: FilterOption, index: number) => {
        return (
          <TouchableOpacity
            key={index}
            onPress={() => handleClick(index, buttonLabel)}
          >
            <View
              style={[
                index === activeBtn
                  ? styles.buttonContainerActive
                  : styles.buttonContainer
              ]}
            >
              <Text
                style={[
                  index === activeBtn
                    ? styles.buttonTextActive
                    : styles.buttonText
                ]}
              >
                {buttonLabel.name}
              </Text>
            </View>
          </TouchableOpacity>
        );
      })}
    </>
  );
};

export const styles = StyleSheet.create({
  buttonContainerActive: {
    backgroundColor: primary600,
    minWidth: 100,
    height: 38,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    borderRadius: 99,
    padding: 10
  },
  buttonContainer: {
    minWidth: 100,
    height: 38,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    borderRadius: 20,
    paddingHorizontal: 16,
    backgroundColor: gray700
  },
  buttonTextActive: {
    fontSize: 14,
    fontWeight: "400",
    color: white
  },
  buttonText: {
    fontSize: 14,
    fontWeight: "400"
  }
});

export default Filters;
