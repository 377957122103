import React, { useEffect, useState } from "react";
import { FilterOption, HomeFilterType } from "../../types/filterTypes";
import { Text, View } from "../components/Themed";
import i18n from "../config/languageInternationalization";
import AccountsTab from "../components/HomeScreen/AccountsTab";
import CollectableTab from "../components/HomeScreen/CollectableTab";
import { HomeFilters } from "../components/HomeScreen/utils/HomeFilterOptions";
import Filters from "../components/HomeScreen/Filters";
import {
  Alert,
  Pressable,
  RefreshControl,
  ScrollView,
  StatusBar,
  TouchableOpacity
} from "react-native";
import { isAndroid, isWeb } from "../utils/platform";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  getFFBalance,
  getUserBalances,
  getUserCollectables
} from "../redux/slices/userThunk";
import Loading from "../components/Loading";
import { ArrowPathIcon } from "react-native-heroicons/solid";
import { setReValidateWallet, setSwitchBalances } from "../redux/slices/user";
import SecondaryButton from "../components/ThemedComponents/SecondaryButton";
import SafeAreaView from "../components/ThemedComponents/SafeAreaView";
import InsertPinCode from "../components/InsertPinCode";
import { blue600, gray400, gray700, gray800, white } from "../utils/colors";
import SendTransferModal from "../components/TransferScreen/TransferModal/SendTransferModal";
import { Balance } from "../../types/balances";

const STATUSBAR_HEIGHT = isAndroid() ? (StatusBar.currentHeight as number) : 20;

export default function HomeScreen({ navigation }: { navigation: any }) {
  const [modal, setModal] = useState<boolean>(false);
  const [token, setToken] = useState<Balance>({} as Balance);
  const dispatch = useAppDispatch();
  const {
    accessToken,
    walletsBalanceTotal,
    frontFinanceTotal,
    switchBalances,
    frontFinanceAuth,
    reValidateWallet
  } = useAppSelector((state) => state.user);
  const [tab, setTab] = useState(HomeFilterType.ACCOUNT);
  const { collectables, loadingCollectables } = useAppSelector(
    (state) => state.user
  );
  const [refreshing, setRefreshing] = useState(false);
  const loading = useAppSelector((state) => state.user.loading);

  const handleTabs = (buttonLabel: FilterOption) => {
    setTab(buttonLabel.type as HomeFilterType);
  };

  const getNfts = async () => {
    await dispatch(getUserCollectables());
  };

  useEffect(() => {
    (async () => {
      const unsubscribe = await navigation.addListener("focus", async () => {
        await dispatch(getUserBalances({}));
        if (collectables.length == 0) await getNfts();
      });
      return await unsubscribe;
    })();
  }, []);

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);

    await dispatch(getUserBalances({ refreshCache: true }));
    const promisesArray: any = [getNfts()];
    if (frontFinanceAuth) {
      if (
        !accessToken ||
        !frontFinanceAuth?.accessToken?.accountTokens[0]?.accessToken
      )
        return;
      promisesArray.push(dispatch(getFFBalance({})));
    }
    await Promise.all(promisesArray);
    setRefreshing(false);
  }, []);

  const nftCheckoutNavigation = useAppSelector(
    (state) => state.user.nftCheckoutId
  );
  useEffect(() => {
    if (nftCheckoutNavigation) {
      setTimeout(() => {
        navigation.navigate("NftCheckoutScreen");
      }, 100);
    }
  }, [nftCheckoutNavigation]);

  return (
    <SafeAreaView
      style={
        isWeb()
          ? {
              backgroundColor: gray800,
              borderRadius: 16,
              borderWidth: 1,
              borderColor: gray700,
              marginVertical: 20
            }
          : {
              flex: 1,
              paddingHorizontal: 16,
              width: "100%"
            }
      }
    >
      {reValidateWallet && (
        <InsertPinCode
          cb={async () => dispatch(setReValidateWallet(false))}
          target={"REVALIDATE_WALLET"}
          closeModal={() => false}
          text="register your IMX account"
          handleError={() => {
            Alert.alert("somethingWentWrongImportingPK", i18n.t("contactUs"));
          }}
        />
      )}
      {modal && (
        <SendTransferModal onClose={() => setModal(false)} token={token} />
      )}
      <ScrollView
        contentContainerStyle={{ flex: 1 }}
        nestedScrollEnabled={true} // Ref: https://stackoverflow.com/a/76658894
        refreshControl={
          <RefreshControl
            tintColor={"white"}
            refreshing={refreshing || loading}
            onRefresh={onRefresh}
          />
        }
      >
        {/* TODO: Cashout logic for app / pwa */}
        {/* <TouchableOpacity
          style={{
            backgroundColor: blue600,
            alignSelf: "flex-end",
            width: 40,
            height: 32,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 8,
            marginTop: isWeb() ? 24 : 10,
            marginRight: isWeb() ? 24 : 0
          }}
        >
          <Text style={{ fontSize: 12, color: white }}>$</Text>
        </TouchableOpacity> */}
        <View style={{ alignItems: "center", marginTop: 32, marginBottom: 18 }}>
          <Text
            style={{
              opacity: 0.6,
              fontWeight: "400",
              fontSize: 16,
              color: gray400
            }}
          >
            {i18n.t("homeScreenAvailableBalance")}
          </Text>
          {loading ? (
            <Text style={{ fontSize: 35, fontWeight: "bold" }}>--.--</Text>
          ) : (
            <>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <Text style={{ fontSize: 60, fontWeight: "bold" }}>
                  $ {!switchBalances ? walletsBalanceTotal : frontFinanceTotal}
                </Text>
                {isWeb() && (
                  <Pressable
                    aria-hidden={false}
                    testID="homeRefreshBtn"
                    accessibilityLabel="homeRefreshBtn"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 12
                    }}
                    onPress={onRefresh}
                  >
                    <ArrowPathIcon
                      color={"white"}
                      style={{ height: 18, width: 18 }}
                    />
                  </Pressable>
                )}
              </View>
              {frontFinanceAuth && (
                <>
                  <SecondaryButton
                    onPress={() => {
                      dispatch(setSwitchBalances(!switchBalances));
                    }}
                  >
                    <Text>
                      {switchBalances
                        ? i18n.t("showSphereOneBalances")
                        : i18n.t("showFFbalances")}
                    </Text>
                  </SecondaryButton>
                </>
              )}
            </>
          )}
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: 20,
            gap: 10
          }}
        >
          <Filters buttons={HomeFilters} handleOnPress={handleTabs} />
        </View>

        {loading ? (
          <View
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center"
            }}
          >
            <Loading />
          </View>
        ) : tab === HomeFilterType.ACCOUNT ? (
          <AccountsTab setModal={setModal} setToken={setToken} />
        ) : (
          <CollectableTab loading={loadingCollectables} />
        )}
      </ScrollView>
    </SafeAreaView>
  );
}
