import fulfillRequest from "../components/TransferScreen/utils/fullfilRequest";
import { setMerchantCharge } from "./localStorage";

export async function onNotificationActionPress({
  notificationData,
  navigation
}: {
  notificationData: any;
  navigation?: any;
}) {
  if (notificationData.requestorAddress) {
    // if it is a payment request, redirect the user to TransferDetail screen with the payment data from the notification
    await fulfillRequest({
      notificationData,
      navigation
    });
  }
  if (notificationData.merchantChargeId) {
    await setMerchantCharge(notificationData.merchantChargeId);
  }
}
