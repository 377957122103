import React from "react";
import { Image } from "react-native";
import {
  BuildingLibraryIcon,
  CreditCardIcon
} from "react-native-heroicons/outline";
import { OnrampPaymentMethod, OnrampProviders } from "../../types/onRamp";
import { SphereOneIcon } from "../assets/icons/SphereOneIcon";

export enum PaymentMethodTypes {
  CARD = "card",
  APPLE = "apple",
  BANK = "bank",
  GOOGLE = "google",
  SPHEREONE = "sphereone"
}

export interface DisplayPaymentMethod extends OnrampPaymentMethod {
  title: string;
  description: string;
  type: PaymentMethodTypes;
}

export const sphereOneMethod = {
  title: "SphereOne balance",
  label: "SphereOne balance",
  description: "SphereOne balance",
  icon: <SphereOneIcon />,
  value: "sphereone",
  type: PaymentMethodTypes.SPHEREONE,
  min: 0,
  max: 0,
  provider: OnrampProviders.SPHEREONE
};

export function getIconComponentByName(iconName: PaymentMethodTypes | null) {
  switch (iconName) {
    case PaymentMethodTypes.SPHEREONE:
      return <SphereOneIcon />;
    case PaymentMethodTypes.CARD:
      return <CardIcon />;
    case PaymentMethodTypes.APPLE:
      return <AppleIcon />;
    case PaymentMethodTypes.GOOGLE:
      return <GoogleIcon />;
    case PaymentMethodTypes.BANK:
      return <BankIcon />;
  }
}

export const validatePaymentMethods = (
  paymentMethods: DisplayPaymentMethod[]
) => {
  const allowedMethods: DisplayPaymentMethod[] = [];
  let moreMethods: DisplayPaymentMethod[] = [];
  const isCardMethod = isCard(paymentMethods);
  const isBankMethod = isBank(paymentMethods);
  const isAppleMethod = isApple(paymentMethods);
  const isGoogleMethod = isGoogle(paymentMethods);
  const isOtherMethods = otherMethods(paymentMethods);
  if (isCardMethod) {
    allowedMethods.push({
      title: "Card",
      label: "Card",
      description: "Pay with credit or debit cards",
      icon: getIconComponentByName(PaymentMethodTypes.CARD),
      type: PaymentMethodTypes.CARD,
      value: isCardMethod.value,
      min: isCardMethod.min,
      max: isCardMethod.max,
      provider: isCardMethod.provider
    });
  }
  if (isBankMethod) {
    allowedMethods.push({
      title: "Bank transfer",
      label: "Bank transfer",
      description: "Pay with bank transfers",
      icon: getIconComponentByName(PaymentMethodTypes.BANK),
      type: PaymentMethodTypes.BANK,
      value: isBankMethod.value,
      min: isBankMethod.min,
      max: isBankMethod.max,
      provider: isBankMethod.provider
    });
  }
  if (isAppleMethod) {
    allowedMethods.push({
      title: "Apple Pay",
      label: "Apple Pay",
      description: "Buy crypto using Apple Pay",
      icon: getIconComponentByName(PaymentMethodTypes.APPLE),
      type: PaymentMethodTypes.APPLE,
      value: isAppleMethod.value,
      min: isAppleMethod.min,
      max: isAppleMethod.max,
      provider: isAppleMethod.provider
    });
  }
  if (isGoogleMethod) {
    allowedMethods.push({
      title: "Google Pay",
      label: "Google Pay",
      description: "Buy crypto using Google Pay",
      icon: getIconComponentByName(PaymentMethodTypes.GOOGLE),
      type: PaymentMethodTypes.GOOGLE,
      value: isGoogleMethod.value,
      min: isGoogleMethod.min,
      max: isGoogleMethod.max,
      provider: isGoogleMethod.provider
    });
  }
  if (isOtherMethods.length > 0) {
    moreMethods = isOtherMethods.map((method) => {
      return {
        title: method.label,
        label: method.label,
        description: "Buy crypto using " + method.label,
        icon: method?.icon ?? getIconComponentByName(PaymentMethodTypes.BANK),
        type: PaymentMethodTypes.BANK,
        value: method.value,
        min: method.min,
        max: method.max,
        provider: method.provider
      };
    });
  }
  return {
    allowedMethods: allowedMethods.sort((a, b) => (a.min || 0) - (b.min || 0)),
    moreMethods: moreMethods.sort((a, b) => (a.min || 0) - (b.min || 0))
  };
};

const AppleIcon = () => {
  return (
    <Image
      style={{ width: 25, height: 25 }}
      source={{
        uri: "https://cdn4.iconfinder.com/data/icons/leto-most-searched-mix-8/64/__apple_mac_logo-1024.png"
      }}
    />
  );
};

const BankIcon = () => {
  return <BuildingLibraryIcon size={25} color="white" />;
};

const GoogleIcon = () => {
  return (
    <Image
      style={{ width: 25, height: 25 }}
      source={{
        uri: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/240px-Google_%22G%22_logo.svg.png"
      }}
    />
  );
};

const CardIcon = () => {
  return <CreditCardIcon size={25} color="white" />;
};

export const findPaymentMethod = async (
  paymentMethods: DisplayPaymentMethod[] | OnrampPaymentMethod[],
  value: PaymentMethodTypes
) => {
  if (value == PaymentMethodTypes.CARD) {
    return isCard(paymentMethods);
  } else if (value == PaymentMethodTypes.APPLE) {
    return isApple(paymentMethods);
  } else if (value == PaymentMethodTypes.GOOGLE) {
    return isGoogle(paymentMethods);
  } else if (value == PaymentMethodTypes.BANK) {
    return isBank(paymentMethods);
  }
};

const isCard = (
  paymentMethods: DisplayPaymentMethod[] | OnrampPaymentMethod[]
) => {
  const regex = /card|credit|debit|visa|master|mastercard/i;
  const method = paymentMethods
    .sort((a, b) => (a.min || 0) - (b.min || 0))
    .find((m: OnrampPaymentMethod | DisplayPaymentMethod) =>
      regex.test(m.label.toLowerCase())
    );
  return method;
};

const isBank = (
  paymentMethods: DisplayPaymentMethod[] | OnrampPaymentMethod[]
) => {
  const regex =
    /bank|ach|swift|imps|upi|banco|ban|boleto|payid|bbva|bci|bcp|bpi|bri|caixa|caja|cimb|codi|enets|bradesco|clearing house|ideal|itau|khipu|revolut|santander|scb|sepa|spei|pse|efecty/i;
  const method = paymentMethods
    .sort((a, b) => (a.min || 0) - (b.min || 0))
    .find((m: OnrampPaymentMethod | DisplayPaymentMethod) =>
      regex.test(m.label.toLowerCase())
    );

  return method;
};

const otherMethods = (
  paymentMethods: DisplayPaymentMethod[] | OnrampPaymentMethod[]
) => {
  const regex =
    /^(?!.*(card|credit|debit|visa|master|mastercard|apple|google))/i;
  const method = paymentMethods
    .sort((a, b) => (a.min || 0) - (b.min || 0))
    .filter((m: OnrampPaymentMethod | DisplayPaymentMethod) =>
      regex.test(m.label.toLowerCase())
    );
  return method;
};

const isGoogle = (
  paymentMethods: DisplayPaymentMethod[] | OnrampPaymentMethod[]
) => {
  const regex = /google/i;
  const method = paymentMethods
    .sort((a, b) => (a.min || 0) - (b.min || 0))
    .find((m: OnrampPaymentMethod | DisplayPaymentMethod) =>
      regex.test(m.label.toLowerCase())
    );
  return method;
};

const isApple = (
  paymentMethods: DisplayPaymentMethod[] | OnrampPaymentMethod[]
) => {
  const regex = /apple/i;
  const method = paymentMethods
    .sort((a, b) => (a.min || 0) - (b.min || 0))
    .find((m: OnrampPaymentMethod | DisplayPaymentMethod) =>
      regex.test(m.label.toLowerCase())
    );
  return method;
};
