import axios from "axios";
import { Balance } from "../../types/balances";
import { REACT_APP_BASE_URL as baseURL } from "@env";
import { LinkedAccountWallet } from "../../types/accounts";
import { LinkedAccount } from "../redux/slices/user";

export const mixBalances = (
  balanceTokens: any[],
  frontFinanceBalance: any[]
) => {
  if (frontFinanceBalance?.length) {
    const mixedBalances: { [key: string]: any } = {};

    for (let i = 0; i < balanceTokens.length; i++) {
      mixedBalances[balanceTokens[i]?.tokenMetadata?.symbol] = {
        ...balanceTokens[i]
      };
    }
    for (let i = 0; i < frontFinanceBalance.length; i++) {
      if (mixedBalances[frontFinanceBalance[i]?.tokenMetadata?.symbol]) {
        mixedBalances[frontFinanceBalance[i]?.tokenMetadata?.symbol].amount =
          Number(
            mixedBalances[
              frontFinanceBalance[i]?.tokenMetadata?.symbol
            ]?.amount?.match(/\+/)
              ? BigInt(
                  mixedBalances[frontFinanceBalance[i]?.tokenMetadata?.symbol]
                    ?.amount
                )
              : mixedBalances[frontFinanceBalance[i]?.tokenMetadata?.symbol]
                  ?.amount
          ) + Number(frontFinanceBalance[i]?.amount) || 0;
        mixedBalances[frontFinanceBalance[i]?.tokenMetadata?.symbol].price =
          Number(
            mixedBalances[frontFinanceBalance[i]?.tokenMetadata?.symbol]?.price
          ) + Number(frontFinanceBalance[i]?.price) || 0;
      } else {
        mixedBalances[frontFinanceBalance[i]?.tokenMetadata?.symbol] = {
          ...frontFinanceBalance[i]
        };
      }
    }
    return Object.values(mixedBalances);
  } else return balanceTokens;
};

// this function checks the wallets that the user has linked, take the not imported (SphereOne wallets) and
// generate a new link to the front finance api that enables transfers only to those wallets, according the network and coins
export function getToAdressesObject({
  linkedAccounts,
  frontFinanceChainsData
}: {
  linkedAccounts: LinkedAccount[];
  frontFinanceChainsData: any[];
}) {
  const toAddresses: { networkId: string; symbol: string; address: string }[] =
    [];
  (linkedAccounts as LinkedAccountWallet[]).forEach(
    ({ chains, isImported, address }) => {
      frontFinanceChainsData.forEach((frontFinanceData) => {
        if (
          chains.includes(frontFinanceData.name.toUpperCase()) &&
          isImported === false
        ) {
          frontFinanceData.supportedTokens.forEach((token: string) => {
            const obj = {
              networkId: frontFinanceData.id,
              symbol: token,
              address: address
            };
            toAddresses.push(obj);
          });
        }
      });
    }
  );

  return toAddresses;
}

export function isTransferableWithFF(
  token: Balance | Omit<Balance, "address">,
  frontFinanceSymbols: {
    supportedBrokerTypes: string[];
    id: string;
    name: string;
    chainId: string;
    supportedTokens: string[];
  }[]
) {
  try {
    let isTransferable = false;
    for (let i = 0; i < frontFinanceSymbols.length; i++) {
      if (
        frontFinanceSymbols[i].name.toLowerCase() ===
          token?.chain.toLowerCase() &&
        frontFinanceSymbols[i].supportedTokens.includes(
          token?.tokenMetadata?.symbol?.toUpperCase()
        )
      ) {
        isTransferable = true;
        break;
      }
    }
    return isTransferable;
  } catch (e: any) {
    return false;
  }
}

export const getFrontGenerateIFrameUrl = ({
  accessToken
}: {
  accessToken: string;
}) => {
  return axios.get(`${baseURL}/frontfinance/generateiframeurl`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
};

export const getFrontGenerateIframeUrlWithTransfers = async ({
  accessToken,
  toAddresses
}: {
  accessToken: string;
  toAddresses: { networkId: string; symbol: string; address: string }[];
}) => {
  return await axios.post(
    `${baseURL}/frontfinance/generateiframeurl`,
    {
      toAddresses
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
};
export const getFrontFinanceBalance = ({
  accessToken,
  authToken,
  type
}: {
  accessToken: string;
  authToken: string;
  type: string;
}) => {
  return axios.post(
    `${baseURL}/frontfinance/getbalances`,
    { authToken, type },
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
};

export const disconnectFrontFinance = ({
  accessToken,
  authToken,
  type
}: {
  accessToken: string;
  authToken: string;
  type: string;
}) => {
  return axios.delete(`${baseURL}/frontfinance/disconnect`, {
    headers: { Authorization: `Bearer ${accessToken}` },
    data: { authToken, type }
  });
};
