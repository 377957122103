import { useAuth } from "react-oidc-context";
import { useAppDispatch } from "../redux/hooks";
import { signOut } from "../redux/slices/userThunk";
import { isWeb } from "../utils/platform";
import { useAuthApp } from "./useAuthContextApp";
import { Alert } from "../utils/Alert";
import i18n from "../config/languageInternationalization";
import { recordError } from "../utils/crashlytics";
import { AppDispatch } from "../redux/store";
import { isTokenExpired } from "../utils/verifyJWT";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { REACT_APP_BASE_URL as baseURL, SHARED_AUTH_KEY } from "@env";
import { storagePath } from "../components/AuthProviderComponent.web";
import { setUserCredentials } from "../redux/slices/user";
import { cleanAuthCode, getAuthCode } from "../utils/localStorage";

import { Configuration, FrontendApi } from "@ory/client";
import { REACT_APP_AUTH_AUDIENCE as audience } from "@env";

export interface User {
  id_token?: string;
  session_state: string | null;
  access_token: string;
  refresh_token?: string;
  token_type: string;
  scope?: string;
  expires_at?: number;
  state: unknown;
  expires_in?: number;
  expired?: boolean;
  scopes: string[];
}

// Here we initialize the SDK to create the logout flow and update it
// with the logout token. This is required to log the user out from ory session.
const oryClient = new FrontendApi(
  new Configuration({
    basePath: audience,
    baseOptions: {
      withCredentials: isWeb() ? true : null
    }
  })
);

export const useCustomAuth = () => {
  const dispatch = useAppDispatch();

  if (isWeb()) {
    const {
      user: userWeb,
      signoutSilent,
      signinRedirect,
      signinSilent
    } = useAuth();

    return {
      login: signinRedirect,
      user: userWeb,
      logout: logout.bind(null, signoutSilent, dispatch),
      signinSilent
    };
  } else {
    const {
      login: loginApp,
      user: userApp,
      logout: logoutApp,
      refreshToken
    } = useAuthApp();
    return {
      login: loginApp,
      user: userApp,
      logout: logout.bind(null, logoutApp, dispatch),
      signinSilent: refreshToken
    };
  }
};

const executeLogout = async (
  authProviderLogout: () => void,
  dispatch: AppDispatch
) => {
  try {
    // Create a "logout flow" in Ory Identities
    const { data: flow } = await oryClient.createBrowserLogoutFlow();
    if (isWeb()) {
      // Use the received token to "update" the flow and thus perform the logout
      await oryClient.updateLogoutFlow({
        token: flow.logout_token
      });
    } else {
      // Mobile apps use Ory Session Tokens
      return await oryClient.performNativeLogout({
        performNativeLogoutBody: {
          session_token: flow.logout_token // In case Native fails, check if this is the correct token
        }
      });
    }
  } catch (error) {
    recordError(error, "logout");
  } finally {
    await dispatch(signOut());
    authProviderLogout();
  }
};

const logout = async (
  authProviderLogout: () => Promise<void>,
  dispatch: AppDispatch,
  optional = true
) => {
  const buttons: any[] = [
    { text: i18n.t("profileScreenCancel"), style: "cancel" },
    {
      text: i18n.t("ok"),
      onPress: async () => executeLogout(authProviderLogout, dispatch),
      style: "destructive"
    }
  ];

  if (optional) {
    return Alert.alert(
      i18n.t("profileScreenLogout"),
      i18n.t("profileScreenLogoutConfirmation"),
      buttons
    );
  } else {
    executeLogout(authProviderLogout, dispatch);
  }
};

export const loginFromAuthCode = async (
  accessTokenFromStore: string | undefined,
  refreshToken: string | undefined,
  isOnboard: boolean,
  dispatch: AppDispatch
) => {
  const authCode = await getAuthCode();
  if (
    !isWeb() ||
    !authCode ||
    (isOnboard && !isTokenExpired(accessTokenFromStore)) ||
    refreshToken
  ) {
    authCode && (await cleanAuthCode());
    return accessTokenFromStore;
  }
  const response = await axios.get(baseURL + "/auth/code/" + authCode, {
    headers: {
      "x-shared-auth": SHARED_AUTH_KEY,
      contentType: "application/json"
    }
  });
  if (!response.data?.data) return;
  const { accessToken } = response.data.data;
  await AsyncStorage.setItem(
    storagePath,
    JSON.stringify({
      access_token: accessToken
    })
  );
  await dispatch(
    setUserCredentials({
      accessToken: accessToken,
      isSharedAccessToken: true
    })
  );
  await cleanAuthCode();
  if (!accessTokenFromStore) {
    // only reload if there's no access token in the store (user is coming from logout state)
    window.location.reload();
  }
  return accessToken;
};
