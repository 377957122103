export const isEmptyString = (str: string) => str === "";
export const isStrShorterThan = (str: string, length: number) =>
  str.length <= length;
export const isEmail = (str: string) => /\S+@\S+\.\S+/.test(str);
export const isPhoneNumber = (str: string) =>
  /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(str);
export const isListNullOrEmpty = (list: []) =>
  list.length == 0 || list === null || list === undefined;
export const parsePhoneNumber = (str: string | undefined) => {
  if (str === undefined) return "";
  const phoneNumber = str.replace(/\s|-/g, "");
  return phoneNumber;
};
export const isValidUsername = (username: string): boolean =>
  /^[a-zA-Z][a-zA-Z0-9-_.]{2,}$/.test(username); // allow letters, numbers and -_. but not at the beginning. It must be at least 3 characters long. Matches "user", "Us3r", "us-er", and "us.er1" but not "123", "&user", "us@r", or "us er".
// works with Hex ONLY
// source: https://stackoverflow.com/a/51567564
// other possible soluation is: https://github.com/bgrins/TinyColor
export const isColorLight = (color?: string) => {
  if (!color) return false;

  const hex = color.replace("#", "");
  const c_r = parseInt(hex.substring(0, 0 + 2), 16);
  const c_g = parseInt(hex.substring(2, 2 + 2), 16);
  const c_b = parseInt(hex.substring(4, 4 + 2), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;

  return brightness > 155;
};
