import React, { useState } from "react";
import {
  DisplayPaymentMethod,
  getIconComponentByName
} from "../../utils/validatePaymentMethods";
import {
  StyledKeyboardAvoidingView,
  StyledText,
  StyledTextInput,
  StyledTouchableOpacity,
  StyledView
} from "../ThemedComponents/NativeWindStyledWrappers";
import { ScrollView, useWindowDimensions, Image, Platform } from "react-native";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon
} from "react-native-heroicons/outline";
import {
  CheckoutSkeleton,
  paymentMethodsSkeletonLayout
} from "./CheckoutSkeleton";
import { isWeb } from "../../utils/platform";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { isWert } from "../../utils/onramp";
import { checkoutActions } from "../../redux/slices/checkoutOnramp";
import { Badge, InfoAlert } from "./Alerts";
import i18n from "../../config/languageInternationalization";
import { gray300, gray400 } from "../../utils/colors";
import { useGetRouteEstimation } from "../../hooks/useOnRamp";

export const PaymentMethodsSection = ({
  title,
  methods,
  paymentMethodsLoading,
  showSearchBar = false
}: {
  title: string;
  methods: DisplayPaymentMethod[];
  paymentMethodsLoading: boolean;
  showSearchBar?: boolean;
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showMoreMethods, setShowMoreMethods] = useState(false);

  return (
    <StyledKeyboardAvoidingView
      className="max-w-full max-h-full flex-col"
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <StyledTouchableOpacity
        onPress={() => setShowMoreMethods(!showMoreMethods)}
        disabled={!showSearchBar}
      >
        <StyledView className={`flex-row justify-between items-center pb-4`}>
          <StyledText
            className={`text-white  ${
              showSearchBar
                ? "h-6 text-base font-bold"
                : "text-sm font-semibold"
            }`}
          >
            {title}
          </StyledText>
          {showSearchBar && (
            <StyledView className="p-3">
              {!showMoreMethods ? (
                <ChevronDownIcon
                  id="onramp-more-methods"
                  size={14}
                  color={gray300}
                  strokeWidth={4}
                />
              ) : (
                <ChevronUpIcon
                  id="onramp-more-methods"
                  size={14}
                  color={gray300}
                  strokeWidth={4}
                />
              )}
            </StyledView>
          )}
        </StyledView>
      </StyledTouchableOpacity>
      {!showSearchBar && (
        <CheckoutSkeleton
          isLoading={paymentMethodsLoading}
          layout={paymentMethodsSkeletonLayout}
        >
          {methods.length ? (
            methods.map((method) => (
              <PaymentMethod key={method.title} method={method} />
            ))
          ) : (
            <InfoAlert
              title="Sorry"
              message={i18n.t("checkoutNoPaymentMethodsAvailable")}
              status="error"
            />
          )}
        </CheckoutSkeleton>
      )}
      {showMoreMethods && (
        <>
          <SearchBar setSearchTerm={setSearchTerm} />

          <ScrollView className="max-h-72">
            {methods
              .filter((method) =>
                method.title.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((method) => (
                <PaymentMethod key={method.title} method={method} />
              ))}
          </ScrollView>
        </>
      )}
    </StyledKeyboardAvoidingView>
  );
};

export const PaymentMethod = ({ method }: { method: DisplayPaymentMethod }) => {
  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const {
    buyLoading,
    paymentMethod: selectedMethod,
    fiat: countryFiat
  } = useAppSelector((state) => state.checkoutOnramp);
  const fiat = isWert(method.provider) ? "USD" : countryFiat;
  const [imageUri, setImageUri] = useState(method.icon);
  const { walletsBalanceTotal } = useAppSelector((state) => state.user);
  const { isFetching: isRouteFetching } = useGetRouteEstimation();
  const handleImageError = () => {
    if (!method?.icon || typeof method.icon !== "string") return;
    setImageUri(method.icon.replace("-colored.svg", ".svg"));
  };
  const handleIconComponent = () => {
    if (React.isValidElement(method.icon)) {
      return React.cloneElement(method.icon);
    }
    if (
      !React.isValidElement(method.icon && method.icon !== "string") &&
      method.icon
    ) {
      return getIconComponentByName(method.type);
    }
  };
  return (
    <StyledTouchableOpacity
      onPress={() => {
        dispatch(checkoutActions.setPaymentMethod(method));
        dispatch(checkoutActions.onPaymentMethodChangeCleanup());
      }}
      disabled={
        buyLoading || isRouteFetching || method.title === selectedMethod?.title
      }
      key={method?.title}
    >
      <StyledView
        className={`h-12 p-3  bg-gray-600 rounded-lg flex-row justify-between items-center mb-3 ${
          method.title === selectedMethod?.title
            ? "border-[1px] border-blue-500"
            : ""
        }`}
      >
        <StyledView className="flex-row items-center">
          {method.icon && typeof method.icon !== "string" ? (
            handleIconComponent()
          ) : (
            <Image
              source={{
                uri:
                  typeof imageUri === "string"
                    ? imageUri.replace(".svg", "-colored.svg")
                    : undefined
              }}
              onError={handleImageError}
              className="w-5 h-5"
            />
          )}
          <StyledText
            className={`text-white font-bold ml-3 ${
              width > 1000 ? "text-base" : "text-xs"
            }`}
          >
            {method.title}
          </StyledText>
        </StyledView>
        {method.min > 0 && (
          <Badge
            text={`${width > 1000 ? "Minimum" : "Min"}: ${fiat} ${method.min}`}
          />
        )}
        {method.title === "SphereOne balance" && walletsBalanceTotal ? (
          <StyledText className="text-white text-xs font-normal">
            US$ {walletsBalanceTotal}
          </StyledText>
        ) : null}
      </StyledView>
    </StyledTouchableOpacity>
  );
};

export const SearchBar = ({
  setSearchTerm
}: {
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <StyledView
      className={` ${
        isWeb()
          ? "items-start inline-flex max-w-full h-9 mb-3 px-4 py-3 bg-gray-700 rounded-lg border border-gray-600 flex-col justify-center"
          : "h-9 bg-gray-700 px-4 py-3 rounded-lg mb-3 items-start inline-flex max-w-full flex-col justify-center"
      }`}
    >
      <StyledView className="flex-row items-center w-full">
        <MagnifyingGlassIcon size={14} color={gray400} strokeWidth={2} />
        <StyledTextInput
          placeholder="Search payment method"
          className={`grow shrink basis-0 self-stretch text-gray-400 font-normal bg-transparent outline-none ml-2 px-2 ${
            isWeb() ? "text-sm" : "text-xs"
          }`}
          onChangeText={(text) => setSearchTerm(text)}
        />
      </StyledView>
    </StyledView>
  );
};
