import React, { useState } from "react";
import { Text, View } from "../components/Themed";
import Input from "../components/ThemedComponents/Input";
import i18n from "../config/languageInternationalization";
import { TouchableOpacity } from "react-native";
import {
  EyeIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon
} from "react-native-heroicons/outline";
import * as Clipboard from "expo-clipboard";
import Animated, {
  useSharedValue,
  withTiming,
  Easing,
  useAnimatedStyle
} from "react-native-reanimated";

const PrivateKey = ({
  isLoading,
  showPrivateKey,
  privateKey,
  setShowPrivateKey,
  isPrivateKey
}: {
  isLoading: boolean;
  showPrivateKey: boolean;
  privateKey: string;
  setShowPrivateKey: any;
  isPrivateKey: boolean;
}) => {
  const [copied, setCopied] = useState(false);
  const animation = useSharedValue(0);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: animation.value
    };
  });

  const handleCopyText = async () => {
    await Clipboard.setStringAsync(privateKey);
    setCopied(true);
    animation.value = withTiming(
      1,
      { duration: 1500, easing: Easing.bounce },
      () => {
        animation.value = 0;
        setCopied(false);
      }
    );
  };

  return (
    <View style={{ marginTop: isPrivateKey ? 0 : 20 }}>
      <Text>
        {i18n.t("recoverStarkPrivateKeyAlert", {
          privateKey: isPrivateKey ? "private key" : "stark private key"
        })}
      </Text>
      <Input
        editable={false}
        style={{ marginTop: 10 }}
        value={
          isLoading
            ? "Loading..."
            : showPrivateKey
            ? privateKey
            : "******************************"
        }
        rightIcon={
          <View style={{ flexDirection: "row", rowGap: 2 }}>
            <TouchableOpacity disabled={isLoading} onPress={handleCopyText}>
              {copied ? (
                <Animated.View style={animatedStyle}>
                  <ClipboardDocumentCheckIcon size="24" color="white" />
                </Animated.View>
              ) : (
                <ClipboardDocumentIcon size="24" color="gray" />
              )}
            </TouchableOpacity>
            <TouchableOpacity
              disabled={isLoading}
              onPress={() => setShowPrivateKey(showPrivateKey ? false : true)}
            >
              <EyeIcon size="24" color={!showPrivateKey ? "gray" : "white"} />
            </TouchableOpacity>
          </View>
        }
        multiline={true}
      />
    </View>
  );
};

export default PrivateKey;
