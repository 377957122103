import React from "react";
import { Text, View } from "../Themed";
import { Dimensions, ImageSourcePropType, ImageBackground } from "react-native";
import { colorLightGrayText } from "../../utils/colors";

const { width: windowWidth, height: windowHeight } = Dimensions.get("screen");

export interface SlideData {
  id: string;
  image: ImageSourcePropType;
  title: string;
  subtitle: string;
}

const Slide = React.memo(function Slide({ data }: { data: SlideData }) {
  return (
    <View
      style={{
        width: windowWidth,
        alignItems: "center"
      }}
    >
      <ImageBackground
        source={data.image}
        resizeMode="contain"
        style={{
          flex: 1,
          justifyContent: "flex-end",
          width: windowWidth,
          alignContent: "center",
          alignItems: "center"
        }}
      >
        <Text
          style={{
            textAlign: "center",
            marginTop: 24,
            fontSize: 32,
            fontWeight: "700",
            maxWidth: 300
          }}
        >
          {data.title}
        </Text>
        <Text
          style={{
            maxWidth: 300,
            fontSize: 18,
            marginTop: 18,
            textAlign: "center",
            color: colorLightGrayText
          }}
        >
          {data.subtitle}
        </Text>
      </ImageBackground>
    </View>
  );
});

export default Slide;
