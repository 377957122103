import { createSlice } from "@reduxjs/toolkit";
import { fetchMinimumAppVersion } from "./forceUpdateThunk";

interface ForceUpdateState {
  loading: boolean;
  iosMinimumVersion: string | null;
  androidMinimumVersion: string | null;
  needsUpdate: boolean;
}

const initialState: ForceUpdateState = {
  loading: true,
  iosMinimumVersion: null,
  androidMinimumVersion: null,
  needsUpdate: false
};

const forceUpdateSlice = createSlice({
  name: "ForceUpdate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMinimumAppVersion.pending, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchMinimumAppVersion.fulfilled, (state, action) => {
      state.iosMinimumVersion = action.payload?.ios
        ? action.payload?.ios
        : null;
      state.androidMinimumVersion = action.payload?.android
        ? action.payload?.android
        : null;
      state.needsUpdate = action.payload.needsUpdate;
    });
    builder.addCase(fetchMinimumAppVersion.rejected, (state) => {
      state.loading = false;
    });
  }
});

export default forceUpdateSlice.reducer;
