import React from "react";
import { StyleSheet, View } from "react-native";
import { LockClosedIcon } from "react-native-heroicons/solid";

import i18n from "../config/languageInternationalization";
import { colorBlurBackground } from "../utils/colors";
import PrimaryButton from "../components/ThemedComponents/PrimaryButton";
import { Text } from "../components/Themed";
import { getScreenHeight, getScreenWidth } from "../utils/platform";
import SafeAreaView from "../components/ThemedComponents/SafeAreaView";

export default function LockScreen() {
  return (
    <SafeAreaView style={styles.screenContainer}>
      <View
        style={[
          styles.absolute,
          { backgroundColor: colorBlurBackground, opacity: 0.7 }
        ]}
      />
      <View style={[styles.flexColumnCenter, styles.horMargin20container]}>
        <Text style={[styles.boldText20, styles.centerAlignText]}>
          {i18n.t("lockScreenDisplayMessage")}
        </Text>
      </View>
      <PrimaryButton
        style={styles.button}
        leftIcon={<LockClosedIcon size={18} color={"white"} />}
      >
        {i18n.t("lockScreenUnlockButtonTitle")}
      </PrimaryButton>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    position: "absolute",
    zIndex: 100,
    width: getScreenWidth(),
    height: getScreenHeight(),
    justifyContent: "center",
    alignItems: "center"
  },
  absolute: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: getScreenWidth() * 0.06,
    right: 0
  },
  flexColumnCenter: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center"
  },
  horMargin20container: {
    marginHorizontal: 20
  },
  button: {
    top: getScreenHeight() * 0.85,
    width: getScreenWidth() * 0.8,
    position: "absolute"
  },
  boldText20: {
    fontSize: 20,
    fontWeight: "bold"
  },
  centerAlignText: {
    textAlign: "center"
  }
});
