import { createSlice } from "@reduxjs/toolkit";
import {
  getUserInfo,
  getProfileImage,
  updateUserInfo,
  signOut,
  loadOnboardingStatus,
  setOnboardingStatus,
  getNotificationConfig,
  setNotificationsConfig,
  setProfileImage,
  getTokenPrices,
  getUserCollectables,
  getUserBalances,
  getLinkedAccounts,
  getFFBalance,
  setNewAccessToken,
  getChainLogos
} from "./userThunk";

import {
  LinkedAccountBank,
  LinkedAccountCreditCard,
  LinkedAccountWallet
} from "../../../types/accounts";
import { Balance } from "../../../types/balances";
import formatName from "../../utils/formatNameWithUpperCase";
import { FrontPayload } from "@front-finance/link";
import { mixBalances } from "../../utils/frontFinance";
import { SupportedChains } from "../../utils/supportedChains";

export type LinkedAccount =
  | LinkedAccountBank
  | LinkedAccountCreditCard
  | LinkedAccountWallet;

export enum OnboardingStatus {
  ONBOARD = "ONBOARD",
  LANDING_SCREEN = "LANDING_SCREEN",
  PREVIEW = "PREVIEW",
  NOTIFICATIONS = "NOTIFICATIONS",
  PINCODE = "PINCODE"
}

export interface Collectable {
  name: string;
  address: string;
  img: string;
  tokenType?: string | undefined;
  selected?: boolean;
  chain?: SupportedChains;
  tokenId?: string;
  walletAddress?: string;
  isSpam?: boolean;
}

export interface UserState {
  isAskingPermissions: boolean;
  uid?: string | null;
  name?: string;
  email?: string | null;
  username?: string;
  phone?: string;
  pfp?: string;
  loading: boolean;
  error?: string;
  countryCode?: string | any;
  countryFlag?: string;
  onboardingStatus?: OnboardingStatus | null;
  isOnboard: boolean;
  notificationEnabled?: boolean;
  walletsBalanceTotal: number;
  walletsBalanceTokens: Balance[];
  walletsP2PBalance: { title: string; data: Balance[] }[];
  balancesCacheTimestamp?: number;
  collectables: Collectable[];
  spamCollectables: Collectable[];
  loadingCollectables: boolean;
  currency?: string;
  linkedAccounts: LinkedAccount[];
  ethPrice?: string;
  supportBiometric: boolean;
  isMerchant?: boolean;
  accessToken?: string;
  refreshToken?: string;
  idToken?: string;
  frontFinanceBalance: Omit<Balance, "address">[];
  frontFinanceAuth: null | FrontPayload;
  frontFinanceTotal: number;
  mixedBalances: (Balance | Omit<Balance, "address">)[];
  mixedTotal: number;
  switchBalances: boolean;
  loginInitiated: boolean;
  nftCheckoutId?: string;
  isSharedAccessToken: boolean;
  isPinCodeSetup?: boolean;
  isPhoneAdded?: boolean;
  showRecoveryFlowModal: boolean;
  firstShowOFRecoveryFlowModal: boolean;
  reValidateWallet?: boolean;
  chainLogos?: {
    [key: string]: string;
  };
}

const initialState: UserState = {
  // Set initial state as loading because we first load the user data and onboarding status.
  // This loading is used by the splash screen
  isAskingPermissions: false,
  loadingCollectables: false,
  loading: true,
  isOnboard: false,
  linkedAccounts: [],
  supportBiometric: false,
  walletsBalanceTotal: 0,
  walletsBalanceTokens: [],
  walletsP2PBalance: [],
  frontFinanceBalance: [],
  frontFinanceAuth: null,
  frontFinanceTotal: 0,
  mixedBalances: [],
  mixedTotal: 0,
  switchBalances: false,
  loginInitiated: false,
  isSharedAccessToken: false,
  collectables: [],
  spamCollectables: [],
  showRecoveryFlowModal: false,
  firstShowOFRecoveryFlowModal: true
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUserInfo: () => ({ ...initialState, loading: false }),
    setAskingPermission: (state, action) => {
      state.isAskingPermissions = action.payload;
    },
    setSupportBiometric: (state, action) => {
      state.supportBiometric = action.payload;
    },
    setFrontFinanceAuthData: (state, action) => {
      state.frontFinanceAuth = action.payload;
    },
    setSwitchBalances: (state, action) => {
      state.switchBalances = action.payload;
    },
    setLoginInitiated: (state, action) => {
      state.loginInitiated = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    setNftCheckoutId: (state, action) => {
      state.nftCheckoutId = action.payload;
    },
    setReValidateWallet: (state, action) => {
      state.reValidateWallet = action.payload;
    },
    setUserCredentials: (state, action) => {
      const { accessToken, refreshToken, idToken, isSharedAccessToken } =
        action.payload;
      if (accessToken) state.accessToken = accessToken;
      if (refreshToken) state.refreshToken = refreshToken;
      if (idToken) state.idToken = idToken;
      state.isSharedAccessToken = !!isSharedAccessToken;
    },
    setEmail: (state, action) => {
      const emailToLowerCase = action.payload.toLowerCase();
      state.email = emailToLowerCase;
      if (!state.name) state.name = emailToLowerCase;
      if (!state.username) state.username = emailToLowerCase;
    },
    setPinCodeSetup: (state, action) => {
      state.isPinCodeSetup = action.payload;
    },
    setIsPhoneAdded: (state) => {
      state.isPhoneAdded = true;
    },
    setShowRecoveryFlowModal: (state, action) => {
      state.showRecoveryFlowModal = action.payload;
    },
    firstTimeRecoveryFlow(state, action) {
      state.firstShowOFRecoveryFlowModal = action.payload;
    }
  },
  extraReducers: (builder) => {
    // Logout
    builder.addCase(signOut.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signOut.fulfilled, () => ({
      ...initialState,
      loading: false,
      onboardingStatus: OnboardingStatus.LANDING_SCREEN
    }));

    // Onboarding status
    builder.addCase(setOnboardingStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setOnboardingStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.onboardingStatus = action.payload;
      state.isOnboard = action.payload === OnboardingStatus.ONBOARD;
    });
    builder.addCase(loadOnboardingStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadOnboardingStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.onboardingStatus = action.payload;
      state.isOnboard = action.payload === OnboardingStatus.ONBOARD;
    });

    // Get user info
    builder.addCase(getUserInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserInfo.rejected, (state, action) => {
      state.loading = false;
      state.uid = null;
      state.email = null;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      const { name, ...rest } = action.payload;
      const upperCaseName = formatName(name || "");
      return {
        ...state,
        ...rest,
        ...action.payload,
        name: upperCaseName,
        loading: false
      };
    });
    builder.addCase(getNotificationConfig.fulfilled, (state, action) => {
      state.loading = false;
      return (state.notificationEnabled = action.payload);
    });
    builder.addCase(getNotificationConfig.rejected, (state, action) => {
      state.loading = false;
      state.error = "Something went wrong";
    });
    builder.addCase(getNotificationConfig.pending, (state) => {
      state.loading = true;
    });

    // Get profile image
    builder.addCase(getProfileImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProfileImage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getProfileImage.fulfilled, (state, action) => {
      state.loading = false;
      state.pfp = action.payload;
    });

    // Set profile image
    builder.addCase(setProfileImage.fulfilled, (state, action) => {
      state.loading = false;
      state.pfp = action.payload;
    });

    // Update user info
    builder.addCase(updateUserInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserInfo.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.name) state.name = action.payload?.name;
      if (action.payload?.username) state.username = action.payload?.username;
      if (action.payload?.phone) state.phone = action.payload?.phone;
      if (action.payload?.countryCode)
        state.countryCode = action.payload?.countryCode;
      if (action.payload?.countryFlag)
        state.countryFlag = action.payload?.countryFlag;
      if (action.payload?.currency) state.currency = action.payload?.currency;
      if (action.payload?.isMerchant)
        state.isMerchant = action.payload?.isMerchant;
      state.error = "";
    });
    builder.addCase(updateUserInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(setNotificationsConfig.fulfilled, (state, action) => {
      state.notificationEnabled = action.payload;
    });

    builder.addCase(getChainLogos.rejected, (state, action) => {
      state.chainLogos = {};
    });
    builder.addCase(getChainLogos.fulfilled, (state, action) => {
      state.chainLogos = action.payload;
    });

    // Get Token Prices
    builder.addCase(getTokenPrices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTokenPrices.rejected, (state) => {
      state.error = "Something went wrong fetching token prices.";
      state.loading = false;
    });
    builder.addCase(getTokenPrices.fulfilled, (state, action) => {
      state.loading = false;
      state.ethPrice = action.payload;
    });

    // Get User Nfts
    builder.addCase(getUserCollectables.pending, (state) => {
      state.loadingCollectables = true;
    });
    builder.addCase(getUserCollectables.fulfilled, (state, action) => {
      state.collectables = action.payload.legit;
      state.spamCollectables = action.payload.spam;
      state.loadingCollectables = false;
    });
    builder.addCase(getUserCollectables.rejected, (state) => {
      state.loadingCollectables = false;
    });

    // Get user balances
    builder.addCase(getUserBalances.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserBalances.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getUserBalances.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.walletsP2PBalance = action.payload.balancesByChainForP2p;
        state.walletsBalanceTotal = action.payload.totalPrice;
        state.walletsBalanceTokens = action.payload.formattedBalances;
        state.balancesCacheTimestamp = Date.now();

        state.mixedBalances = action.payload.totalBalances
          ? mixBalances(
              action.payload.totalBalances || [],
              state.frontFinanceBalance
            )
          : state.frontFinanceBalance
          ? [...state.frontFinanceBalance]
          : [];
        state.mixedTotal = action.payload.total
          ? action.payload.total + (state.frontFinanceTotal || 0)
          : state.frontFinanceTotal || 0;
      }
    });

    // Get linked accounts
    builder.addCase(getLinkedAccounts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLinkedAccounts.rejected, (state) => {
      state.loading = false;
      state.linkedAccounts = [];
    });
    builder.addCase(getLinkedAccounts.fulfilled, (state, action) => {
      state.loading = false;
      state.linkedAccounts = action.payload;
    });

    // Front Finance
    builder.addCase(getFFBalance.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFFBalance.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getFFBalance.fulfilled, (state, action) => {
      state.loading = false;
      state.frontFinanceBalance = action.payload.balances;
      state.mixedBalances = action.payload.balances
        ? mixBalances(
            state.walletsBalanceTokens || [],
            state.frontFinanceBalance
          )
        : state.walletsBalanceTokens
        ? [...state.walletsBalanceTokens]
        : [];

      state.frontFinanceTotal = action.payload.total;
      state.mixedTotal = action.payload.total
        ? action.payload.total + state.walletsBalanceTotal
        : state.walletsBalanceTotal || 0;
    });
    builder.addCase(setNewAccessToken.fulfilled, (state, action) => {
      state.accessToken = action.payload;
    });
    builder.addCase(setNewAccessToken.rejected, (state) => {
      state.accessToken = undefined;
    });
  }
});

export const {
  resetUserInfo,
  setAskingPermission,
  setSupportBiometric,
  setFrontFinanceAuthData,
  setSwitchBalances,
  setLoginInitiated,
  setRefreshToken,
  setNftCheckoutId,
  setUserCredentials,
  setEmail,
  setPinCodeSetup,
  setIsPhoneAdded,
  setShowRecoveryFlowModal,
  firstTimeRecoveryFlow,
  setReValidateWallet
} = userSlice.actions;

export default userSlice.reducer;
