import jwtDecode, { JwtPayload } from "jwt-decode";
import axios from "axios";
import { REACT_APP_BASE_URL } from "@env";

export async function verifyJWT(data: string) {
  await axios.post(`${REACT_APP_BASE_URL}/verifyJWT`, { data });
  const decodeTransactions = jwtDecode(data) as any;
  return decodeTransactions;
}

export function isTokenExpired(accessToken: string | undefined) {
  const decodedToken: JwtPayload | undefined = accessToken
    ? jwtDecode(accessToken)
    : undefined;
  const currentTime = Date.now() / 1000;
  return decodedToken?.exp ? decodedToken.exp < currentTime : true; // if token is undefined we treat it as expired
}
