import React from "react";
import { REACT_APP_AUTH_CLIENT_ID, REACT_APP_AUTH_URL } from "@env";
import { AuthProvider } from "react-oidc-context";
import { isWeb } from "../utils/platform";
import { WebStorageStateStore } from "oidc-client-ts";

export const storagePath = `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_AUTH_CLIENT_ID}`;
const oidcConfig = {
  authority: REACT_APP_AUTH_URL as string,
  client_id: REACT_APP_AUTH_CLIENT_ID as string,
  redirect_uri: window.location.origin,
  response_type: "code",
  scope: "openid profile offline_access",
  automaticSilentRenew: true,
  userStore: isWeb()
    ? new WebStorageStateStore({
        store: window.localStorage
      })
    : undefined
};

export const AuthProviderComponent = (props: {
  children: React.ReactElement;
}) => {
  return <AuthProvider {...oidcConfig}>{props.children}</AuthProvider>;
};
