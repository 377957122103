import React from "react";
import { Alert, Modal, ScaledSize, StyleSheet } from "react-native";

import { colorCardBackground } from "../utils/colors";
import SecondaryButton from "./ThemedComponents/SecondaryButton";
import { Text, View } from "./Themed";
import PrimaryButton from "./ThemedComponents/PrimaryButton";
import Input from "./ThemedComponents/Input";
import { callFunction } from "../utils/server";
import i18n from "../config/languageInternationalization";
import { isWeb, pwaMaxWidth } from "../utils/platform";
import { getLinkedAccounts } from "../redux/slices/userThunk";
import { useAppDispatch } from "../redux/hooks";

export enum ModalStateEnumIMX {
  INIT = "INIT",
  INSERT_KEY = "INSERT_KEY",
  SUCCESS = "SUCCESS"
}

export const AddIMXStarkPrivateKey = ({
  publicKey,
  recoveryKey,
  accessToken,
  closeModal,
  starkPK,
  setStarkPK,
  starkPKError,
  setStarkPKError,
  modal,
  setModal,
  loading,
  setLoading,
  dimensions,
  wrappedDek
}: {
  publicKey: string;
  recoveryKey: { mnemonic: string } | { privateKey: string };
  accessToken: string;
  closeModal: () => void;
  starkPK: string;
  setStarkPK: (e: string) => void;
  starkPKError: string;
  setStarkPKError: (e: string) => void;
  modal: ModalStateEnumIMX;
  setModal: (e: ModalStateEnumIMX) => void;
  loading: boolean;
  setLoading: (e: boolean) => void;
  dimensions: ScaledSize;
  wrappedDek: string;
}) => {
  const onChangeStarkPrivateKey = (e: string) => {
    setStarkPKError("");
    if (e.length <= 63) {
      setStarkPK(e);
    }
    if (!e.match(/[0-9a-fA-F]{63}/)) {
      setStarkPKError(i18n.t("addIMXStarkPrivateKeyValidation"));
    } else {
      setStarkPKError("");
    }
  };

  const dispatch = useAppDispatch();

  const styles = StyleSheet.create({
    boxContainerStyle: {
      backgroundColor: colorCardBackground,
      width: "90%",
      borderRadius: 25,
      padding: 16,
      gap: 16,
      alignSelf: "center",
      justifyContent: "space-between"
    }
  });

  return (
    <View style={{ padding: 20, margin: 20, flex: 1 }}>
      <Modal visible={true} transparent>
        <View
          style={[
            {
              flex: 1,
              justifyContent: "center"
            },
            isWeb() && {
              width: pwaMaxWidth(dimensions),
              alignSelf: "center"
            }
          ]}
        >
          {modal === ModalStateEnumIMX.INIT && (
            <View style={styles.boxContainerStyle}>
              <Text
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: 18,
                  fontWeight: "bold"
                }}
              >
                {i18n.t("addIMXStarkPrivateKeyWalletSuccessfullyImported")}
              </Text>
              <Text style={{ color: "white", textAlign: "center" }}>
                {i18n.t(
                  "addIMXStarkPrivateKeyWalletSuccessfullyImportedMessage"
                )}
              </Text>

              <PrimaryButton
                onPress={() => {
                  setModal(ModalStateEnumIMX.INSERT_KEY);
                }}
                style={
                  isWeb() && {
                    alignSelf: "center",
                    width: "90%"
                  }
                }
                textStyle={{ fontSize: 16 }}
              >
                <Text>{i18n.t("addIMXStarkPrivateKeyEnterStarknetPK")}</Text>
              </PrimaryButton>
              <SecondaryButton
                onPress={() => closeModal()}
                style={
                  isWeb() && {
                    alignSelf: "center",
                    width: "90%"
                  }
                }
                textStyle={{ fontSize: 16 }}
              >
                <Text>{i18n.t("close")}</Text>
              </SecondaryButton>
            </View>
          )}

          {modal === ModalStateEnumIMX.INSERT_KEY && (
            <View style={styles.boxContainerStyle}>
              <Text style={{ color: "white", textAlign: "center" }}>
                {i18n.t("addIMXStarkPrivateKeyPleaseEnterStarknetPK")}
              </Text>
              <Input
                value={starkPK || ""}
                onChangeText={(e) => onChangeStarkPrivateKey(e)}
                placeholder={`${i18n.t(
                  "addIMXStarkPrivateKeyPKPlaceholder"
                )}...`}
                returnKeyType={"next"}
                maxLength={63}
              />
              {starkPKError && (
                <Text className="text-red-600">{starkPKError}</Text>
              )}

              <PrimaryButton
                style={
                  isWeb() && {
                    alignSelf: "center",
                    width: "100%"
                  }
                }
                isLoading={loading}
                isDisabled={loading || !!starkPKError}
                onPress={async () => {
                  setLoading(true);
                  try {
                    const { data } = await callFunction({
                      url: "/checkStarkPrivateKey",
                      method: "POST",
                      data: {
                        DEK: wrappedDek,
                        publicKey,
                        recoveryKey,
                        starkPrivateKey: starkPK
                      },
                      accessToken
                    });
                    if (data) {
                      dispatch(getLinkedAccounts());
                      setModal(ModalStateEnumIMX.SUCCESS);
                    }
                  } catch (error: any) {
                    if (error?.message?.includes("Stark Private Key")) {
                      setStarkPKError(error.message || error);
                      setModal(ModalStateEnumIMX.INSERT_KEY);
                    } else {
                      Alert.alert(
                        i18n.t("errorImportingWallet"),
                        error?.message || error
                      );
                    }
                  } finally {
                    setLoading(false);
                  }
                }}
              >
                <Text>{i18n.t("verificationPhoneInputScreenConfirm")}</Text>
              </PrimaryButton>
              <SecondaryButton
                style={
                  isWeb() && {
                    alignSelf: "center",
                    width: "100%"
                  }
                }
                onPress={() => {
                  closeModal();
                }}
              >
                <Text>{i18n.t("close")}</Text>
              </SecondaryButton>
            </View>
          )}

          {modal === ModalStateEnumIMX.SUCCESS && (
            <View style={styles.boxContainerStyle}>
              <View
                style={{
                  backgroundColor: colorCardBackground,
                  width: "90%",
                  margin: "auto",
                  alignSelf: "center",
                  borderRadius: 25,
                  padding: 16,
                  gap: 16,
                  justifyContent: "space-between"
                }}
              >
                <Text style={{ color: "white", textAlign: "center" }}>
                  {i18n.t(
                    "addIMXStarkPrivateKeySuccessfullyImportedIMXStarknetPK"
                  )}
                </Text>
                <Text style={{ color: "white", textAlign: "center" }}>
                  {i18n.t("addIMXStarkPrivateKeySuccessfullyImportedMessage")}
                </Text>

                <PrimaryButton
                  style={
                    isWeb() && {
                      alignSelf: "center",
                      width: "90%"
                    }
                  }
                  onPress={() => {
                    closeModal();
                  }}
                >
                  <Text>{i18n.t("close")}</Text>
                </PrimaryButton>
              </View>
            </View>
          )}
        </View>
      </Modal>
    </View>
  );
};
