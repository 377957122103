import React, { useEffect, useState } from "react";
import { KeyboardAvoidingView, useWindowDimensions } from "react-native";
import Toast from "react-native-toast-message";

import Header from "../../components/Header";
import Input from "../../components/ThemedComponents/Input";
import { colorBackground } from "../../utils/colors";
import PrimaryButton from "../../components/ThemedComponents/PrimaryButton";
import FormControl from "../../components/ThemedComponents/FormControl";
import { isEmptyString, isEmail } from "../../utils/validation";
import i18n from "../../config/languageInternationalization";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { isWeb, pwaMaxWidth } from "../../utils/platform";
import { recordError } from "../../utils/crashlytics";
import { Alert } from "../../utils/Alert";
import SafeAreaView from "../../components/ThemedComponents/SafeAreaView";
import { setEmail } from "../../redux/slices/user";
import { useCustomAuth } from "../../hooks/useAuth";
import { hideSplashScreen } from "../../redux/slices/splash";
import { postDocumentData } from "../../utils/server";

export default function AddEmailScreen() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { accessToken } = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch(hideSplashScreen());
  }, []);
  // Save user data
  async function handleSave() {
    try {
      setLoading(true);
      if (!isEmail(state))
        return setError(i18n.t("editProfileScreenEmailIsInvalidErrorMessage"));

      if (!accessToken) throw new Error("Session is not valid");

      if (state) {
        //save email in db and Ory
        await postDocumentData({
          url: `/user/email`,
          data: { email: state },
          accessToken
        });
        dispatch(setEmail(state));
      }
      // There are updates
      Toast.show({
        type: "success",
        text1: i18n.t("AddEmailScreenUpdateSuccess")
      });
    } catch (error: any) {
      if (
        error.response?.data?.error ===
        "Email already linked to another account"
      ) {
        if (isWeb()) {
          return Alert.alert(i18n.t("AddEmailScreenEmailAlreadyLinked"));
        } else
          return Toast.show({
            type: "error",
            text1: i18n.t("AddEmailScreenEmailAlreadyLinked")
          });
      }

      Toast.show({
        type: "error",
        text1: i18n.t("AddEmailScreenUpdateError")
      });
      recordError(error.message, "AddEmail.tsx");
    } finally {
      setLoading(false);
    }
  }
  const dimensions = useWindowDimensions();

  const { logout } = useCustomAuth();
  return (
    <SafeAreaView
      style={{
        backgroundColor: colorBackground,
        width: "100%"
      }}
    >
      <SafeAreaView
        style={{
          backgroundColor: colorBackground,
          width: isWeb() ? pwaMaxWidth(dimensions) : "100%"
        }}
      >
        <Header
          title={i18n.t("addEmailAddress")}
          onBackPress={() => logout()}
        />
        {/* User data form */}
        <KeyboardAvoidingView
          behavior="position"
          style={{ paddingHorizontal: 16, marginTop: isWeb() ? 48 : 24 }}
        >
          {/* Email input */}
          <FormControl
            style={{ marginBottom: 18 }}
            isInvalid={error !== ""}
            errorMessage={error}
            label={i18n.t("AddEmailScreenEmailDescription")}
          >
            <Input
              style={{ marginTop: 20 }}
              onChangeText={(text) => {
                setError("");
                setState(text);
              }}
              value={state}
              placeholder={i18n.t("editProfileScreenEmailPlaceholder")}
            />
          </FormControl>

          <PrimaryButton
            isDisabled={isEmptyString(state) || !isEmptyString(error)}
            style={{ marginHorizontal: 1 }}
            onPress={handleSave}
            isLoading={loading}
          >
            {i18n.t("editProfileScreenSave")}
          </PrimaryButton>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </SafeAreaView>
  );
}
