import React from "react";
import { Text, View } from "./Themed";
import { ChevronLeftIcon, XMarkIcon } from "react-native-heroicons/solid";
import {
  StyleProp,
  TextStyle,
  TouchableOpacity,
  ViewStyle
} from "react-native";
import { useNavigation } from "@react-navigation/native";

interface HeaderTitleViewProps extends ViewStyle, TextStyle {}

export default function Header({
  title,
  onBackPress,
  xIcon,
  styles,
  onlyTitle,
  titleStyles,
  accessibilityLabel
}: {
  title?: string;
  onBackPress?: () => any; // By default navigates back
  xIcon?: boolean; // Weather to use back arrow or X icon
  styles?: StyleProp<ViewStyle>;
  backButton?: boolean;
  onlyTitle?: boolean;
  titleStyles?: StyleProp<HeaderTitleViewProps>;
  accessibilityLabel?: string;
}) {
  const navigation = useNavigation();

  return (
    <View
      style={[
        {
          flexDirection: "row",
          alignItems: "center",
          paddingVertical: 24,
          paddingHorizontal: 14
        },
        styles
      ]}
    >
      {!onlyTitle && (
        <TouchableOpacity
          testID="recoverWalletHeaderButton"
          accessibilityLabel={accessibilityLabel}
          onPress={
            onBackPress !== undefined ? onBackPress : () => navigation.goBack()
          }
        >
          {xIcon ? (
            <XMarkIcon size={29} color="white" />
          ) : (
            <ChevronLeftIcon size={29} color="white" />
          )}
        </TouchableOpacity>
      )}
      {title !== undefined && (
        <Text
          style={[
            {
              fontSize: 18,
              marginLeft: onlyTitle ? 2 : 16
            },
            titleStyles
          ]}
        >
          {title}
        </Text>
      )}
    </View>
  );
}
