import { TransactionReceiver } from "../../../../types/navigationTypes";
import { TransferType } from "../../../../types/transfer";
import { SupportedChains } from "../../../utils/supportedChains";

export const navigateToDetail = ({
  navigation,
  receiver,
  type,
  amount,
  tokenSymbol,
  message,
  chain
}: {
  navigation: any;
  receiver: TransactionReceiver;
  type: TransferType;
  amount: number;
  tokenSymbol: string;
  chain?: SupportedChains;
  message?: string;
}) => {
  navigation.navigate("P2PTransfer", {
    screen: "TransferDetail",
    params: {
      type,
      receiver,
      amount,
      tokenSymbol,
      message,
      chain,
      isNotification: true
    }
  });
};
