import React from "react";
import {
  GestureResponderEvent,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import {
  colorBorder,
  colorLabelTextSecondary,
  gray700,
  gray800,
  white
} from "../../utils/colors";
import { ChevronRightIcon } from "react-native-heroicons/solid";

export default function SettingsCell({
  icon,
  iconBgColor,
  title,
  onPress,
  removeBottomBorder,
  titleStyle
}: {
  icon: JSX.Element;
  iconBgColor: string;
  title: string;
  onPress?: (event: GestureResponderEvent) => void;
  removeBottomBorder?: boolean;
  titleStyle?: object;
}) {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: gray800,
        borderRadius: 8,
        marginBottom: 12,
        borderWidth: 1,
        borderColor: gray700
      }}
    >
      <View
        style={{
          marginHorizontal: 16,
          height: 30,
          width: 30,
          borderRadius: 8,
          backgroundColor: iconBgColor,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {icon}
      </View>
      <View
        style={{
          height: 44,
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Text style={[{ color: "white", fontSize: 16 }, titleStyle]}>
          {title}
        </Text>
        <ChevronRightIcon style={{ marginRight: 16 }} size={24} color={white} />
      </View>
    </TouchableOpacity>
  );
}
