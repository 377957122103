import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { REACT_APP_BASE_URL as baseURL } from "@env";
import { ThemeData } from "./theme";

export const getTheme = createAsyncThunk(
  "theme/getTheme",
  async (
    { apiKey, txId }: { apiKey: string; txId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.request({
        method: "POST",
        url: `${baseURL}/theme`,

        headers: { "Content-Type": "application/json" },
        // `apiKey` is prioritized before `transactionId`
        data: {
          apiKey: apiKey,
          transactionId: txId
        }
      });
      return response.data.data as ThemeData;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
