import {
  setAuthCode,
  setOnrampPreferences,
  cleanCheckoutTxIdFromStorage,
  cleanCheckoutWallet,
  setCheckoutAction,
  setCheckoutTxIdFromStorage,
  setCheckoutWallet
} from "./localStorage";
import { isWeb } from "./platform";
import { SupportedChains } from "./supportedChains";

export interface OnrampLinkParams {
  txid: string;
  amount: string;
  tokenSymbol: string;
  tokenAddress: string;
  toAddress: string;
  chain: string;
}
export const setMerchantParams = async (
  callBack?: (apikey?: string, txId?: string) => void
) => {
  if (!isWeb()) return;
  const urlParams = new URLSearchParams(window.location.search);
  const txId = urlParams.get("txid");
  const chain = urlParams.get("chain");
  const token = urlParams.get("token");
  const apiKey = urlParams.get("apiKey");
  const authCode = urlParams.get("authCode");
  const wallet = urlParams.get("wallet");
  const walletLabel = urlParams.get("wallet_label");

  if (authCode) setAuthCode(authCode);

  authCode && setAuthCode(authCode);

  if (txId) {
    await setCheckoutTxIdFromStorage({ txId: txId });
    await setCheckoutAction("pay");
    await cleanCheckoutWallet();
  } else if (wallet || (token && chain)) {
    await setOnrampPreferences({
      chain: chain as SupportedChains,
      token,
      wallet,
      walletLabel
    });
    await cleanCheckoutTxIdFromStorage();
  }

  // only trigger the callback if we have callback exists and we have either apiKey or txId
  if (callBack) callBack(apiKey ?? undefined, txId ?? undefined);
};
