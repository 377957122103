export function openWebComposer({
  to,
  cc,
  bcc,
  subject,
  body
}: {
  to: string;
  cc?: string;
  bcc?: string;
  subject: string;
  body: string;
}) {
  const parameters = [
    cc && `cc=${cc}`,
    bcc && `bcc=${bcc}`,
    `subject=${encodeURIComponent(subject)}`,
    `body=${encodeURIComponent(body)}`
  ];
  // Remove any undefined or empty values from the array
  const validParameters = parameters.filter((param) => !!param);

  const mailtoString = `mailto:${to}?${validParameters.join("&")}`;

  window.open(mailtoString, "_blank");
}
