import * as Localization from "expo-localization";
import { Dict, I18n } from "i18n-js";
import en from "./languages/en.json";
import es from "./languages/dist/es.json";
import fr from "./languages/dist/fr.json";
import zhTW from "./languages/dist/zh-TW.json";
import zhCN from "./languages/dist/zh-CN.json";
import hi from "./languages/dist/hi.json";
import ru from "./languages/dist/ru.json";
import pt from "./languages/dist/pt.json";
import id from "./languages/dist/id.json";
import ja from "./languages/dist/ja.json";
import de from "./languages/dist/de.json";
import it from "./languages/dist/it.json";
import el from "./languages/dist/el.json";
import ar from "./languages/dist/ar.json";
import tr from "./languages/dist/tr.json";
import ko from "./languages/dist/ko.json";
import vi from "./languages/dist/vi.json";
import uk from "./languages/dist/uk.json";
import th from "./languages/dist/th.json";
import ne from "./languages/dist/ne.json";
import sw from "./languages/dist/sw.json";

export const translations: Dict = {
  en, // English
  es, // Spanish
  zhTW, // Chinese (Traditional)
  zhCN, // Chinese (Simplified)
  hi, // Hindi
  fr, // French
  ru, // Russian
  pt, // Portuguese (Portugal, Brazil)
  id, // Indonesian
  ja, // Japanese
  de, // German
  it, // Italian
  el, // Greek
  ar, // Arabic
  tr, // Turkish
  ko, // Korean
  vi, // Vietnamese
  uk, // Ukrainian
  th, // Thai
  ne, // Nepali
  sw // Swahili
};

const i18n = new I18n(translations);

i18n.locale = Localization.locale.slice(0, 2);
i18n.enableFallback = true;

export const cellphoneLanguage = Localization.locale.slice(0, 2);

export default i18n;
