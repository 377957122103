import React, { useEffect, useState } from "react";
import { Text, View } from "../components/Themed";
import i18n from "../config/languageInternationalization";
import {
  Alert,
  Image,
  Pressable,
  RefreshControl,
  ScrollView,
  SectionList,
  TouchableOpacity,
  useWindowDimensions
} from "react-native";
import { isWeb, pwaMaxWidth } from "../utils/platform";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getFFBalance, getUserBalances } from "../redux/slices/userThunk";
import Loading from "../components/Loading";
import { setSwitchBalances } from "../redux/slices/user";
import SafeAreaView from "../components/ThemedComponents/SafeAreaView";
import { colorLightGrayText, gray400, gray700, gray800 } from "../utils/colors";
import images from "../assets/images";
import ValueListElement from "../components/ValueListElement";
import { ArrowPathIcon } from "react-native-heroicons/outline";
import SecondaryButton from "../components/ThemedComponents/SecondaryButton";
import SendTransferModal from "../components/TransferScreen/TransferModal/SendTransferModal";
import { Balance } from "../../types/balances";

export function notEnoughFundsAlert(amount: number) {
  Alert.alert(
    i18n.t("amountScreenNotEnoughFunds"),
    i18n.t("amountScreenNotEnoughFundsWithAmountMsg", {
      amount: amount,
      currency: "USDC"
    })
  );
}

export default function TransferScreen({ navigation }: { navigation: any }) {
  const dispatch = useAppDispatch();
  const dimensions = useWindowDimensions();
  const [refreshing, setRefreshing] = useState(false);
  const [modal, setModal] = useState<boolean>(false);
  const [token, setToken] = useState<Balance>({} as Balance);
  const loading = useAppSelector((state) => state.user.loading);
  const user = useAppSelector((state) => state.user);
  const { walletsP2PBalance, switchBalances, walletsBalanceTotal } =
    useAppSelector((state) => state.user);
  const { accessToken, frontFinanceAuth, reValidateWallet } = useAppSelector(
    (state) => state.user
  );

  useEffect(() => {
    (async () => {
      const unsubscribe = await navigation.addListener("focus", async () => {
        await dispatch(getUserBalances({}));
      });
      return await unsubscribe;
    })();
  }, []);

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);

    await dispatch(getUserBalances({ refreshCache: true }));
    const promisesArray: any = [dispatch(getFFBalance({}))];
    if (frontFinanceAuth) {
      if (
        !accessToken ||
        !frontFinanceAuth?.accessToken?.accountTokens[0]?.accessToken
      )
        return;
    }
    await Promise.all(promisesArray);
    setRefreshing(false);
  }, []);

  return (
    <SafeAreaView
      style={
        isWeb()
          ? {
              backgroundColor: gray800,
              borderRadius: 16,
              borderWidth: 1,
              borderColor: gray700
            }
          : {
              flex: 1,
              paddingHorizontal: 16,
              width: "100%"
            }
      }
    >
      {modal && (
        <SendTransferModal onClose={() => setModal(false)} token={token} />
      )}
      <ScrollView
        contentContainerStyle={{
          flex: 1
        }}
        nestedScrollEnabled={true} // Ref: https://stackoverflow.com/a/76658894
        refreshControl={
          <RefreshControl
            tintColor={"white"}
            refreshing={refreshing || loading}
            onRefresh={onRefresh}
          />
        }
      >
        <View style={{ alignItems: "center", marginTop: 32, marginBottom: 18 }}>
          <Text
            style={{
              opacity: 0.6,
              fontWeight: "400",
              fontSize: 16,
              color: gray400
            }}
          >
            {i18n.t("homeScreenAvailableBalance")}
          </Text>
          {loading ? (
            <Text style={{ fontSize: 35, fontWeight: "bold" }}>--.--</Text>
          ) : (
            <>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <Text style={{ fontSize: 60, fontWeight: "bold" }}>
                  $ {walletsBalanceTotal}
                </Text>
                {isWeb() && (
                  <Pressable
                    aria-hidden={false}
                    testID="homeRefreshBtn"
                    accessibilityLabel="homeRefreshBtn"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 12
                    }}
                    onPress={onRefresh}
                  >
                    <ArrowPathIcon
                      color={"white"}
                      style={{ height: 18, width: 18 }}
                    />
                  </Pressable>
                )}
              </View>
              {frontFinanceAuth && (
                <>
                  <SecondaryButton
                    onPress={() => {
                      dispatch(setSwitchBalances(!switchBalances));
                    }}
                  >
                    <Text>
                      {switchBalances
                        ? i18n.t("showSphereOneBalances")
                        : i18n.t("showFFbalances")}
                    </Text>
                  </SecondaryButton>
                </>
              )}
            </>
          )}
        </View>
        {loading ? (
          <View
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center"
            }}
          >
            <Loading />
          </View>
        ) : (
          <SectionList
            sections={walletsP2PBalance ?? []}
            style={{ flex: 1, alignSelf: isWeb() ? "center" : undefined }}
            scrollEnabled={true}
            showsVerticalScrollIndicator={true}
            renderSectionHeader={({ section: { title } }) => (
              <Text className="mt-4 mb-0.5">{title}</Text>
            )}
            renderItem={({ item }: { item: any }) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    setToken({ ...item });
                    setModal(true);
                  }}
                  style={{
                    backgroundColor: gray800,
                    borderColor: gray700,
                    borderWidth: 1,
                    marginVertical: 5,
                    borderRadius: 16,
                    paddingVertical: 12,
                    paddingHorizontal: 12,
                    height: 70,
                    width: isWeb() ? pwaMaxWidth(dimensions) - 50 : undefined
                  }}
                >
                  <ValueListElement
                    icon={
                      item.tokenMetadata.logoURI.length
                        ? item.tokenMetadata.logoURI
                        : "https://i.postimg.cc/mrqxZ7H6/Fallback-Coin.png"
                    }
                    currency={item.tokenMetadata.symbol}
                    amount={item.amount}
                    price={item.price}
                  />
                </TouchableOpacity>
              );
            }}
            ListEmptyComponent={
              <View
                style={{
                  alignItems: "center"
                }}
              >
                <Image
                  source={images.Search}
                  style={{
                    height: 250,
                    width: 250
                  }}
                />
                <Text
                  style={{
                    marginHorizontal: 16,
                    marginVertical: 12,
                    fontSize: 18,
                    color: colorLightGrayText
                  }}
                >
                  {i18n.t("homeScreenHaventAssets")}
                </Text>
              </View>
            }
          />
        )}
      </ScrollView>
    </SafeAreaView>
  );
}
