import { Alert } from "../utils/Alert";
import i18n from "../config/languageInternationalization";
import { Onramp } from "../components/OnRampScreen/utils/onRampProviders";
import {
  handleGoBack,
  constructPaymentMethodsUrl,
  isWert,
  isSphereOneMethod,
  payErrorMessage
} from "../utils/onramp";
import { SupportedChains } from "../utils/supportedChains";
import {
  CustomOnrampToken,
  OnrampGenericResult,
  QuoteData,
  OnrampTokenType
} from "../../types/onRamp";
import { getDocumentData } from "../utils/server";
import {
  DisplayPaymentMethod,
  sphereOneMethod,
  validatePaymentMethods
} from "../utils/validatePaymentMethods";
import { getTokenPrice } from "../utils/prices";
import { recordError } from "../utils/crashlytics";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { RouteStatus, checkoutActions } from "../redux/slices/checkoutOnramp";
import { useQuery } from "@tanstack/react-query";
import { useNavigation } from "@react-navigation/native";
import {
  MerchantTransaction,
  RouteResponse,
  TxStatus
} from "../../types/transfer";
import axios from "axios";
import { useCustomAuth } from "./useAuth";
import { REACT_APP_BASE_URL as baseURL } from "@env";
import { SphereToken } from "../redux/slices/tokens";
import {
  DEFAULT_CHAIN,
  DEFAULT_TOKEN,
  DEFAULT_WERT_PAYMENT_METHODS,
  EXCLUDED_TOKENS
} from "../config/onramp";
import { isTokenExpired } from "../utils/verifyJWT";
import { findWalletByChain } from "../utils/findWalletByChain";
import { createCustomOnrampTransaction } from "../screens/CheckoutOnrampScreen";
import { useEffect, useRef } from "react";
import { convertToSmallestUnit } from "../utils/numberWithCommas";
import { getLinkedAccounts } from "../redux/slices/userThunk";

export const useSupportedChains = () => {
  const { fiat, countryCode, chain, onrampPreferencesChainAndToken } =
    useAppSelector((state) => state.checkoutOnramp);
  const dispatch = useAppDispatch();
  const chainToSet = chain || DEFAULT_CHAIN;

  const fetchSupportedChains = async () => {
    const chains = (
      await getDocumentData({
        url: `/onramps/getSupportedChains?fiat=${fiat}&countryCode=${countryCode}`
      })
    ).data as { label: string; value: SupportedChains }[];

    const defaultChain = chains.find((c) => c.value === chainToSet);

    dispatch(checkoutActions.setChain(defaultChain?.value || chains[0]?.value));

    return chains;
  };

  return useQuery(["supportedChains", fiat], fetchSupportedChains, {
    enabled: !!fiat && !onrampPreferencesChainAndToken,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    initialData: () => {
      if (!onrampPreferencesChainAndToken) {
        !chain && dispatch(checkoutActions.setChain(SupportedChains.POLYGON));
        return [
          {
            label: chain ?? SupportedChains.POLYGON,
            value: chain ?? SupportedChains.POLYGON
          }
        ];
      }
    }
  });
};

function normalizeTokensData(tokens: SphereToken[]) {
  return tokens
    .filter(
      (token) =>
        !EXCLUDED_TOKENS.some(
          (excludedToken) =>
            excludedToken.address.toLowerCase() ===
              token.address.toLowerCase() &&
            excludedToken.symbol === token.symbol
        )
    )
    .map((token) => {
      return { ...token, value: token.symbol, label: `${token.symbol}` };
    });
}

export const useSupportedTokens = () => {
  const { fiat, chain, token, onrampPreferencesChainAndToken } = useAppSelector(
    (state) => state.checkoutOnramp
  );
  const tokenToSet = token || DEFAULT_TOKEN;
  const { sphereTokens } = useAppSelector((state) => state.tokens);

  const dispatch = useAppDispatch();

  const fetchSupportedTokens = async () => {
    const tokensList = normalizeTokensData(
      sphereTokens[chain as SupportedChains]
    );

    const defaultToken = tokensList.find((t) => t.symbol === tokenToSet.symbol);
    dispatch(checkoutActions.setToken(defaultToken || tokensList[0]));
    return tokensList as SphereToken[];
  };

  return useQuery(
    ["supportedTokens", fiat, chain, sphereTokens],
    async () => await fetchSupportedTokens(),
    {
      enabled: !!fiat && !!chain,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      initialData: () => {
        if (!onrampPreferencesChainAndToken) {
          !token && dispatch(checkoutActions.setToken(DEFAULT_TOKEN));
          return [token ?? DEFAULT_TOKEN];
        }
      }
    }
  );
};

export const useFetchCharge = () => {
  const { txId } = useAppSelector((state) => state.checkoutOnramp);
  const { sphereTokens } = useAppSelector((state) => state.tokens);
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const {
    data: charge,
    isLoading: isChargeLoading,
    isError: isChargeError
  } = useQuery(
    ["fetchCharge"],
    () => fetchCharge() as Promise<MerchantTransaction>,
    {
      enabled: !!txId && !!Object.values(sphereTokens).length,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: false
    }
  );
  async function fetchCharge() {
    try {
      const { data: charge } = await getDocumentData({
        url: "/transactions/" + txId
      });

      if (!charge || !charge?.total)
        throw new Error(i18n.t("checkoutInvalidCharge"));
      if (charge.status === TxStatus.SUCCESS)
        throw new Error(i18n.t("checkoutPaid"));
      if (charge.status === TxStatus.CANCELED)
        throw new Error(i18n.t("checkoutCancelled"));
      if (charge.status === TxStatus.PROCESSING)
        throw new Error(i18n.t("checkoutProcessing"));
      if (charge.expirationDate?.seconds * 1000 < new Date().getTime())
        throw new Error(i18n.t("checkoutExpired"));

      const token = sphereTokens[charge.chain as SupportedChains]?.find(
        (t) => t.address.toLowerCase() === charge.tokenAddress.toLowerCase()
      );
      dispatch(
        checkoutActions.setOnrampPaymentDetailsOnChargeFetch({
          token: token,
          tokenAmount: charge.total,
          chain: charge.chain
        })
      );
      return charge;
    } catch (e: any) {
      recordError(e, "CheckoutScreen.tsx");
      Alert.alert(e?.response?.data?.error || e.message, undefined);
      await handleGoBack(txId, navigation, dispatch);
    }
  }
  return { charge, isChargeLoading, isChargeError };
};

export const useFetchPaymentMethods = () => {
  const { token, chain, fiat, txId, countryCode } = useAppSelector(
    (state) => state.checkoutOnramp
  );
  const dispatch = useAppDispatch();
  const getPaymentMethods = async () => {
    dispatch(checkoutActions.onPaymentMethodChangeCleanup());
    if (!token || !fiat || !chain || !countryCode) return;
    try {
      const url = await constructPaymentMethodsUrl(
        token.symbol,
        fiat,
        countryCode,
        chain,
        txId
      );
      const methods = await getDocumentData({
        url
      });

      const { allowedMethods, moreMethods } = validatePaymentMethods(
        methods.data
      );
      const defaultMethods = txId
        ? [sphereOneMethod, ...allowedMethods]
        : allowedMethods;

      return {
        defaultMethods,
        moreMethods
      };
    } catch (error: any) {
      recordError(error, "CheckoutScreen - Payment Methods");
    }
  };

  const getInitialDefaultMethods = () => {
    const defaultMethods = [];

    if (txId) {
      defaultMethods.push(sphereOneMethod);
    }
    if (
      chain &&
      [
        SupportedChains.POLYGON,
        SupportedChains.ETHEREUM,
        SupportedChains.DFK
      ].includes(chain)
    ) {
      defaultMethods.push(...DEFAULT_WERT_PAYMENT_METHODS);
    }

    return defaultMethods.length
      ? { defaultMethods, moreMethods: [] }
      : undefined;
  };

  const {
    data: { defaultMethods, moreMethods } = {
      defaultMethods: [],
      moreMethods: []
    },
    isLoading: paymentMethodsLoading
  } = useQuery(
    ["fetchPaymentMethods", fiat, chain, token?.symbol],
    () => getPaymentMethods(),
    {
      enabled: !!token?.symbol && !!chain && !!fiat,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      initialData: () => getInitialDefaultMethods()
    }
  );

  return { defaultMethods, moreMethods, paymentMethodsLoading };
};

export const useFetchQuote = () => {
  const dispatch = useAppDispatch();
  const {
    fiat,
    chain,
    token,
    paymentMethod,
    tokenAmount,
    tokenToOnramp,
    countryCode,
    walletAddress,
    txId
  } = useAppSelector((state) => state.checkoutOnramp);
  const { sphereTokens } = useAppSelector((state) => state.tokens);
  const { accessToken, linkedAccounts, uid } = useAppSelector(
    (state) => state.user
  );
  const { data, isLoading } = useQuery(
    [
      "fetchQuote",
      accessToken,
      chain,
      token,
      fiat,
      paymentMethod?.value,
      paymentMethod?.type,
      tokenAmount
    ],
    () => handleFetchQuote() as Promise<QuoteData>,
    {
      enabled: !!(
        chain &&
        token &&
        fiat &&
        paymentMethod &&
        Number(tokenAmount) &&
        paymentMethod?.provider !== "sphereone"
      ),
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false
    }
  );
  const handleFetchQuote = async () => {
    if (
      !chain ||
      !token ||
      !fiat ||
      !countryCode ||
      !paymentMethod ||
      !tokenAmount ||
      !sphereTokens
    )
      return;
    try {
      const onrampInstance = new Onramp(paymentMethod.provider);
      let customToken = tokenToOnramp;
      if (!customToken) {
        const response = await onrampInstance.getTokenType({
          token: token.symbol,
          chain,
          fiat,
          countryCode,
          tokenAddress: token.address
        });
        if (
          response?.tokenType === OnrampTokenType.CUSTOM &&
          response?.customToken
        ) {
          customToken = response.customToken;
        }
      }
      if (customToken) {
        return await handleCustomToken(
          customToken,
          chain,
          tokenAmount,
          paymentMethod,
          onrampInstance,
          fiat,
          countryCode
        );
      } else {
        return await getQuote(
          token,
          tokenAmount,
          chain,
          paymentMethod,
          onrampInstance,
          fiat,
          countryCode,
          false,
          null
        );
      }
    } catch (error: any) {
      return handleFetchQuoteError(error.message);
    }
  };

  const handleCustomToken = async (
    tokenToOnramp: CustomOnrampToken,
    chain: SupportedChains,
    tokenAmount: string,
    paymentMethod: DisplayPaymentMethod,
    onrampInstance: Onramp,
    fiat: string,
    countryCode: string
  ) => {
    if (!chain) return;
    const desiredAmountUsdc = await getTokenPrice(
      chain,
      tokenToOnramp.address,
      Number(tokenAmount),
      tokenToOnramp.symbol
    );
    if (!desiredAmountUsdc) {
      return Promise.reject(new Error("Token not found"));
    }

    // Covers the case of DFK chain bridge fee when onramping from MATIC. TODO: Bring the surplus from the server already considering the bridge scenario, maybe when getting the tokenType
    const isMaticWithBridge =
      tokenToOnramp.onRampTo.symbol === "MATIC" &&
      tokenToOnramp.chain !== "POLYGON";

    const surplusPercentage = isMaticWithBridge ? 0.25 : 0.2; // surplus swaps and etc
    let surplusInUsdc = desiredAmountUsdc * surplusPercentage;

    if (surplusInUsdc < 2) surplusInUsdc = isMaticWithBridge ? 2 : 0.5;
    else if (surplusInUsdc > 10) surplusInUsdc = 10;

    const amount = desiredAmountUsdc + surplusInUsdc;

    // Get amount of token to onramp
    const onrampTokenUnitPrice = await getTokenPrice(
      tokenToOnramp.onRampTo.chain,
      tokenToOnramp.onRampTo.address,
      1,
      tokenToOnramp.onRampTo.symbol
    );
    if (!onrampTokenUnitPrice) {
      return Promise.reject(new Error("Token not found"));
    }

    const bridgeSum = isMaticWithBridge ? 1 : 0;
    const onrampAmount = (amount / onrampTokenUnitPrice + bridgeSum).toString();

    dispatch(
      checkoutActions.setCustomOnrampDetails({
        tokenToOnramp,
        customOnrampSurplus: 1 + surplusPercentage
      })
    );

    // Calculate fiat amount
    return await getQuote(
      tokenToOnramp.onRampTo,
      onrampAmount,
      tokenToOnramp.onRampTo.chain,
      paymentMethod,
      onrampInstance,
      fiat,
      countryCode,
      isMaticWithBridge,
      tokenToOnramp
    );
  };

  const getQuote = async (
    onrampToken: SphereToken,
    onrampAmount: string,
    onrampChain: string,
    paymentMethod: DisplayPaymentMethod,
    onrampInstance: Onramp,
    fiat: string,
    countryCode: string,
    isMaticWithBridge: boolean,
    customToken: CustomOnrampToken | null = null
  ) => {
    const data = await onrampInstance.getTokenPrice({
      fiatSymbol: !isWert(paymentMethod.provider) ? fiat : "USD",
      token: onrampToken,
      tokenAmount: onrampAmount,
      chain: onrampChain,
      paymentMethod: paymentMethod.value,
      countryCode: countryCode
    });
    if (!data) {
      return Promise.reject(new Error("No data"));
    }
    const { isValid, type, maxInFiat, minInFiat, minInToken, maxInToken } =
      onrampInstance.validateAmount(
        data.tokenAmount,
        data.fiatAmount,
        Array(paymentMethod),
        paymentMethod.value
      );
    const validFiatAmount =
      type === null
        ? data.fiatAmount
        : type === "minimum"
        ? minInFiat
        : maxInFiat;
    const validTokenAmount = parseValidTokenAmount(
      isValid,
      type,
      minInToken,
      maxInToken,
      isMaticWithBridge,
      data.tokenAmount
    );
    dispatch(
      checkoutActions.setQuoteOnrampDetails({
        validAmount: isValid,
        amountError: type
          ? {
              type,
              validAmount: Number(validFiatAmount),
              message: i18n.t("checkoutAmountError", {
                type,
                fiat: isWert(paymentMethod.provider) ? "USD" : fiat,
                validAmount: validFiatAmount
              })
            }
          : null,
        onrampAmount: validTokenAmount
      })
    );
    if (customToken && uid && !txId) {
      await createCustomOnrampTransaction({
        onrampFromToken: customToken.onRampTo,
        tokenAmount: tokenAmount!, // amount in custom token
        chain: chain!,
        symbol: token?.symbol as string,
        tokenAddress: customToken.address,
        walletAddress,
        accessToken: accessToken!,
        linkedAccounts,
        dispatch
      });
    }

    return {
      tokenAmount: validTokenAmount,
      fiatAmount: validFiatAmount,
      fee: Number(data.networkFee) + Number(data.transactionFee)
    };
  };

  const parseValidTokenAmount = (
    isValid: boolean,
    type: "minimum" | "maximum" | null,
    minInToken: number,
    maxInToken: number,
    isMaticWithBridge: boolean,
    onrampAmount: string
  ) => {
    if (isValid) return onrampAmount;
    if (type === "minimum")
      return isMaticWithBridge
        ? (minInToken + 1).toString() // add 1 MATIC to the minimum amount to cover the bridge fee
        : minInToken.toString();
    else return maxInToken.toString();
  };

  const handleFetchQuoteError = (error: string) => {
    if (error.includes("wallet")) {
      // For new users with SIWE, Sphere wallets aren't created immediately, if no wallet is found, we need to fetch the linked accounts again
      dispatch(getLinkedAccounts());
    }
    dispatch(
      checkoutActions.setAmountError({
        type: "invalid",
        message: error.includes("token")
          ? i18n.t("tokenNotFound")
          : i18n.t("onRampErrorFetchingPrice")
      })
    );
    recordError(error);
    return Promise.reject(error);
  };

  return { data, isLoading };
};

export const useGetRouteEstimation = () => {
  const user = useCustomAuth();
  const { charge } = useFetchCharge();
  const state = useAppSelector((state) => state.checkoutOnramp);
  const { linkedAccounts, accessToken } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const isSphereOne =
    state.txId && isSphereOneMethod(state.paymentMethod?.provider);
  const isOnrampPrev = useRef(false); // to check if the user switched from sphere one to onramp
  const isOnramp =
    ((state.txId && !isOnrampPrev.current) || !!state.customOnrampTxId) &&
    state.onrampAmount;
  const createMockedBalances = () => {
    if (isSphereOneMethod(state.paymentMethod?.provider)) return null;
    const userWallet = findWalletByChain(
      linkedAccounts,
      state.tokenToOnramp?.onRampTo?.chain ?? state.chain!
    );
    const decimals =
      state.tokenToOnramp?.onRampTo.decimals ?? state.token?.decimals;
    const amount = convertToSmallestUnit(state.onrampAmount!, decimals!);
    return [
      {
        walletAddress: userWallet.address,
        tokenAddress:
          state.tokenToOnramp?.onRampTo?.address ?? charge?.tokenAddress,
        chain: state.tokenToOnramp?.onRampTo?.chain ?? state.chain!,
        amount
      }
    ];
  };

  const initiateRoute = async () => {
    try {
      isOnrampPrev.current = !!isOnramp;
      const res = await axios.request({
        url: baseURL + "/pay/route",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json"
        },
        data: {
          transactionId: state.txId || state.customOnrampTxId,
          fromBalances: createMockedBalances(),
          forceRefresh: true
        },
        method: "POST"
      });
      dispatch(checkoutActions.setRouteStatus(RouteStatus.SUCCESS));
      return res.data.data as RouteResponse;
    } catch (error: any) {
      dispatch(checkoutActions.setRouteStatus(RouteStatus.ERROR));
      dispatch(
        checkoutActions.setBuyStatusDetails({
          buyStatusMessage: payErrorMessage(error.response?.data)
        })
      );
      throw error;
    }
  };
  return useQuery(
    ["routeEstimation", accessToken, state.customOnrampTxId],
    initiateRoute,
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: false,
      retryOnMount: false,
      enabled:
        !!user &&
        !!linkedAccounts.length &&
        !isTokenExpired(accessToken) &&
        (!!isSphereOne || !!isOnramp)
    }
  );
};
export interface CountryData {
  ip: string;
  city: string;
  region: string;
  region_code: string;
  country: string;
  country_code: string;
  country_code_iso3: string;
  country_name: string;
  country_capital: string;
  country_tld: string;
  country_area: number;
  country_population: number;
  continent_code: string;
  in_eu: boolean;
  postal: string;
  latitude: number;
  longitude: number;
  timezone: string;
  utc_offset: string;
  country_calling_code: string;
  currency: string;
  currency_name: string;
  languages: string;
  asn: string;
  org: string;
}

export const useCountryData = () => {
  async function getCountryData() {
    try {
      const response = await axios.get("https://ipapi.co/json");
      return response.data as CountryData;
    } catch (error) {
      recordError(error, "CheckoutScreen.tsx. Country data");
    }
  }

  return useQuery(["countryData"], () => getCountryData(), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    staleTime: Infinity
  });
};

export const useTransactionStatusInterval = (
  transactionId: string | null,
  onSuccess: (res: any) => void
): any => {
  useEffect(() => {
    if (!transactionId) return;

    const getStatus = setInterval(() => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/transactions/${transactionId}`,
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then(onSuccess)
        .catch((error) =>
          Alert.alert("Error fetching transaction data: " + error)
        );
    }, 5000);

    return () => clearInterval(getStatus);
  }, [transactionId]);
};
