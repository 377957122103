import React from "react";
import {
  GestureResponderEvent,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { colorLabelTextSecondary } from "../../utils/colors";
import { ChevronRightIcon } from "react-native-heroicons/solid";

export default function PaymentMethodCell({
  icon,
  iconBgColor,
  title,
  onPress,
  titleStyle,
  subtitle
}: {
  icon: JSX.Element; // size 24 color white
  iconBgColor: string;
  title: string;
  onPress?: (event: GestureResponderEvent) => void;
  removeBottomBorder?: boolean;
  titleStyle?: object;
  subtitle?: string;
}) {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{ flexDirection: "row", alignItems: "center" }}
    >
      <View
        style={{
          marginRight: 8,
          height: 30,
          width: 30,
          backgroundColor: iconBgColor,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {icon}
      </View>
      <View
        style={{
          height: 60,
          flex: 1
        }}
      >
        <Text
          style={[
            { color: "white", fontSize: 17, marginTop: subtitle ? 8 : 17 },
            titleStyle
          ]}
        >
          {title}
        </Text>
        {subtitle && (
          <Text style={{ color: "gray", fontSize: 12, marginTop: 3 }}>
            {subtitle}
          </Text>
        )}
      </View>
      <ChevronRightIcon
        style={{ marginRight: 16 }}
        size={14}
        color={colorLabelTextSecondary}
      />
    </TouchableOpacity>
  );
}
