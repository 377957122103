import React from "react";
import { View } from "../Themed";
import {
  colorBackgroundTransparent,
  gray700,
  gray800
} from "../../utils/colors";
import { isWeb } from "../../utils/platform";
import InsertPinCode from "../InsertPinCode";
import { callFunction } from "../../utils/server";
import { useAppSelector } from "../../redux/hooks";
import { SupportedChains } from "../../utils/supportedChains";
import PostTransfer from "./PostTransfer";
import PreTransfer from "./PreTransfer";
import { useHeaderHeight } from "@react-navigation/elements";

enum TransferNftStep {
  SEND,
  SUCCESS
}

interface Props {
  tokenId?: string;
  chain: SupportedChains;
  nftTokenAddress: string;
  fromAddress: string;
  nftName: string;
  onClose: () => void;
}

function SendNftModal({
  tokenId,
  chain,
  nftTokenAddress,
  fromAddress,
  nftName,
  onClose
}: Props) {
  const { accessToken } = useAppSelector((state) => state.user);
  const [showPincode, setShowPincode] = React.useState(false);
  const [step, setStep] = React.useState(TransferNftStep.SEND);
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({
    toAddress: "",
    reason: ""
  });

  const submit = async (wrappedDek: string) => {
    try {
      setLoading(true);
      const response = await callFunction({
        url: "/transferNft",
        accessToken: accessToken as string,
        data: {
          wrappedDek,
          chain,
          nftTokenAddress,
          tokenId,
          fromAddress,
          toAddress: state.toAddress,
          reason: state.reason
        },
        method: "POST"
      });

      if (response.error === null) setStep(TransferNftStep.SUCCESS);
    } catch (e: any) {
      console.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const headerHeight = useHeaderHeight();

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: 1000,
        backgroundColor: colorBackgroundTransparent,
        justifyContent: "center"
      }}
    >
      <View
        className={"m-6 text-center bg-red-500 w-max"}
        style={{ backgroundColor: colorBackgroundTransparent }}
      >
        <View
          className="w-max"
          style={{
            backgroundColor: gray800,
            borderRadius: 8,
            borderWidth: 1,
            borderColor: gray700,
            padding: 32,
            display: "flex"
          }}
        >
          {step === TransferNftStep.SEND ? (
            <PreTransfer
              state={state}
              setState={setState}
              loading={loading}
              onClose={onClose}
              setShowPincode={setShowPincode}
            />
          ) : (
            <PostTransfer
              toAddress={state.toAddress}
              nftName={nftName}
              onClose={onClose}
            />
          )}
        </View>
        {showPincode && (
          <InsertPinCode
            cb={submit}
            target="SEND_NFT"
            closeModal={() => setShowPincode(false)}
            height="50vh"
          />
        )}
      </View>
    </View>
  );
}

export default SendNftModal;
