import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Token } from "./tokens";
import { SupportedChains } from "../../utils/supportedChains";
import { getDocumentData } from "../../utils/server";

// This is to avoid call multiple times and just fetch to the redux all the tokens
export const fetchSphereOneTokens = createAsyncThunk(
  "tokens/fetchSphereTokens",
  async () => {
    const tokens = await getDocumentData({
      url: `/supportedTokens`
    });
    return tokens.data;
  }
);

export const fetchTokensList = createAsyncThunk(
  "tokens/fetchTokensList",
  async () => {
    const [inchTokens, jupiterTokens, popularTokens, polygonTokens] =
      await Promise.all([
        inchGetTokenList(),
        jupGetTokenList(),
        getPopularTokensList(),
        inchGetTokenListPolygon()
      ]);
    return { inchTokens, jupiterTokens, popularTokens, polygonTokens };
  }
);

// Get tokens available for swapping
export const inchGetTokenList = async () => {
  const source = "https://api.1inch.io/v5.0/1/tokens";
  const res = await axios.get(source);

  const tokens: Token[] = Object.values(res.data.tokens).map(
    ({ address, symbol, name, decimals, logoURI, tags }: any) => ({
      address,
      symbol,
      name,
      decimals,
      logoURI,
      tags,
      tokenType: "ERC20"
    })
  );

  return {
    source,
    tokens
  };
};

// Get tokens available for swapping
export const inchGetTokenListPolygon = async () => {
  const source = "https://api.1inch.io/v5.0/137/tokens";
  const res = await axios.get(source);

  const tokens: Token[] = Object.values(res.data.tokens).map(
    ({ address, symbol, name, decimals, logoURI, tags }: any) => ({
      address,
      symbol,
      name,
      decimals,
      logoURI,
      tags,
      tokenType: "ERC20"
    })
  );

  return {
    source,
    tokens
  };
};

export const jupGetTokenList = async () => {
  const source = "https://cache.jup.ag/tokens";
  const res = await axios.get(source);

  const tokens: Token[] = res.data.map(
    ({ address, symbol, name, decimals, logoURI, tags }: any) => ({
      address,
      symbol,
      name,
      decimals,
      logoURI,
      tags,
      tokenType: "SPL"
    })
  );

  return {
    source,
    tokens
  };
};

export const getPopularTokensList = async () => {
  // TODO: Update this mocking function with real data
  const popularTokens: Token[] = [
    {
      symbol: "USDC",
      name: "USD Coin",
      address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      decimals: 6,
      logoURI:
        "https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
      tags: ["tokens", "PEG:USD"],
      tokenType: "ERC20"
    }
  ];

  return popularTokens;
};

type ChainSpecificAddresses = {
  [key in SupportedChains]: string;
};

export const UsdcAddresses: ChainSpecificAddresses = {
  POLYGON: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
  ETHEREUM: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  OPTIMISM: "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
  GNOSIS: "0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83",
  AVALANCHE: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
  SOLANA: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
  IMMUTABLE: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  BINANCE: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
  ARBITRUM: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
  FANTOM: "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
  [SupportedChains.BLANK]: ""
};
