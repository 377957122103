export enum HomeFilterType {
  ACCOUNT,
  COLLECTIBLES
}

export enum DiscoverFilterType {
  ALL,
  OFFERS,
  DISCOUNTS,
  SPHEREONE_PAY
}

export enum ProfileButtonType {
  WHATSAPP,
  TELEGRAM,
  EMAIL,
  CANCEL
}

export interface FilterOption {
  name: string;
  type: HomeFilterType | DiscoverFilterType | ProfileButtonType;
}
