import { firebaseConfig } from "./firebaseConfig";
import firebaseWeb from "firebase/app";
import "firebase/storage";
import { isWebViewedOnIOS } from "./platform";
import "firebase/messaging";

// Initialize Firebase
export const firebaseApp = firebaseWeb.initializeApp(firebaseConfig);
export const storage = firebaseApp.storage();
// This module is throwing an error when the user is in web IOS
export const msg =
  !isWebViewedOnIOS() &&
  (firebaseWeb.messaging.isSupported() ? firebaseApp.messaging() : null);

export const firebaseProjectId = firebaseConfig.projectId;
