import React from "react";
import { Pressable } from "react-native";
import { View, Text } from "../Themed";
import Avatar from "../Avatar";
import { colorBorder } from "../../utils/colors";
import TransferWarnings from "../TransferDetailScreen/TransferWarnings";
import { DevicePhoneMobileIcon } from "react-native-heroicons/solid";
import i18n from "../../config/languageInternationalization";
import { isWeb } from "../../utils/platform";

export type ContactBadgeProps = {
  name: string;
  detail?: string | undefined;
  isUser: boolean;
  onPress?: any | undefined;
  Icon?: any | undefined;
  badgeStyle?: any | undefined;
  containerStyle?: any | undefined;
};

export default function ContactBadge({
  name,
  detail,
  onPress,
  Icon,
  badgeStyle,
  containerStyle,
  isUser
}: ContactBadgeProps) {
  return (
    <Pressable
      style={({ pressed }) => [
        {
          ...containerStyle,
          backgroundColor: pressed ? "gray" : "transparent",
          marginTop: isWeb() ? 16 : 0,
          borderBottomWidth: 1,
          borderColor: colorBorder,
          paddingVertical: 16,
          width: "100%"
        }
      ]}
      onPress={onPress}
    >
      <View
        style={{
          marginBottom: isUser ? 0 : 16,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row"
        }}
      >
        {Icon ? (
          <Avatar nameOrEmail={name} source={{ uri: Icon }} size={60} />
        ) : (
          <View
            style={{
              ...badgeStyle,
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Text style={{ fontSize: 20 }}>{name[0]}</Text>
          </View>
        )}

        <View
          style={{
            flex: 2,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            marginStart: 20
          }}
        >
          <Text style={{ fontSize: 16 }}>{name}</Text>
          {detail && <Text style={{ color: "lightgray" }}>{detail}</Text>}
        </View>
      </View>
      {/* Hasn't joined SphereOne app */}
      {!isUser && (
        <TransferWarnings
          message={i18n.t("transferDetailScreenHasntJoined")}
          icon={<DevicePhoneMobileIcon color={"white"} size={16} />}
        />
      )}
    </Pressable>
  );
}
