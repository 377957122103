import React from "react";
import { isWeb } from "../../utils/platform";
import { View } from "../Themed";
import Modal from "../ThemedComponents/Modal";
import actionSheetStyles from "./ActionSheetStyles";
import { ActionSheetCustom as ActionSheet } from "react-native-actionsheet";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ProfileActionSheetButtonOptions } from "./utils/ButtonOptions";
import i18n from "../../config/languageInternationalization";
import { openWebComposer } from "./utils/openWebComposer";
import { SUPPORT_EMAIL } from "@env";
import { setAskingPermission } from "../../redux/slices/user";
import { ProfileButtonType } from "../../../types/filterTypes";
import { openComposer } from "react-native-email-link";
import { ModalHook } from "../../hooks/useModal";

const ContactSupport = ({
  actionSheet,
  supportModal,
  subject
}: {
  actionSheet: React.LegacyRef<ActionSheet> | undefined;
  supportModal: ModalHook;
  subject?: string;
}) => {
  const { name } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const optionArray = ProfileActionSheetButtonOptions;

  function onModalSupportConfirm() {
    openWebComposer({
      to: SUPPORT_EMAIL,
      subject: subject ?? "Need support about ",
      body: `Hi Sphereone team!  ${
        name ? `I'm ${name} and I` : "I"
      } need help with `
    });
    supportModal.hideModal();
  }
  return (
    <View>
      {isWeb() ? (
        <Modal
          visible={supportModal.visible}
          title={i18n.t("profileScreenSupportMsg")}
          buttonLabel="Email"
          onCancel={supportModal.hideModal}
          onConfirm={onModalSupportConfirm}
        />
      ) : (
        <ActionSheet
          styles={actionSheetStyles}
          ref={actionSheet}
          title={i18n.t("profileScreenSupport")}
          options={optionArray.map((o) => o.name)} // pass in list of button names
          cancelButtonIndex={optionArray.length - 1}
          destructiveButtonIndex={optionArray.length - 1}
          onPress={(index: number) => {
            dispatch(setAskingPermission(true));
            switch (optionArray[index].type) {
              case ProfileButtonType.WHATSAPP:
                break;
              case ProfileButtonType.TELEGRAM:
                //TODO: add Telegram support
                break;
              case ProfileButtonType.EMAIL:
                openComposer({
                  to: SUPPORT_EMAIL,
                  subject: subject ?? "Need support about ",
                  body: `Hi Sphereone team!  ${
                    name ? `I'm ${name} and I` : "I"
                  } need help with `
                });
                break;
              default:
                // default is ProfileButtonType.CANCEL
                break;
            }
          }}
        />
      )}
    </View>
  );
};

export default ContactSupport;
