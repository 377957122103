import React, { useEffect, useState } from "react";
import { FlatList, Image } from "react-native";
import {
  colorLightGrayText,
  background,
  blue600,
  gray400,
  gray800,
  gray700
} from "../../utils/colors";
import { Text, View } from "../../components/Themed";
import PrimaryButton from "../../components/ThemedComponents/PrimaryButton";
import Avatar from "../../components/Avatar";
import i18n from "../../config/languageInternationalization";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getUserCollectables,
  setProfileImage
} from "../../redux/slices/userThunk";
import { recordError } from "../../utils/crashlytics";
import Loading from "../../components/Loading";
import { Alert } from "../../utils/Alert";
import { Collectable } from "../../redux/slices/user";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootStackParamList } from "../../../types";
import { isWeb, pwaMaxWidth } from "../../utils/platform";
import Header from "../../components/Header";
import { useWindowDimensions } from "react-native";
import SafeAreaView from "../../components/ThemedComponents/SafeAreaView";
import NftCard from "../../components/ChooseProfileNft/NftCard";
import images from "../../assets/images";

export default function ChooseNftScreen({
  navigation
}: {
  navigation: NativeStackNavigationProp<
    RootStackParamList,
    "ChooseNftScreen",
    undefined
  >;
}) {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedNft, setSelectedNft] = useState<Collectable | undefined>(
    undefined
  );
  const dimensions = useWindowDimensions();
  const cardSize = dimensions.width >= 740 ? 140 : 75;
  const cardMargin = dimensions.width >= 740 ? 20 : 10;

  const loadNft = async () => {
    try {
      if (!user.collectables.length) await dispatch(getUserCollectables());
      const selected = user.collectables.find(
        (nft: Collectable) => nft.selected === true
      );
      setSelectedNft(selected);
    } catch (error) {
      recordError(error, "chooseNftScreen.tsx");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectNft = (selected: Collectable) => setSelectedNft(selected);

  const handleDonePress = async () => {
    try {
      await dispatch(setProfileImage(selectedNft?.img as string));
      navigation.goBack();
    } catch (error) {
      recordError(error, "ChooseNftScreen.tsx");
      Alert.alert(i18n.t("alertErrorTitle"), i18n.t("alertErrorSubtitle"));
    }
  };

  useEffect(() => {
    loadNft();
  }, []);

  return (
    <SafeAreaView
      style={
        isWeb()
          ? {
              backgroundColor: gray800,
              borderRadius: 16,
              marginVertical: 20,
              borderColor: gray700,
              borderWidth: 1
            }
          : { paddingHorizontal: 16 }
      }
    >
      <Header xIcon={true} styles={{ paddingBottom: 0 }} />
      {user.collectables.length > 0 ? (
        <>
          <View
            style={{
              alignItems: "center",
              marginBottom: 18
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 5
              }}
            >
              <Avatar
                nameOrEmail={user.name}
                source={
                  user.pfp
                    ? { uri: selectedNft?.img ? selectedNft?.img : user.pfp }
                    : null
                }
                size={128}
                style={{
                  transform: [{ rotate: "45deg" }],
                  overflow: "hidden",
                  borderRadius: 0
                }}
                imageStyle={{
                  width: "100%",
                  height: "100%",
                  transform: [{ rotate: "-45deg" }],
                  borderRadius: 0
                }}
              />
            </View>
            <Text style={{ fontSize: 22 }}>{user.name}</Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{
                color: colorLightGrayText,
                marginBottom: 12,
                textAlign: "left",
                fontSize: isWeb() ? 22 : 16,
                marginLeft: 16
              }}
            >
              {i18n.t("chooseNftScreenAvailableNftsForYou")}
            </Text>

            <View
              style={{
                flex: 4,
                maxWidth: "100%"
              }}
            >
              {isLoading ? (
                <View
                  style={{
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Loading />
                </View>
              ) : (
                <FlatList
                  data={user.collectables}
                  numColumns={isWeb() ? 5 : 4}
                  ItemSeparatorComponent={() => (
                    <View style={{ height: 16 }}></View>
                  )}
                  ListEmptyComponent={
                    <Text style={{ flex: 1, flexWrap: "wrap" }}>
                      {i18n.t("ChooseCollectibleScreenNoCollectibles")}
                    </Text>
                  }
                  renderItem={({ item, index }) => (
                    <NftCard
                      key={`nftcard-${index}`}
                      onPress={() => handleSelectNft(item)}
                      size={100}
                      image={item.img}
                      selected={item.address === selectedNft?.address}
                    />
                  )}
                  keyExtractor={(item) => item.name}
                />
              )}
            </View>

            <PrimaryButton
              testID="SaveNftButton"
              onPress={handleDonePress}
              style={{
                marginVertical: 30,
                width: isWeb() ? "95%" : undefined,
                alignSelf: isWeb() ? "center" : undefined,
                borderRadius: 8,
                backgroundColor: blue600
              }}
            >
              {i18n.t("chooseNftScreenDone")}
            </PrimaryButton>
          </View>
        </>
      ) : (
        <View
          style={{
            flex: 1
          }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: isWeb() ? "center" : "flex-start",
              alignItems: "center"
            }}
          >
            <Image
              source={images.EmptyCollectables}
              style={{ width: 240, height: 240, marginBottom: 32 }}
            />
            <Text
              style={{ color: gray400, fontSize: 16 }}
            >{`You don't have any NFTs`}</Text>
          </View>
          <PrimaryButton
            testID="SaveNftButton"
            onPress={handleDonePress}
            style={{
              marginVertical: 30,
              width: "95%",
              alignSelf: isWeb() ? "center" : undefined,
              borderRadius: 8,
              backgroundColor: blue600
            }}
          >
            {i18n.t("addNfts")}
          </PrimaryButton>
        </View>
      )}
    </SafeAreaView>
  );
}
