import React, { useState } from "react";
import { KeyboardAvoidingView } from "react-native";
import Header from "../../components/Header";
import { Text, View } from "../../components/Themed";
import PrimaryButton from "../../components/ThemedComponents/PrimaryButton";
import i18n from "../../config/languageInternationalization";
import { colorBackground } from "../../utils/colors";
import { recordError } from "../../utils/crashlytics";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateUserInfo } from "../../redux/slices/userThunk";
import { Alert } from "../../utils/Alert";
import { updateDocumentData } from "../../utils/server";
import { isWeb } from "../../utils/platform";
import SafeAreaView from "../../components/ThemedComponents/SafeAreaView";

enum SuccessState {
  SUCCESS,
  FAILURE,
  NONE
}

const UpgradeToMerchantScreen = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<SuccessState>(SuccessState.NONE);
  const { uid, accessToken } = useAppSelector((state) => state.user);

  const onSubmit = async () => {
    setLoading(true);

    try {
      if (!accessToken) throw new Error("No access token");
      if (!uid) throw new Error("Unauthorized");

      const { error } = await updateDocumentData({
        url: "/user",
        updateData: { isMerchant: true },
        accessToken
      });

      if (error) {
        setIsSuccess(SuccessState.FAILURE);
        throw new Error(error);
      } else {
        // ... on success
        await dispatch(updateUserInfo({ isMerchant: true }));
        setIsSuccess(SuccessState.SUCCESS);
      }
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      Alert.alert(errorCode, errorMessage);
      recordError(errorCode, errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={{ backgroundColor: colorBackground, flex: 1 }}>
      <SafeAreaView style={{ backgroundColor: "transparent" }}>
        <Header
          title={i18n.t("upgradeToMerchantScreenHeaderTitle")}
          styles={{ marginBottom: 10 }}
        />

        <KeyboardAvoidingView style={{ marginTop: 26, paddingHorizontal: 16 }}>
          {isSuccess !== SuccessState.NONE ? (
            <Text style={{ fontSize: 16 }}>
              {isSuccess === SuccessState.SUCCESS
                ? i18n.t("upgradeToMerchantScreenOnSuccess")
                : i18n.t("upgradeToMerchantScreenOnFailure")}
            </Text>
          ) : (
            <PrimaryButton
              isLoading={loading}
              onPress={onSubmit}
              style={{ marginTop: 24 }}
            >
              {i18n.t("profileScreenUpgradeButtonSubTitle")}
            </PrimaryButton>
          )}
        </KeyboardAvoidingView>
      </SafeAreaView>
    </View>
  );
};

export default UpgradeToMerchantScreen;
