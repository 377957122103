import BlueClockIcon from "./blue_clock_icon.png";
import InverseDownloadIcon from "./tray_above_arrow_down_icon.png";
import QrCodeIcon from "./QrCodeIcon.png";
import GoogleIcon from "./GoogleIcon.png";
import FacebookIcon from "./FacebookIcon.png";
import AppleIcon from "./AppleIcon.png";
import BackSpaceIcon from "./BackSpaceIcon.png";
import AppFeature01 from "./AppFeature01b.png";
import AppFeature02 from "./AppFeature02b.png";
import AppFeature03 from "./AppFeature03b.png";
import balloon from "./balloon.png";
import Search from "./Search.png";
import SphereOneIconSolid from "./SphereOneIconSolid.png";
import SphereOneIcon from "./SphereIconLogoGreen.png";
import BalloonGroup from "./Group.png";
import BorderBg from "./BorderBack.png";
import SphereOneWhiteLogoWithLetters from "./SphereOneLogoWhiteWithLetters.png";
import CheckCircle from "./CheckCircle.png";
import FallbackCoin from "./FallbackCoin.png";
import EmptyCollectables from "./EmptyCollectables.png";

export default {
  BlueClockIcon,
  InverseDownloadIcon,
  QrCodeIcon,
  GoogleIcon,
  FacebookIcon,
  AppleIcon,
  BackSpaceIcon,
  AppFeature01,
  AppFeature02,
  AppFeature03,
  balloon,
  Search,
  SphereOneIconSolid,
  SphereOneIcon,
  BalloonGroup,
  BorderBg,
  SphereOneWhiteLogoWithLetters,
  CheckCircle,
  FallbackCoin,
  EmptyCollectables
};
