import React from "react";
import Svg, { Path, G, Mask } from "react-native-svg";
export const SphereOneIcon = () => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <Mask
      id="mask0_510_9472"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <Path d="M24 0H0V24H24V0Z" fill="white" />
    </Mask>
    <G mask="url(#mask0_510_9472)">
      <Path
        d="M0 10.576C0.762714 9.35546 1.49105 8.20783 2.20081 7.04911C3.60082 4.76304 5.53315 3.04435 7.91049 1.75923C8.85343 1.24907 9.71369 0.592219 10.6176 -0.000976562C10.5377 0.729679 10.4903 1.54336 10.351 2.34136C10.1717 3.36261 9.98218 4.38848 9.68396 5.38114C8.79025 8.35913 6.65725 9.95973 3.58224 10.3712C2.38754 10.5317 1.16776 10.5142 0.000929043 10.576H0Z"
        fill="white"
      />
      <Path
        d="M10.6055 24.0005C9.69413 23.4064 8.81343 22.7393 7.8482 22.2227C5.2925 20.8537 3.34903 18.932 1.96573 16.4209C1.39347 15.3812 0.664192 14.4235 -0.000976562 13.418C0.657689 13.4678 1.40276 13.4669 2.12551 13.5914C3.28213 13.7907 4.47776 13.9364 5.56098 14.3479C7.67262 15.1505 9.06055 16.6958 9.70992 18.8453C10.2311 20.5677 10.5219 22.3251 10.6046 24.0005H10.6055Z"
        fill="white"
      />
      <Path
        d="M23.9999 10.576C23.2372 9.35546 22.5089 8.20783 21.7991 7.04911C20.3991 4.76304 18.4668 3.04435 16.0894 1.75923C15.1465 1.24907 14.2862 0.592219 13.3823 -0.000976562C13.4622 0.729679 13.5096 1.54336 13.6489 2.34136C13.8283 3.36261 14.0177 4.38848 14.316 5.38114C15.2096 8.35913 17.3427 9.95973 20.4177 10.3712C21.6124 10.5317 22.8322 10.5142 23.999 10.576H23.9999Z"
        fill="white"
      />
      <Path
        d="M13.3936 24.0005C14.3049 23.4064 15.1856 22.7393 16.1508 22.2227C18.7065 20.8537 20.65 18.932 22.0333 16.4209C22.6056 15.3812 23.3349 14.4235 24.0001 13.418C23.3413 13.4678 22.5963 13.4669 21.8736 13.5914C20.7169 13.7907 19.5213 13.9364 18.438 14.3479C16.3264 15.1505 14.9385 16.6958 14.2891 18.8453C13.7679 20.5677 13.4772 22.3251 13.3945 24.0005H13.3936Z"
        fill="white"
      />
      <Path
        d="M11.8741 14.9883C13.5436 14.9883 14.897 13.6628 14.897 12.0278C14.897 10.3928 13.5436 9.06738 11.8741 9.06738C10.2045 9.06738 8.85107 10.3928 8.85107 12.0278C8.85107 13.6628 10.2045 14.9883 11.8741 14.9883Z"
        fill="white"
      />
    </G>
  </Svg>
);
