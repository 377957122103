import React, { useState } from "react";
import { View, Image, TouchableOpacity } from "react-native";
import { checkMarkGreen, colorBackground } from "../../utils/colors";
import { CheckIcon } from "react-native-heroicons/solid";
import { isEmptyString } from "../../utils/validation";

interface OctagonProps {
  size: number;
  image: string;
  selected: boolean;
  onPress: () => void;
}

function Octagon({ size, image, selected, onPress }: OctagonProps) {
  const [imageSrc, setImageSrc] = useState<string>(image);
  const fallbackImage =
    "https://firebasestorage.googleapis.com/v0/b/spheremvp.appspot.com/o/og%2Fbroken-image-fallback-3.png?alt=media&token=603d5ccb-4d45-4866-8d5a-2b149a8657fe";

  function handleErrorImage(): void {
    // NOTE: Purposely not setting the fallback image here
    // because we want to show an empty spot for empty Collectibles.
    if (isEmptyString(imageSrc)) return;
    // NOTE: Should only show the fallback image if <Image> fails to load
    // an actual image url.
    setImageSrc(fallbackImage);
  }
  return (
    <TouchableOpacity
      style={{
        width: size,
        height: size,
        zIndex: 0,
        overflow: "hidden",
        position: "relative",
        marginHorizontal: 10
      }}
      onPress={onPress}
    >
      <View
        style={{
          width: size,
          height: size,
          transform: [{ rotate: "45deg" }],
          overflow: "hidden"
        }}
      >
        <Image
          source={{
            uri: image
          }}
          style={{
            flex: 1,
            width: "100%",
            height: "100%",
            transform: [{ rotate: "-45deg" }]
          }}
          onError={handleErrorImage}
        />
      </View>
      {selected && (
        <View
          style={{
            height: 20,
            width: 20,
            backgroundColor: checkMarkGreen,
            position: "absolute",
            borderWidth: 2,
            borderRadius: 100,
            borderColor: colorBackground,
            right: 0,
            bottom: 0,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CheckIcon size={14} color="white" />
        </View>
      )}
    </TouchableOpacity>
  );
}

export default Octagon;
