import React from "react";
import { Text, View } from "../Themed";
import { Modal as RNModal } from "react-native";
import {
  colorBackgroundTransparent,
  colorCardBackground,
  colorWhiteAlpha80
} from "../../utils/colors";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
const Modal = ({
  visible,
  title,
  message,
  buttonLabel,
  onConfirm,
  onCancel,
  cancelLabel = "Cancel",
  modalWidth = "50%",
  modalHeight = undefined,
  modalBackgroundColor = colorCardBackground,
  titleFontSize = 24,
  textColor = colorWhiteAlpha80,
  messageFontSize = 16
}: {
  visible: boolean;
  title: string;
  message?: string;
  buttonLabel: string;
  onConfirm: () => void;
  onCancel?: () => void;
  cancelLabel?: string;
  modalWidth?: string;
  modalHeight?: string;
  modalBackgroundColor?: string;
  titleFontSize?: number;
  messageFontSize?: number;
  textColor?: string;
  primaryButtonColor?: string;
  secondaryButtonColor?: string;
}) => {
  return (
    <RNModal animationType="slide" transparent visible={visible}>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: colorBackgroundTransparent
        }}
      >
        <View
          style={{
            backgroundColor: modalBackgroundColor,
            borderRadius: 10,
            padding: 20,
            width: modalWidth,
            height: modalHeight,
            alignItems: "center"
          }}
        >
          <Text
            style={{
              fontSize: titleFontSize,
              fontWeight: "bold",
              marginBottom: 10,
              color: textColor
            }}
          >
            {title}
          </Text>
          <Text
            style={{
              fontSize: messageFontSize,
              textAlign: "center",
              marginBottom: 20,
              color: textColor
            }}
          >
            {message}
          </Text>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <PrimaryButton
              style={{ paddingHorizontal: 20, paddingVertical: 10 }}
              onPress={onConfirm}
            >
              <Text
                style={{
                  color: colorWhiteAlpha80,
                  fontWeight: "bold",
                  fontSize: 16
                }}
              >
                {buttonLabel}
              </Text>
            </PrimaryButton>
            {onCancel && (
              <SecondaryButton
                style={{
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  marginLeft: 10
                }}
                onPress={onCancel}
              >
                <Text
                  style={{
                    color: colorWhiteAlpha80,
                    fontWeight: "bold",
                    fontSize: 16
                  }}
                >
                  {cancelLabel}
                </Text>
              </SecondaryButton>
            )}
          </View>
        </View>
      </View>
    </RNModal>
  );
};

export default Modal;
