import React, { useEffect } from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { hideSplashScreen } from "../redux/slices/splash";
import { RootStackNavigatorParamList } from "../../types/navigationTypes";
import { setAttributes } from "../utils/crashlytics";
import {
  isWeb,
  isWebViewedOnAndroid,
  isWebViewedOnIOS
} from "../utils/platform";
import NotFoundScreen from "../screens/NotFoundScreen";
import SuccessScreen from "../screens/SuccessScreen";
import { BottomTabNavigator } from "./BottomTabNavigator";
import { DrawerTabNavigator } from "./DrawerTabNavigator";
import {
  firstTimeRecoveryFlow,
  setShowRecoveryFlowModal
} from "../redux/slices/user";

// ================ ROOT STACK ================ //

const RootStack = createNativeStackNavigator<RootStackNavigatorParamList>();

export function RootNavigator() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const displaySplash = useAppSelector((state) => state.splash.display);
  // This is used to share login credentials to the UnitySDK via postMessage()
  // This code will be running inside an iframe

  enum SDK_BRIDGE_METHOD {
    RETURN_CREDENTIALS = "RETURN-CREDENTIALS",
    REQUEST_CREDENTIALS = "REQUEST-CREDENTIALS",
    LOGOUT = "LOGOUT"
  }

  useEffect(() => {
    if (!isWeb()) return;

    if (!window.parent) return;

    if (user.accessToken) {
      // Login
      sendCredentialsMsg();
    } else {
      // Logout
      sendLogoutMsg();
    }

    function sendLogoutMsg() {
      const message = JSON.stringify({
        method: SDK_BRIDGE_METHOD.LOGOUT
      });

      // We could set target origin here for increased security
      window.parent.postMessage(message, "*");
    }

    function sendCredentialsMsg() {
      const message = JSON.stringify({
        method: SDK_BRIDGE_METHOD.RETURN_CREDENTIALS,

        // TODO We should refactor user to have the credentials objects, not just access token
        credentials: {
          access_token: user.accessToken,
          refresh_token: "",
          audience: "",
          client_id: "",
          expires_in: 99999999,
          oauthTokenScope: "",
          scope: "",
          token_type: "Bearer"
        }
      });

      // We could set target origin here for increased security
      window.parent.postMessage(message, "*");
    }

    window.addEventListener("message", function (event) {
      // Check if event.data is a string before attempting to trim and parse
      if (typeof event.data === "string") {
        const trimmedData = event.data.trim();

        // Now, we can safely check if it looks like a JSON string
        if (trimmedData.startsWith("{") || trimmedData.startsWith("[")) {
          try {
            const data = JSON.parse(trimmedData);

            if (data.method === SDK_BRIDGE_METHOD.REQUEST_CREDENTIALS) {
              sendCredentialsMsg();
            } else if (data.method === SDK_BRIDGE_METHOD.LOGOUT) {
              // TODO: Call the logout function
              // Note: A logout refactor is needed as there are multiple somewhat duplicated versions across the code
            }
          } catch (error) {
            // If parsing fails, it's likely not a JSON string, so we can silently ignore or handle as needed
            console.error("Failed to parse event data as JSON:", error);
          }
        }
      }
    });
  }, [user.accessToken]);

  useEffect(() => {
    if (user.isOnboard && user.uid !== undefined && displaySplash) {
      // onboarding status and auth loaded. displaySplash is there to make sure this code is runned only on startup
      if (user.uid !== null) setAttributes({ uid: user.uid });
      dispatch(hideSplashScreen());
    }
  }, [user.isOnboard, user.uid]);

  useEffect(() => {
    // in case the user logins and doesn't have the isPhoneAdded, we should ask him to add it
    // but it is optional so we use a flag to know if we should show the modal or not
    if (
      user.uid !== null &&
      !user.isPhoneAdded &&
      user.firstShowOFRecoveryFlowModal
    ) {
      dispatch(firstTimeRecoveryFlow(false));
      dispatch(setShowRecoveryFlowModal(true));
    }
  }, []);

  return (
    <RootStack.Navigator>
      <RootStack.Screen
        name="Root"
        component={
          !isWeb() || isWebViewedOnAndroid() || isWebViewedOnIOS()
            ? BottomTabNavigator
            : DrawerTabNavigator
        }
        options={{ headerShown: false }}
      />
      {!isWeb() && (
        <RootStack.Screen
          name="NotFound"
          component={NotFoundScreen}
          options={{ title: "Oops!" }}
        />
      )}
      <RootStack.Group
        screenOptions={{ presentation: "modal", headerShown: false }}
      >
        <RootStack.Screen name="Success" component={SuccessScreen} />
      </RootStack.Group>
    </RootStack.Navigator>
  );
}
