import React, { useCallback, useRef, useState, useEffect } from "react";
import { Dimensions, FlatList, TouchableOpacity } from "react-native";
import { Text, View } from "../../components/Themed";
import {
  colorBackground,
  colorDarkGray3,
  colorPrimary
} from "../../utils/colors";
import images from "../../assets/images";
import { ArrowLongRightIcon } from "react-native-heroicons/solid";
import { CircularProgressBase } from "react-native-circular-progress-indicator";
import useTimer from "../../hooks/useTimer";
import TabsIndicator from "../../components/AppPreviewScreen/TabsIndicator";
import Slide, { SlideData } from "../../components/AppPreviewScreen/Slide";
import i18n from "../../config/languageInternationalization";
import { setOnboardingStatus } from "../../redux/slices/userThunk";
import { OnboardingStatus } from "../../redux/slices/user";
import { useAppDispatch } from "../../redux/hooks";
import SafeAreaView from "../../components/ThemedComponents/SafeAreaView";

const { height: windowHeight } = Dimensions.get("screen");

const slideList: SlideData[] = [
  {
    id: "0",
    image: images.AppFeature01,
    title: i18n.t("appPreviewWelcome"),
    subtitle: i18n.t("appPreviewWelcomeSubtitle")
  },
  {
    id: "1",
    image: images.AppFeature02,
    title: i18n.t("appPreviewConnectBanks"),
    subtitle: i18n.t("appPreviewConnectBanksSubtitle")
  },
  {
    id: "2",
    image: images.AppFeature03,
    title: i18n.t("appPreviewTrackCurrencies"),
    subtitle: i18n.t("appPreviewTrackCurrenciesSubtitle")
  }
];

export default function AppPreviewScreen({ navigation }: { navigation: any }) {
  const dispatch = useAppDispatch();
  const flatListRef = useRef<FlatList>(null);
  const [index, setIndex] = useState(0);
  const indexRef = useRef(index);
  indexRef.current = index;
  const timePerTab = 3000; // Time each tab will be displayed in ms

  const onScroll = useCallback((event) => {
    const slideSize = event.nativeEvent.layoutMeasurement.width;
    const idx = event.nativeEvent.contentOffset.x / slideSize; // calculate index based on scroll position
    const roundIndex = Math.round(idx); // apparent new index based on idx
    const distance = Math.abs(roundIndex - idx); // Distance inside new tab slide being scrolled

    // Prevent one pixel triggering setIndex in the middle
    // of the transition. With this we have to scroll a bit
    // more to trigger the index change.
    const isNoMansLand = 0.4 < distance;

    if (roundIndex !== indexRef.current && !isNoMansLand) {
      setIndex(roundIndex);
    }
  }, []);

  const next = () => {
    if (index === slideList.length - 1) {
      dispatch(setOnboardingStatus(OnboardingStatus.LANDING_SCREEN));
      navigation.navigate("LandingScreen");
    } else {
      flatListRef.current?.scrollToIndex({ index: index + 1, animated: true });
    }
  };

  const { timeLeft, restart, stop } = useTimer(timePerTab, () => next());
  useEffect(() => restart(), [index]);

  useEffect(() => navigation.addListener("focus", () => restart()), []);
  useEffect(() => navigation.addListener("blur", () => stop()), []);

  return (
    <SafeAreaView>
      <FlatList
        ref={flatListRef}
        data={slideList}
        style={{
          height: windowHeight * 0.4,
          marginBottom: 30,
          marginTop: -150
        }}
        renderItem={useCallback(
          ({ item }) => (
            <Slide data={item} />
          ),
          []
        )}
        pagingEnabled
        horizontal
        bounces={false}
        onScroll={onScroll}
        initialNumToRender={0}
        showsHorizontalScrollIndicator={false}
        maxToRenderPerBatch={1}
        scrollEventThrottle={16}
        keyExtractor={(_, index) => String(index)}
        getItemLayout={(_, index) => ({
          length: windowHeight * 0.4,
          offset: windowHeight * 0.4 * index,
          index
        })}
      />
      <View style={{ flex: 1, alignItems: "center" }}>
        <View style={{ width: 60, height: 60, marginBottom: 30 }}>
          <CircularProgressBase
            value={timePerTab - timeLeft}
            maxValue={timePerTab}
            radius={30}
            activeStrokeColor="white"
            inActiveStrokeColor={colorDarkGray3}
            activeStrokeWidth={1}
            inActiveStrokeWidth={1}
            duration={200}
          />
          <TouchableOpacity
            onPress={next}
            style={{
              position: "absolute",
              backgroundColor: "white",
              height: 48,
              width: 48,
              borderRadius: 100,
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 34,
              top: 6,
              left: 6
            }}
          >
            <ArrowLongRightIcon size={24} color="black" />
          </TouchableOpacity>
        </View>

        <TabsIndicator currentIndex={index} />

        <TouchableOpacity onPress={() => navigation.navigate("LandingScreen")}>
          <Text
            style={{
              textDecorationLine: "none",
              color: colorPrimary,
              fontSize: 18
            }}
          >
            {i18n.t("appPreviewSkip")}
          </Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
}
