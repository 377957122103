import React, { useEffect } from "react";
import { Text, View } from "../components/Themed";
import FormControl from "../components/ThemedComponents/FormControl";
import SafeAreaView from "../components/ThemedComponents/SafeAreaView";
import {
  colorRed,
  gray400,
  gray600,
  gray700,
  gray800,
  primary600,
  white
} from "../utils/colors";
import Button from "../components/ThemedComponents/Button";
import { callFunction } from "../utils/server";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  setPinCodeSetup,
  setShowRecoveryFlowModal
} from "../redux/slices/user";
import {
  GreenCheckCircleIcon,
  SphereOneLogoWhite
} from "../components/InsertPinCode";
import { hideSplashScreen } from "../redux/slices/splash";
import i18n from "../config/languageInternationalization";
import { Keyboard, TouchableWithoutFeedback } from "react-native";
import { isWeb } from "../utils/platform";
import PasswordInput from "../components/ThemedComponents/PasswordInput";

enum PinCodeState {
  ADD,
  VERIFY,
  SUCCESS
}

export const PinCodeScreenContainer = ({
  children
}: {
  children: JSX.Element;
}) => {
  return (
    <TouchableWithoutFeedback
      onPress={() => {
        if (!isWeb()) Keyboard.dismiss();
      }}
    >
      <SafeAreaView style={{ width: "100%" }}>
        <View className="m-auto p-8 sm:max-w-[50%] xl:max-w-[33%] text-center">
          <View style={{ alignItems: "center" }}>
            <SphereOneLogoWhite />
          </View>
          <View
            style={{
              backgroundColor: gray800,
              borderWidth: 1,
              borderRadius: 10
            }}
          >
            <View
              style={{
                backgroundColor: gray800,
                borderRadius: 8,
                borderWidth: 1,
                borderColor: gray700,
                padding: 32
              }}
              className="lg:min-w-[550px]"
            >
              {children}
            </View>
          </View>
        </View>
      </SafeAreaView>
    </TouchableWithoutFeedback>
  );
};

export const PinCodeTitle = ({ text }: { text: string }) => {
  return (
    <Text
      style={{ color: white }}
      className="text-left text-xl font-medium leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
    >
      {text}
    </Text>
  );
};

export const PinCodeSubtitle = ({ text }: { text: string }) => {
  return (
    <Text
      style={{ color: gray400, textAlign: "justify" }}
      className="text-sm font-medium leading-tight tracking-tight text-gray-900 md:text-base dark:text-gray-400 text-left"
    >
      {text}
    </Text>
  );
};

export const PinCodePrimaryButton = ({
  text,
  handleOnPress,
  disabled,
  isLoading
}: {
  text: string;
  handleOnPress: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}) => {
  return (
    <Button
      style={{
        backgroundColor: primary600,
        borderRadius: 8
      }}
      onPress={handleOnPress}
      disabled={disabled}
      isLoading={isLoading}
    >
      {text}
    </Button>
  );
};
export const PinCodeSecondaryButton = ({
  text,
  handleOnPress,
  isDisabled
}: {
  text: string;
  handleOnPress: () => void;
  isDisabled?: boolean;
}) => {
  return (
    <Button
      style={{
        backgroundColor: gray600,
        borderRadius: 8
      }}
      isDisabled={isDisabled}
      onPress={handleOnPress}
    >
      {text}
    </Button>
  );
};

function AddPinCodeScreen() {
  const [pinCode, setPinCode] = React.useState("");
  const [verifyPin, setVerifyPin] = React.useState("");
  const [error, setError] = React.useState("");
  const [state, setState] = React.useState(PinCodeState.ADD);
  const { accessToken, isPhoneAdded } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    dispatch(hideSplashScreen());
  }, []);

  if (state === PinCodeState.ADD)
    return (
      <TouchableWithoutFeedback
        onPress={() => {
          if (!isWeb()) Keyboard.dismiss();
        }}
      >
        <PinCodeScreenContainer>
          <FormControl style={{ gap: 24 }}>
            <PinCodeTitle text={i18n.t("addPinCodeTitle")} />
            <PinCodeSubtitle text={i18n.t("addPinCodeSubtitle")} />
            <PasswordInput
              value={pinCode ?? ""}
              onChangeText={(e) => {
                if (pinCode.length <= 4 && !isNaN(Number(e))) setPinCode(e);
              }}
              maxLength={4}
              keyboardType="numeric"
            />
            <PinCodePrimaryButton
              text={i18n.t("authScreensContinue")}
              disabled={pinCode.length !== 4 || loading}
              handleOnPress={() => {
                setState(PinCodeState.VERIFY);
              }}
            />
          </FormControl>
        </PinCodeScreenContainer>
      </TouchableWithoutFeedback>
    );

  if (state === PinCodeState.VERIFY) {
    return (
      <TouchableWithoutFeedback
        onPress={() => {
          if (!isWeb()) Keyboard.dismiss();
        }}
      >
        <PinCodeScreenContainer>
          <FormControl style={{ gap: 24 }}>
            <PinCodeTitle text={i18n.t("addPinCodeConfirmTitle")} />
            <PinCodeSubtitle text={i18n.t("addPinCodeConfirmSubtitle")} />
            <PasswordInput
              value={verifyPin ?? ""}
              onChangeText={(e) => {
                if (verifyPin.length <= 4 && !isNaN(Number(e))) {
                  setVerifyPin(e);
                }
              }}
              maxLength={4}
              keyboardType="numeric"
            />
            {error ? <Text style={{ color: colorRed }}>{error}</Text> : <></>}
            <PinCodePrimaryButton
              text="Continue"
              isLoading={loading}
              disabled={verifyPin.length !== 4 || loading}
              handleOnPress={async () => {
                try {
                  setLoading(true);
                  if (pinCode === verifyPin) {
                    if (!accessToken) throw new Error("No access token");

                    await callFunction({
                      url: "/user/pincode",
                      accessToken,
                      method: "POST",
                      data: { pinCode }
                    });

                    setState(PinCodeState.SUCCESS);
                  } else setError(i18n.t("addPinCodeError"));
                } catch (e: any) {
                  setError(e.message);
                  setPinCode("");
                  setVerifyPin("");
                  setState(PinCodeState.ADD);
                } finally {
                  setLoading(false);
                }
              }}
            />
            <PinCodeSecondaryButton
              text="Back"
              handleOnPress={() => {
                setPinCode("");
                setVerifyPin("");
                setError("");
                setState(PinCodeState.ADD);
              }}
            />
          </FormControl>
        </PinCodeScreenContainer>
      </TouchableWithoutFeedback>
    );
  }
  if (state === PinCodeState.SUCCESS) {
    return (
      <TouchableWithoutFeedback
        onPress={() => {
          if (!isWeb()) Keyboard.dismiss();
        }}
      >
        <PinCodeScreenContainer>
          <FormControl style={{ gap: 24 }}>
            <View style={{ alignItems: "center" }}>
              <GreenCheckCircleIcon />
            </View>
            <PinCodeTitle text={i18n.t("congratulations")} />

            <PinCodeSubtitle text={i18n.t("addPinCodeSuccess")} />
            <PinCodePrimaryButton
              text={i18n.t("authScreensContinue")}
              handleOnPress={() => {
                dispatch(setPinCodeSetup(true));
                !isPhoneAdded && dispatch(setShowRecoveryFlowModal(true));
              }}
            />
          </FormControl>
        </PinCodeScreenContainer>
      </TouchableWithoutFeedback>
    );
  }
  return <></>;
}

export default AddPinCodeScreen;
