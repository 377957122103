import React, { useState } from "react";
import { View, Switch } from "react-native";
import Header from "../../components/Header";
import {
  colorDarkGray4,
  colorGreenSwitch,
  colorCardBackground
} from "../../utils/colors";
import { Text } from "../../components/Themed";
import PrimaryButton from "../../components/ThemedComponents/PrimaryButton";
import { useAppDispatch } from "../../redux/hooks";
import i18n from "../../config/languageInternationalization";
import { setOnboardingStatus } from "../../redux/slices/userThunk";
import { OnboardingStatus, setSupportBiometric } from "../../redux/slices/user";
import {
  getBiometricPreference,
  hasTouchIdOrFaceIdConfigured,
  setBiometricPreference
} from "../../utils/localStorage";
import { Alert } from "../../utils/Alert";
import SafeAreaView from "../../components/ThemedComponents/SafeAreaView";
import { AppDispatch } from "../../redux/store";

export const checkPinCodeStatus = async (dispatch: AppDispatch) => {
  const hasEnrolled = await hasTouchIdOrFaceIdConfigured();
  const hasBiometric = await getBiometricPreference();
  if (hasEnrolled && !hasBiometric) {
    // has facial or fingerprint data configured, NOT including Device PIN and haven't allowed biometric before
    dispatch(setOnboardingStatus(OnboardingStatus.PINCODE));
  } else {
    // if no facial or fingerprint data configured or has allowed biometric before, then no need to go to PinCodeSetup
    dispatch(setSupportBiometric(hasBiometric)); // set in redux
    await setBiometricPreference(hasBiometric); // update biometric preference in AsyncStorage
    dispatch(setOnboardingStatus(OnboardingStatus.ONBOARD));
  }
};

export default function PinCodeSetup() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [allowBiometric, setAllowBiometric] = useState<boolean>(false);
  async function toggleBiometricPermission(enable: boolean) {
    if (enable === true) {
      setAllowBiometric(true);
      getBiometricPermissions();
    } else {
      setAllowBiometric(false);
    }
  }

  // Checks whether user allows Biometric Validation in AsyncStorage
  async function getBiometricPermissions() {
    // check if device already has Biometric Data (FaceID/TouchID)
    const hasEnrolled = await hasTouchIdOrFaceIdConfigured();
    if (hasEnrolled) {
      // if device already has biometric data, prompt user to allow or don't allow
      Alert.alert(
        i18n.t("pinCodeSetupAllowPrompt"),
        i18n.t("pinCodeSetupAllowPromptDescription"),
        [
          {
            text: i18n.t("pinCodeSetupDontAllow"),
            onPress: async () => setAllowBiometric(false)
          },
          {
            text: i18n.t("pinCodeSetupAllow"),
            onPress: async () => setAllowBiometric(true)
          }
        ],
        { cancelable: false }
      );
    } else {
      // if device doesn't have Biometric Data already set up, automatically set to false for now
      setAllowBiometric(false);
    }
  }

  const onContinue = async () => {
    setLoading(true);
    dispatch(setSupportBiometric(allowBiometric));
    await setBiometricPreference(allowBiometric);
    dispatch(setOnboardingStatus(OnboardingStatus.ONBOARD));
  };

  return (
    <SafeAreaView>
      <Header
        title={i18n.t("pinCodeSetupTitle")}
        styles={{ justifyContent: "center" }}
        onlyTitle
      />
      <View style={{ padding: 16 }}>
        <Text>{i18n.t("pinCodeSetupDescription")}</Text>
        <View
          style={{
            height: 55,
            backgroundColor: colorCardBackground,
            borderRadius: 14,
            justifyContent: "space-between",
            alignItems: "center",
            padding: 12,
            flexDirection: "row",
            marginTop: 16,
            marginBottom: 32
          }}
        >
          <Text style={{ fontSize: 16 }}>
            {i18n.t("pinCodeSetupUseBiometrics")}
          </Text>
          <Switch
            trackColor={{ false: colorDarkGray4, true: colorGreenSwitch }}
            thumbColor={"white"}
            ios_backgroundColor={colorDarkGray4}
            value={allowBiometric}
            onValueChange={(v) => toggleBiometricPermission(v)}
          />
        </View>
        <PrimaryButton isLoading={loading} onPress={onContinue}>
          {i18n.t("pinCodeSetupContinue")}
        </PrimaryButton>
      </View>
    </SafeAreaView>
  );
}
