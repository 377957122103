import AsyncStorage from "@react-native-async-storage/async-storage";
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";
import {
  AuthConfiguration,
  authorize,
  refresh,
  revoke as RNLogout
} from "react-native-app-auth";
import { Alert } from "react-native";
import {
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_URL,
  REACT_APP_AUTH_AUDIENCE,
  REACT_APP_AUTH0_DOMAIN_MOBILE
} from "@env";
import { recordError } from "../utils/crashlytics";
import { getUserOIDC, setUserOIDC } from "../utils/localStorage";
import i18n from "../config/languageInternationalization";

// TODO: Quick-fix: Re-purposing `REACT_APP_AUTH0_DOMAIN_MOBILE` for mobile specifically. Will need to change later.
const clientIssuer = REACT_APP_AUTH0_DOMAIN_MOBILE;
const clientId = REACT_APP_AUTH_CLIENT_ID;
const oryProjectUrl = REACT_APP_AUTH_URL;
const redirectUrl = "com.sphereglobal.wallet://login/callback";

export const authDefaultConfig: AuthConfiguration = {
  issuer: clientIssuer,
  serviceConfiguration: {
    authorizationEndpoint: `${oryProjectUrl}/oauth2/auth`,
    tokenEndpoint: `${oryProjectUrl}/oauth2/token`
  },
  clientId,
  redirectUrl,
  scopes: ["openid", "email", "offline_access"],
  additionalParameters: {
    audience: REACT_APP_AUTH_AUDIENCE,
    prompt: "login",
    returnTo: "com.sphereglobal.com://login/callback"
  }
};

export interface UserState {
  access_token: string;
  id_token: string;
  refresh_token: string;
}

interface AuthContextAppInterface {
  user: UserState | null | undefined;
  setUser: Dispatch<SetStateAction<UserState | null | undefined>>;
  loading: boolean;
}
const AuthContextApp = createContext<AuthContextAppInterface>({
  user: undefined,
  setUser: () => {},
  loading: false
});

export const AuthProviderApp = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<UserState | null | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getUserOIDC()
      .then((data: string | null) => {
        if (!data) return setUser(null);
        const user = JSON.parse(data);
        const { access_token } = user;
        if (!access_token) return setUser(null);
        return setUser(user);
      })
      .catch((e: any) => {
        recordError(e, "useAuthContextApp.tsx");
        setUser(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <AuthContextApp.Provider value={{ user, setUser, loading }}>
      {children}
    </AuthContextApp.Provider>
  );
};

export const useAuthApp = () => {
  const { user, setUser, loading } = useContext(AuthContextApp);
  const logout = async () => {
    if (!user) return;
    if (!user.refresh_token) {
      return setUser(null);
    }
    try {
      const response = (await RNLogout(
        { issuer: REACT_APP_AUTH_URL, clientId: REACT_APP_AUTH_CLIENT_ID },
        {
          tokenToRevoke: user.refresh_token,
          sendClientId: true
        }
      )) as any;
      if (response && response.ok) setUser(null);
      else throw new Error("Error login out, please try again");
    } catch (e: any) {
      recordError(e, "useAuthContextApp.tsx");
      Alert.alert(
        "Error",
        e.message || i18n.t("transactionsScreenNoResultsMatchedSubtitle")
      );
    }
  };

  const login = async (config: AuthConfiguration = authDefaultConfig) => {
    try {
      const result = await authorize(config);
      if (result) {
        const { accessToken } = result;
        if (!accessToken) setUser(null);
        const userToSave = {
          ...result,
          access_token: accessToken,
          refresh_token: result.refreshToken,
          id_token: result.idToken
        };
        await setUserOIDC(userToSave).then(() => setUser(userToSave));
      }
    } catch (e: any) {
      setUser(null);
      logout();
      recordError(e, "useAuthContextApp.tsx");
    }
  };

  const getCredentials = async () => {
    if (!user || !user.access_token) return null;
    return Promise.resolve({
      accessToken: user.access_token,
      refreshToken: user.refresh_token,
      idToken: user.id_token
    });
  };

  const refreshToken = async () => {
    if (!user || !user.refresh_token) return null;
    let newUser = null;
    try {
      newUser = await refresh(authDefaultConfig, {
        refreshToken: user.refresh_token
      });
      if (!newUser) {
        await AsyncStorage.clear();
        return setUser(null);
      } else {
        const newRefreshToken = newUser.refreshToken;
        if (newRefreshToken === null) {
          await AsyncStorage.clear();
          return setUser(null);
        }

        const newUserDataToSave = {
          ...newUser,
          access_token: newUser.accessToken,
          id_token: newUser.idToken,
          refresh_token: newRefreshToken // this is to avoid typescript errors
        };
        await setUserOIDC(newUserDataToSave);
        setUser(newUserDataToSave);
        return newUserDataToSave;
      }
    } catch (e: any) {
      recordError(e, "useAuthContextApp.tsx");
      if (!e?.message?.includes("refresh token is invalid")) {
        Alert.alert("Error", e.message || "Please try again");
      }
    }
  };

  return { user, login, logout, getCredentials, refreshToken, loading };
};
