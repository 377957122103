import React from "react";

export default function Timer(milliseconds: number, timeOutHandler: () => any) {
  const [timeLeft, setTimeLeft] = React.useState(milliseconds);
  const [stopped, setStopped] = React.useState(false);

  React.useEffect(() => {
    if (stopped) return;
    if (!timeLeft) return timeOutHandler();

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 100);
    }, 100);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return {
    timeLeft,
    restart: () => {
      setStopped(false);
      setTimeLeft(milliseconds);
    },
    stop: () => setStopped(true),
    resume: () => setStopped(false)
  };
}
