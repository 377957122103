import React from "react";
import {
  TextInputProps,
  TextInput as DefaultTextInput,
  StyleProp,
  ViewStyle,
  TextStyle,
  Platform
} from "react-native";
import {
  colorAlert,
  colorInputBackground,
  colorInputBorder,
  colorInputPlaceholder,
  colorPrimary
} from "../../utils/colors";
import { View } from "../Themed";
import { isWeb } from "../../utils/platform";

export interface InputProps extends TextInputProps {
  style?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<TextStyle>;
  isInvalid?: boolean;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
}

export default function Input({
  style,
  inputStyle,
  leftIcon,
  rightIcon,
  isInvalid,
  ...otherProps
}: InputProps) {
  const [isFocus, setIsFocus] = React.useState(false);
  const handleFocus = (state: boolean) => setIsFocus(state);

  return (
    <View
      className="bg-gray-800"
      style={[
        {
          borderRadius: 8,
          padding: 10,
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 1,
          borderColor:
            isInvalid === true
              ? colorAlert
              : isFocus
              ? colorPrimary
              : colorInputBorder
        },
        isWeb() && {
          width: "100%",
          alignSelf: "center"
        },
        style
      ]}
    >
      {leftIcon && <View style={{ marginRight: 10 }}>{leftIcon}</View>}
      <DefaultTextInput
        onFocus={() => handleFocus(true)}
        onBlur={() => handleFocus(false)}
        autoCorrect={false}
        placeholderTextColor={colorInputPlaceholder}
        style={[
          {
            fontSize: 14,
            color: "white",
            flex: 1,
            fontFamily: "DMSans-Regular",
            ...Platform.select({
              web: { outline: "none" }
            })
          },
          inputStyle
        ]}
        {...otherProps}
      />
      {rightIcon && <View style={{ marginLeft: 10 }}>{rightIcon}</View>}
    </View>
  );
}
