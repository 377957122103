import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { HomeStackNavigatorParamList } from "../../types/navigationTypes";
import HomeScreen from "../screens/HomeScreen";
import { CashoutNavigator } from "./CashoutNavigator";
import NftCheckout from "../screens/NftCheckout";
import CheckoutOnrampScreen from "../screens/CheckoutOnrampScreen";

const HomeStack = createNativeStackNavigator<HomeStackNavigatorParamList>();

export function HomeNavigator() {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={{ headerShown: false }}
      />
      <HomeStack.Group>
        <HomeStack.Screen
          name="NftCheckoutScreen"
          component={NftCheckout}
          options={{ headerShown: false }}
        />
        <HomeStack.Screen
          name="CashoutScreen"
          component={CashoutNavigator}
          options={{ headerShown: false }}
        />
        <HomeStack.Screen
          name="CheckoutOnramp"
          component={CheckoutOnrampScreen}
          options={{ headerShown: false }}
        />
      </HomeStack.Group>
    </HomeStack.Navigator>
  );
}
