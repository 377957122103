import React from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { EllipsisVerticalIcon } from "react-native-heroicons/solid";
import { ImageSourcePropType } from "react-native";

import {
  colorLabelTextSecondary,
  colorBorder,
  colorLightGrayElipsisIcon
} from "../../utils/colors";
import constantsLayout from "../../constants/Layout";

export interface bankProps {
  bank: string;
  ubi: string;
  image: ImageSourcePropType;
  onPress?: () => any;
  onPressIcon?: () => any;
  removeBottomBorder?: boolean;
}

export default function LinkedBankAccount({
  bank,
  ubi,
  image,
  onPress,
  onPressIcon,
  removeBottomBorder
}: bankProps) {
  return (
    <TouchableOpacity onPress={onPress} style={styles.flex}>
      {/* TODO
      add images in assets for the banks we are going to use and then, like in cripto, have the
      bankIcons[bank] to provide the source for the image?*/}
      <Image source={image} height={40} width={40} style={styles.image} />

      <View
        style={{
          ...styles.dataContainer,
          borderBottomWidth: removeBottomBorder ? 0 : 0.5
        }}
      >
        <View style={styles.widthForAccounts}>
          <Text style={styles.title}>{ubi}</Text>
          <Text style={styles.subtitles}>{bank}</Text>
        </View>
        <TouchableOpacity onPress={onPressIcon}>
          <EllipsisVerticalIcon
            style={styles.mr16}
            size={24}
            color={colorLightGrayElipsisIcon}
          />
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  flex: { flexDirection: "row", alignItems: "center" },
  title: { color: "white", fontSize: 14 },
  subtitles: { color: colorLabelTextSecondary, fontSize: 12 },
  widthForAccounts: { height: 42, width: constantsLayout.window.width * 0.6 },
  image: {
    height: 40,
    width: 40,
    borderRadius: 7,
    marginHorizontal: 16,
    backgroundColor: "white"
  },
  dataContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomColor: colorBorder,
    paddingVertical: 11
  },
  mr16: { marginRight: 16 }
});
