import React from "react";
import Header from "../components/Header";
import { Text, View } from "../components/Themed";
import Input from "../components/ThemedComponents/Input";
import i18n from "../config/languageInternationalization";
import { callFunction, formatErrorMessage } from "../utils/server";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Alert } from "../utils/Alert";
import { recordError } from "../utils/crashlytics";
import { getLinkedAccounts } from "../redux/slices/userThunk";
import {
  LinkedAccountsParamsList,
  WalletActions
} from "../../types/navigationTypes";
import FormControl from "../components/ThemedComponents/FormControl";
import PrimaryButton from "../components/ThemedComponents/PrimaryButton";
import SafeAreaView from "../components/ThemedComponents/SafeAreaView";
import { colorBackground } from "../utils/colors";
import PrivateKey from "../components/privateKey";
import { verifyJWT } from "../utils/verifyJWT";
import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import Toast from "react-native-toast-message";
import InsertPinCode from "../components/InsertPinCode";

const WalletActionScreen = ({
  route,
  navigation
}: {
  route: RouteProp<LinkedAccountsParamsList, "WalletActionScreen">;
  navigation: NativeStackNavigationProp<
    LinkedAccountsParamsList,
    "WalletActionScreen"
  >;
}) => {
  const { walletId, action, readOnly } = route.params;
  const [privateKey, setPrivateKey] = React.useState<string>("");
  const [starkPrivateKey, setStarkPrivateKey] = React.useState<string>("");
  const [showPrivateKey, setShowPrivateKey] = React.useState(false);
  const [showStarkPrivateKey, setShowStarkPrivateKey] = React.useState(false);
  const [label, setLabel] = React.useState<string>("");
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const { accessToken, isOnboard } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const updateWalletLabel = async (label: string) => {
    setSubmitLoading(true);
    if (!label) {
      Alert.alert(i18n.t("pleaseEnterLabel"));
      setSubmitLoading(false);
      return;
    }
    try {
      await callFunction({
        url: `/user/wallets/${walletId}`,
        accessToken: accessToken as string,
        method: "PUT",
        data: { label }
      });
      await dispatch(getLinkedAccounts());
      setSubmitLoading(false);
      Alert.alert(i18n.t("labelUpdatedSuccessfully"), undefined, [
        {
          text: i18n.t("ok"),
          onPress: () => {
            isOnboard
              ? navigation.navigate("LinkedAccounts")
              : navigation.goBack();
          }
        }
      ]);
    } catch (error: any) {
      Alert.alert(i18n.t("sorryContactSupport"), error);
      recordError(error, "PrivateKey.tsx - renameUserWallet");
    } finally {
      setSubmitLoading(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        if (
          action === WalletActions.DELETE ||
          action === WalletActions.PRIVATE_KEY
        ) {
          await handlePrivateKeyOrDeleteAction();
        }
      } catch (error: any) {
        recordError(error, "PrivateKey.tsx - removeUserWallet");
        if (!error?.contains("401")) {
          Alert.alert(i18n.t("sorryContactSupport"), error);
        }
      }
    })();
  }, []);

  const [modal, setModal] = React.useState(false);

  const getPkAndDeleteAccount = async (wrappedDek: string) => {
    const data = await getUserPrivateKey(
      wrappedDek,
      walletId,
      accessToken,
      readOnly
    );
    if (!data?.data) throw new Error("Error in getting private key");
    data?.data?.privateKey && setPrivateKey(data.data.privateKey);
    data?.data?.starkPrivateKey &&
      setStarkPrivateKey(data.data.starkPrivateKey);
    if (action === WalletActions.DELETE) {
      await callFunction({
        url: `/user/wallets/${walletId}` as string,
        accessToken: accessToken as string,
        method: "DELETE"
      });
      dispatch(getLinkedAccounts());
      Toast.show({
        text1: i18n.t("walletRemovedSuccessfully"),
        position: "top"
      });
    }
  };

  const handlePrivateKeyOrDeleteAction = async () => {
    setModal(true);
  };

  return (
    <View style={{ backgroundColor: colorBackground, flex: 1 }}>
      <SafeAreaView style={{ backgroundColor: "transparent" }}>
        <Header
          title={i18n.t("profileScreenLinkedAccounts")}
          styles={{ marginBottom: 10 }}
        />

        {action === WalletActions.DELETE ||
        action === WalletActions.PRIVATE_KEY ? (
          <View style={{ marginHorizontal: 16 }}>
            {privateKey && (
              <PrivateKey
                isPrivateKey={true}
                isLoading={false}
                showPrivateKey={showPrivateKey}
                privateKey={privateKey}
                setShowPrivateKey={setShowPrivateKey}
              />
            )}
            {starkPrivateKey && (
              <PrivateKey
                isPrivateKey={false}
                isLoading={false}
                showPrivateKey={showStarkPrivateKey}
                privateKey={starkPrivateKey}
                setShowPrivateKey={setShowStarkPrivateKey}
              />
            )}
          </View>
        ) : (
          <View style={{ marginHorizontal: 16 }}>
            <FormControl style={{ marginVertical: 24 }}>
              <Text>{i18n.t("renameWalletNameDescription")}</Text>
              <Input
                placeholder={i18n.t("renameWalletNamePlaceholder")}
                style={{ marginTop: 10 }}
                value={label}
                focusable
                maxLength={20}
                onChangeText={(text) => setLabel(text)}
              />
            </FormControl>
            <PrimaryButton
              isLoading={submitLoading}
              isDisabled={submitLoading}
              onPress={() => {
                updateWalletLabel(label);
              }}
            >
              {i18n.t("linkedAccountsContinue")}
            </PrimaryButton>
          </View>
        )}
        {modal && (
          <InsertPinCode
            closeModal={() => setModal(false)}
            text="get your private key so you can save it before you delete this wallet from this account"
            cb={getPkAndDeleteAccount}
            target={TargetOperation.GET_PRIVATE_KEY}
            handleError={handleError}
          />
        )}
      </SafeAreaView>
    </View>
  );
};

export default WalletActionScreen;

export enum TargetOperation {
  ADD_WALLET = "ADD_WALLET",
  GET_PRIVATE_KEY = "GET_PRIVATE_KEY",
  CHECK_STARK_PRIVATE_KEY = "CHECK_STARK_PRIVATE_KEY"
}

export const getUserDek = async (
  accessToken: string,
  data: { target: TargetOperation | string; pinCode: string }
) => {
  try {
    const { data: DEK } = await callFunction({
      url: "/createOrRecoverAccount",
      accessToken: accessToken as string,
      method: "POST",
      data
    });
    return DEK;
  } catch (error) {
    throw new Error(formatErrorMessage(error, "getUserDek"));
  }
};

const getUserPrivateKey = async (
  DEK: string,
  walletId: string,
  accessToken: string | undefined,
  readOnly: boolean
) => {
  if (readOnly || !accessToken) return;
  const { data: response } = await callFunction({
    url: `/user/wallets/privateKey/${walletId}`,
    accessToken: accessToken,
    method: "POST",
    data: { DEK }
  });
  const data = await verifyJWT(response);
  return { data };
};

const handleError = () => {
  Alert.alert(i18n.t("somethingWentWrongImportingPK"), i18n.t("contactUs"));
};
