import React from "react";
import { Text, View } from "../Themed";
import {
  Image,
  SectionList,
  TouchableOpacity,
  useWindowDimensions
} from "react-native";
import i18n from "../../config/languageInternationalization";
import ValueListElement from "../ValueListElement";
import { gray700, gray800 } from "../../utils/colors";
import { colorLightGrayText } from "../../utils/colors";
import { useAppSelector } from "../../redux/hooks";
import { isWeb, pwaMaxWidth } from "../../utils/platform";
import images from "../../assets/images/index";
import { Balance } from "../../../types/balances";

interface AccountsTabProps {
  setToken: React.Dispatch<React.SetStateAction<Balance>>;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccountsTab = ({ setModal, setToken }: AccountsTabProps) => {
  const { walletsP2PBalance } = useAppSelector((state) => state.user);
  const dimensions = useWindowDimensions();

  return (
    <View style={{ flex: 1 }}>
      <SectionList
        sections={walletsP2PBalance ?? []}
        style={{ flex: 1, alignSelf: isWeb() ? "center" : undefined }}
        scrollEnabled={true}
        showsVerticalScrollIndicator={true}
        renderSectionHeader={({ section: { title } }) => (
          <Text className="mt-4 mb-0.5">{title}</Text>
        )}
        renderItem={({ item }: { item: Balance }) => {
          return (
            <TouchableOpacity
              onPress={() => {
                setToken({ ...item });
                setModal(true);
              }}
              style={{
                backgroundColor: gray800,
                borderColor: gray700,
                borderWidth: 1,
                marginVertical: 5,
                borderRadius: 16,
                paddingVertical: 12,
                paddingHorizontal: 12,
                height: 70,
                width: isWeb() ? pwaMaxWidth(dimensions) - 50 : undefined
              }}
            >
              <ValueListElement
                icon={
                  item.tokenMetadata.logoURI.length
                    ? item.tokenMetadata.logoURI
                    : "https://i.postimg.cc/mrqxZ7H6/Fallback-Coin.png"
                }
                currency={item.tokenMetadata.symbol}
                amount={item.amount}
                price={item.price}
              />
            </TouchableOpacity>
          );
        }}
        ListEmptyComponent={
          <View
            style={{
              alignItems: "center"
            }}
          >
            <Image
              source={images.Search}
              style={{
                height: 250,
                width: 250
              }}
            />
            <Text
              style={{
                marginHorizontal: 16,
                marginVertical: 12,
                fontSize: 18,
                color: colorLightGrayText
              }}
            >
              {i18n.t("homeScreenHaventAssets")}
            </Text>
          </View>
        }
      />
    </View>
  );
};

export default AccountsTab;
