import React from "react";
import { colorPrimary, colorPrimaryDisabled } from "../../utils/colors";
import Button, { ButtonProps } from "./Button";
import { useAppSelector } from "../../redux/hooks";
import { isColorLight } from "../../utils/validation";

export default function PrimaryButton(props: ButtonProps) {
  const { style, textStyle, isDisabled, isLoading, ...otherProps } = props;

  const { merchantTheme } = useAppSelector((state) => state.theme);

  const getTextColor = (isDisabled: boolean | undefined) => {
    if (isDisabled) return colorPrimary;
    else {
      if (isColorLight(merchantTheme?.primaryColor)) return "black";
      else return "white";
    }
  };

  return (
    <Button
      rounded
      isDisabled={isDisabled}
      isLoading={isLoading}
      className={`${
        isDisabled
          ? "bg-blue-800"
          : merchantTheme?.primaryColor ?? "bg-blue-600"
      }`}
      style={[style]}
      textStyle={[
        {
          color: getTextColor(isDisabled),
          fontWeight: "700",
          fontSize: 18
        },
        textStyle
      ]}
      {...otherProps}
    />
  );
}
