import { SphereToken } from "../src/redux/slices/tokens";
import { SupportedChains } from "../src/utils/supportedChains";
import { DisplayPaymentMethod } from "../src/utils/validatePaymentMethods";

export enum OnrampProviders {
  BANXA = "banxa",
  SARDINE = "sardine",
  WERT = "wert",
  ONRAMPER = "onramper",
  SPHEREONE = "sphereone"
}
export interface OnrampGenericResult {
  label: string;
  value: string;
}

export interface OnrampPaymentMethod {
  label: string;
  value: string;
  min: number;
  max: number;
  icon?: React.ReactElement | string;
  provider: OnrampProviders;
}

export interface OnrampGetTokenPrice {
  fiatSymbol: string;
  tokenAmount?: string;
  token: SphereToken;
  chain: string;
  paymentMethod: string;
  countryCode: string;
}

export interface OnrampGetTokenPriceResponse {
  tokenAmount: string;
  tokenSymbol: string;
  tokenPrice: string;
  fiatAmount: string;
  fiatSymbol: string;
  networkFee: string;
  paymentMethod: string;
  transactionFee: string;
}

export interface OnrampGetTokenType {
  fiat: string;
  countryCode: string;
  token: string;
  chain: string;
  tokenAddress: string;
}

export interface OnrampGetFiatsResult {
  isCurrencySupported: boolean;
  fiats: OnrampGenericResult[];
}

export interface OnrampCreateOrder {
  customerId: string;
  email: string;
  phone: string;
  walletAddress: string;
  fiatAmount?: string;
  tokenAmount?: string;
  fiatSymbol: string;
  tokenSymbol: string;
  chain: string;
  paymentMethod: string;
  withFiat?: boolean;
}

export interface WertData {
  partner_id: string;
  click_id: string;
  origin: string;
  blockchain: string;
  commodities: string;
  commodity: string;
  theme: "dark" | undefined;
  extra: {
    wallets: {
      name: string;
      blockchain: string;
      network: string;
      address: string;
    }[];
  };
}

export interface WertThemeOptions {
  color_background?: string;
  color_buttons: string;
  color_buttons_text?: string;
  color_secondary_buttons?: string;
  color_secondary_buttons_text?: string;
  color_main_text: string;
  color_secondary_text?: string;
  color_icons: string;
  color_links?: string;
  color_success?: string;
  color_warning?: string;
  color_error?: string;
}

export enum OnrampTokenType {
  SUPPORTED = "supported",
  CUSTOM = "custom"
}

export interface OnrampTokenTypeResponse {
  tokenType: OnrampTokenType;
  customToken?: CustomOnrampToken;
}
export interface CustomOnrampToken {
  name: string;
  chain: string;
  address: string;
  decimals: number;
  logoURI: string;
  symbol: string;
  onRampTo: SphereToken;
}

export interface CheckoutState {
  countryFlag: string | null;
  countryCode: string | null;
  currency: string | null;
  method: DisplayPaymentMethod | null;
  validAmount: boolean;
  amountError: {
    type: "minimum" | "maximum" | "invalid";
    message: string;
    validAmount?: number;
  } | null;
  onrampAmount: string | null;
  tokenToOnramp: string | null;
  onrampUrl: string | null;
  showWert: WertData | null;
  buyStatus: "success" | "error" | null;
  buyStatusMessage: string | null;
  buyLoading: boolean;
}

export interface QuoteData {
  fiatAmount: string;
  tokenAmount: string;
  fee: number;
}

export type AmountError = {
  type: "minimum" | "maximum" | "invalid";
  message: string;
  validAmount?: number | undefined;
};

export interface OnrampPreferences {
  chain: SupportedChains | null;
  token: SphereToken | null;
  wallet: string | null;
  walletLabel: string | null;
}

export interface OnrampPreferencesStorage {
  chain: SupportedChains | null;
  token: string | null;
  wallet: string | null;
  walletLabel: string | null;
}
