import React from "react";
import {
  TextInputProps,
  View,
  TextInput as DefaultTextInput,
  TouchableOpacity,
  Platform
} from "react-native";
import { EyeIcon, EyeSlashIcon } from "react-native-heroicons/outline";
import {
  colorAlert,
  colorInputBorder,
  colorLightGrayText,
  colorPrimary,
  darkGray
} from "../../utils/colors";

interface PasswordInputProps extends TextInputProps {
  isInvalid?: boolean;
}

export default function PasswordInput(props: PasswordInputProps) {
  const { style, isInvalid, ...otherProps } = props;

  const [hidePassword, setHidePassword] = React.useState(true);
  const [isFocus, setIsFocus] = React.useState(false);
  const handleFocus = (state: boolean) => setIsFocus(state);

  return (
    <View
      style={[
        {
          backgroundColor: darkGray,
          borderRadius: 8,
          borderWidth: 1,
          borderColor:
            isInvalid === true
              ? colorAlert
              : isFocus
              ? colorPrimary
              : colorInputBorder,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between"
        },
        style
      ]}
    >
      <DefaultTextInput
        style={{
          flex: 1,
          padding: 10,
          fontSize: 14,
          color: "white",
          justifyContent: "center",
          alignItems: "center",
          ...Platform.select({
            web: { outline: "none" }
          })
        }}
        onFocus={() => handleFocus(true)}
        onBlur={() => handleFocus(false)}
        autoCapitalize="none"
        secureTextEntry={hidePassword}
        autoCorrect={false}
        returnKeyType="send"
        placeholderTextColor={colorLightGrayText}
        {...otherProps}
      />
      <TouchableOpacity
        onPress={() => setHidePassword(!hidePassword)}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          marginRight: 10
        }}
      >
        {hidePassword ? (
          <EyeSlashIcon size={24} color={colorLightGrayText} />
        ) : (
          <EyeIcon size={24} color={colorLightGrayText} />
        )}
      </TouchableOpacity>
    </View>
  );
}
