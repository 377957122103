import React from "react";
import { Text, Modal as RNModal, TouchableOpacity } from "react-native";
import {
  blue500,
  blue600,
  colorBlurBackground,
  gray400,
  gray50,
  gray600,
  gray700,
  gray800,
  green300,
  green900,
  red300,
  red900,
  white,
  xIconInputColor
} from "../utils/colors";
import { View } from "./Themed";
import { TxStatus } from "../../types/transfer";
import { ArrowDownTrayIcon, XMarkIcon } from "react-native-heroicons/solid";
import { custom_transaction_DateTimeFormat } from "../utils/times";
import format from "date-fns/format";
import { TxType } from "../screens/TransactionsScreen";
import i18n from "../config/languageInternationalization";
import { es } from "date-fns/locale";

interface TransactionModalProps {
  visible: boolean;
  transactionId: string;
  transactionType: TxType;
  status: TxStatus;
  name: string;
  date: Date;
  amount: string | number;
  onClose: () => void;
  onReportIssue: () => void;
}

export default function TransactionDetailModal({
  visible,
  name,
  amount,
  date,
  status,
  transactionId,
  transactionType,
  onClose,
  onReportIssue
}: TransactionModalProps) {
  function getTextColor(status: TxStatus): string {
    return status === TxStatus.SUCCESS
      ? green300
      : status === TxStatus.FAILURE
      ? red300
      : "white";
  }

  function getBackgroundColor(status: TxStatus): string {
    return status === TxStatus.SUCCESS
      ? green900
      : status === TxStatus.FAILURE
      ? red900
      : blue500;
  }

  function getStatusLabel(status: TxStatus): string {
    switch (status) {
      case TxStatus.PENDING:
        return i18n.t("transactionsScreenPENDING");
      case TxStatus.SUCCESS:
        return i18n.t("transactionsScreenSUCCESS");
      case TxStatus.FAILURE:
        i18n.t("transactionsScreenFAILURE");
      case TxStatus.PROCESSING:
        return "Proccesing";
      default:
        return "";
    }
  }

  function getDisplayTimeDate(time: Date, dateTimeFormat: string) {
    if (time === undefined || time === null) return "";
    const dateTimeString = format(time, dateTimeFormat, {
      locale: i18n.locale === "en" ? undefined : es
    });

    return dateTimeString;
  }

  return (
    <RNModal transparent animationType="slide" visible={visible}>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: colorBlurBackground
        }}
      >
        <View
          style={{
            position: "relative",
            backgroundColor: gray800,
            borderRadius: 8,
            padding: 32,
            width: "40%"
          }}
        >
          <TouchableOpacity
            style={{ position: "absolute", top: 10, right: 10 }}
            onPress={onClose}
          >
            <XMarkIcon color={xIconInputColor} size={24} />
          </TouchableOpacity>
          {/* Title */}
          <View style={{ marginBottom: 20 }}>
            <Text style={{ color: gray50, fontSize: 20 }}>
              {i18n.t("transactionModalDetail")}
            </Text>
          </View>
          {/* Transaction details */}
          <View>
            {/* Amount */}
            <View
              style={{
                paddingVertical: 8,
                paddingHorizontal: 16,
                borderRadius: 8,
                backgroundColor: gray700,
                marginBottom: 24
              }}
            >
              <Text style={{ color: white, fontSize: 16 }}>
                {transactionType === TxType.SEND && "-"}
                {amount}
              </Text>
            </View>
            {/* Name and date */}
            <View
              style={{
                paddingVertical: 10,
                borderColor: gray700,
                borderBottomWidth: 1
              }}
            >
              <Text style={{ color: white, fontSize: 16, marginBottom: 2 }}>
                {name}
              </Text>
              <Text style={{ color: gray400, fontSize: 12 }}>
                {getDisplayTimeDate(date, custom_transaction_DateTimeFormat)}
              </Text>
            </View>
            {/* Status */}
            <View
              style={{
                paddingVertical: 10,
                borderColor: gray700,
                borderBottomWidth: 1,
                justifyContent: "space-between",
                flexDirection: "row"
              }}
            >
              <Text style={{ color: gray400, fontSize: 16 }}>
                {i18n.t("transactionsScreenStatus")}
              </Text>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 6,
                  paddingVertical: 2,
                  paddingHorizontal: 10,
                  backgroundColor: getBackgroundColor(status)
                }}
              >
                <Text style={{ color: getTextColor(status) }}>
                  {getStatusLabel(status)}
                </Text>
              </View>
            </View>
            {/* Transaction */}
            <View
              style={{
                paddingVertical: 10,
                borderColor: gray700,
                borderBottomWidth: 1,
                justifyContent: "space-between",
                flexDirection: "row",
                flexWrap: "wrap"
              }}
            >
              <Text style={{ color: gray400, fontSize: 16 }}>
                {i18n.t("transactionModalId")}
              </Text>
              <Text
                style={{
                  color: blue500
                }}
              >
                {transactionId}
              </Text>
            </View>
            {/* Statement */}
            <View
              style={{
                paddingVertical: 10,
                borderColor: gray700,
                borderBottomWidth: 1,
                justifyContent: "space-between",
                flexDirection: "row"
              }}
            >
              <Text style={{ color: gray400, fontSize: 16 }}>
                {i18n.t("transactionsScreenStatement")}
              </Text>
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Text style={{ color: blue500 }}>
                  {i18n.t("transactionsScreenDownload")}
                </Text>
                <ArrowDownTrayIcon size={18} color={blue500} />
              </TouchableOpacity>
            </View>
            {/* Fees */}
            <View
              style={{
                paddingVertical: 10,
                borderColor: gray700,
                borderBottomWidth: 1,
                justifyContent: "space-between",
                flexDirection: "row",
                marginBottom: 24
              }}
            >
              <Text style={{ color: gray400, fontSize: 16 }}>
                {i18n.t("transactionsScreenFees")}
              </Text>
              <Text style={{ color: gray400 }}>
                {i18n.t("transactionsScreenNoFee")}
              </Text>
            </View>
            {/* Report an issue */}
            {status === TxStatus.FAILURE && (
              <TouchableOpacity
                onPress={onReportIssue}
                style={{
                  borderColor: gray600,
                  borderWidth: 1,
                  paddingVertical: 12,
                  paddingHorizontal: 20,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 8
                }}
              >
                <Text style={{ color: gray400 }}>
                  {i18n.t("transactionsScreenContactSupport")}
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
      </View>
    </RNModal>
  );
}
