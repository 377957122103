import { useRef } from "react";

export const useDebounce = (delay: number) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debounce = (callback: (value: string) => void, value: string) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      callback(value);
    }, delay);
  };

  return debounce;
};
