import React from "react";
import { Text, View } from "../../components/Themed";
import Header from "../../components/Header";
import { ProfileStackNavigatorParamList } from "../../../types/navigationTypes";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  blue600,
  colorBackground,
  colorLightGray,
  gray700,
  gray800
} from "../../utils/colors";
import FormControl from "../../components/ThemedComponents/FormControl";
import Input from "../../components/ThemedComponents/Input";
import PrimaryButton from "../../components/ThemedComponents/PrimaryButton";
import i18n from "../../config/languageInternationalization";
import { recordError } from "../../utils/crashlytics";
import {
  isEvmPrivateKeyLength,
  isMnemonic,
  isMnemonicValid,
  isSolPrivateKeyLength
} from "../../utils/web3";
import { isWeb } from "../../utils/platform";
import { Alert } from "../../utils/Alert";
import {
  Keyboard,
  TouchableWithoutFeedback,
  useWindowDimensions
} from "react-native";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getLinkedAccounts } from "../../redux/slices/userThunk";
import { callFunction, formatErrorMessage } from "../../utils/server";
import {
  AddIMXStarkPrivateKey,
  ModalStateEnumIMX
} from "../../components/AddIMXStarkPrivateKey";
import SafeAreaView from "../../components/ThemedComponents/SafeAreaView";
import { TargetOperation } from "../WalletActionScreen";
import InsertPinCode from "../../components/InsertPinCode";

type RecoverWalletScreenProps = NativeStackScreenProps<
  ProfileStackNavigatorParamList,
  "RecoverWalletScreen"
>;

export default function RecoverWalletScreen(props: RecoverWalletScreenProps) {
  const dimensions = useWindowDimensions();
  const [recovery, setRecovery] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const { accessToken } = useAppSelector((state) => state.user);
  const [immutableModal, setImmutableModal] = React.useState(false);
  const [
    paramsToAddImutableStarkPrivateKey,
    setParamsToAddImutableStarkPrivateKey
  ] = React.useState<{
    accessToken: string;
    publicKey: string;
    recoveryKey: { mnemonic: string } | { privateKey: string };
  }>({ accessToken: "", publicKey: "", recoveryKey: { privateKey: "" } });
  const [modal, setModal] = React.useState(ModalStateEnumIMX.INIT);
  const [starkPK, setStarkPK] = React.useState("");
  const [starkPKError, setStarkPKError] = React.useState("");

  const [modalPinCode, setModalPinCode] = React.useState(false);

  const onSubmit = async () => {
    setErrorMessage("");
    try {
      if (!accessToken) throw new Error("No access token");

      if (isMnemonic(recovery) && !isMnemonicValid(recovery)) {
        setErrorMessage(
          i18n.t("recoverWalletScreenMnemonicInvalidLengthError")
        );
      } else if (
        !isMnemonic(recovery) &&
        !isSolPrivateKeyLength(recovery) &&
        !isEvmPrivateKeyLength(recovery)
      ) {
        setErrorMessage(i18n.t("recoverWalletScreenPkInvalidLengthError"));
      } else {
        setLoading(true);
        setModalPinCode(true);
      }
    } catch (e: any) {
      setLoading(false);
      recordError(e, "RecoverWallet.tsx");
      Alert.alert(i18n.t("errorImportingWallet"));
    }
  };

  const [wrappedDek, setWrappedDek] = React.useState("");
  const callback = async (wrappedDek: string) => {
    if (!accessToken) return;
    setWrappedDek(wrappedDek);
    const reqParams: any = { DEK: wrappedDek };
    if (isMnemonic(recovery)) reqParams.mnemonic = recovery;
    else reqParams.privateKey = recovery;
    const { data, error } = await callFunction({
      url: "/importWallet",
      method: "POST",
      data: { ...reqParams },
      accessToken
    });
    dispatch(getLinkedAccounts());
    if (error) throw new Error(error);
    if (data) {
      setRecovery("");
      const recoveryKey = {
        mnemonic: reqParams?.mnemonic,
        privateKey: reqParams?.privateKey
      };
      if (data.isRegisteredOnImmutable) {
        setParamsToAddImutableStarkPrivateKey({
          accessToken,
          recoveryKey,
          publicKey: data.wallets[0].address
        });
        setImmutableModal(true);
        setModalPinCode(false);
      } else {
        Alert.alert(i18n.t("addIMXStarkPrivateKeyWalletSuccessfullyImported"));
        props.navigation.pop();
      }
    } else {
      Alert.alert(i18n.t("errorImportingWallet"));
      recordError(error, "RecoverWallet.tsx");
    }
    setLoading(false);
  };

  const handlerError = async (error: any) => {
    recordError(error, "RecoverWallet.tsx");
    Alert.alert(
      i18n.t("errorImportingWallet"),
      formatErrorMessage(error, "Recover wallet")
    );
    setLoading(false);
  };

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        if (!isWeb()) Keyboard.dismiss();
      }}
    >
      <SafeAreaView
        style={
          isWeb()
            ? {
                backgroundColor: gray800,
                marginVertical: 20,
                borderWidth: 1,
                borderColor: gray700,
                borderRadius: 16
              }
            : { flex: 1, backgroundColor: colorBackground }
        }
      >
        <Header
          titleStyles={{ fontSize: 20, fontWeight: "700" }}
          accessibilityLabel="recoverWalletHeaderButton"
          title={i18n.t("recoverWalletScreenHeaderTitle")}
        />

        <View
          style={{
            flex: 1,
            backgroundColor: gray800,
            margin: 10,
            padding: 10
          }}
        >
          {immutableModal ? (
            AddIMXStarkPrivateKey({
              ...paramsToAddImutableStarkPrivateKey,
              closeModal: () => {
                setImmutableModal(false);
                setStarkPK("");
                setModal(ModalStateEnumIMX.INIT);
                props.navigation.pop();
              },
              modal,
              setModal,
              starkPK,
              setStarkPK,
              starkPKError,
              setStarkPKError,
              loading,
              setLoading,
              dimensions,
              wrappedDek
            })
          ) : (
            // Add private key form
            <View
              style={
                isWeb()
                  ? { backgroundColor: gray800, flex: 1 }
                  : { paddingHorizontal: 16 }
              }
            >
              <Text style={{ color: colorLightGray }}>
                {i18n.t("recoverWalletScreenRecoverYourWallet")}
              </Text>

              <FormControl
                style={{ marginVertical: 24 }}
                label={i18n.t(
                  "recoverWalletScreenRecoveryPhraseOrPrivateKeyLabel"
                )}
                isInvalid={errorMessage !== ""}
                errorMessage={errorMessage}
              >
                <Input
                  value={recovery}
                  onChangeText={(t) => setRecovery(t)}
                  textAlignVertical="top"
                  multiline={true}
                  numberOfLines={4}
                  style={{ backgroundColor: gray700 }}
                />
              </FormControl>
            </View>
          )}
          <PrimaryButton
            disabled={recovery.length === 0}
            isLoading={loading}
            onPress={onSubmit}
            style={{ borderRadius: 8, backgroundColor: blue600 }}
          >
            {i18n.t("recoverWalletScreenContinue")}
          </PrimaryButton>
        </View>

        {modalPinCode && (
          <InsertPinCode
            cb={callback}
            handleError={handlerError}
            target={TargetOperation.ADD_WALLET}
            closeModal={() => setModalPinCode(false)}
            text="import a wallet"
            parentSetLoading={setLoading}
          />
        )}
      </SafeAreaView>
    </TouchableWithoutFeedback>
  );
}
