import { ImageProps, Platform } from "react-native";

let FastImage: React.ComponentType<ImageProps> | null;

if (Platform.OS !== "web") {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  FastImage = require("react-native-fast-image").default;
} else {
  FastImage = null;
}

export default FastImage;
