import React, { useState } from "react";
import {
  useFetchCharge,
  useFetchQuote,
  useGetRouteEstimation
} from "../../hooks/useOnRamp";
import { useAppSelector } from "../../redux/hooks";
import { isSphereOneMethod, isWert } from "../../utils/onramp";
import {
  StyledImage,
  StyledText,
  StyledTouchableOpacity,
  StyledView
} from "../ThemedComponents/NativeWindStyledWrappers";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  InformationCircleIcon
} from "react-native-heroicons/outline";
import { CheckoutSkeleton, priceSkeletonLayout } from "./CheckoutSkeleton";
import i18n from "../../config/languageInternationalization";
import {
  BatchType,
  formatBatch
} from "../../screens/Transfer/P2PTransferDetailScreen";
import { Batch, ParsedRoute } from "../../../types/transfer";
import USDLogo from "../../assets/images/CryptoCoins/USD.png";
import Tooltip from "react-native-walkthrough-tooltip";
import { gray600 } from "../../utils/colors";

export const PaymentDetails = ({ totalUsd }: { totalUsd?: number }) => {
  const {
    fiat: countryFiat,
    tokenAmount,
    token,
    paymentMethod,
    customOnrampSurplus,
    tokenToOnramp
  } = useAppSelector((state) => state.checkoutOnramp);
  const fiat = isWert(paymentMethod?.provider) ? "USD" : countryFiat;
  const { data: quoteData, isLoading: isQuoteLoading } = useFetchQuote();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [surplusTooltipVisible, setSurplusTooltipVisible] =
    useState<boolean>(false);
  const isSphereOne = isSphereOneMethod(paymentMethod?.provider);
  const { data: routeEstimation, isError: routeError } =
    useGetRouteEstimation();
  const transactionFee = quoteData?.fee || routeEstimation?.estimation?.costUsd;
  const parsedBatches = () => {
    if (!routeEstimation) return;
    const route = JSON.parse(routeEstimation.estimation.route) as ParsedRoute;
    const batches = route?.batches || route;
    return batches
      .filter((b: Batch) => !b.description.includes("credit")) // // omit the batch to add gas credit
      .map((b: Batch) => formatBatch(b.description, b.actions));
  };

  const batches = parsedBatches();

  function splitMinutesToMinutesAndSeconds(minutes: number): string {
    const totalSeconds = minutes * 60;
    const minutesPart = Math.floor(totalSeconds / 60);
    const secondsPart = Math.round(totalSeconds % 60);

    return `${minutesPart} min ${secondsPart} sec`;
  }
  return (
    <StyledView className="flex-col w-full">
      <StyledTouchableOpacity
        onPress={() => {
          setShowDetails(!showDetails);
        }}
      >
        <StyledView
          className={`${
            !showDetails ? "border-gray-600" : "border-blue-500"
          } h-8 p-3 py-2 bg-gray-800 rounded-lg border-[1px] justify-center items-center space-x-0.5 inline-flex`}
        >
          <StyledText
            className={`text-2xs font-medium leading-none ${
              !showDetails ? "text-gray-400" : "text-blue-400"
            }`}
          >
            {!showDetails
              ? i18n.t("checkoutShowPaymentDetails")
              : i18n.t("checkoutHidePaymentDetails")}
            {!showDetails ? (
              <ChevronDownIcon
                size={12}
                style={{ marginLeft: 8 }}
                color="rgb(156 163 175)"
                strokeWidth={4}
              />
            ) : (
              <ChevronUpIcon
                size={12}
                style={{ marginLeft: 8 }}
                color="rgb(96 165 250)"
                strokeWidth={4}
              />
            )}
          </StyledText>
        </StyledView>
      </StyledTouchableOpacity>
      {/* Payment Details */}
      {showDetails && (
        <StyledView className="w-full flex-col py-1">
          {/* You Pay */}
          <StyledView className="flex-row justify-between mt-4 w-full">
            <StyledText className="text-white text-sm font-bold">
              {i18n.t("checkoutYouPay")}
            </StyledText>
            <CheckoutSkeleton
              layout={priceSkeletonLayout}
              isLoading={!isSphereOne && isQuoteLoading}
              alignItems="flex-end"
            >
              <StyledText className="text-white text-sm">
                {isSphereOne
                  ? `US$ ${totalUsd?.toFixed(2)}`
                  : `${fiat} ${quoteData?.fiatAmount ?? "-"}`}
              </StyledText>
            </CheckoutSkeleton>
          </StyledView>
          {/* You Receive */}
          <StyledView className="flex-row justify-between w-full mt-5">
            <StyledText className="text-white text-sm font-bold">
              {i18n.t("checkoutYouReceive")}
            </StyledText>
            <StyledText className="text-white text-sm">
              {tokenAmount} {""}
              {token?.symbol}
            </StyledText>
          </StyledView>
          {/* Surplus */}
          {customOnrampSurplus && (
            <StyledView className="flex-row justify-between w-full mt-5">
              <StyledText className="text-white text-sm font-bold">
                {i18n.t("checkoutSurplus")}
              </StyledText>
              <StyledView className="flex-row items-center">
                <Tooltip
                  isVisible={surplusTooltipVisible}
                  content={
                    <StyledView>
                      <StyledText className="text-white text-xs">
                        {i18n.t("checkoutSurplusMessage", {
                          customOnrampSurplus,
                          token: token?.symbol,
                          tokenAmount,
                          onrampToken: tokenToOnramp?.onRampTo?.symbol,
                          pwaDomain: process.env.PWA_DOMAIN
                        })}
                      </StyledText>
                    </StyledView>
                  }
                  placement="top"
                  onClose={() => setSurplusTooltipVisible(false)}
                  disableShadow={true}
                  contentStyle={{ backgroundColor: gray600 }}
                >
                  <StyledTouchableOpacity
                    onPress={() => setSurplusTooltipVisible(true)}
                  >
                    <InformationCircleIcon
                      size={16}
                      style={{ marginRight: 4 }}
                      color="rgb(156 163 175)"
                      strokeWidth={3}
                    />
                  </StyledTouchableOpacity>
                </Tooltip>
                <StyledText className="text-white text-sm">
                  {`${customOnrampSurplus}%`}
                </StyledText>
              </StyledView>
            </StyledView>
          )}
          {/* Time estimation */}
          {routeEstimation?.estimation?.timeEstimate && (
            <StyledView className="flex-row justify-between mt-5 w-full">
              <StyledText className="text-white text-sm font-bold">
                {i18n.t("checkoutEstimatedTime")}
              </StyledText>
              <StyledText className="text-white text-sm">
                {splitMinutesToMinutesAndSeconds(
                  routeEstimation.estimation.timeEstimate +
                    (!isSphereOne ? 30 : 0) ?? 0
                )}
              </StyledText>
            </StyledView>
          )}
          {/* Operations */}
          {batches && !routeError && (
            <StyledView className="flex-column justify-between mt-5 w-full">
              <StyledText className="text-white text-sm font-bold">
                {i18n.t("checkoutOperations")}
              </StyledText>
              <RouteBatchesStepper batches={batches} />
            </StyledView>
          )}
          <StyledView className="h-px w-full my-4 bg-gray-600" />
          <StyledView className="flex-row justify-between w-full">
            <StyledText className="text-white text-sm font-bold">
              {i18n.t("checkoutSubtotal")}
            </StyledText>
            <CheckoutSkeleton
              layout={priceSkeletonLayout}
              isLoading={!isSphereOne && isQuoteLoading}
              alignItems="flex-end"
            >
              <StyledText className="text-white text-sm">
                {isSphereOne
                  ? `US$ ${totalUsd?.toFixed(2)}`
                  : quoteData?.fiatAmount
                  ? `${fiat} ${(
                      Number(quoteData?.fiatAmount) - Number(quoteData?.fee)
                    ).toFixed(2)}`
                  : `${fiat} -`}
              </StyledText>
            </CheckoutSkeleton>
          </StyledView>
          {/* Transaction Fee */}
          {!!transactionFee && (
            <StyledView className="flex-row justify-between mt-5 w-full">
              <StyledText className="text-white text-sm font-bold">
                {i18n.t("checkoutTransactionFees")}
              </StyledText>
              <CheckoutSkeleton
                layout={priceSkeletonLayout}
                isLoading={!isSphereOne && isQuoteLoading}
                alignItems="flex-end"
              >
                <StyledText className="text-white text-sm">
                  {isSphereOne ? "US$" : fiat} {transactionFee.toFixed(2)}
                </StyledText>
              </CheckoutSkeleton>
            </StyledView>
          )}
        </StyledView>
      )}
      {/* Total */}
      <StyledView className="flex-row justify-between mt-5 w-full">
        <StyledView className="flex-row items-center">
          <InformationCircleIcon
            size={16}
            style={{ marginRight: 4 }}
            color="rgb(156 163 175)"
            strokeWidth={3}
          />
          <StyledText className="text-white text-sm font-bold">
            {i18n.t("checkoutTotal")}
          </StyledText>
        </StyledView>
        <CheckoutSkeleton
          layout={priceSkeletonLayout}
          isLoading={!isSphereOne && isQuoteLoading}
          alignItems="flex-end"
        >
          <StyledText className="text-white text-sm">
            {isSphereOne && totalUsd
              ? `US$ ${(totalUsd + (transactionFee ?? 0)).toFixed(2)}`
              : `${fiat} ${quoteData?.fiatAmount ?? "-"}`}
          </StyledText>
        </CheckoutSkeleton>
      </StyledView>
    </StyledView>
  );
};

export const ChargeDetails = () => {
  const { fiat: countryFiat, paymentMethod } = useAppSelector(
    (state) => state.checkoutOnramp
  );
  const fiat = isWert(paymentMethod?.provider) ? "USD" : countryFiat;
  const { charge } = useFetchCharge();
  const { data: quoteData, isLoading: isQuoteLoading } = useFetchQuote();
  const isSphereOne = isSphereOneMethod(paymentMethod?.provider);
  return (
    <>
      <StyledImage
        className="w-36 h-48 rounded-lg self-center"
        source={
          charge?.items && charge.items[0]?.image
            ? {
                uri: charge.items[0].image
              }
            : USDLogo
        }
      />
      <StyledView className="self-stretch py-3 justify-between items-center flex-row">
        {charge?.items && (
          <StyledView className="flex-col justify-start items-start">
            <StyledText className="text-white text-sm font-semibold">
              {charge?.items[0]?.name}
            </StyledText>
          </StyledView>
        )}
        <StyledView className="flex-col py-3 justify-center items-end">
          <StyledText className="text-white text-sm font-normal">
            {charge?.total} {""}
            {charge?.symbol}
          </StyledText>
          <CheckoutSkeleton
            layout={priceSkeletonLayout}
            isLoading={!isSphereOne && isQuoteLoading}
            alignItems="flex-end"
          >
            <StyledText className="text-gray-400 text-xs font-normal ">
              {isSphereOne
                ? `US$ ${charge?.totalUsd.toFixed(2)}`
                : `${fiat} ${quoteData?.fiatAmount ?? "-"}`}
            </StyledText>
          </CheckoutSkeleton>
        </StyledView>
      </StyledView>
    </>
  );
};

export const RouteBatchesStepper = ({
  batches
}: {
  batches:
    | {
        type: BatchType;
        title: string | null;
        operations: string[];
      }[]
    | undefined;
}) => {
  return (
    <StyledView className="flex-col w-full pt-4">
      {batches?.map((b, i) => (
        <Step
          key={i}
          number={i + 1}
          type={b.type}
          description={b.operations[0]}
        />
      ))}
    </StyledView>
  );
};

const Step = ({
  number,
  type,
  description
}: {
  number: number;
  type: BatchType;
  description: string;
}) => {
  return (
    <StyledView
      className={`flex-row items-center text-blue-500 space-x-2.5 mb-4`}
    >
      <StyledView
        className={`flex-row items-center justify-center w-6 h-6 border-[1px] border-blue-500 rounded-full`}
      >
        <StyledText>{number}</StyledText>
      </StyledView>
      <StyledView className="flex-end">
        <StyledText className="font-medium text-xs">{type}</StyledText>
        <StyledText className="text-xs">{description}</StyledText>
      </StyledView>
    </StyledView>
  );
};
