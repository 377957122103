import i18n from "../../../config/languageInternationalization";
import { FilterOption, ProfileButtonType } from "../../../../types/filterTypes";

export const ProfileActionSheetButtonOptions: FilterOption[] = [
  {
    name: i18n.t("profileScreenEmail"),
    type: ProfileButtonType.EMAIL
  },
  {
    name: i18n.t("profileScreenCancel"),
    type: ProfileButtonType.CANCEL
  }
];
