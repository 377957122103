import { styled } from "nativewind";
import { Text, View } from "../Themed";
import {
  KeyboardAvoidingView,
  SafeAreaView,
  ScrollView,
  TextInput,
  TouchableOpacity,
  Image
} from "react-native";

export const StyledTextInput = styled(TextInput);
export const StyledView = styled(View);
export const StyledScrollView = styled(ScrollView);
export const StyledTouchableOpacity = styled(TouchableOpacity);
export const StyledText = styled(Text);
export const StyledKeyboardAvoidingView = styled(KeyboardAvoidingView);
export const StyledSafeAreaView = styled(SafeAreaView);
export const StyledImage = styled(Image);
