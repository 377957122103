export enum SupportedChains {
  ETHEREUM = "ETHEREUM",
  SOLANA = "SOLANA",
  POLYGON = "POLYGON",
  GNOSIS = "GNOSIS",
  OPTIMISM = "OPTIMISM",
  IMMUTABLE = "IMMUTABLE",
  AVALANCHE = "AVALANCHE",
  BINANCE = "BINANCE",
  ARBITRUM = "ARBITRUM",
  FANTOM = "FANTOM",
  BLANK = "",
  BASE = "BASE",
  EOSEVM = "EOSEVM",
  FLOW = "FLOW",
  KLAYTN = "KLAYTN",
  DFK = "DFK"
}
export enum SupportedChainsCode {
  ETHEREUM = "ETH",
  SOLANA = "SOL",
  POLYGON = "MATIC",
  BINANCE = "BSC",
  AVALANCHE = "AVAX-C",
  ARBITRUM = "ARB",
  FANTOM = "FTM",
  OPTIMISM = "OP",
  IMMUTABLE = "IMX",
  GNOSIS = "GNO",
  BLANK = ""
}
export class SupportedChainsManager {
  private chainName: SupportedChains = SupportedChains.BLANK;
  private chainSymbol = SupportedChainsCode.BLANK;

  constructor(chain: SupportedChains) {
    this.chainName = chain;
    this.determineSymbol(chain);
  }
  // private because it's mutating the private property `chainSymbol`
  private determineSymbol(chain: SupportedChains) {
    switch (chain) {
      case SupportedChains.ETHEREUM:
        return (this.chainSymbol = SupportedChainsCode.ETHEREUM);
      case SupportedChains.POLYGON:
        return (this.chainSymbol = SupportedChainsCode.POLYGON);
      case SupportedChains.SOLANA:
        return (this.chainSymbol = SupportedChainsCode.SOLANA);
      case SupportedChains.BINANCE:
        return (this.chainSymbol = SupportedChainsCode.BINANCE);
      case SupportedChains.AVALANCHE:
        return (this.chainSymbol = SupportedChainsCode.AVALANCHE);
      case SupportedChains.ARBITRUM:
        return (this.chainSymbol = SupportedChainsCode.ARBITRUM);
      case SupportedChains.FANTOM:
        return (this.chainSymbol = SupportedChainsCode.FANTOM);
      case SupportedChains.OPTIMISM:
        return (this.chainSymbol = SupportedChainsCode.OPTIMISM);
      case SupportedChains.IMMUTABLE:
        return (this.chainSymbol = SupportedChainsCode.IMMUTABLE);
      case SupportedChains.GNOSIS:
        return (this.chainSymbol = SupportedChainsCode.GNOSIS);
      default:
        return (this.chainSymbol = SupportedChainsCode.BLANK);
    }
  }

  name() {
    return this.chainName;
  }
  code() {
    return this.chainSymbol;
  }
}
export const supportedChains = Object.values(SupportedChains).map(
  (chain) => new SupportedChainsManager(chain)
);
