import AsyncStorage from "@react-native-async-storage/async-storage";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { EditUserState } from "../../../types/accounts";
import { cleanStorage } from "../../utils/localStorage";
import { Collectable, OnboardingStatus } from "./user";

import { LinkedAccountWallet } from "../../../types/accounts";
import {
  removeTokenFromDocument,
  setAsyncStorageNotificationSettings
} from "../../utils/pushNotifications";
import { Balance } from "../../../types/balances";
import { RootState } from "../store";
import {
  callFunction,
  getFundsAvailable,
  getProfileImageApiCall,
  getUser,
  getUserWallets,
  updateDocumentData
} from "../../utils/server";
import BigNumber from "bignumber.js";
import { normalizeDecimals } from "../../utils/numberWithCommas";
import { getFrontFinanceBalance } from "../../utils/frontFinance";
import { recordError } from "../../utils/crashlytics";
import { isWeb } from "../../utils/platform";
import { isTokenExpired } from "../../utils/verifyJWT";

// GET user info
export const getUserInfo = createAsyncThunk(
  "get/userinfo",
  async (
    { authUser, accessToken }: { authUser: any; accessToken: string },
    { rejectWithValue }
  ) => {
    try {
      if (authUser === null)
        return {
          uid: null,
          email: null
        };
      const user = await getUser(accessToken);
      if (!user)
        return {
          uid: null,
          email: null
        };
      return {
        name: user?.name,
        phone: user?.phone,
        countryCode: user?.countryCode,
        countryFlag: user?.countryFlag,
        uid: user.uid,
        email: user.email,
        username: user?.username,
        currency: user?.currency,
        pfp: user?.profilePicture,
        isMerchant: user?.isMerchant,
        accessToken: accessToken,
        isPinCodeSetup: user?.isPinCodeSetup ? true : false,
        isPhoneAdded: user?.isPhoneAdded ? true : false,
        reValidateWallet: user?.reValidateWallet
      };
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// GET profile image
export const getProfileImage = createAsyncThunk(
  "get/profileImage",
  async (_, { rejectWithValue, getState }) => {
    try {
      // get the thumbnail image
      const { data: thumbnailUrlImage, error } = await getProfileImageApiCall({
        type: "thumbnail",
        accessToken: (getState() as any).user.accessToken
      });
      if (error) throw new Error(error);
      return thumbnailUrlImage;
    } catch (err: any) {
      if (err.code === "storage/object-not-found") {
        try {
          // Thumbnail may not be available, let's try getting the original object before returning error
          // get the original image
          const { data: image, error } = await getProfileImageApiCall({
            accessToken: (getState() as any).user.accessToken
          });
          if (error) throw new Error(error);
          return image;
        } catch (e) {
          return rejectWithValue(err.response.data.message);
        }
      } else return rejectWithValue(err.response.data.message);
    }
  }
);

// SET Profile Image
export const setProfileImage = createAsyncThunk(
  "set/profileImage",
  async (url: string, { getState }) => {
    //
    // Update user profile picture with api call
    const { error } = await updateDocumentData({
      url: "/user",
      updateData: { profilePicture: url },
      accessToken: (getState() as any).user.accessToken
    });

    if (error) throw new Error(error);
    return url;
  }
);

// PUT user
export const updateUserInfo = createAsyncThunk(
  "user/updateUserInfo",
  async (updates: EditUserState, { rejectWithValue, getState }) => {
    try {
      const { error } = await updateDocumentData({
        url: "/user",
        updateData: updates,
        accessToken: (getState() as any).user.accessToken
      });
      if (error) throw new Error(error);

      return updates;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Auth signout and cleanup
export const signOut = createAsyncThunk(
  "user/signOut",
  async (_, { getState }) => {
    try {
      const accessToken = await (getState() as any).user.accessToken;
      if (!isTokenExpired(accessToken)) {
        await removeTokenFromDocument(accessToken);
      }
      await cleanStorage();
      if (isWeb()) {
        window.location.reload();
      }
    } catch (error) {
      recordError(error);
    }
  }
);

// Onboarding
export const setOnboardingStatus = createAsyncThunk(
  "user/setOnboardingStatus",
  async (data: OnboardingStatus) => {
    await AsyncStorage.setItem("@onboardingStatus", data);
    return data;
  }
);
export const loadOnboardingStatus = createAsyncThunk(
  "user/loadOnboardingStatus",
  async () => {
    const status = await AsyncStorage.getItem("@onboardingStatus");
    return status;
  }
);

// Get token prices
export const getTokenPrices = createAsyncThunk(
  "user/getTokenPrices",
  async () => {
    const url =
      "https://api.1inch.io/v5.0/1/quote?fromTokenAddress=0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee&toTokenAddress=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&amount=1000000000000000000";
    const response = await axios.get(url);
    const price = (response.data.toTokenAmount / Math.pow(10, 6)).toFixed();
    return price;
  }
);

// GET user linked accounts
export const getLinkedAccounts = createAsyncThunk(
  "user/getLinkedAccounts",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { data, error } = await getUserWallets({
        accessToken: (getState() as any).user.accessToken
      });
      if (error) {
        throw new Error(error);
      }
      const wallets: LinkedAccountWallet[] = data.map((w: any) => ({
        chains: w.chains,
        address: w.address,
        type: "wallet",
        id: w.address,
        isImported: w.isImported,
        label: w.label,
        readOnly: w.readOnly,
        missingStarkKey: w.missingStarkKey ?? false
      }));
      // TODO: Fetch other linked accounts
      return [...wallets];
    } catch (e: any) {
      return rejectWithValue(e.message || e.response.data.message);
    }
  }
);

// GET notifications config
export const getNotificationConfig = createAsyncThunk(
  "get/notificationAsyncStorage",
  async () => {
    return await AsyncStorage.getItem("@notifications");
  }
);
export const setNotificationsConfig = createAsyncThunk(
  "set/notificationAsyncStorage",
  async (data: boolean) => {
    await setAsyncStorageNotificationSettings(data);
    return data;
  }
);

// Fetch user collectables
export const getUserCollectables = createAsyncThunk(
  "user/getUserCollectables",
  async (_, { rejectWithValue, getState }) => {
    try {
      let collectibles = [] as Collectable[];
      const allNfts = {
        legit: [] as Collectable[],
        spam: [] as Collectable[]
      };

      const collectiblePromises = [
        callFunction({
          url: "/getNftsAvailable",
          accessToken: (getState() as any).user.accessToken,
          method: "GET"
        }),
        callFunction({
          url: "/getNftsSolana",
          accessToken: (getState() as any).user.accessToken,
          method: "GET"
        })
      ];

      (await Promise.allSettled(collectiblePromises))
        .filter((result) => result.status === "fulfilled")
        .map((result) => {
          return (
            result as {
              status: "fulfilled";
              value: { error: string | null; data: Collectable[] };
            }
          ).value;
        })
        .forEach((result) => {
          collectibles = [...collectibles, ...result.data];
        });

      allNfts.legit = collectibles.filter((nft) => !nft.isSpam);
      allNfts.spam = collectibles.filter((nft) => nft.isSpam);

      return allNfts;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

// Fetch user balances
export const getUserBalances = createAsyncThunk<any, any, { state: RootState }>(
  "user/getUserBalances",
  async (
    { refreshCache }: { refreshCache?: boolean },
    { rejectWithValue, getState }
  ) => {
    const oneDay = 86400000;
    const timestamp = getState().user.balancesCacheTimestamp ?? -Infinity;
    const diff = Date.now() - timestamp;
    if (!refreshCache && diff < oneDay) return null;

    const res = await getFundsAvailable(
      (getState() as any).user.accessToken,
      refreshCache
    );

    if (res.error) return rejectWithValue(res.error);
    const { total, balances }: any = res.data;

    const sumSameTokens: any = {};

    for (const balance of balances) {
      const currentSymbol = balance.tokenMetadata.symbol;
      const price = new BigNumber(balance.price);
      const amount = normalizeDecimals(
        balance.amount,
        balance.tokenMetadata.decimals
      );

      if (!sumSameTokens[currentSymbol])
        sumSameTokens[currentSymbol] = {
          ...balance,
          amount,
          price
        };
      else
        sumSameTokens[currentSymbol] = {
          ...sumSameTokens[currentSymbol],
          amount: sumSameTokens[currentSymbol].amount.plus(amount),
          price: sumSameTokens[currentSymbol].price.plus(price)
        };
    }

    const totalPrice = Number(new BigNumber(total).div(10 ** 6).toFixed(2));
    const formattedBalances: Balance[] = Object.values(sumSameTokens).map(
      (balance: any) => {
        return {
          ...balance,
          amount: balance.amount.gte(1)
            ? balance.amount.toFixed(2)
            : balance.amount.toFixed(8),
          price: balance.price.div(10 ** 6).toFixed(2)
        };
      }
    );

    // this is for p2p
    const allChains = [...new Set(balances.map((b: Balance) => b.chain))];
    const balancesByChainForP2p = [];
    for (const chain of allChains) {
      const balancesPerChain = balances.filter(
        (b: Balance) => b.chain === chain
      );
      const formatedBalances = balancesPerChain.map((b: Balance) => {
        return {
          ...b,
          amount:
            b.tokenMetadata.decimals > 12
              ? normalizeDecimals(b.amount, b.tokenMetadata.decimals).toFixed(8)
              : normalizeDecimals(b.amount, b.tokenMetadata.decimals).toFixed(
                  4
                ),
          price: (Number(b.price) / 10 ** 6).toFixed(2)
        };
      });
      balancesByChainForP2p.push({ title: chain, data: formatedBalances });
    }

    return { totalPrice, formattedBalances, balancesByChainForP2p };
  }
);

// Fetch user balances
export const getFFBalance = createAsyncThunk<any, any, { state: RootState }>(
  "user/getFFBalance",
  async (_, { getState }) => {
    try {
      const accessToken = getState().user.accessToken;
      const authToken =
        getState().user.frontFinanceAuth?.accessToken?.accountTokens[0]
          ?.accessToken;
      if (!accessToken || !authToken)
        throw new Error("No access token or authToken");
      const {
        data: { data: balancesFormatted, error }
      } = await getFrontFinanceBalance({
        accessToken,
        authToken,
        type: "coinbase"
      });
      if (error) throw new Error(error);
      return {
        balances: balancesFormatted.balances,
        total: balancesFormatted.total
      };
    } catch (error: any) {
      return {
        balances: [],
        total: 0
      };
    }
  }
);

export const setNewAccessToken = createAsyncThunk(
  "user/setNewAccessToken",
  async (accessToken: string | undefined, { rejectWithValue }) => {
    if (!accessToken) return rejectWithValue("No access token provided");
    return accessToken;
  }
);

export const getChainLogos = createAsyncThunk(
  "user/getChainLogos",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { chainLogos } = await callFunction({
        accessToken: (getState() as any).user.accessToken,
        url: "/config/chains/images",
        method: "GET"
      });
      return chainLogos;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
