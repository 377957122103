import { getWindowHeight, getWindowWidth } from "../utils/platform";

const width = getWindowWidth();
const height = getWindowHeight();

export default {
  window: {
    width,
    height
  },
  isSmallDevice: width < 375
};
