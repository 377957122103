import React, { useState } from "react";
import { View, Text } from "../../components/Themed";
import Header from "../../components/Header";
import PrimaryButton from "../../components/ThemedComponents/PrimaryButton";
import { Image, useWindowDimensions } from "react-native";
import Link from "../../components/ThemedComponents/Link";
import BellIcon from "../../assets/images/BellIcon.png";
import i18n from "../../config/languageInternationalization";
import {
  updateNotificationSettings,
  setAsyncStorageNotificationSettings,
  getCurrentNotificationsConfig
} from "../../utils/pushNotifications";
import { colorLightGrayText } from "../../utils/colors";
import { LoginStackParamList } from "../../../types/navigationTypes";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import useDisableBackNavigation from "../../hooks/useDisableBackNavigation";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setNotificationsConfig,
  setOnboardingStatus
} from "../../redux/slices/userThunk";
import { openSettings } from "expo-linking";
import Modal from "../../components/ThemedComponents/Modal";
import { isWeb, isWebViewedOnIOS, pwaMaxWidth } from "../../utils/platform";
import { Alert } from "../../utils/Alert";
import SafeAreaView from "../../components/ThemedComponents/SafeAreaView";
import { checkPinCodeStatus } from "./PinCodeSetup";
import { useToken } from "../../hooks/useNotifications";

type AskPushNotificationsScreenNavigationProp =
  NativeStackScreenProps<LoginStackParamList>;
export default function AskPushNotificationsScreen({
  navigation
}: AskPushNotificationsScreenNavigationProp) {
  const dimensions = useWindowDimensions();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useAppDispatch();
  useDisableBackNavigation({ navigation });
  const { notificationEnabled, accessToken } = useAppSelector(
    (state) => state.user
  );
  const handleModalConfirm = () => {
    setIsModalVisible(false);
  };
  async function updateNotificationPermission(val: boolean) {
    await setAsyncStorageNotificationSettings(val);
    accessToken && (await updateNotificationSettings(val, accessToken));
    dispatch(setNotificationsConfig(val));
    const hasNotificationsEnabled = await getCurrentNotificationsConfig();
    if (val && !hasNotificationsEnabled) {
      openSettings();
    }
    await checkPinCodeStatus(dispatch);
  }

  const handlePress = async () => {
    if (!isWeb()) {
      Alert.alert(
        `${i18n.t("notificationScreenStep3AlertTitleAlertStep3")}`,
        `${i18n.t("notificationScreenStep3AlertSubtitleAlertStep3")}`,
        [
          {
            text: `${i18n.t("notificationScreenStep3AlertDontAllowButton")}`,
            style: "cancel",
            onPress: () => updateNotificationPermission(false)
          },
          {
            text: `${i18n.t("notificationScreenStep3AlertAllowButton")}`,
            style: "default",
            onPress: () => updateNotificationPermission(true)
          }
        ]
      );
    } else {
      if (!isWebViewedOnIOS()) {
        const status = await Notification.requestPermission();
        if (status === "granted") {
          await updateNotificationPermission(true);
          useToken(); // If first time enabling notifications, register the token
        } else {
          setIsModalVisible(true);
        }
      }
    }
  };
  return (
    <SafeAreaView>
      <Header
        title={i18n.t("notificationScreenStep3TitleStep3")}
        styles={{ justifyContent: "center" }}
        onlyTitle
      />
      <View
        style={{
          marginTop: 30,
          alignItems: "center"
        }}
      >
        <Image style={{ width: 210, height: 175 }} source={BellIcon} />
      </View>
      <Text
        style={{
          color: colorLightGrayText,
          textAlign: "center",
          marginTop: 90,
          fontSize: 16,
          fontWeight: "400",
          marginHorizontal: 50
        }}
      >
        {i18n.t("notificationScreenStep3SubtitleStep3")}
      </Text>
      <View
        style={{
          marginHorizontal: 20,
          marginTop: 30
        }}
      >
        <PrimaryButton
          textStyle={{ fontSize: 18, fontWeight: "500" }}
          style={
            isWeb() && {
              width: pwaMaxWidth(dimensions) - 50,
              alignSelf: "center"
            }
          }
          onPress={() => handlePress()}
        >
          {i18n.t("notificationScreenStep3ButtonStep3")}
        </PrimaryButton>
      </View>
      <View
        style={{
          alignItems: "center",
          marginTop: 20
        }}
      >
        <Link
          textStyle={{ fontSize: 18, fontWeight: "500" }}
          onPress={() => {
            checkPinCodeStatus(dispatch);
          }}
        >
          {i18n.t("notificationScreenStep3SkipLink")}
        </Link>
      </View>
      <Modal
        visible={isModalVisible}
        title={i18n.t("allowNotificationsModalTitle")}
        message={i18n.t("allowNotificationsModalMsg")}
        buttonLabel={"OK"}
        onConfirm={handleModalConfirm}
      />
    </SafeAreaView>
  );
}
