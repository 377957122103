import { View, Text, TouchableOpacity } from "react-native";
import React from "react";
import Button from "../ThemedComponents/Button";
import FormControl from "../ThemedComponents/FormControl";
import { gray600, primary600 } from "../../utils/colors";
import Input from "../ThemedComponents/Input";
import { XMarkIcon } from "react-native-heroicons/solid";
import i18n from "../../config/languageInternationalization";

interface Props {
  state: State;
  loading: boolean;
  setState: (x: any) => void;
  onClose: () => void;
  setShowPincode: (x: boolean) => void;
}

interface State {
  reason: string;
  toAddress: string;
}

export default function PreTransfer({
  state,
  loading,
  setState,
  onClose,
  setShowPincode
}: Props) {
  return (
    <FormControl style={{ gap: 24 }}>
      <View className="flex-row items-center justify-between">
        <Text
          style={{ color: "white" }}
          className="text-left text-xl font-medium leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
        >
          {i18n.t("sendNftSendTitle")}
        </Text>
        <TouchableOpacity onPress={onClose}>
          <XMarkIcon size={30} color="white" />
        </TouchableOpacity>
      </View>
      <View className="items-start">
        <Text className="text-lg mb-1 text-white">{i18n.t("sendNftTo")}</Text>
        <Input
          value={state.toAddress}
          onChangeText={(e) =>
            setState({
              ...state,
              toAddress: e
            })
          }
          keyboardType="default"
        />
      </View>
      <View className="items-start">
        <Text className="text-lg mb-1 text-white">
          {i18n.t("sendNftReason")}
        </Text>
        <Input
          value={state.reason}
          onChangeText={(e) =>
            setState({
              ...state,
              reason: e
            })
          }
          keyboardType="default"
          multiline={true}
          numberOfLines={4}
        />
      </View>
      <View className="flex-row justify-between">
        <Button
          style={{
            backgroundColor: gray600,
            borderRadius: 8,
            width: "45%"
          }}
          isDisabled={loading}
          onPress={onClose}
        >
          {i18n.t("sendNftCancel")}
        </Button>
        <Button
          style={{
            backgroundColor: primary600,
            borderRadius: 8,
            width: "45%"
          }}
          onPress={() => setShowPincode(true)}
          disabled={loading}
          isLoading={loading}
        >
          {i18n.t("sendNftConfirm")}
        </Button>
      </View>
    </FormControl>
  );
}
