export const isMnemonic = (str: string) => str.includes(" ");
export const isMnemonicValid = (mnemonic: string) => {
  const MNEMONIC_WORD_COUNT = 12;
  return mnemonic.trim().split(" ").length === MNEMONIC_WORD_COUNT;
};
export const isSolPrivateKeyLength = (pk: string) => {
  const SOLANA_PRIVATEKEY_LENGTHS = [87, 88];
  return (
    SOLANA_PRIVATEKEY_LENGTHS.includes(pk.length) && pk.split(" ").length === 1
  );
};
export const isEvmPrivateKeyLength = (pk: string) => {
  const EVM_PRIVATEKEY_LENGTH = 66;
  return (
    (pk.length === EVM_PRIVATEKEY_LENGTH ||
      ("0x" + pk).length === EVM_PRIVATEKEY_LENGTH) &&
    pk.split(" ").length === 1
  );
};
export const isEvmAddress = (addr: string) => {
  if (/^0x[a-fA-F0-9]{40}$/g.test(addr)) {
    return true;
  }
};

export const isSolAddress = (addr: string) => {
  return /^[1-9A-HJ-NP-Za-km-z]{32,44}$/.test(addr);
};
