import React from "react";
import {
  Text as DefaultText,
  View as DefaultView,
  TextInput as DefaultTextInput
} from "react-native";

import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";

export function useThemeColor(
  props: { light?: string; dark?: string },
  colorName: keyof typeof Colors.light & keyof typeof Colors.dark
) {
  const theme = useColorScheme();
  const colorFromProps = props[theme];

  if (colorFromProps) {
    return colorFromProps;
  } else {
    return Colors[theme][colorName];
  }
}

type ThemeProps = {
  lightColor?: string;
  darkColor?: string;
  className?: string;
};

export type TextProps = ThemeProps & DefaultText["props"];
export type ViewProps = ThemeProps & DefaultView["props"];
export type InputProps = ThemeProps & DefaultTextInput["props"];

export function Text(props: TextProps) {
  const { style, ...otherProps } = props;

  return (
    <DefaultText
      style={[{ color: "white", fontFamily: "DMSans-Regular" }, style]}
      {...otherProps}
    />
  );
}

export function View(props: ViewProps) {
  const { style, ...otherProps } = props;

  return (
    <DefaultView
      style={[{ backgroundColor: "transparent" }, style]}
      {...otherProps}
    />
  );
}

export function TextInput(props: InputProps) {
  const { style, ...otherProps } = props;

  return (
    <DefaultTextInput
      style={[
        { backgroundColor: "transparent", fontFamily: "DMSans-Regular" },
        style
      ]}
      {...otherProps}
    />
  );
}
