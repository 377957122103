import React from "react";
import { Balance } from "../../../../types/balances";
import { useAppSelector } from "../../../redux/hooks";
import {
  colorBackgroundTransparent,
  gray700,
  gray800
} from "../../../utils/colors";
import { callFunction } from "../../../utils/server";
import InsertPinCode from "../../InsertPinCode";
import { View } from "../../Themed";
import SendStep from "./SendStep";
import SuccessStep from "./SuccessStep";

interface SendTransferModalProps {
  token: Balance;
  onClose: () => void;
}

enum TransferStep {
  SEND,
  SUCCESS
}

export interface State {
  toAddress: string;
  tokenAmount: string;
  reason: string;
}

const SendTransferModal: React.FC<SendTransferModalProps> = ({
  onClose,
  token
}: SendTransferModalProps): React.ReactElement => {
  const { accessToken } = useAppSelector((state) => state.user);
  const [step, setStep] = React.useState<TransferStep>(TransferStep.SEND);
  const [showPinCode, setShowPinCode] = React.useState<boolean>(false);
  const [txId, setTxId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState<State>({
    toAddress: "",
    tokenAmount: "",
    reason: ""
  });

  const callback = async (dek: string) => {
    await callFunction({
      url: "/pay",
      accessToken: accessToken as string,
      data: { transactionId: txId, wrappedDek: dek },
      method: "POST"
    });
    setStep(TransferStep.SUCCESS);
  };

  return (
    <>
      <View
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 1000,
          backgroundColor: colorBackgroundTransparent,
          justifyContent: "center"
        }}
      >
        <View className={"m-6 text-center w-max"}>
          <View
            className="w-max"
            style={{
              backgroundColor: gray800,
              borderRadius: 8,
              borderWidth: 1,
              borderColor: gray700,
              padding: 32,
              display: "flex"
            }}
          >
            {step === TransferStep.SEND ? (
              <SendStep
                onClose={onClose}
                state={state}
                setState={setState}
                token={token}
                setShowPincode={setShowPinCode}
                loading={loading}
                setTxId={setTxId}
                setLoading={setLoading}
              />
            ) : (
              <SuccessStep
                token={token}
                toAddress={state.toAddress}
                tokenAmount={state.tokenAmount}
                onClose={onClose}
                txId={txId}
              />
            )}
          </View>
        </View>
      </View>
      {showPinCode && (
        <InsertPinCode
          cb={callback}
          target={txId}
          closeModal={() => setShowPinCode(false)}
        />
      )}
    </>
  );
};

export default SendTransferModal;
