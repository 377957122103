import queryString from "query-string";
import { OnrampProviders, WertData } from "../../types/onRamp";
import { callFunction } from "./server";
import { SupportedChains, SupportedChainsManager } from "./supportedChains";
import { AppDispatch } from "../redux/store";
import { checkoutActions } from "../redux/slices/checkoutOnramp";
import {
  cleanCheckoutAction,
  cleanCheckoutTxIdFromStorage,
  cleanCheckoutWallet
} from "./localStorage";
import { isWeb } from "./platform";
import { TxStatus } from "../../types/transfer";
import i18n from "../config/languageInternationalization";
import { SphereToken } from "../redux/slices/tokens";

export const isWert = (provider: OnrampProviders | null | undefined) => {
  return provider === OnrampProviders.WERT;
};

export const isSphereOneMethod = (
  provider: OnrampProviders | null | undefined
) => {
  return provider === OnrampProviders.SPHEREONE;
};
export function onrampGetProviderChainLabel(
  chain: SupportedChains,
  providerName: OnrampProviders
) {
  const isBanxa = providerName === OnrampProviders.BANXA;
  const chainManager = new SupportedChainsManager(chain);
  const providerChain = isBanxa ? chainManager.code() : chainManager.name();
  if (!providerChain) {
    throw new Error(`Unsupported chain: ${chain}`);
  }
  return providerChain;
}

export const getOrderIdFromResponse = (
  provider: OnrampProviders | undefined,
  order: string,
  wertData: WertData | null
): string | null => {
  if (!provider) return null;
  const params = queryString.parse(order);
  // each provider has a different way to get the order id
  switch (provider) {
    case OnrampProviders.BANXA:
      return params.oid as string;
    case OnrampProviders.ONRAMPER:
      return params.partnerContext as string;
    case OnrampProviders.SARDINE:
      return params.reference_id as string;
    case OnrampProviders.WERT:
      return wertData?.click_id ?? null;
    default:
      return null;
  }
};

export const constructPaymentMethodsUrl = async (
  token: string | null,
  currency: string,
  countryCode: string,
  chain: string | null,
  txId: string | null
) => {
  const params = {
    fiat: currency,
    countryCode,
    chain,
    tokenSymbol: token,
    txId
  };
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([_, v]) => v !== null)
  );
  return `/onramps/getPaymentMethods?${queryString.stringify(filteredParams)}`;
};

export const handleGoBack = async (
  txId: string | null,
  navigation: any,
  dispatch: AppDispatch
) => {
  await cleanCheckoutTxIdFromStorage();
  await cleanCheckoutWallet();
  await cleanCheckoutAction();
  dispatch(checkoutActions.cleanCheckoutTxId());
  dispatch(checkoutActions.cleanCheckoutWallet());

  if (txId) {
    isWeb() ? window.location.reload() : navigation.goBack();
  }
  if (navigation.canGoBack()) {
    navigation.goBack();
  }
};

export const handleOnrampTransaction = async (
  method: "POST" | "PUT",
  data: {
    order_id: string | null;
    symbol?: string;
    total?: string | null;
    totalUsd?: string | null;
    onrampFromToken?: SphereToken;
    toAddress?: string;
    tokenAddress?: string;
    chain?: SupportedChains;
    status?: TxStatus;
  },
  accessToken: string | undefined,
  wrappedDek: string,
  txId?: string
) => {
  const { data: id } = await callFunction({
    url: `/onramp-transactions${txId ? `/${txId}` : ""}`,
    accessToken: accessToken,
    method,
    data
  });

  callFunction({
    url: "/pay",
    data: { transactionId: id, wrappedDek },
    method: "POST",
    accessToken: accessToken
  });
};

export const payErrorMessage = (error: any) => {
  if (
    error?.error?.code === "insufficient-balances" ||
    error?.error?.code === "empty-balances" ||
    error?.error?.message.includes("Insufficient native balance") ||
    error?.data?.onrampLink
  ) {
    return i18n.t("checkoutNotEnoughFundsMessage");
  } else if (error?.error === '"exp" claim timestamp check failed') {
    return i18n.t("checkoutAuthTokenExpiredErrorMessage");
  } else if (error?.error?.message.includes("minimum amount")) {
    return i18n.t("checkoutMinimumAmountErrorMessage");
  } else return i18n.t("checkoutTransactionErrorMessage");
};
