import React, { useEffect, useRef, useState } from "react";
import { AppState, ColorSchemeName } from "react-native";

import {
  NavigationContainer,
  DarkTheme,
  useNavigationContainerRef,
  NavigationContainerRefWithCurrent
} from "@react-navigation/native";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  getTokenPrices,
  getUserInfo,
  loadOnboardingStatus,
  getUserBalances,
  getLinkedAccounts,
  setNewAccessToken,
  getChainLogos
} from "../redux/slices/userThunk";
import { setSupportBiometric, setUserCredentials } from "../redux/slices/user";
import { fetchMinimumAppVersion } from "../redux/slices/forceUpdateThunk";

import LinkingConfiguration from "./LinkingConfiguration";
import { handleAppStateChange } from "./handleAppStateChange";
import {
  cleanCheckoutTxIdFromStorage,
  cleanCheckoutWallet,
  getAuthCode,
  getBiometricPreference,
  getOnrampPreferences,
  getCheckoutAction,
  getCheckoutTxIdFromStorage,
  getCheckoutWallet,
  getUserOIDC,
  hasTouchIdOrFaceIdConfigured,
  setUserOIDC,
  cleanOnrampPreferences
} from "../utils/localStorage";
import { isWeb } from "../utils/platform";
import { handleBackgroundNotifications } from "../utils/pushNotifications";
import { analyticReport } from "../utils/firebase";

import { useDynamicLinks } from "../hooks/useDynamicLinks";
import { useForegroundNotifications } from "../hooks/useNotifications";

import { LoginNavigator } from "./LoginNavigator";
import { RootNavigator } from "./RootNavigator";

import SplashScreen from "../screens/SplashScreen";
import LockScreen from "../screens/LockScreen";
import ForceUpdate from "../screens/ForceUpdate";
import { loginFromAuthCode, useCustomAuth } from "../hooks/useAuth";
import { nearBlack } from "../utils/colors";
import { SplashNavigator } from "./SplashNavigator";
import { recordError } from "../utils/crashlytics";
import { isTokenExpired } from "../utils/verifyJWT";
import AddEmailScreen from "../screens/Profile/AddEmail";
import { RootStackParamList } from "../../types";
import axios from "axios";
import { refresh } from "react-native-app-auth";
import { authDefaultConfig } from "../hooks/useAuthContextApp";
import AddPinCodeScreen from "../screens/AddPinCodeScreen";
import { CheckoutNavigator } from "./CheckoutNavigator.";
import { CHECKOUT_URL } from "@env";
import RecoveryFlowScreen from "../screens/RecoveryFlowScreen";
import { checkoutActions } from "../redux/slices/checkoutOnramp";
import { callFunction } from "../utils/server";
import { fetchSphereOneTokens } from "../redux/slices/tokensThunk";
import { SupportedChains } from "../utils/supportedChains";
import { SphereToken } from "../redux/slices/tokens";
export default function Navigation({
  colorScheme
}: {
  colorScheme: ColorSchemeName;
}) {
  const dispatch = useAppDispatch();

  const displaySplash = useAppSelector((state) => state.splash.display);
  const {
    isAskingPermissions,
    isOnboard,
    accessToken,
    uid,
    email,
    isPinCodeSetup,
    showRecoveryFlowModal
  } = useAppSelector((state) => state.user);
  const accessTokenFromRedux = accessToken;
  const { needsUpdate, loading: needsUpdateLoading } = useAppSelector(
    (state) => state.forceUpdate
  );
  const canSupportBiometric = useAppSelector(
    (state) => state.user.supportBiometric
  );
  const [hasValidated, setHasValidated] = useState(false);
  const [promptValidation, setPromptValidation] = useState(true);

  const routeNameRef = useRef<string>();
  const navigationRef: NavigationContainerRefWithCurrent<RootStackParamList> =
    useNavigationContainerRef();

  const { user, logout, signinSilent } = useCustomAuth();

  const getUserData = async () => {
    try {
      if (user !== undefined) {
        // means that there is no user, because user === null. This could be because there is really no user after a refresh token or because there is no user in the storage
        // here we should check that if there is no user in the storage, we should logout but not have an infinite loop
        // we should add an extra "property" that is there only when the user logins
        let accessToken = !isTokenExpired(accessTokenFromRedux)
          ? accessTokenFromRedux
          : user?.access_token;

        const authCode = await getAuthCode();
        if (authCode) {
          // first login the user if there is an authCode
          const accessTokenFromAuthCode = await loginFromAuthCode(
            accessToken,
            user?.refresh_token,
            isOnboard,
            dispatch
          );
          accessToken = accessTokenFromAuthCode;
        }

        if (!user && uid) {
          return await logout(false);
        }

        // in this case we should only shows the login screen and nothing else, just return and do nothing
        if (!user && !uid) return;
        // if the token is expired, we should logout the user
        if (isTokenExpired(accessToken)) {
          // if there is no refresh token but the token is nor expired, nothing should be done, just use that access token
          if (!user?.refresh_token && !authCode) {
            await logout(false);
          } else {
            // do refresh logic
            const newUser = await signinSilent();
            if (!newUser) return await logout(false);
            return;
          }
        }
        if (accessToken && !isTokenExpired(accessToken)) {
          await dispatch(
            getUserInfo({
              authUser: user,
              accessToken
            })
          );
          dispatch(
            setUserCredentials({
              accessToken: accessToken,
              refreshToken: user?.refresh_token,
              idToken: user?.id_token
            })
          );
          dispatch(getLinkedAccounts());
          dispatch(getUserBalances({}));
          dispatch(fetchMinimumAppVersion());
          dispatch(getChainLogos());
        }
      }
    } catch (e) {
      recordError(e, "Navigation.tsx");
    }
  };

  const { refreshToken } = useAppSelector((state) => state.user);
  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error?.response?.status === 401) {
          if (isWeb()) {
            if (!refreshToken) {
              logout(false);
              window.location.reload();
            }
          } else {
            return await getUserOIDC()
              .then(async (oidc) => {
                if (!oidc) {
                  throw new Error("No user stored");
                } else {
                  const user = JSON.parse(oidc);
                  if (!user.refresh_token) {
                    throw new Error("No refresh token stored");
                  } else {
                    const newUser = await refresh(authDefaultConfig, {
                      refreshToken: user.refresh_token
                    });
                    if (!newUser) {
                      throw new Error("No new user");
                    } else {
                      const newRefreshToken = newUser.refreshToken;
                      if (newRefreshToken !== null) {
                        const newUserDataToSave = {
                          ...newUser,
                          access_token: newUser.accessToken,
                          id_token: newUser.idToken,
                          refresh_token: newRefreshToken // this is to avoid typescript errors
                        };
                        await setUserOIDC(newUserDataToSave);
                        await dispatch(setNewAccessToken(newUser.accessToken));
                        error.config.headers["Authorization"] =
                          "Bearer " + newUser.accessToken;
                        return axios.request(error.config);
                      } else
                        throw new Error(
                          "Error refreshing the token. No access token in the user refreshed"
                        );
                    }
                  }
                }
              })
              .catch(async (err: any) => {
                logout(false);
                return Promise.reject(error);
              });
          }
        }
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    // the user can be an object, null or undefined
    // if user is undefined it means it is still loading the user
    getUserData();
  }, [user, email]);

  // Update user state redux
  useEffect(() => {
    dispatch(loadOnboardingStatus());
    dispatch(getTokenPrices());
    dispatch(fetchSphereOneTokens());

    (async () => {
      // Only show LockScreen IF and ONLY IF user has configured
      // touchID/fingerprint data AND they enabled it.
      const deviceResponse = await hasTouchIdOrFaceIdConfigured();
      const biometricEnabled = await getBiometricPreference();
      dispatch(setSupportBiometric(deviceResponse && biometricEnabled));
    })();
  }, []);

  // Handle dynamic links
  useDynamicLinks({ navigation: navigationRef });

  // Handle foreground notifications
  useForegroundNotifications({ navigation: navigationRef });

  // Handle background notifications
  handleBackgroundNotifications({ navigation: navigationRef, accessToken });

  useEffect(() => {
    // while app is currently active, add eventListener
    const subscription = AppState.addEventListener("change", (nextAppState) =>
      handleAppStateChange({
        nextAppState,
        isAskingPermissions,
        dispatch,
        setPromptValidation,
        setHasValidated
      })
    );

    return () => {
      subscription.remove();
    };
  }, [isAskingPermissions]);

  const editedDarkTheme = {
    ...DarkTheme,
    colors: {
      ...DarkTheme.colors,
      background: nearBlack
    }
  };

  const { txId, isCheckoutSite } = useAppSelector(
    (state) => state.checkoutOnramp
  );
  const { sphereTokens } = useAppSelector((state) => state.tokens);
  useEffect(() => {
    async function checkoutNavigation() {
      const checkoutTxId = await getCheckoutTxIdFromStorage();
      const onrampPreferences = await getOnrampPreferences();

      if (checkoutTxId) {
        // Clean checkout wallet and load tx
        await cleanOnrampPreferences();
        dispatch(checkoutActions.cleanCheckoutWallet());
        dispatch(checkoutActions.setCheckoutTxId(checkoutTxId.txId));
      }
      if (onrampPreferences && sphereTokens) {
        await cleanCheckoutTxIdFromStorage();
        const token =
          sphereTokens[onrampPreferences.chain as SupportedChains]?.find(
            (t: SphereToken) => t.symbol === onrampPreferences.token
          ) || null;
        dispatch(
          checkoutActions.setOnrampPreferences({
            ...onrampPreferences,
            token
          })
        );
        dispatch(checkoutActions.cleanCheckoutTxId());
      }

      if (
        isWeb() &&
        (window.location.origin === CHECKOUT_URL || checkoutTxId)
      ) {
        dispatch(checkoutActions.setIsCheckoutSite(true));
      }

      // TODO
      // decide if we want to erase this information after the first load in the redux or keep it in the storage
      // in case the user reloads the app
      // return await cleanLinkOnrampFromStorage();
    }
    checkoutNavigation();
  }, [sphereTokens]);

  const renderNavigator = () => {
    if (showRecoveryFlowModal) {
      return <RecoveryFlowScreen />;
    }
    // the user is not logged in or it has no finished the onboarding
    if (user && uid && isPinCodeSetup === false) {
      return <AddPinCodeScreen />;
    } else if (txId || isCheckoutSite) {
      return <CheckoutNavigator />;
    } else if (user && uid && !email && !isOnboard) {
      return <AddEmailScreen />;
    } else if (user === null || (user && uid && !isOnboard)) {
      // there is a user logged in but there is no email in the document in DB
      return <LoginNavigator />;
    }
    // the user is logged in, has an email in the DB and has finished the onboarding
    // else if (user && email && isOnboard) {
    else if (user && isOnboard) {
      return (
        <>
          {!isWeb() &&
            hasValidated === false &&
            canSupportBiometric &&
            promptValidation &&
            !needsUpdate &&
            !needsUpdateLoading && (
              <LockScreen setHasValidated={setHasValidated} />
            )}
          <RootNavigator />
        </>
      );
    }
    // while loading the user (user = undefined) show the splash screen
    else return <SplashNavigator />;
  };

  return (
    <NavigationContainer
      ref={navigationRef}
      onReady={() => {
        routeNameRef.current = navigationRef.getCurrentRoute()?.name;
      }}
      onStateChange={async () => {
        const previousRoute = routeNameRef.current;
        const currentRoute = navigationRef.getCurrentRoute()?.name;
        const trackScreenView = async (screen: any) => {
          if (!isWeb()) {
            await analyticReport.logScreenView({
              screen_name: screen,
              screen_class: screen
            });
          }
        };
        if (previousRoute !== currentRoute) {
          routeNameRef.current = currentRoute;
          await trackScreenView(currentRoute);
        }
      }}
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? editedDarkTheme : editedDarkTheme}
    >
      {!isWeb() && needsUpdate && <ForceUpdate />}
      {!isWeb() && displaySplash && <SplashScreen />}
      {renderNavigator()}
    </NavigationContainer>
  );
}
