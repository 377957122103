import React from "react";
import {
  colorBackgroundSecondaryButton,
  colorTextSecondaryButton
} from "../../utils/colors";
import Button, { ButtonProps } from "./Button";

export default function SecondaryButton(props: ButtonProps) {
  const { style, textStyle, ...otherProps } = props;

  return (
    <Button
      className="bg-gray-800"
      rounded
      style={[style]}
      textStyle={[
        {
          color: colorTextSecondaryButton,
          fontWeight: "700",
          fontSize: 18
        },
        textStyle
      ]}
      {...otherProps}
    />
  );
}
