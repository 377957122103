import { createAsyncThunk } from "@reduxjs/toolkit";
import { recordError } from "../../utils/crashlytics";
import { getDocumentData } from "../../utils/server";
import { Alert } from "../../utils/Alert";
import i18n from "../../config/languageInternationalization";

export const getUserPaymentData = createAsyncThunk(
  "userPaymentData/getUserPaymentData",
  async (email: string, { getState }) => {
    try {
      const { data: userPaymentDataDoc } = await getDocumentData({
        url: `/user/${email}/payment-data`,
        accessToken: (getState() as any).user.accessToken
      });

      if (!userPaymentDataDoc)
        Alert.alert(i18n.t("transferScreenEmailNotFound"));

      return userPaymentDataDoc;
    } catch (error) {
      recordError(error, "userPaymenDataThunk.ts");
      throw error;
    }
  }
);
