import { createAsyncThunk } from "@reduxjs/toolkit";
import VersionCheck from "react-native-version-check-expo";
import { compareVersions } from "compare-versions";
import { isAndroid } from "../../utils/platform";
import { getDocumentData } from "../../utils/server";

export const fetchMinimumAppVersion = createAsyncThunk(
  "forceUpdate/fetchMinimumAppVersion",
  async (_, { getState }) => {
    const currentVersion = VersionCheck.getCurrentVersion();
    const { data: updateConfig } = await getDocumentData({
      url: "/config/minimumAppVersion",
      accessToken: (getState() as any).user.accessToken
    });

    const android = updateConfig?.android ? updateConfig?.android : "1.0.0";
    const ios = updateConfig?.ios ? updateConfig?.ios : "1.0.0";

    // NOTE: if currentVersion is same or greater (0 or 1), no need to update
    // e.g of 0: compareVersions("1.0.0", "1.0.0") <-- same version
    // e.g of 1: compareVersions("1.0.1", "1.0.0") <-- this will force-downgrade
    // NOTE: if currentVersion is smaller (-1), will need to force update (This is what we want!)
    // e.g of -1: compareVersions("1.0.0", "1.0.1") <-- need to force-upgrade
    const needsUpdate = compareVersions(
      currentVersion,
      isAndroid() ? android : ios
    );
    return {
      ios,
      android,
      needsUpdate: needsUpdate === -1
    };
  }
);
