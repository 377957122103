import React from "react";
import { ScrollView } from "react-native-gesture-handler";
import Header from "../../components/Header";
import { Text, View } from "../../components/Themed";
import { termsAndConditions } from "../../config/TermsAndConditions";
import { colorBackground, gray700, gray800 } from "../../utils/colors";
import i18n from "../../config/languageInternationalization";
import SafeAreaView from "../../components/ThemedComponents/SafeAreaView";
import { isWeb } from "../../utils/platform";

export default function TermsAndConditionsScreens() {
  return (
    <SafeAreaView
      style={
        isWeb()
          ? {
              flex: 1,
              marginVertical: 20,
              borderRadius: 16,
              backgroundColor: gray800,
              borderColor: gray700,
              borderWidth: 1
            }
          : { backgroundColor: colorBackground, flex: 1 }
      }
    >
      <Header title={i18n.t("termsAndConditionsScreenTermsAndConditions")} />
      <View
        style={{
          flex: 1,
          alignItems: "center",
          marginBottom: isWeb() ? 20 : undefined
        }}
      >
        <ScrollView
          contentContainerStyle={{ marginHorizontal: 20 }}
          showsVerticalScrollIndicator={false}
        >
          <Text style={{ fontSize: 18 }}>{termsAndConditions}</Text>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
}
