import React from "react";
import { useWindowDimensions } from "react-native";
import { OnrampGenericResult } from "../../../types/onRamp";
import { Dropdown } from "react-native-element-dropdown";
import { flowbiteBorderColor, gray700 } from "../../utils/colors";
import { SphereToken } from "../../redux/slices/tokens";
export const BuyCryptoDropdown = ({
  data,
  value,
  onChange,
  placeholder,
  width
}: {
  data: OnrampGenericResult[] | undefined;
  value: string | undefined;
  onChange: (item: OnrampGenericResult) => void;
  placeholder: string;
  width: string;
}) => {
  const { width: windowWidth } = useWindowDimensions();
  return (
    <Dropdown
      search={true}
      searchField="value"
      dropdownPosition="auto"
      style={{
        backgroundColor: gray700,
        width,
        height: 36,
        borderRadius: 8,
        borderColor: flowbiteBorderColor,
        borderWidth: 1,
        alignItems: "stretch",
        justifyContent: "space-between"
      }}
      containerStyle={{
        backgroundColor: gray700,
        borderRadius: 8,
        borderColor: flowbiteBorderColor
      }}
      itemTextStyle={{
        color: "#f9fafb"
      }}
      selectedTextStyle={{
        color: "#f9fafb",
        marginHorizontal: 10,
        fontSize: 14
      }}
      placeholderStyle={{
        color: "#f9fafb",
        fontSize: 14,
        marginHorizontal: 10
      }}
      inputSearchStyle={{
        color: "#f9fafb",
        fontSize: windowWidth > 1160 ? 18 : 10,
        borderRadius: 8,
        paddingHorizontal: 10
      }}
      activeColor={"#1d4fd8"}
      data={data ?? ([{ value: "", label: "" }] as any)}
      value={value}
      labelField="label"
      valueField="value"
      onChange={(item: OnrampGenericResult) => {
        onChange(item);
      }}
      placeholder={placeholder}
    />
  );
};
