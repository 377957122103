import { createSlice } from "@reduxjs/toolkit";
import { SupportedChains } from "../../utils/supportedChains";
import { fetchSphereOneTokens, fetchTokensList } from "./tokensThunk";

export interface Token {
  address: string;
  symbol: string;
  name: string;
  decimals: number;
  logoURI: string;
  tags: string[];
  tokenType: "ERC20" | "SPL";
}

export interface SphereToken {
  address: string;
  symbol: string;
  name: string;
  decimals: number;
  logoURI: string;
  chain: SupportedChains;
}

export type SphereTokens = {
  [key in SupportedChains]: Array<SphereToken>;
};

interface TokenState {
  loading: boolean;
  popular: Token[];
  allProviders: Token[];
  inchProvider: Token[];
  inchProviderPol: Token[];
  jupiterProvider: Token[];
  sphereTokens: SphereTokens;
}

const initialState: TokenState = {
  loading: false,
  popular: [],
  allProviders: [],
  inchProvider: [],
  inchProviderPol: [],
  jupiterProvider: [],
  sphereTokens: Object.create(null)
};

const tokensSlice = createSlice({
  name: "tokens",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTokensList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchTokensList.fulfilled, (state, { payload }) => {
      state.popular = payload.popularTokens;
      state.inchProvider = payload.inchTokens.tokens;
      state.inchProviderPol = payload.polygonTokens.tokens;
      state.jupiterProvider = payload.jupiterTokens.tokens;
      state.allProviders = [
        ...payload.inchTokens.tokens,
        ...payload.jupiterTokens.tokens,
        ...payload.polygonTokens.tokens
      ];
      state.loading = false;
    });
    builder.addCase(fetchTokensList.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchSphereOneTokens.fulfilled, (state, action) => {
      state.sphereTokens = action.payload;
    });
  }
});

export default tokensSlice.reducer;
