import React, { useEffect, useState } from "react";
import {
  Alert,
  FlatList,
  Image,
  TouchableOpacity,
  useWindowDimensions
} from "react-native";
import { Text, View } from "../Themed";
import CollectableCard from "./CollectableCard";
import { useAppSelector } from "../../redux/hooks";
import { Collectable } from "../../redux/slices/user";
import images from "../../assets/images";
import { colorLightGrayText, gray400 } from "../../utils/colors";
import i18n from "../../config/languageInternationalization";
import { isWeb } from "../../utils/platform";
import { ScrollView } from "react-native-gesture-handler";
import Loading from "../Loading";
import { SupportedChains } from "../../utils/supportedChains";
import SendNftModal from "../NftModal/SendNftModal";
import {
  ChevronDownIcon,
  ChevronRightIcon
} from "react-native-heroicons/solid";

export default function CollectableTab({ loading }: { loading: boolean }) {
  const [showSpam, setShowSpam] = useState(false);
  const [alertDisplayed, setAlertDisplayed] = useState(false);
  const [modal, setModal] = useState(false);
  const [nft, setNft] = useState({
    tokenId: "",
    chain: "",
    nftTokenAddress: "",
    fromAddress: "",
    name: ""
  });
  const { collectables, spamCollectables } = useAppSelector(
    (state) => state.user
  );

  // Ref: https://devfabi.com/react-native-dynamic-flatlist
  const calcNumColumns = (width: number) => {
    if (isWeb()) {
      const minCols = 1;
      const cols = width / 300;
      const colsFloor = Math.floor(cols) > minCols ? Math.floor(cols) : minCols;
      const colsMinusMargin = cols - 2 * colsFloor * 6;
      if (colsMinusMargin < colsFloor && colsFloor > minCols)
        return colsFloor - 1;
      else return colsFloor;
    } else {
      return 2;
    }
  };

  const { width } = useWindowDimensions();
  const [numColumns, setNumColumns] = useState<number>(calcNumColumns(width));

  useEffect(() => {
    setNumColumns(calcNumColumns(width));
  }, [width]);

  useEffect(() => {
    if (modal) return; // this is to avoid multiple modal being opened
  }, [nft]);

  useEffect(() => {
    return () => {
      setAlertDisplayed(false);
    };
  }, []);

  const emptyCollectible: Collectable = {
    name: "",
    address: "",
    img: "",
    tokenType: "",
    selected: false
  };

  const formatData = (data: Collectable[], numColumns: number) => {
    const dataList = [...data];
    if (dataList.length <= numColumns) return dataList;
    const amountFullRows = Math.floor(data.length / numColumns);
    let amountItemsLastRow = data.length - amountFullRows * numColumns;

    while (amountItemsLastRow !== numColumns && amountItemsLastRow !== 0) {
      dataList.push(emptyCollectible);
      amountItemsLastRow++;
    }
    return dataList;
  };

  if (loading)
    return (
      <View
        style={{
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center"
        }}
      >
        <Loading />
      </View>
    );

  const WebCollectibleList = () => {
    return (
      <>
        {modal && (
          <SendNftModal
            onClose={() => setModal(false)}
            tokenId={nft.tokenId}
            chain={nft.chain as SupportedChains}
            nftTokenAddress={nft.nftTokenAddress as string}
            fromAddress={nft.fromAddress as string}
            nftName={nft.name}
          />
        )}

        <ScrollView style={{ height: "70vh" }}>
          <FlatList
            nestedScrollEnabled={true}
            contentContainerStyle={{
              alignItems: "center",
              justifyContent: "center",
              position: "relative"
            }}
            data={formatData(collectables, numColumns)}
            showsVerticalScrollIndicator={false}
            key={numColumns}
            numColumns={numColumns}
            renderItem={({ item }: { item: Collectable }) => (
              <View style={{ marginBottom: 12, marginHorizontal: 10 }}>
                <CollectableCard
                  name={item.name}
                  image={item.img}
                  chain={item.chain as SupportedChains}
                  tokenId={item.tokenId || ""}
                  nftTokenAddress={item.address}
                  fromAddress={item.walletAddress as string}
                  nft={nft}
                  setNft={setNft}
                  setModal={setModal}
                />
              </View>
            )}
            ListEmptyComponent={
              <View style={{ alignItems: "center" }}>
                <Image
                  source={images.Search}
                  style={{
                    height: 250,
                    width: 250
                  }}
                />
                <Text
                  style={{
                    marginHorizontal: 16,
                    marginVertical: 12,
                    fontSize: 18,
                    color: colorLightGrayText
                  }}
                >
                  {i18n.t("homeScreenHaventAssets")}
                </Text>
              </View>
            }
          />

          {spamCollectables.length > 0 && (
            <>
              <TouchableOpacity
                onPress={() => {
                  if (!alertDisplayed) {
                    const accept = window.confirm(i18n.t("spamNftsWarning"));

                    if (accept) {
                      setShowSpam(!showSpam);
                      setAlertDisplayed(true);
                    } else return;
                  }

                  setShowSpam(!showSpam);
                }}
                style={{
                  alignItems: "center",
                  justifyContent: "space-around",
                  flexDirection: "row",
                  marginTop: 15,
                  marginBottom: 10
                }}
              >
                <Text className="font-medium text-base">Spam</Text>
                <Text className="font-medium text-base">
                  {showSpam ? (
                    <ChevronDownIcon size={20} color={gray400} />
                  ) : (
                    <ChevronRightIcon size={20} color={gray400} />
                  )}
                </Text>
              </TouchableOpacity>
              {showSpam && (
                <FlatList
                  nestedScrollEnabled={true}
                  contentContainerStyle={{
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative"
                  }}
                  data={formatData(spamCollectables, numColumns)}
                  showsVerticalScrollIndicator={false}
                  key={numColumns}
                  numColumns={numColumns}
                  renderItem={({ item }: { item: Collectable }) => (
                    <View
                      style={{
                        marginBottom: 12,
                        marginHorizontal: 10,
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <CollectableCard
                        name={item.name}
                        image={item.img}
                        chain={item.chain as SupportedChains}
                        tokenId={item.tokenId || ""}
                        nftTokenAddress={item.address}
                        fromAddress={item.walletAddress as string}
                        nft={nft}
                        setNft={setNft}
                        setModal={setModal}
                      />
                    </View>
                  )}
                />
              )}
            </>
          )}
        </ScrollView>
      </>
    );
  };

  const MobileCollectibleList = () => {
    return (
      <ScrollView nestedScrollEnabled={true}>
        <FlatList
          nestedScrollEnabled={true}
          contentContainerStyle={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center"
          }}
          data={formatData(collectables, numColumns)}
          showsVerticalScrollIndicator={true}
          key={numColumns}
          numColumns={numColumns}
          renderItem={({ item }: { item: Collectable }) => (
            <View style={{ marginBottom: 12, marginHorizontal: 10 }}>
              <CollectableCard
                name={item.name}
                image={item.img}
                chain={item.chain as SupportedChains}
                tokenId={item.tokenId || ""}
                nftTokenAddress={item.address}
                fromAddress={item.walletAddress as string}
              />
            </View>
          )}
          ListEmptyComponent={
            <View style={{ alignItems: "center" }}>
              <Image
                source={images.Search}
                style={{
                  height: 250,
                  width: 250
                }}
              />
              <Text
                style={{
                  marginHorizontal: 16,
                  marginVertical: 12,
                  fontSize: 18,
                  color: colorLightGrayText
                }}
              >
                {i18n.t("homeScreenHaventAssets")}
              </Text>
            </View>
          }
        />
        {spamCollectables.length > 0 && (
          <>
            <View
              style={{
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
                marginTop: 15,
                marginBottom: 10
              }}
            >
              <Text className="font-medium text-base">Spam</Text>
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  justifyContent: "center"
                }}
                onPress={() => {
                  if (!showSpam) {
                    Alert.alert(
                      i18n.t("spamNftsTitleWarning"),
                      i18n.t("spamNftsWarning"),
                      [
                        {
                          text: "Cancel",
                          onPress: () => {
                            return;
                          },
                          style: "cancel"
                        },
                        {
                          text: "Accept",
                          onPress: () => {
                            setShowSpam(!showSpam);
                            setAlertDisplayed(true);
                          }
                        }
                      ],
                      { cancelable: false }
                    );
                  } else setShowSpam(false);
                }}
              >
                {showSpam ? (
                  <ChevronDownIcon size={20} color={gray400} />
                ) : (
                  <ChevronRightIcon size={20} color={gray400} />
                )}
              </TouchableOpacity>
            </View>
            {showSpam && (
              <FlatList
                nestedScrollEnabled={true}
                contentContainerStyle={{
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative"
                }}
                data={formatData(spamCollectables, numColumns)}
                showsVerticalScrollIndicator={false}
                key={numColumns}
                numColumns={numColumns}
                renderItem={({ item }: { item: Collectable }) => (
                  <View
                    style={{
                      marginBottom: 12,
                      marginHorizontal: 10,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <CollectableCard
                      name={item.name}
                      image={item.img}
                      chain={item.chain as SupportedChains}
                      tokenId={item.tokenId || ""}
                      nftTokenAddress={item.address}
                      fromAddress={item.walletAddress as string}
                      nft={nft}
                      setNft={setNft}
                      setModal={setModal}
                    />
                  </View>
                )}
              />
            )}
          </>
        )}
      </ScrollView>
    );
  };

  return isWeb() ? <WebCollectibleList /> : <MobileCollectibleList />;
}
