import React, { useEffect } from "react";
import { Text, View } from "../components/Themed";
import SafeAreaView from "../components/ThemedComponents/SafeAreaView";
import { colorBackground, colorLightGrayText } from "../utils/colors";
import Button from "../components/ThemedComponents/Button";
import { Image, TouchableOpacity, useWindowDimensions } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import WertWidget from "@wert-io/widget-initializer";
import { pwaMaxWidth } from "../utils/platform";
import axios from "axios";
import { REACT_APP_BASE_URL } from "@env";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useNavigation } from "@react-navigation/native";
import { setNftCheckoutId } from "../redux/slices/user";
import i18n from "../config/languageInternationalization";
import { TransactionType } from "../../types/transfer";

interface NftCharge {
  type: TransactionType;
  merchantId: string;
  address: string;
  commodity: string;
  commodityAmount: number;
  network: "mumbai";
  scAddress: string;
  scInputData: string;
  signature: string;
  partnerId: string;
  origin: string;
  itemInfo: {
    author?: string;
    imageUrl?: string;
    name?: string;
    seller?: string;
    description?: string;
  };
}

export default function NftCheckout() {
  const navigation = useNavigation();
  const dimensions = useWindowDimensions();
  const [isLoading, setIsLoading] = React.useState(false);
  const [nftCharge, setNftCharge] = React.useState<NftCharge | null>(null);
  const nftCheckoutNavigation = useAppSelector(
    (state) => state.user.nftCheckoutId
  );
  const dispatch = useAppDispatch();

  const buyNft = async () => {
    try {
      setIsLoading(true);
      if (!nftCharge) return;

      const wertWidget = new WertWidget({
        address: nftCharge.address,
        commodity: nftCharge.commodity,
        commodity_amount: nftCharge.commodityAmount,
        origin: nftCharge.origin,
        partner_id: nftCharge.partnerId,
        click_id: nftCheckoutNavigation,
        network: nftCharge.network,
        sc_address: nftCharge.scAddress,
        sc_input_data: nftCharge.scInputData,
        signature: nftCharge.signature,
        extra: {
          item_info: {
            author: nftCharge.itemInfo.author,
            image_url: nftCharge.itemInfo.imageUrl,
            name: nftCharge.itemInfo.name,
            seller: nftCharge.itemInfo.seller
          }
        },
        listeners: {
          loaded: () => setIsLoading(false),
          close: () => {
            dispatch(setNftCheckoutId(null));
            navigation.goBack();
          }
        }
      });

      wertWidget.mount();
    } catch (e) {
      setIsLoading(false);
      alert(i18n.t("sorrySomethingWentWrong"));
    }
  };

  useEffect(() => {
    const getNftCharge = async () => {
      try {
        const nftCharge = await axios.get(
          `${REACT_APP_BASE_URL}/nft-checkout/${nftCheckoutNavigation}`
        );
        setNftCharge(nftCharge.data.data);
      } catch (e) {
        alert(i18n.t("sorrySomethingWentWrong"));
        navigation.goBack();
      }
    };

    getNftCharge();
  }, []);

  if (!nftCharge) return null;
  return (
    <View style={{ backgroundColor: colorBackground, flex: 1 }}>
      <SafeAreaView>
        <ScrollView
          style={{
            paddingHorizontal: 20,
            paddingBottom: 32,
            paddingTop: 32,
            backgroundColor: colorBackground
          }}
        >
          <TouchableOpacity
            style={{ marginBottom: 16 }}
            onPress={() => {
              dispatch(setNftCheckoutId(null));
              navigation.goBack();
            }}
          >
            <Text
              style={{
                textDecorationLine: "underline",
                color: colorLightGrayText
              }}
            >
              {i18n.t("goBack")}
            </Text>
          </TouchableOpacity>

          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Image
              source={{
                uri: nftCharge?.itemInfo.imageUrl
              }}
              style={{
                width: pwaMaxWidth(dimensions),
                height: 400,
                borderRadius: 16
              }}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              marginTop: 20,
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Text style={{ fontSize: 36 }}>{nftCharge.itemInfo.name}</Text>
            <Text style={{ fontSize: 14 }}>
              {nftCharge.commodityAmount} {nftCharge.commodity}
            </Text>
          </View>
          {nftCharge.itemInfo.description && (
            <Text style={{ textAlign: "justify", marginTop: 16 }}>
              {nftCharge.itemInfo.description}
            </Text>
          )}
          <Button
            isLoading={isLoading}
            onPress={buyNft}
            style={{ marginTop: 24 }}
            rounded
          >
            {i18n.t("buy")}
          </Button>
        </ScrollView>
      </SafeAreaView>
    </View>
  );
}
