import React, { useEffect } from "react";
import {
  Image,
  ImageSourcePropType,
  StyleProp,
  ViewStyle,
  ImageURISource,
  ImageStyle
} from "react-native";
import { colorProfileBackground } from "../utils/colors";
import { Text, View, ViewProps } from "./Themed";

interface AvatarProps extends ViewProps {
  nameOrEmail: string | undefined;
  style?: StyleProp<ViewStyle>;
  imageStyle?: StyleProp<ImageStyle>;
  source?: ImageSourcePropType | ImageURISource | null;
  size: number;
}

export default function Avatar({
  nameOrEmail,
  style,
  imageStyle,
  source,
  size,
  ...otherProps
}: AvatarProps) {
  if (source)
    return (
      <View
        style={[
          {
            height: size,
            width: size,
            overflow: "hidden",
            borderRadius: size
          },
          style
        ]}
        {...otherProps}
      >
        <Image
          source={source}
          height={size}
          width={size}
          style={[
            { height: size, width: size, borderRadius: size },
            imageStyle
          ]}
        />
      </View>
    );
  else
    return (
      <View
        style={{
          height: size,
          width: size,
          overflow: "hidden"
        }}
      >
        <View
          style={[
            {
              height: size,
              width: size,
              backgroundColor: colorProfileBackground,
              borderRadius: size,
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden"
            },
            style
          ]}
          {...otherProps}
        >
          <Text
            style={{
              color: "black",
              fontSize: size * 0.3,
              fontWeight: "bold"
            }}
          >
            {nameOrEmail
              ? nameOrEmail
                  .split(" ")
                  .map((str) => str[0]?.toUpperCase())
                  .join("")
              : ""}
          </Text>
        </View>
      </View>
    );
}
