import React from "react";
import {
  CheckCircleIcon,
  ClockIcon,
  XMarkIcon
} from "react-native-heroicons/outline";
import { Balance } from "../../../../types/balances";
import i18n from "../../../config/languageInternationalization";
import { checkMarkGreen, primary600, red300 } from "../../../utils/colors";
import { View, Text } from "../../Themed";
import Button from "../../ThemedComponents/Button";
import { useTransactionStatusInterval } from "../../../hooks/useOnRamp";
import { TxStatus } from "../../../../types/transfer";

interface SuccessStepProps {
  token: Balance;
  tokenAmount: string;
  toAddress: string;
  onClose: () => void;
  txId: string;
}

const SuccessStep: React.FC<SuccessStepProps> = ({
  token,
  toAddress,
  tokenAmount,
  onClose,
  txId
}: SuccessStepProps): React.ReactElement => {
  const [paymentStatus, setPaymentStatus] = React.useState(TxStatus.PROCESSING);

  useTransactionStatusInterval(txId, (res) => {
    setPaymentStatus(res.data.data.status);
  });

  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <View>
        {paymentStatus === TxStatus.PROCESSING ? (
          <ClockIcon size={100} color={checkMarkGreen} />
        ) : paymentStatus === TxStatus.SUCCESS ? (
          <CheckCircleIcon size={100} color={checkMarkGreen} />
        ) : (
          <XMarkIcon width={24} height={24} color={red300} strokeWidth={2} />
        )}
      </View>
      <View
        style={{
          marginTop: 25,
          marginStart: 80,
          marginEnd: 80,
          flex: 1,
          alignItems: "center"
        }}
      >
        <Text
          style={{
            fontSize: 25,
            textAlign: "center",
            width: "75%"
          }}
        >
          {paymentStatus === TxStatus.PROCESSING
            ? i18n.t("processingTxTitle")
            : paymentStatus === TxStatus.SUCCESS
            ? i18n.t("successTxTitle")
            : i18n.t("failedTxTitle")}
        </Text>
        <Text
          style={{
            fontSize: 15,
            color: "lightgray",
            textAlign: "center",
            marginTop: 25
          }}
        >
          {paymentStatus === TxStatus.PROCESSING
            ? i18n.t("processingTxSubtitle")
            : paymentStatus === TxStatus.SUCCESS
            ? i18n.t("successTxTitle")
            : i18n.t("failedTxSubtitle")}
        </Text>
      </View>
      <Button
        onPress={onClose}
        style={{
          backgroundColor: primary600,
          borderRadius: 8,
          width: "45%",
          marginTop: 5
        }}
      >
        {i18n.t("ok")}
      </Button>
    </View>
  );
};

export default SuccessStep;
