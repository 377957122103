import visaIcon from "../../assets/images/visa.png";
import masterCardIcon from "../../assets/images/mastercard.png";
import moneygramIcon from "../../assets/images/moneygram.png";
import React from "react";
import { Image } from "react-native";

export const CardsIcons = () => {
  return (
    <>
      <Image
        style={{ height: 12, width: 20, marginBottom: 7 }}
        source={visaIcon}
        resizeMode="contain"
      />
      <Image
        style={{ height: 13, width: 22 }}
        source={masterCardIcon}
        resizeMode="contain"
      />
    </>
  );
};

export const MoneyGramIcon = () => {
  return (
    <Image
      style={{ height: 30, width: 30 }}
      source={moneygramIcon}
      resizeMode="contain"
    />
  );
};
