import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SupportedChains } from "../../utils/supportedChains";
import { DisplayPaymentMethod } from "../../utils/validatePaymentMethods";
import {
  AmountError,
  CustomOnrampToken,
  OnrampPreferences,
  WertData
} from "../../../types/onRamp";
import { SphereToken } from "./tokens";

export enum BuyStatus {
  SUCCESS = "success",
  ERROR = "error",
  PINCODE = "pincode"
}

export enum RouteStatus {
  SUCCESS = "success",
  ERROR = "error",
  PENDING = "pending"
}

export interface CheckoutOnrampState {
  txId: string | null;
  chain: SupportedChains | undefined;
  token: SphereToken | undefined;
  tokenAmount: string | undefined;
  countryFlag: string | null;
  countryCode: string | null;
  fiat: string | null;
  paymentMethod: DisplayPaymentMethod | null;
  amountError: AmountError | null;
  validAmount: boolean;
  onrampAmount: string | null;
  tokenToOnramp: CustomOnrampToken | null;
  onrampUrl: string | null;
  showWert: WertData | null;
  buyStatus: BuyStatus | null;
  buyStatusMessage: string | null;
  buyLoading: boolean;
  isCheckoutSite: boolean;
  wrappedDek: string | undefined;
  isChargeStatusWaiting: boolean;
  routeStatus: RouteStatus;
  onrampPreferencesChainAndToken: boolean;
  customOnrampTxId: string | null;
  walletAddress: string | null;
  walletLabel: string | null;
  customOnrampSurplus: number | null;
}

const initialState: CheckoutOnrampState = {
  txId: null,
  chain: undefined,
  tokenAmount: undefined,
  token: undefined,
  countryFlag: null,
  countryCode: null,
  fiat: null,
  paymentMethod: null,
  amountError: null,
  validAmount: true,
  onrampAmount: null,
  tokenToOnramp: null,
  onrampUrl: null,
  showWert: null,
  buyStatus: null,
  buyStatusMessage: null,
  buyLoading: false,
  isCheckoutSite: false,
  wrappedDek: undefined,
  isChargeStatusWaiting: false,
  routeStatus: RouteStatus.PENDING,
  onrampPreferencesChainAndToken: false,
  customOnrampTxId: null,
  walletAddress: null,
  walletLabel: null,
  customOnrampSurplus: null
};

const checkoutOnrampSlice = createSlice({
  name: "checkoutOnramp",
  initialState,
  reducers: {
    setCheckoutTxId: (state, { payload }) => {
      state.txId = payload;
    },
    setChain: (state, action: PayloadAction<SupportedChains | undefined>) => {
      state.chain = action.payload;
    },
    setToken: (state, action: PayloadAction<SphereToken | undefined>) => {
      state.token = action.payload;
    },
    setTokenAmount: (state, action: PayloadAction<string | undefined>) => {
      state.tokenAmount = action.payload;
    },
    onBuyCryptoDropdownChangeCleanup: (state) => {
      state.tokenAmount = "";
      state.tokenToOnramp = null;
      state.onrampAmount = null;
      state.paymentMethod = null;
      state.customOnrampTxId = null;
    },
    onPaymentMethodChangeCleanup: (state) => {
      state.onrampAmount = null;
      state.tokenToOnramp = null;
      state.amountError = null;
    },
    setCountryData: (
      state,
      action: PayloadAction<{
        fiat: string | null;
        countryCode: string | null;
        countryFlag: string | null;
      }>
    ) => {
      state.fiat = action.payload.fiat;
      state.countryCode = action.payload.countryCode;
      state.countryFlag = action.payload.countryFlag;
    },
    onCountryDataChangeCleanup: (state) => {
      state.showWert = null;
      state.onrampUrl = null;
      state.paymentMethod = null;
    },
    setPaymentMethod: (
      state,
      action: PayloadAction<DisplayPaymentMethod | null>
    ) => {
      state.paymentMethod = action.payload;
    },
    setValidAmount: (state, action: PayloadAction<boolean>) => {
      state.validAmount = action.payload;
    },
    setOnrampAmount: (state, action: PayloadAction<string | null>) => {
      state.onrampAmount = action.payload;
    },
    setCustomOnrampDetails: (
      state,
      action: PayloadAction<{
        tokenToOnramp: CustomOnrampToken;
        customOnrampSurplus: number;
      }>
    ) => {
      state.tokenToOnramp = action.payload.tokenToOnramp;
      state.customOnrampSurplus = action.payload.customOnrampSurplus;
    },
    setOnrampPaymentDetailsOnChargeFetch: (
      state,
      action: PayloadAction<{
        token: SphereToken | undefined;
        chain: SupportedChains | undefined;
        tokenAmount: string | undefined;
      }>
    ) => {
      state.token = action.payload.token;
      state.chain = action.payload.chain;
      state.tokenAmount = action.payload.tokenAmount;
    },
    setOnrampPreferences: (state, action: PayloadAction<OnrampPreferences>) => {
      const chain = action.payload.chain;
      const token = action.payload.token;
      const isChainAndToken = chain && token;
      if (isChainAndToken) {
        // only set token and chain if both are present
        state.token = token;
        state.chain = chain;
        state.onrampPreferencesChainAndToken = true;
      }
      state.walletAddress = action.payload.wallet;
      state.walletLabel = action.payload.walletLabel;
    },
    setQuoteOnrampDetails: (
      state,
      action: PayloadAction<{
        validAmount: boolean;
        amountError: AmountError | null;
        onrampAmount: string;
      }>
    ) => {
      state.validAmount = action.payload.validAmount;
      state.amountError = action.payload.amountError;
      state.onrampAmount = action.payload.onrampAmount;
    },
    setAmountError: (state, action: PayloadAction<AmountError>) => {
      state.validAmount = false;
      state.amountError = action.payload;
    },
    setOnrampUrl: (state, action: PayloadAction<string | null>) => {
      state.onrampUrl = action.payload;
      state.isChargeStatusWaiting = true;
    },
    setShowWert: (state, action: PayloadAction<WertData | null>) => {
      state.showWert = action.payload;
      state.isChargeStatusWaiting = true;
    },
    setBuyStatusDetails: (
      state,
      action: PayloadAction<{
        buyStatus?: BuyStatus | null;
        buyStatusMessage?: string | null;
      }>
    ) => {
      if (action.payload.buyStatus !== undefined) {
        state.buyStatus = action.payload.buyStatus;
      }
      if (action.payload.buyStatusMessage !== undefined) {
        state.buyStatusMessage = action.payload.buyStatusMessage;
      }
    },
    setBuyLoading: (state, action: PayloadAction<boolean>) => {
      state.buyLoading = action.payload;
    },
    onBuyViewGoBackCleanup: (state) => {
      state.buyStatus = null;
      state.buyStatusMessage = null;
      state.buyLoading = false;
      state.showWert = null;
      state.onrampUrl = null;
    },
    setIsCheckoutSite: (state, action: PayloadAction<boolean>) => {
      state.isCheckoutSite = action.payload;
    },
    setWrappedDek: (state, action: PayloadAction<string | undefined>) => {
      state.wrappedDek = action.payload;
    },
    setIsChargeStatusWaiting: (state, action: PayloadAction<boolean>) => {
      state.isChargeStatusWaiting = action.payload;
    },
    setRouteStatus: (state, action: PayloadAction<RouteStatus>) => {
      state.routeStatus = action.payload;
    },
    setCheckoutOnrampState: (
      state,
      action: PayloadAction<Partial<CheckoutOnrampState>>
    ) => {
      return { ...state, ...action.payload };
    },
    setCheckoutWallet: (
      state,
      action: PayloadAction<{
        address: string | null;
        label: string | null;
      }>
    ) => {
      state.walletAddress = action.payload.address;
      state.walletLabel = action.payload.label;
    },
    cleanCheckoutWallet: (state) => {
      state.walletAddress = null;
      state.walletLabel = null;
    },
    cleanCheckoutTxId: (state) => {
      state.txId = null;
      state.customOnrampTxId = null;
    },
    setCustomOnrampTxId: (state, action) => {
      state.customOnrampTxId = action.payload;
    }
  }
});

export const checkoutActions = checkoutOnrampSlice.actions;
export default checkoutOnrampSlice.reducer;
